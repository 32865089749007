import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import NoDataFound from "components/NoDataFound";
import nftbanner from "images/nft-bnr-1.jpg";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import CollectionCard from "../components/CollectionCard";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { useStoreSettingsSelector } from "_common/hooks/selectors/storeSelector";

export interface PageSearchProps {
	className?: string;
}

const PageCollectionNormalize = () => {

	const collectionApi = useCollectionApi();
	const productApi = useProductApi();
	const commonApi = useCommonApi();
	const storeSettings = useStoreSettingsSelector();

	const { showLoader, hideLoader } = useAppLoader();

	const limit: number = 12;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [collections, setCollections] = useState<any>(null);

	const [isOpen, setIsOpen] = React.useState(false);
	const [ddd, setDd] = React.useState(false);
	const [filter, setFilter] = useState<any>({});
	const [blockchainFilter, setBlockchainFilter] = useState<any>(null);
	const [categories, setCategories] = useState<any>(null);
	const [selectedCategory, setSelectedCategory] = useState<any>(null);
	const [callingData, setCallingData] = useState<boolean>(false);

	const sortBy = [
		{ name: "Featured", id: "isFeatured" },
		{ name: "Reedamable", id: "isReedamable" }
	];


	const collectionsContainer: any = useRef();

	const isBottom = (el: any) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	const trackScrolling_ = () => {
		if (!callingData) {
			setDd(true)
			if (collectionsContainer.current) {
				if (isBottom(collectionsContainer.current)) {
					if (currentPage < totalPage) {
						setCurrentPage(currentPage + 1);
					} else {
						//document.removeEventListener('scroll', trackScrolling, true);
					}
				}
			} else {
				console.log('Trakc Scroll : #ERROR : No Element Found!')
			}
		}

	};

	const addTrackScrollEvent = () => {
		function trackScrolling() {
			if (!callingData) {
				setDd(true)
				if (collectionsContainer.current) {
					if (isBottom(collectionsContainer.current)) {
						document.removeEventListener('scroll', trackScrolling, true);
						console.log('Trakc Scroll : Bottom Reached.', currentPage, totalPage);
						if (currentPage < totalPage) {
							setCurrentPage(currentPage + 1);
						}
					}
				} else {
					//console.log('Trakc Scroll : #ERROR : No Element Found!')
				}
			}

		};

		document.addEventListener('scroll', trackScrolling, true);

	}


	const getCollections = () => {
		if (currentPage > 1 && currentPage > totalPage && collections != null) {
			console.log('noMore Data');
			return;
		}

		if (currentPage > 1 && currentPage > totalPage && totalPage == 1) {
			console.log('wait for prev Data');
			return;
		}
		console.log('Get Collection =>', currentPage, totalPage, collections)
		setCallingData(true)
		//let listeners:any = getEventListeners(document);
		//console.log(listeners)
		//console.log('PRE REMOVE LISTNER ----------------------------------------------------------------')
		//document.removeEventListener('scroll', trackScrolling, true);
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage,
			searchText: searchKey,
			isActive: 1,
			isPublished: 1,
			isDeleted: 0
		}

		switch (filter?.radioBtnFiltrations?.sortBy) {
			case 'name:asc':
				params.sortingBy = 'collectionName';
				params.sortingOrder = 'asc';
				break;
			case 'name:desc':
				params.sortingBy = 'collectionName';
				params.sortingOrder = 'desc';
				break;
			default:
		}

		if (filter?.radioBtnFiltrations?.BlockChain) {
			blockchainFilter && blockchainFilter.length > 0 &&
				blockchainFilter.forEach((item: any) => {
					if (item.id == filter?.radioBtnFiltrations?.BlockChain) {
						params.blockchainNetworkID = item.id;
						return
					}
				})
		}

		if (selectedCategory) {
			params['categoryID'] = selectedCategory;
		}
		// console.log('params => ', params);

		showLoader();
		collectionApi.getCollections(params, (message: string, resp: any) => {
			hideLoader();
			if (collections) {
				setCollections([...collections, ...resp.collections]);
			} else {
				setCollections(resp.collections);
			}
			setTotalPage(Math.ceil(resp.totalRecord / limit));
			setCallingData(false)
		}, (message: string) => {
			hideLoader();
			console.log(message);
			setCallingData(false)
		})
	}

	const getCategories = () => {
		let params: any = {
			isActive: 1,
			usePaging: false,
			sortingBy: 'categoryName',
			sortingOrder: 'asc',
			parentCategoryID: 0,
			isDeleted: 0
		}
		productApi.getCategories(params, (message: string, resp: any) => {
			if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('categorydm') && resp.categorydm.length > 0) {
				resp.categorydm.unshift({
					categoryID: '',
					categoryName: 'All'
				})
				setCategories(resp.categorydm);
			}
		}, (message: string) => {
			console.log(message);
		});
	}

	const getblockChainNetwork = () => {
		commonApi.getBlockchainNetworks({
			isActive: 1,
			isDeleted: 0
		},
			(message: string, resp: any) => {
				let list: any = []
				resp.networks.forEach((item: any) => {
					let tempObj = { name: `${item.chainName}`, id: `${item.networkID}` }
					list.push(tempObj)
				})
				setBlockchainFilter(list)
			}, (message: string) => {
				console.log(message)
			})
	}

	useEffect(() => {
		if (collections) {
			if (currentPage == 1) {
				setTotalPage(1);
				setCollections(null);
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter])

	useEffect(() => {
		if (collections) {
			if (currentPage == 1) {
				setTotalPage(1);
				setCollections(null);
			} else {
				setCurrentPage(1);
			}
		}

	}, [searchKey])

	useEffect(() => {
		if (currentPage == 1) {
			setTotalPage(1);
			setCollections(null);
		} else {
			setCurrentPage(1);
		}
	}, [selectedCategory])

	useEffect(() => {
		if (currentPage == 1) {
			setTotalPage(1);
			setCollections(null)
		} else {
			getCollections();
		}

	}, [currentPage]);

	useEffect(() => {
		if (collections) {
			addTrackScrollEvent();

		} else {
			getCollections();
		}
	}, [collections])

	useEffect(() => {
		getCategories();
		getblockChainNetwork();
	}, []);

	// useEffect(() => { console.log({ blockchainFilter }) }, [blockchainFilter])

	return (
		<React.Fragment>
			<div className={`nc-PageSearch`} data-nc-id="PageSearch">
				<Helmet>
					<title>Collections</title>
					<meta name="title" content={storeSettings.settings?.SEO?.Metatags?.TitleMetatag?.appValue} />
					<meta name="description" content={storeSettings.settings?.SEO?.Metatags?.DescriptionMetatag?.appValue} />
					<meta name="keywords" content={storeSettings.settings?.SEO?.Metatags?.KeywordMetatag?.appValue} />
				</Helmet>

				<PageHeaderWithSearch title="Collections" onSearch={setSearchKey} searchKey={searchKey} banner={nftbanner} />


				<div className="container-full py-16 lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28 pl-10 pr-10">
					<main>
						{/* FILTER */}
						<HeaderFilterSearchPage tabnavdata={categories} blockchainFilter={blockchainFilter}
							onChangeCategory={(CategoryID: any) => {
								setSelectedCategory(CategoryID);
							}}
							onApplyFilter={(data) => {
								setFilter(data);
							}}
						/>


						{/* LOOP ITEMS */}
						<div ref={collectionsContainer} className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-8 lg:mt-10 custom-xs-col-2">
							{collections?.map((collection: any, index: any) => (
								<CollectionCard data={collection} key={index} />
							))}
						</div>
						{collections?.length == 0 && (<NoDataFound />)}




						{currentPage < totalPage && collections?.length != 0 && (
							<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
								<ButtonPrimary loading>Show me more</ButtonPrimary>
							</div>
						)}
					</main>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PageCollectionNormalize;