import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import Slider from "rc-slider";
import ButtonSubmit from "./ButtonSubmit";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import Radio from "shared/Radio/Radio";
import { FiLink } from 'react-icons/fi';

export interface PriceRangeInputProps {
  onChange?: (data: any) => void;
  fieldClassName?: string;
  setChainInputValue: any;
  chainInputValue: any;
}

const PriceRangeInput: FC<PriceRangeInputProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  setChainInputValue,
  chainInputValue,
}) => {
  const [rangePrices, setRangePrices] = useState([0.01, 10]);
  const commonApi = useCommonApi()
  const blockchainNetworks = useBlockchainNetworksSelector();


  const [filterChain, setFilterChain] = useState<any>([]);

  const getBlockchainNetworks = () => {
    commonApi.getBlockchainNetworks({},
      (message: string, resp: any) => { },
      (message: string, resp: any) => {
        console.log(message, resp)
      }
    )
  }

  useEffect(() => {
    if (blockchainNetworks) {
      let options: any = [];
      blockchainNetworks.map((col: any, i: any) => { options.push({ name: col.chainLabel, id: col.networkID },) });
      setFilterChain(options)
    } else {
      getBlockchainNetworks();
    }
  }, [blockchainNetworks])

  return (
    <Popover className="flex relative flex-1">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex text-left flex-1 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
              }`}
            // PHẦN LOCATION-INPUT KHÔNG BẮT ĐƯỢC EVEN-CLICK KHI CLICK VÀO NÚT NÀY, NÊN CẦN CHẠY HACK NÀY
            onClick={() => {
              if (window.innerWidth >= 1024) {
                document.querySelector("html")?.click();
              }
            }}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <FiLink className="h-2/4 w-6" />
            </div>
            <div className="flex-grow">
              {chainInputValue && filterChain && chainInputValue.length > 0 ? filterChain.map((item: any, index: number) => (
                <span className="block xl:text-lg font-semibold min-w-[130px]" key={index}>
                  {item.id == chainInputValue ? item.name : ""}
                </span>
              ))
                :
                <>
                  <span className="block xl:text-lg font-semibold">Blockchain</span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                    Type of blockchain
                  </span>
                </>
              }
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="">
                <div className="relative flex flex-col space-y-5 cstm-tab-filter-5 cstm-scrollbar">
                  {filterChain && filterChain.map((item: any, index: number) => (
                    <div key={index} className="">
                      <Radio
                        id={item.id}
                        name="radioNameSort"
                        label={item.name}
                        defaultChecked={chainInputValue === item.id}
                        onChange={(e) => { setChainInputValue(e) }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
    //   <div className="sm:pr-1 md:pr-2 xl:pr-4 flex items-center">
    //   <ButtonSubmit />
    // </div>
  );
};

export default PriceRangeInput;
