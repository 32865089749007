import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ButtonPrimary, { ButtonPrimaryProps } from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";

// export interface FollowButtonProps extends ButtonPrimaryProps {
//   isFollowing?: boolean;
// }
export interface NewFollowButtonProps {
  isAuth?: boolean;
  id?: string;
  type?: number;
  followerCount?: number;
  callBackFn?: any;

  isFollowing?: boolean;
  className?: string;
  sizeClass?: string;
  fontSize?: string;
}

const FollowButton: FC<NewFollowButtonProps> = ({
  className,
  id,
  type,
  isAuth,
  followerCount = 0,
  callBackFn,
}) => {

  const [following, setFollowing] = useState(false);

  const collectionApi = useCollectionApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any = useUserSelector()

  const getCollectionFollowUnFollow = () => {
    showLoader();
    collectionApi.getCollectionfollowunfollow({
      itemType: type,
      itemID: id,
      addedBy: (isAuth) ? user?.userID : null,
      authrequired: isAuth
    }, (message: string, resp: any) => {
      hideLoader();
      console.log(resp);
      if (resp?.followUnfollows && resp?.followUnfollows.length > 0) {
        setFollowing(resp.followUnfollows[0]?.followDetails[0]?.isFollow)
      }
    }, (message: string) => {
      hideLoader();
      console.log(message);
    })
  }

  const applyFollowUnfollow = (isFollow: number) => {
    // console.log(isFollow);
    showLoader();
    collectionApi.saveFollow({
      "itemType": type,
      "itemID": id,
      "isFollow": isFollow
    }, (message: string, resp: any) => {
      toast.success(message)
      hideLoader();
      getCollectionFollowUnFollow();
      callBackFn();
    }, (message: string) => {
      hideLoader();
      console.log(message);
    });
  }

  const handleClickFollow = () => {
    console.log("called follow")
    if (!following) {
      if (id) {
        console.log("called follow")
        applyFollowUnfollow(1)
        setFollowing(!following)
      }
    } else {
      if (id) {
        console.log("called unfollow")
        applyFollowUnfollow(0)
        setFollowing(!following)
      }
    }
  }

  useEffect(() => {
    if (id) {
      getCollectionFollowUnFollow()
    }
  }, [id])


  return (
    <React.Fragment>
      {isAuth ?
        following ?
          <ButtonSecondary
            className={`relative z-10 whitespace-nowrap`}
            sizeClass={`px-4 py-1.5 min-w-[84px]`}
            fontSize={`text-sm font-medium`}
            onClick={isAuth ? handleClickFollow : () => toast.error('please login to unfollow')}
          >
            <span className="text-sm ">Unfollow (<strong>{followerCount}</strong> <span className="font-light">followers</span>)</span>
          </ButtonSecondary>
          :
          <ButtonPrimary
            className={`relative z-10 whitespace-nowrap`}
            sizeClass={`px-4 py-1.5 min-w-[84px]`}
            fontSize={`text-sm font-medium`}
            onClick={isAuth ? handleClickFollow : () => toast.error('please login to follow')}
          >
            <span className="text-sm ">Follow (<strong>{followerCount}</strong> <span className="font-light">followers</span>)</span>
          </ButtonPrimary>
        :
        <ButtonSecondary
          className={`relative z-10 whitespace-nowrap`}
          sizeClass={`px-4 py-1.5 min-w-[84px]`}
          fontSize={`text-sm font-medium`}
          onClick={isAuth ? handleClickFollow : () => toast.error('please login to follow')}
        >
          <span className="text-sm ">(<strong>{followerCount}</strong>) <span className="font-light">Followers</span></span>
        </ButtonSecondary>
      }
    </React.Fragment>
  )
};

export default FollowButton;
