import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useCmsApi() {

  const callApi = useApiCall()

  const getCmsList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CMS.GET_LIST, data, onSuccess, onError);
  }

  const deleteCms = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CMS.DELETE_CMS, data, onSuccess, onError);
  }

  const addCms = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CMS.ADD_CMS, data, onSuccess, onError);
  }

  const editCms = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CMS.EDIT_CMS, data, onSuccess, onError);
  }

  return {
    getCmsList: getCmsList,
    deleteCms: deleteCms,
    addCms: addCms,
    editCms: editCms,
  }
}