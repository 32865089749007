import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as rdd from 'react-device-detect';
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import QRCode from "react-qr-code";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import VerifyIcon from "components/VerifyIcon";
import Details from "./Details";
import LikeButton from "../../components/LikeButton";
import FavoriteButton from "../../components/FavoriteButton";
import AccordionInfo from "./AccordionInfo";
import ProductFileThumb from "./ProductFileThumb";
import ProductFile from "./ProductFile";
import NoUserImage from "images/avatars/Image-1.png";
import NoImage from "images/placeholder-small.png";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useParams, Link, useSearchParams } from "react-router-dom";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";
import { useAuthStatus } from "_common/hooks/authHook";
import NftMoreDropdown from "components/NftMoreDropdown";
import { API_BASE_URL, STORAGE } from "_config";
import { ITEM_TYPE_1 } from "../../../../_common/_constant";
import { useCartSelector } from "_common/hooks/selectors/cartSelector";
import { toast } from "react-hot-toast";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useSiteSettingsSelector } from "_common/hooks/selectors/commonSelector";
import { HiOutlineEyeOff } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";
import ViewCount from "themes/Normalize/components/ViewCount";
import NcModal from "shared/NcModal/NcModal";
import AccordionDetails from "./AccordionDetails";
import ActivitiesHistory from "./ActivitiesHistory";
import UnlockableContent from "./UnlockableContent";
import UnlockableContentModal from "../../components/Nft/UnlockableContentModal";
import NcCustomModal from "components/Modal/NcCustomModal";
import TransferNft from '../../components/Nft/TransferNft'
import useMetaMask, { getLibrary } from "../../../../_common/provider/metamask-provider";
import axios from 'axios';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SwitchNetwork from "themes/Normalize/components/Crypto/SwitchNetwork";
import ConnectWallet from "themes/Normalize/components/Crypto/ConnectWallet";

const browserObject = {
	browserName: rdd['browserName'],
	browserVersion: rdd['browserVersion'],
	deviceType: rdd['deviceType'],
	engineName: rdd['engineName'],
	engineVersion: rdd['engineVersion'],
	fullBrowserVersion: rdd['fullBrowserVersion'],
	getUA: rdd['getUA'],
	isAndroid: rdd['isAndroid'],
	isBrowser: rdd['isBrowser'],
	isChrome: rdd['isChrome'],
	isChromium: rdd['isChromium'],
	isConsole: rdd['isConsole'],
	isDesktop: rdd['isDesktop'],
	isEdge: rdd['isEdge'],
	isEdgeChromium: rdd['isEdgeChromium'],
	isElectron: rdd['isElectron'],
	isEmbedded: rdd['isEmbedded'],
	isFirefox: rdd['isFirefox'],
	isIE: rdd['isIE'],
	isIOS: rdd['isIOS'],
	isIOS13: rdd['isIOS13'],
	isIPad13: rdd['isIPad13'],
	isIPhone13: rdd['isIPhone13'],
	isIPod13: rdd['isIPod13'],
	isLegacyEdge: rdd['isLegacyEdge'],
	isMIUI: rdd['isMIUI'],
	isMacOs: rdd['isMacOs'],
	isMobile: rdd['isMobile'],
	isMobileOnly: rdd['isMobileOnly'],
	isMobileSafari: rdd['isMobileSafari'],
	isOpera: rdd['isOpera'],
	isSafari: rdd['isSafari'],
	isSamsungBrowser: rdd['isSamsungBrowser'],
	isSmartTV: rdd['isSmartTV'],
	isTablet: rdd['isTablet'],
	isWearable: rdd['isWearable'],
	isWinPhone: rdd['isWinPhone'],
	isWindows: rdd['isWindows'],
	isYandex: rdd['isYandex'],
	mobileModel: rdd['mobileModel'],
	mobileVendor: rdd['mobileVendor'],
	osName: rdd['osName'],
	osVersion: rdd['osVersion']
}

const NftDetailPage: any = () => {

	const { connect, disconnect, isLoading, isActive, chainId, account, addNetwork, switchNetwork, isConnected, library } = useMetaMask()
  	const { ethereum }: any = window;

	const isAuth = useAuthStatus();
	const cart: any = useCartSelector();
	const settings: any = useSiteSettingsSelector();

	const { id } = useParams();	
	const [queryParams, setQueryParams] = useSearchParams()
	const productApi = useProductApi();
	const commonApi = useCommonApi();
	const userApi = useUserApi();
	const collectionApi = useCollectionApi();
	const { showLoader, hideLoader } = useAppLoader();


	const [product, setProduct] = useState<any>(null);
	const [cartProduct, setCartProduct] = useState<any>(null);
	const [inCart, setInCart] = useState<boolean>(cart.filter((item: any) => item.productID == id).length > 0);
	const [moreoptions, setMoreoptions] = useState<any>([]);
	const [isfollowed, setIsfollowed] = React.useState(false);
	const [followdata, setFollowdata] = useState<any>(null);
	const [totalFollowers, setTotalFollowers] = useState<any>(0);
	const [followerCount, setFollowerCount] = useState<number>(0);
	const [favouriteCount, setFavouriteCount] = useState<number>(0);
	const [likeCount, setLikeCount] = useState<number>(0);
	const [createdBy, setCreatedBy] = useState<any>(null);
	const [collection, setCollection] = useState<any>(null);
	const [productfiles, setProductFiles] = useState<any[] | null>(null);
	const [selectedProductFile, setSelectedProductFile] = useState<any>(null);
	const [showBlurFile, setShowBlurFile] = useState<boolean>(false)
	const [showSuspiciousMsg, setShowSuspiciousMsg] = useState<boolean>(false);
	const [viewCount, setViewCount] = useState<number>(0);
	const [owners, setOwners] = useState<any>(null);
	const [show, setShow] = useState<boolean>(false);
	const [isCreatorOrOwner, setIsCreatorOrOwner] = useState<boolean>(false)
	const [isNftOwner, setIsNftOwner] = useState<boolean>(false)

	const [modalContent, setModalContent] = useState<any>('')
	const [isShowModal, setIsShowModal] = useState(false);
	const [modalTitle, setModalTitle] = useState<string>('');



	const user: any = useSelector((state: StateExtended) => state.user);

	const getSiteSettings = () => {
		commonApi.getSiteSettings({}, (message: any, resp: any) => { }, (message: any) => { })
	}

	const getCollectionFollowUnFollow = () => {
		showLoader();
		collectionApi.getCollectionfollowunfollow({
			itemType: ITEM_TYPE_1.NFT,
			itemID: id,
			addedBy: (isAuth) ? user.user.userID : null,
			authrequired: isAuth
		}, (message: string, resp: any) => {
			hideLoader();
			if (resp.code === 200 && resp.successful === true && resp.followUnfollows.length > 0) {
				setIsfollowed(true);
				setFollowdata(resp.followUnfollows[0]);
				setTotalFollowers(resp.totalRecord);
				console.log({ updateMoreOption: resp })
				if (isAuth) {
					setMoreoptions([{
						id: "unfollow",
						name: `(${followerCount} followers) Unfollow`,
						icon: "las la-times-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: `(${followerCount}) Follower(s)`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			} else {
				setIsfollowed(false);
				setFollowdata(null);
				setTotalFollowers(0);
				if (isAuth) {
					setMoreoptions([{
						id: "follow",
						name: `(${followerCount} followers) Follow`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}])
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: `(${followerCount}) Follower(s)`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			}
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const addView = () => {
		commonApi.addView({
			"itemType": ITEM_TYPE_1.NFT,
			"itemID": id,
			"walletAddress": isAuth && user?.user ? user.user.custodialWalletAddress : '',
			"browserObject": JSON.stringify(browserObject)
		}, (message: any, resp: any) => { }, (message: any) => { })
	}

	const getProduct = () => {
		let userToken = false;
		if (localStorage.getItem(STORAGE)) {
			let user = JSON.parse(localStorage.getItem(STORAGE) || '');
    		userToken = user.responseToken ? true : false;
		}

		showLoader();
		productApi.getMyProducts({ 
			nftid: id,
			isguest: userToken
			// isguest: queryParams.get('guest') == '1'
		}, (message: string, resp: any) => {
			hideLoader();
			setProduct(resp.nfTs[0]);
			// getCollectionFollowUnFollow();
			setFavouriteCount(resp?.totalFavouriteCount)
			setLikeCount(resp?.totalLikeCount)
			setFollowerCount(resp?.totalFollowCount)
			setViewCount(resp.totalViewCount)
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getPrice = () => {
		if (product.nftPrice.length) {
			let activePrice = product.nftPrice.filter((price: any) => price.isActive == 1);
			if (cartProduct) {
				return activePrice.length ? inCart ? activePrice[0].cryptoStartPrice * cartProduct.__cart_quantity : activePrice[0].cryptoStartPrice : 'NA'
			}
			return activePrice.length ? inCart ? activePrice[0].cryptoStartPrice * 1 : activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}
	}

	const applyFollowUnfollow = (value: number) => {
		if (!isAuth) {
			toast.error('please login to follow')
		} else {
			showLoader();
			collectionApi.saveFollow({
				itemType: ITEM_TYPE_1.NFT,
				itemID: id,
				isFollow: value,
			}, (message: string, resp: any) => {
				hideLoader();
				getCollectionFollowUnFollow();
				getProduct()
			}, (message: string) => {
				hideLoader();
				console.log(message);
			});
		}
	}

	const getCreatedBy = () => {
		userApi.getUserProfile({ customerID: product.addedBy }, (message: string, resp: any) => {
			if (resp.customers.length) {
				setCreatedBy(resp.customers[0]);
			}
		}, (message: string) => {
			console.log(message);
		})
	}

	const getCollection = () => {
		collectionApi.getCollections({ collectionID: product.collectionID }, (message: string, resp: any) => {
			setCollection(resp.collections[0])
		}, (message: string) => {
			console.log(message);
		})
	}

	const collectProductFiles = () => {
		let files: any = [];
		let prodFile = {
			file: product.nftFileUrl,
			thumb: product.nftThumbUrl,
			mimeType: product.mimeType
		}
		files.push(prodFile);
		setSelectedProductFile(prodFile)
		if (product.isShowPreviewInFrontend) {
			product?.productPreview.map((preview: any) => {
				files.push({
					file: preview.fileUrl,
					thumb: preview.thumbFileUrl,
					mimeType: preview.mimeType
				});
			})
		}
		setProductFiles(files)
	}

	const getOwnerByNft = () => {
		let userToken = false;
		if (localStorage.getItem(STORAGE)) {
			let user = JSON.parse(localStorage.getItem(STORAGE) || '');
    		userToken = user.responseToken ? true : false;
		}

		showLoader();
		productApi.getOwnerByNft({ 
			nftid: id,
			isguest: userToken // queryParams.get('guest') == '1' 
		}, (message: string, resp: any) => {
			hideLoader();
			setOwners(resp.owners)

			const ownerDetails = resp.owners.filter((owner: any) => owner['customerID'] == user.user.userID)
			if ( isAuth && ownerDetails.length ) {
				setIsCreatorOrOwner(true);
				setIsNftOwner(true)
			}
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const viewContent = () => {
		if ( isCreatorOrOwner ) {
			const params = {
				nftid: product['nftid'],
				browserObject: JSON.stringify(browserObject)
			}
			showLoader();
			productApi.fetchNftUnlockableContent(params, (message: string, resp: any) => {
				hideLoader();
				if ( resp.successful ) {
					const message = resp['nftUnlockableContent'] && resp['nftUnlockableContent'][0] && resp['nftUnlockableContent'][0]['unlockableContent'] || ''
					setModalContent(<UnlockableContentModal cancel={closeModal} message={message} />)
					showModal('Unlockable Content')
				} else {
					toast.error(resp.message)
				}
			}, (message: string) => {
				hideLoader();
				toast.error(message)
			})
		}
	}

	const showModal = (title: string) => {
		setIsShowModal(true)
		setModalTitle(title)
	}

	const closeModal = () => {
		setIsShowModal(false);
		setModalTitle('')
		setModalContent('')
	}

	const onCloseModal = () => {
		setShow(false)
	}

	const renderContent = () => {
		return (
			<React.Fragment>
				{owners && owners.length > 0 && owners.map((item: any, index: number) =>
				(
					<div className="flex justify-between border-2 m-1 p-2" key={index}>
						<div className="">
							<h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
								{`${item?.firstName} ${item?.lastName}`}
							</h3>
							<span className="text-sm">
								{`${item?.emailID}`}
							</span>
						</div>
						<div className="flex flex-col justify-center">
							<span className="">#Nfts {`${item?.nftCount}`}</span>
						</div>
					</div>
				))
				}
			</React.Fragment>
		)
	};

	const renderTrigger = () => {
		return null;
	};

	const handleTransferNft = () => {
		if ( user && user.user && (user.user.isCustodialWalletEnabled === "true" || user.user.isCustodialWalletEnabled === true) && user.user.custodialWalletAddress ) {
			setModalContent(<TransferNft className="" nft={product} collection={collection} owners={owners} cancel={closeModal} fromWallet={user.user.custodialWalletAddress} />)
			showModal('Transfer NFT')
		} else {
			handleWalletConnect(null, account, chainId, (connectedAcc: string) => {
				setModalContent(<TransferNft className="" nft={product} collection={collection} owners={owners} cancel={closeModal} fromWallet={connectedAcc} />)
				showModal('Transfer NFT')
			})
		}
	}

	const handleDownloadNft = (product:any) => {
		//const url = 'https://www.w3schools.com/images/myw3schoolsimage.jpg' //product['nftFileUrl']
		const url = `${API_BASE_URL}/NFT/DownloadFile?NFTID=${product.nftid}`
		let userToken = false;
		if (localStorage.getItem(STORAGE)) {
			let user = JSON.parse(localStorage.getItem(STORAGE) || '');
    		userToken = user.responseToken ? true : false;
		}
		
		return axios({ 
			method: 'GET', url,
			headers: {
				Authorization: `Bearer ${userToken}`
			}
		}).then(success => {
			console.log('success: ', success)
		  }).catch(error => {
			console.log('err: ', error)
		  })
		/*
		console.log(product);
		showLoader();
		productApi.downloadNFTs({ 
			NFTID: product.nftid			
		}, (message: string, resp: any) => {
			hideLoader();
			console.log(resp);
		}, (message: string) => {
			hideLoader();
		})
		*/
	}

	const handleWalletConnect = async (item: any, _account: string, _chainId: number, onSuccess: Function) => {
		if (typeof window !== 'undefined') {
			if (!ethereum) {
				toast.error('Please install the metamask extension');
			} else {
				if (_account) {
					if (_chainId === collection['chainID']) {
						closeModal();
            			onSuccess(_account)
					} else {
						setModalContent(<SwitchNetwork changeChainId={collection['chainID']} callback={handleWalletConnect} item={collection} cancel={closeModal} onSuccess={onSuccess} />)
						showModal('Switch Network');
					}
				} else {
					setModalContent(<ConnectWallet cancel={closeModal} callback={handleWalletConnect} item={collection} onSuccess={onSuccess} showCustodialBalance={false} showMetamaskBalance={false} />)
					showModal('Connect Wallet')
				}
			}
		}
	}

	useEffect(() => {
		getSiteSettings();
		getProduct();
		addView();
		// getOwnerByNft()
	}, []);

	useEffect(() => {
		if (settings && Object.keys(settings).length && product) {
			const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
			if (spamSettings.length) {
				const banItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBanItemSpam"]) || []
				const blurItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBlurItemSpam"]) || []

				if (banItemSettings.length && +banItemSettings[0]['appValue'] <= product['reportedSpamCount']) {
					setShowSuspiciousMsg(true)
				} if (blurItemSettings.length && +blurItemSettings[0]['appValue'] <= product['reportedSpamCount']) {
					setShowBlurFile(true)
				}
			}
		}
	}, [settings, product]);

	const removeBlurEffect = () => {
		setShowBlurFile(false)
	}

	const downloadFile = () => {
		const url = 'https://www.w3schools.com/images/myw3schoolsimage.jpg' //product['nftFileUrl']
		return axios({ method: 'GET', url }).then(success => {
			console.log('success: ', success)
		  }).catch(error => {
			console.log('err: ', error)
		  });

		/*fetch(url)
		.then(response => response.blob())
		.then(blob => {
			const blobURL = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = blobURL;
			// a.style = "display: none";

			a.download = url.split('.').pop() || 'download.jpeg';
			document.body.appendChild(a);
			a.click();
			a.remove()
		})
		.catch((err: any) => console.log('err: ', err));*/
	}

	useEffect(() => {
		console.log("Session User xxx", user);
		if (product) {
			console.log('Product=>', product);
			collectProductFiles();
			getCreatedBy();
			getCollection();
						
			getCollectionFollowUnFollow();			

			if ( product['collectionTypeId'] != 1 ) {
				getOwnerByNft()
			} else if ( isAuth && ((product['addedBy'] == user.user.userID && product['isCreatedByCustomer']) || product['ownerID'] == user.user.userID) ) {
				setIsCreatorOrOwner(true);
			}

			if (user.user && product['ownerID'] == user.user.userID ) {
				setIsNftOwner(true)
			}
		}
	}, [product]);

	useEffect(() => {
		if (inCart) {
			setCartProduct(cart.filter((item: any) => item.productID == id)[0])
		} else {
			setCartProduct(null)
		}
	}, [inCart, cart]);


	return (
		<React.Fragment>
			<Helmet>
				<title>{product?.name}</title>
				<meta name="description" content={product?.description} />
			</Helmet>

			<div className={`nc-NftDetailPage`} data-nc-id="NftDetailPage">
				{/* MAIn */}
				<main className="container mt-11 flex pl-10 pr-10">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
						{/* CONTENT */}
						<div className="space-y-8 lg:space-y-10">
							<div className={"bg-gray-100 dark:bg-neutral-800 overflow-hidden relative rounded-2xl w-full" + (showBlurFile ? ' spam-product-card' : '')} style={{ paddingTop: '100%' }}>
								<div className="center-icon w-full">
									{
										showBlurFile && (
											<div className="spam-overlay spam-overlay-rounded-full">
												<HiOutlineEyeOff />
												<p className="text-sm mb-4">Inappropriate content.</p>
												<ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={removeBlurEffect}>view</ButtonSecondary>
											</div>
										) || ''
									}
									<ProductFile file={selectedProductFile} />
								</div>
								{product && (<FavoriteButton className="absolute left-3 top-3 " type="NFT" _id={product?.nftid} favouriteCount={favouriteCount} getProduct={getProduct} isAuth={isAuth} />)}
								{product && (<LikeButton className="absolute right-3 top-3 " type="NFT" _id={product?.nftid} likeCount={likeCount} getProduct={getProduct} isAuth={isAuth} />)}
							</div>
							{product?.isShowPreviewInFrontend ?
								<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-4 mt-4 lg:mt-10 custom-xs-col-2">
									{productfiles?.map((file: any, i: any) => (
										<ProductFileThumb key={i} file={file} selected={selectedProductFile == file} onClick={setSelectedProductFile} />
									))}
								</div>
								: null}
							
							<div className="space-y-2 lg:space-y-2">
								{product && (<AccordionInfo product={product} showListing={false} />)}
								{product && (<AccordionDetails product={product} />)}
								{product && (<ActivitiesHistory product={product}/>)}
							</div>

						</div>

						{/* SIDEBAR */}
						<div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
							<div className="divide-y divide-neutral-100 dark:divide-neutral-800">
								{/* ---------- 1 ----------  */}
								<div className="pb-9 space-y-5">
									{
										showSuspiciousMsg ?
											<div className="bg-pink-100 flex font-medium inline-flex justify-center nc-Badge px-2.5 py-1 relative rounded-full text-center text-pink-800 w-full" role="">
												<AiOutlineWarning size={20} /> &nbsp; <p>Reported for suspicious activity!</p>
											</div> : ''
									}

									<div className="flex justify-between items-center">
										<div>
											<Badge name={product?.categoryName} color="blue" />
										</div>
										<div className="flex">
											<NcShareDropDown
												className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
												panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
											/>
											<NftMoreDropdown
												type_id={3}
												actions={moreoptions}
												containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer ml-3"
												onClick={(data: any) => {
													if (data === 'follow') {
														applyFollowUnfollow(1);
													}

													if (data === 'unfollow') {
														applyFollowUnfollow(0);
													}

													if (data === 'nofollow') {
														applyFollowUnfollow(0);
													}
												}}
											/>
										</div>

									</div>
									<div>
										<h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-3 flex gap-2 items-center">
											{product?.name}
										</h2>
										{product?.isFeatured ? (<Badge name={"Featured"} color="green" />) : null}
									</div>

									{/* ---------- 4 ----------  */}
									<div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
										<div className="flex items-center ">
											{product && product['collectionTypeId'] == 1 ?
												<>
													<Avatar imgUrl={product?.ownerProfileImageUrl ? product?.ownerProfileImageUrl : NoUserImage} sizeClass="h-9 w-9" radius="rounded-full" />
													<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
														<span className="text-sm">Owner</span>
														<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
															<Link to={`/author/${product?.ownerID}`}>
																<span>{product && product.ownerName || 'Unknown'}</span>
															</Link>
															{/* {owners && owners.length == 1 ?
																<Link to={`/author/${product?.creatorId}`}>
																	<span>{product && product.createdBy || ''}</span>
																</Link>
																:
																<span>Unknown</span>} */}
														</span>
													</span>
												</>
												:
												<>
													<Avatar imgUrl={NoUserImage} sizeClass="h-9 w-9" radius="rounded-full" />
													<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col cursor-pointer" onClick={() => setShow(!show)}>
														<span className="text-sm">Owners</span>
														<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
															<span>{`${owners && owners.length || 0}`}</span>
														</span>
													</span>
												</>
											}
										</div>
										<div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
										<div className="flex items-center">
											<Avatar
												imgUrl={collection?.logoUrl ? collection?.logoUrl : NoImage}
												sizeClass="h-9 w-9"
												radius="rounded-full"
											/>
											<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
												<span className="text-sm">Collection</span>
												<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
													<Link to={`/collection/${product?.collectionID}`} className={`flex items-center`}><span>{product?.collectionName}</span> {collection?.isVerify ? <VerifyIcon iconClass="w-6 h-6" /> : null} </Link>

												</span>
											</span>
										</div>
									</div>
									<ViewCount count={viewCount} />

									{product?.isUnlockableContent ? <UnlockableContent callViewContent={viewContent} id={product.productId} clickable={isCreatorOrOwner} /> : null}
								</div>

								{
									isAuth && isNftOwner && product && product['nftguid'] && (
										<div className="pb-9 space-y-5">
											<QRCode value={product['nftguid']} />
										</div>
									) || ''
								}
								
								<div className="pt-9">
									{product && (<Details product={product} />)}
								</div>

								{user.user && (
								<div>
									<ButtonPrimary onClick={handleTransferNft} type="button">Transfer NFT</ButtonPrimary>
									<ButtonPrimary className={`ml-3`} onClick={() => {
										handleDownloadNft(product);
									}} type="button">Download NFT</ButtonPrimary>
								</div>
								)}

								{/* ---------- 9 ----------  */}

							</div>
						</div>
					</div>
				</main>
			</div>
			<NcModal
				isOpenProp={show}
				onCloseModal={onCloseModal}
				contentExtraClass="max-w-screen-sm"
				renderContent={renderContent}
				renderTrigger={renderTrigger}
				modalTitle="Owner List"
			/>
			<NcCustomModal contentExtraClass="max-w-3xl" modalTitle={modalTitle} modalStatus={isShowModal} closeModal={closeModal}>
				{modalContent}
			</NcCustomModal>
		</React.Fragment>
	);
};

export default NftDetailPage;
