import React, { useEffect } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import { useForm } from "react-hook-form";

export interface props {
    onSearch: any;
    searchKey?:string;
}

const FloatSearchBox: React.FC<props> = ({onSearch, searchKey}) => {
    const { register, handleSubmit, reset} = useForm();

    const onSubmit = (data:any)=>{
        onSearch(data.searchText)
    }

    useEffect(() => {
        reset({searchText:searchKey})
    },[searchKey])
    
    return (
        <div className="container">
            <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
                <form className="relative w-full" onSubmit={handleSubmit(onSubmit)}>
                    <label
                        htmlFor="search-input"
                        className="text-neutral-500 dark:text-neutral-300"
                    >
                        <span className="sr-only">Search all icons</span>
                        <Input
                            className="shadow-lg border-0 dark:border"
                            id="search-input"
                            type="search"
                            placeholder="Type your keywords"
                            sizeClass="pl-14 py-5 pr-5 md:pl-16"
                            rounded="rounded-full"
                            {...register('searchText')}
                        />
                        <ButtonCircle
                            className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                            size=" w-11 h-11"
                            type="submit"
                           
                        >
                            <i className="las la-arrow-right text-xl"></i>
                        </ButtonCircle>
                        <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M22 22L20 20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                    </label>
                </form>
            </header>
        </div>
    );
};

export default FloatSearchBox;