import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as rdd from 'react-device-detect';
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import VerifyIcon from "components/VerifyIcon";
import Details from "./Details";
import UnlockableContent from "./UnlockableContent";
import LikeButton from "../../components/LikeButton";
import FavoriteButton from "../../components/FavoriteButton";
import AccordionInfo from "./AccordionInfo";
import ProductFileThumb from "./ProductFileThumb";
import ProductFile from "./ProductFile";
import NoUserImage from "images/avatars/Image-1.png";
import NoImage from "images/placeholder-small.png";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useParams, Link } from "react-router-dom";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";
import { BsBagCheck, BsCartPlus, BsCartX } from 'react-icons/bs'
import Prices from "../../components/Prices";
import QuantityCounter from "../../components/checkout/QuantityCounter";
import ViewCount from "../../components/ViewCount";
import { useAuthStatus } from "_common/hooks/authHook";
import NftMoreDropdown from "components/NftMoreDropdown";
import { store } from "app/store";
import { ACTIONS } from "_config";
import { useCartSelector } from "_common/hooks/selectors/cartSelector";
import { toast } from "react-hot-toast";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useSiteSettingsSelector } from "_common/hooks/selectors/commonSelector";
import { HiOutlineEyeOff } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";
import { BiMinus, BiPlus } from "react-icons/bi";
import EditionDetails from "./EditionDetails";
import EditionHistory from "./EditionHistory";
import EditionListing from "./EditionListing";
import UnlockableContentModal from "themes/Normalize/components/Nft/UnlockableContentModal";
import NcCustomModal from "components/Modal/NcCustomModal";
const browserObject = {
	browserName: rdd['browserName'],
	browserVersion: rdd['browserVersion'],
	deviceType: rdd['deviceType'],
	engineName: rdd['engineName'],
	engineVersion: rdd['engineVersion'],
	fullBrowserVersion: rdd['fullBrowserVersion'],
	getUA: rdd['getUA'],
	isAndroid: rdd['isAndroid'],
	isBrowser: rdd['isBrowser'],
	isChrome: rdd['isChrome'],
	isChromium: rdd['isChromium'],
	isConsole: rdd['isConsole'],
	isDesktop: rdd['isDesktop'],
	isEdge: rdd['isEdge'],
	isEdgeChromium: rdd['isEdgeChromium'],
	isElectron: rdd['isElectron'],
	isEmbedded: rdd['isEmbedded'],
	isFirefox: rdd['isFirefox'],
	isIE: rdd['isIE'],
	isIOS: rdd['isIOS'],
	isIOS13: rdd['isIOS13'],
	isIPad13: rdd['isIPad13'],
	isIPhone13: rdd['isIPhone13'],
	isIPod13: rdd['isIPod13'],
	isLegacyEdge: rdd['isLegacyEdge'],
	isMIUI: rdd['isMIUI'],
	isMacOs: rdd['isMacOs'],
	isMobile: rdd['isMobile'],
	isMobileOnly: rdd['isMobileOnly'],
	isMobileSafari: rdd['isMobileSafari'],
	isOpera: rdd['isOpera'],
	isSafari: rdd['isSafari'],
	isSamsungBrowser: rdd['isSamsungBrowser'],
	isSmartTV: rdd['isSmartTV'],
	isTablet: rdd['isTablet'],
	isWearable: rdd['isWearable'],
	isWinPhone: rdd['isWinPhone'],
	isWindows: rdd['isWindows'],
	isYandex: rdd['isYandex'],
	mobileModel: rdd['mobileModel'],
	mobileVendor: rdd['mobileVendor'],
	osName: rdd['osName'],
	osVersion: rdd['osVersion']
}

const ProductDetailPage: any = () => {
	const isAuth = useAuthStatus();
	const cart: any = useCartSelector();
	const settings: any = useSiteSettingsSelector();

	const { id } = useParams();
	const productApi = useProductApi();
	const commonApi = useCommonApi();
	const userApi = useUserApi();
	const collectionApi = useCollectionApi();
	const { showLoader, hideLoader } = useAppLoader();


	const [product, setProduct] = useState<any>(null);
	const [cartProduct, setCartProduct] = useState<any>(null);
	const [inCart, setInCart] = useState<boolean>(cart.filter((item: any) => item.productID == id).length > 0);

	const [moreoptions, setMoreoptions] = useState<any>([]);
	const [isfollowed, setIsfollowed] = React.useState(false);
	const [followdata, setFollowdata] = useState<any>(null);
	const [totalFollowers, setTotalFollowers] = useState<any>(0);
	const [followerCount, setFollowerCount] = useState<number>(0);
	const [favouriteCount, setFavouriteCount] = useState<number>(0);
	const [likeCount, setLikeCount] = useState<number>(0);

	const [createdBy, setCreatedBy] = useState<any>(null);
	const [collection, setCollection] = useState<any>(null);
	const [productfiles, setProductFiles] = useState<any[] | null>(null);
	const [selectedProductFile, setSelectedProductFile] = useState<any>(null);
	const [showBlurFile, setShowBlurFile] = useState<boolean | null>(null);
	const [showSuspiciousMsg, setShowSuspiciousMsg] = useState<boolean>(false);
	const [viewCount, setViewCount] = useState<number>(0);
	const [spamCount, setSpamCount] = useState<number>(0);
	const [showCheckoutBtn, setShowCheckoutBtn] = useState<boolean>(false);
	const [isCreatorOrOwner, setIsCreatorOrOwner] = useState<boolean>(false)

	const [modalContent, setModalContent] = useState<any>('')
	const [isShowModal, setIsShowModal] = useState(false);
	const [modalTitle, setModalTitle] = useState<string>('');



	const user: any = useSelector((state: StateExtended) => state.user);

	const getSiteSettings = () => {
		commonApi.getSiteSettings({}, (message: any, resp: any) => { }, (message: any) => { })
	}

	const addView = () => {
		console.log('USER=>', user)
		commonApi.addView({
			"itemType": 2,
			"itemID": id,
			"walletAddress": user?.user ? user.user.custodialWalletAddress : '',
			"browserObject": JSON.stringify(browserObject)
		}, (message: any, resp: any) => { }, (message: any) => { })
	}



	const getCollectionFollowUnFollow = () => {
		showLoader();
		collectionApi.getCollectionfollowunfollow({
			itemType: 2,
			itemID: id,
			addedBy: (isAuth) ? user.user.userID : null,
			authrequired: isAuth
		}, (message: string, resp: any) => {
			hideLoader();
			if (resp.code === 200 && resp.successful === true && resp.followUnfollows.length > 0) {
				setIsfollowed(true);
				setFollowdata(resp.followUnfollows[0]);
				setTotalFollowers(resp.totalRecord);
				console.log({ updateMoreOption: resp })
				if (isAuth) {
					setMoreoptions([{
						id: "unfollow",
						name: `(${followerCount} followers) Unfollow`,
						icon: "las la-times-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: `(${followerCount}) Follower(s)`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			} else {
				setIsfollowed(false);
				setFollowdata(null);
				setTotalFollowers(0);
				if (isAuth) {
					setMoreoptions([{
						id: "follow",
						name: `(${followerCount} followers) Follow`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}])
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: `(${followerCount}) Follower(s)`,
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			}
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getProduct = () => {
		showLoader();
		productApi.getProducts({ productID: id }, (message: string, resp: any) => {
			hideLoader();
			setViewCount(resp.totalViewCount)
			setProduct(resp.products[0]);
			getCollectionFollowUnFollow();
			setFavouriteCount(resp?.totalFavouriteCount)
			setLikeCount(resp?.totalLikeCount)
			setFollowerCount(resp?.totalFollowCount)
			setSpamCount(resp?.reportedSpamCount)
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getPrice = () => {
		if (product.productPrice.length) {
			let activePrice = product.productPrice.filter((price: any) => price.isActive == 1);
			if (cartProduct) {
				return activePrice.length ? inCart ? activePrice[0].cryptoStartPrice * cartProduct.__cart_quantity : activePrice[0].cryptoStartPrice : 'NA'
			}
			return activePrice.length ? inCart ? activePrice[0].cryptoStartPrice * 1 : activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}
	}

	const addToCart = () => {
		let _cart: any = JSON.parse(JSON.stringify(cart));
		if (inCart) {
			let i: any = _cart.findIndex((item: any) => item.productID == id);
			_cart.splice(i, 1);
		} else {
			if (_cart.length == 0) {
				_cart.push({ ...product, __cart_quantity: 1 })
			} else {
				let item: any = _cart[0];
				if (item.chainID == product.chainID && item.collectionID == product.collectionID && item.isEdition == product.isEdition) {
					_cart.push({ ...product, __cart_quantity: 1 })
				} else {
					toast((t) => (
						<span>
							<p className="text-lg text-yellow-600">WARNING!</p>
							<p>Your cart contains different collection or network chain or different Edition.</p>
							<p><b>Add Anyway</b> to remove all previous and new one or <b>Cancel</b></p>
							<br />
							<hr className="mb-3 mt-3" />
							<div className="align-middle flex justify-end">
								<ButtonPrimary className="bg-black m-1 sm:h-9"
									onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
								<ButtonPrimary className="bg-green-600 m-1 sm:h-9"
									onClick={() => {
										toast.dismiss(t.id);
										_cart = [{ ...product, __cart_quantity: 1 }];
										setInCart(_cart.filter((item: any) => item.productID == id).length > 0);
										store.dispatch({
											type: ACTIONS.CART.UPDATE,
											payload: {
												items: _cart,
											}
										});
									}}
								>Add Anyway</ButtonPrimary>
							</div>
						</span>
					), { duration: 20000 });
				}
			}
		}

		setInCart(_cart.filter((item: any) => item.productID == id).length > 0);
		store.dispatch({
			type: ACTIONS.CART.UPDATE,
			payload: {
				items: _cart,
			}
		});
	}

	const updateCartQuantity = (n: number) => {
		console.log('updateCartQuantity=>', n);
		let _cart: any = cart.map((_product: any) => {
			if (product.productID == _product.productID) {
				return { ...product, __cart_quantity: n };
			}
			return product
		});
		console.log(_cart);
		store.dispatch({
			type: ACTIONS.CART.UPDATE,
			payload: {
				items: _cart,
			}
		});
	}

	const applyFollowUnfollow = (value: number) => {
		if (!isAuth) {
			toast.error('please login to follow')
		} else {
			showLoader();
			collectionApi.saveFollow({
				itemType: 2,
				itemID: id,
				isFollow: value,
			}, (message: string, resp: any) => {
				hideLoader();
				getCollectionFollowUnFollow();
				getProduct()
			}, (message: string) => {
				hideLoader();
				console.log(message);
			});
		}
	}

	const viewContent = () => {
		if ( isCreatorOrOwner ) {
			const params = {
				productID: product['productID'],
				// browserObject: JSON.stringify(browserObject)
			}
			showLoader();
			productApi.fetchProductUnlockableContent(params, (message: string, resp: any) => {
				hideLoader();
				if ( resp.successful ) {
					const message = resp['productUnlockableContent'] && resp['productUnlockableContent'][0] && resp['productUnlockableContent'][0]['unlockableContent'] || ''
					setModalContent(<UnlockableContentModal cancel={closeModal} message={message} />)
					showModal('Unlockable Content')
				} else {
					toast.error(resp.message)
				}
			}, (message: string) => {
				hideLoader();
				toast.error(message)
			})
		}
	}

	const showModal = (title: string) => {
		setIsShowModal(true)
		setModalTitle(title)
	}

	const closeModal = () => {
		setIsShowModal(false);
		setModalTitle('')
		setModalContent('')
	}

	const getCreatedBy = () => {
		userApi.getUserProfile({ customerID: product.addedBy }, (message: string, resp: any) => {
			if (resp.customers.length) {
				setCreatedBy(resp.customers[0]);
			}
		}, (message: string) => {
			console.log(message);
		})
	}

	const getCollection = () => {
		collectionApi.getCollections({ collectionID: product.collectionID }, (message: string, resp: any) => {
			setCollection(resp.collections[0])
		}, (message: string) => {
			console.log(message);
		})
	}

	const collectProductFiles = () => {
		let files: any = [];
		let prodFile = {
			file: product.productFileUrl,
			thumb: product.productThumbUrl,
			mimeType: product.mimeType
		}
		files.push(prodFile);
		setSelectedProductFile(prodFile)
		if (product.isShowPreviewInFrontend) {
			product?.productPreview.map((preview: any) => {
				files.push({
					file: preview.fileUrl,
					thumb: preview.thumbFileUrl,
					mimeType: preview.mimeType
				});
			})
		}
		setProductFiles(files)
	}

	useEffect(() => {
		addView();
		getSiteSettings();
		getProduct();
	}, []);

	useEffect(() => {
		if (settings && Object.keys(settings).length && product) {
			const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
			if (spamSettings.length) {
				const banItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBanItemSpam"]) || []
				const blurItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBlurItemSpam"]) || []

				if (banItemSettings.length && +banItemSettings[0]['appValue'] <= spamCount) {
					setShowSuspiciousMsg(true)
				} if (blurItemSettings.length && +blurItemSettings[0]['appValue'] <= spamCount) {
					setShowBlurFile(true)
				}
			}
		}
	}, [settings, product]);

	const removeBlurEffect = () => {
		setShowBlurFile(false)
	}

	useEffect(() => {
		if (product) {
			console.log('Product=>', product);
			setShowBlurFile(product.isExplicitSensitiveContent)
			collectProductFiles();
			getCollection();
			getCollectionFollowUnFollow();

			if ( isAuth && product['addedBy'] == user.user.userID && product['isCreatedByCustomer']) {
				setIsCreatorOrOwner(true);
			}
		}
		
		if (product && product.isCreatedByCustomer) {
			getCreatedBy();
		}
	}, [product]);

	useEffect(() => {
		if (product && collection) {
			let show: boolean = collection.contractStatus == 4;
			if (show && product.isEdition != 0 && product.editionId == 0) {
				show = false;
			}
			setShowCheckoutBtn(show);
		}

	}, [product, collection])




	useEffect(() => {
		if (inCart) {
			setCartProduct(cart.filter((item: any) => item.productID == id)[0])
		} else {
			setCartProduct(null)
		}
	}, [inCart, cart]);




	return (
		<React.Fragment>
			<Helmet>
				<title>{product?.productName}</title>
				<meta name="title" content={product?.productName} />
				<meta name="description" content={product?.description} />
			</Helmet>

			<div className={`nc-NftDetailPage`} data-nc-id="NftDetailPage">
				{/* MAIn */}
				<main className="container mt-11 flex pl-10 pr-10">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
						{/* CONTENT */}
						<div className="space-y-8 lg:space-y-10">
							{/* HEADING */}
							{/* <div className="relative">
								<NcImage
									src={product?.productThumbUrl}
									containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
								/>

								{product && (<FavoriteButton className="absolute left-3 top-3 " type="product" _id={product?.productID} favouriteCount={favouriteCount} getProduct={getProduct} isAuth={isAuth} />)}
								{product && (<LikeButton className="absolute right-3 top-3 " type="product" _id={product?.productID} likeCount={likeCount} getProduct={getProduct} isAuth={isAuth} />)}
							</div> */}

							{showBlurFile !== null ?
								<div className={"bg-gray-100 dark:bg-neutral-800 overflow-hidden relative rounded-2xl w-full" + (showBlurFile ? ' spam-product-card' : '')} style={{ paddingTop: '100%' }}>
									<div className={"center-icon w-full"}>
										<ProductFile file={selectedProductFile} />
									</div>
									{showBlurFile && (
										<div className="absolute bg-neutral-900 bg-opacity-50 flex flex-col items-center justify-center  h-full left-0 text-4xl text-white top-0 w-full z-20">
											<div className="bg-neutral-900 flex flex-col items-center justify-center py-3 w-full">
												<HiOutlineEyeOff />
												<p className="text-lg mb-4">Inappropriate content.</p>
												<ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={removeBlurEffect}>view</ButtonSecondary>
											</div>
										</div>
									) || ''
									}
									{product && (<FavoriteButton className="absolute left-3 top-3 z-30" type="product" _id={product?.productID} favouriteCount={favouriteCount} getProduct={getProduct} isAuth={isAuth} />)}
									{product && (<LikeButton className="absolute right-3 top-3 z-30 " type="product" _id={product?.productID} likeCount={likeCount} getProduct={getProduct} isAuth={isAuth} />)}
								</div>
								: null}

							{product?.isShowPreviewInFrontend ?
								<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-4 mt-4 lg:mt-10 custom-xs-col-2">
									{productfiles?.map((file: any, i: any) => (
										<ProductFileThumb key={i} file={file} selected={selectedProductFile == file} onClick={setSelectedProductFile} />
									))}
								</div>
								: null}


							{product && (<AccordionInfo product={product} showListing={product?.productEdition?.length} />)}


						</div>

						{/* SIDEBAR */}
						<div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
							<div className="divide-y divide-neutral-100 dark:divide-neutral-800">
								{/* ---------- 1 ----------  */}
								<div className="pb-9 space-y-5">
									{
										showSuspiciousMsg ?
											<div className="bg-pink-100 flex font-medium inline-flex justify-center nc-Badge px-2.5 py-1 relative rounded-full text-center text-pink-800 w-full" role="">
												<AiOutlineWarning size={20} /> &nbsp; <p>Reported for suspicious activity!</p>
											</div> : null
									}

									<div className="flex justify-between items-center">
										<div>
											<Badge name={product?.categoryName} color="blue" />
										</div>
										<div className="flex">
											<NcShareDropDown
												className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
												panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
											/>
											<NftMoreDropdown
												type_id={2}
												actions={moreoptions}
												containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer ml-3"
												onClick={(data: any) => {
													if (data === 'follow') {
														applyFollowUnfollow(1);
													}

													if (data === 'unfollow') {
														applyFollowUnfollow(0);
													}

													if (data === 'nofollow') {
														applyFollowUnfollow(0);
													}
												}}
											/>
										</div>

									</div>
									<div>
										<h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-3 flex gap-2 items-center">
											{product?.productName}
										</h2>
										{product?.isFeatured ? (<Badge name={"Featured"} color="green" />) : null}
									</div>

									{/* ---------- 4 ----------  */}
									<div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
										<div className="flex items-center ">
											<Avatar imgUrl={product?.isCreatedByCustomer ? createdBy?.profileImageUrl ? createdBy?.profileImageUrl : NoUserImage : NoUserImage} sizeClass="h-9 w-9" radius="rounded-full" />
											<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
												<span className="text-sm">Creator</span>
												<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
													{product?.isCreatedByCustomer ?
														createdBy ?
															<Link to={`/author/${product?.addedBy}?isCus=${product?.isCreatedByCustomer}`}><span>{`${createdBy?.firstName} ${createdBy?.lastName}`}</span></Link>
															:
															<span>{product?.authorName}</span>
														: <Link to={`/author/${product?.addedBy}?isCus=${product?.isCreatedByCustomer}`}><span>{`${product?.authorName}`}</span></Link>
													}
												</span>
											</span>
										</div>
										<div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
										<div className="flex items-center">
											<Avatar
												imgUrl={collection?.logoUrl ? collection?.logoUrl : NoImage}
												sizeClass="h-9 w-9"
												radius="rounded-full"
											/>
											<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
												<span className="text-sm">Collection</span>
												<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
													<Link to={`/collection/${product?.collectionID}`} className={`flex items-center`}><span>{product?.collectionName}</span> {collection?.isVerify ? <VerifyIcon iconClass="w-6 h-6" /> : null} </Link>

												</span>
											</span>
										</div>
									</div>

									<ViewCount count={viewCount} />

									{product?.isUnlockableContent ? <UnlockableContent callViewContent={viewContent} id={product.productId} clickable={isCreatorOrOwner} /> : null}
								</div>

								{inCart && cartProduct ?
									<QuantityCounter size="lg" quantity={cartProduct.__cart_quantity} max={cartProduct.availabeQty} onChange={updateCartQuantity} />
									: null}


								<EditionDetails product={product} />
								{
									product?.productEditionHistory?.length && <EditionHistory product={product} /> || ''
								}


								<div className="pb-9 pt-6">
									<div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:justify-between-n">

										{product && (
											<>
												<Prices network={product?.network} size="lg" price={getPrice()} />
												<span className="border dark:text-neutral-400 flex gap-2 items-center mt-2 p-3 rounded-lg shadow-md sm:ml-10 sm:mt-0 text-lg text-neutral-500 text-sm pl-6 pr-6">
													<strong className="text-2xl text-gray-800 dark:text-gray-200">{product.availabeQty}</strong> in stock
												</span>
											</>
										)}

									</div>

									{showCheckoutBtn ?
										<>
											<div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">

												<ButtonPrimary href={"/checkout/" + id} className={`flex-1 ${(getPrice() == 'NA' || product.availabeQty < 1 ? 'pointer-events-none opacity-50' : '')}`}>
													<BsBagCheck className="text-2xl" />
													<span className="ml-2.5">Checkout</span>
												</ButtonPrimary>

												<ButtonSecondary className={`flex-1 ${(getPrice() == 'NA' || product.availabeQty < 1 ? 'pointer-events-none opacity-50' : '')}`} onClick={addToCart} >
													{inCart ? <BsCartX className="text-2xl" /> : <BsCartPlus className="text-2xl" />}
													<span className="ml-2.5">{inCart ? 'Remove From Cart' : 'Add to Cart'}</span>
												</ButtonSecondary>

											</div>
											{product.availabeQty < 1 ?
												<p className="mt-8 text-center text-md text-red-600">This item is already sold out</p>
												: null}
										</>
										: null}
									<div className="pt-9">
										{product && (<Details product={product} />)}
									</div>
								</div>

								{/* ---------- 9 ----------  */}

							</div>
						</div>
					</div>
				</main>

				{/* OTHER SECTION */}
				{/* {!isPreviewMode && (
          <div className="container py-24 lg:py-32">
       
            <div className="relative py-24 lg:py-28">
              <BackgroundSection />
              <SectionSliderCategories />
            </div>

           
            <SectionBecomeAnAuthor className="pt-24 lg:pt-32" />
          </div>
        )} */}
			</div>

			<NcCustomModal contentExtraClass="max-w-3xl" modalTitle={modalTitle} modalStatus={isShowModal} closeModal={closeModal}>
				{modalContent}
			</NcCustomModal>
		</React.Fragment>
	);
};

export default ProductDetailPage;
