import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';

import * as userSaga from './userSaga';
import * as commonSaga from './commonSaga';
import * as collectionSaga from './collectionSaga';
import * as payoutGroupSaga from './payoutGroupSaga';
import * as productSaga from './productSaga';
import * as cmsSaga from './cmsSaga';
import * as checkoutSaga from './checkoutSaga';
import * as ordersSaga from './ordersSaga';
import * as siteSettingSaga from './siteSettingSaga';
import * as basketSaga from './basketSaga';
import * as metaGallerySaga from './metaGallerySaga';
import * as gallerySaga from './gallerySaga';
export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.SIGNUP, userSaga.signup),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP2, userSaga.registerStepTwo),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP3, userSaga.registerStepThree),
    takeLatest(SAGA_ACTIONS.USER.REGISTER.STEP4, userSaga.registerStepFour),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_SOCIAL, userSaga.updateSocial),
    takeLatest(SAGA_ACTIONS.USER.GET_ADMIN_USER_PROFILE, userSaga.getAdminUserProfile),

    takeEvery(SAGA_ACTIONS.BASKET.GET_BASKET, basketSaga.getBasket),
    takeEvery(SAGA_ACTIONS.BASKET.ADD_BASKET, basketSaga.addBasket),
    takeEvery(SAGA_ACTIONS.BASKET.EDIT_BASKET, basketSaga.editBasket),
    takeEvery(SAGA_ACTIONS.BASKET.DELETE_BASKET, basketSaga.deleteBasket),
    takeEvery(SAGA_ACTIONS.META_GALLERY.ADD_META_GALLERY, metaGallerySaga.addMetaGallery),
    takeEvery(SAGA_ACTIONS.META_GALLERY.GET_META_GALLERY, metaGallerySaga.getMetaGallery),
    takeEvery(SAGA_ACTIONS.META_GALLERY.DELETE_META_GALLERY, metaGallerySaga.deleteMetaGallery),

    takeEvery(SAGA_ACTIONS.COMMON.FILE_UPLOAD, commonSaga.fileUpload),
    takeLatest(SAGA_ACTIONS.COMMON.GET_BLOCKCHAIN_NETWORKS, commonSaga.getBlockchainNetworks),
    takeLatest(SAGA_ACTIONS.COMMON.GET_LIKE_UNLIKE, commonSaga.getLikeUnlike),
    takeLatest(SAGA_ACTIONS.COMMON.POST_LIKE_UNLIKE, commonSaga.postLikeUnlike),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_LIKE_UNLIKE, commonSaga.deleteLikeUnlike),
    takeLatest(SAGA_ACTIONS.COMMON.GET_REPORT, commonSaga.getReport),
    takeLatest(SAGA_ACTIONS.COMMON.POST_REPORT, commonSaga.postReport),
    takeLatest(SAGA_ACTIONS.COMMON.DELETE_REPORT, commonSaga.deleteReport),
    takeLatest(SAGA_ACTIONS.COMMON.GET_REPORT_TYPE, commonSaga.getReportType),
    takeLatest(SAGA_ACTIONS.COMMON.GET_FAVORITE, commonSaga.getFavorite),
    takeLatest(SAGA_ACTIONS.COMMON.POST_FAVORITE, commonSaga.postFavorite),
    takeLatest(SAGA_ACTIONS.COMMON.GET_GLOBAL_SEARCH, commonSaga.getGlobalSearch),
    takeLatest(SAGA_ACTIONS.COMMON.CONTACT_US, commonSaga.postContactUs),
    takeLatest(SAGA_ACTIONS.COMMON.ADD_VIEW, commonSaga.addView),
    takeLatest(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_TXN, commonSaga.saveContractTxn),
    takeLatest(SAGA_ACTIONS.COMMON.GET_DATE_RANGE_TYPES, commonSaga.getDateRangeTypes),
    takeLatest(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_CREATE_UPDATE_TXN, commonSaga.saveContractDeployTxn),
    

    takeLatest(SAGA_ACTIONS.COLLECTION.GET_COLLECTION_TYPES, collectionSaga.getCollectionTypes),
    takeLatest(SAGA_ACTIONS.COLLECTION.ADD_UPDATE_DETAILS, collectionSaga.addUpdateDetails),
    takeEvery(SAGA_ACTIONS.COLLECTION.GET_COLLECTIONS, collectionSaga.getCollections),
    takeEvery(SAGA_ACTIONS.COLLECTION.DELETE_COLLECTION, collectionSaga.deleteCollection),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_SOCIALS, collectionSaga.updateCollectionSocials),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_PRESALE, collectionSaga.updateCollectionPresale),
    takeLatest(SAGA_ACTIONS.COLLECTION.UPDATE_GENESIS, collectionSaga.updateCollectionGenesis),
    takeLatest(SAGA_ACTIONS.COLLECTION.ASSIGN_PAYOUT_GROUP, collectionSaga.assignCollectionPayoutGroup),
    takeLatest(SAGA_ACTIONS.COLLECTION.FETCH_CONTRACT_ABI, collectionSaga.fetchCollectionAbi),
    takeLatest(SAGA_ACTIONS.COLLECTION.DEPLOY_CONTRACT, collectionSaga.deployCollectionThroughAPI),

    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.GET_PAYOUT_GROUPS, payoutGroupSaga.getPayoutGroups),
    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.ADD_PAYOUT_GROUP, payoutGroupSaga.addPayoutGroups),
    takeLatest(SAGA_ACTIONS.PAYOUT_GROUP.UPDATE_PAYOUT_GROUP, payoutGroupSaga.updatePayoutGroups),


    takeEvery(SAGA_ACTIONS.PRODUCT.GET_CATEGORIES, productSaga.getCategories),
    takeEvery(SAGA_ACTIONS.PRODUCT.ADD_UPDATE_CATEGORY, productSaga.addUpdateCategory),
    takeEvery(SAGA_ACTIONS.PRODUCT.DELETE_CATEGORY, productSaga.deleteCategory),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_GROUP_LIST, productSaga.getPayoutGroup),
    takeEvery(SAGA_ACTIONS.PRODUCT.GET, productSaga.getProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.MAPPRODUCTPAYOUTGROUP, productSaga.mapProductPayout),
    takeEvery(SAGA_ACTIONS.PRODUCT.PUBLISH, productSaga.publishProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.DELETE, productSaga.deleteProducts),
    takeEvery(SAGA_ACTIONS.PRODUCT.FEATURE, productSaga.featuredProducts),
    takeLatest(SAGA_ACTIONS.PRODUCT.ASSIGN_REDEMPTION, productSaga.assignRedemptionProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.DELETE_REDEMPTION, productSaga.deleteRedemptionProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.LIST_REDEMPTION, productSaga.listRedemptionProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.DELETE_PREVIEW_IMAGE, productSaga.deleteProductPreviewImage),
    takeLatest(SAGA_ACTIONS.PRODUCT.ASSIGN_PREVIEW_PRODUCT, productSaga.assignPreviewProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.UPDATE_PRODUCT_PRICE, productSaga.updateProductPrice),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_ADD, productSaga.addProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.PRODUCT_UPDATE, productSaga.updateProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.GET_OWNER_BY_NFT, productSaga.getOwnerByNft),
    takeLatest(SAGA_ACTIONS.PRODUCT.GET_UNLOCKABLE_CONTENT_NFT, productSaga.getUnlockableContentNft),
    takeLatest(SAGA_ACTIONS.PRODUCT.GET_UNLOCKABLE_CONTENT_PRODUCT, productSaga.getUnlockableContentProduct),
    takeLatest(SAGA_ACTIONS.PRODUCT.TRANSFER_WITH_CUSTODIAL_WALLET, productSaga.nftTransferWithCustodial),
    takeLatest(SAGA_ACTIONS.PRODUCT.TRANSFER_WITH_METAMASK, productSaga.nftTransferWithMetamask),


    takeLatest(SAGA_ACTIONS.USER.LOGINSTEP1, userSaga.loginstep1),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotpassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.setforgottenpassword),
    takeEvery(SAGA_ACTIONS.STORE.CONFIG, userSaga.getStoreConfiguration),
    takeEvery(SAGA_ACTIONS.STORE.CONFIG_SAVE, userSaga.saveStoreConfiguration),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.GET_TYPE, productSaga.getCustomFieldType),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.SAVE, productSaga.saveCustomfield),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.GET, productSaga.getCustomfields),
    takeEvery(SAGA_ACTIONS.CUSTOMFIELD.DELETE, productSaga.deleteCustomfield),
    
    takeEvery(SAGA_ACTIONS.USER.GET_USER_PROFILE, userSaga.getUserProfile),
    takeEvery(SAGA_ACTIONS.USER.SAVE_PROFILE, userSaga.updateProfile),
    takeEvery(SAGA_ACTIONS.USER.UPDATE_PASSWORD, userSaga.changePassword),
    takeLatest(SAGA_ACTIONS.USER.CONNECT_WALLET, userSaga.connectWallet),

    takeEvery(SAGA_ACTIONS.CMS.GET_LIST, cmsSaga.getCmsList),
    takeEvery(SAGA_ACTIONS.CMS.DELETE_CMS, cmsSaga.deleteCms),
    takeLatest(SAGA_ACTIONS.CMS.ADD_CMS, cmsSaga.addCms),
    takeLatest(SAGA_ACTIONS.CMS.EDIT_CMS, cmsSaga.editCms),
    takeLatest(SAGA_ACTIONS.COLLECTION.GET_MY_COLLECTIONS, collectionSaga.getMyCollections),
    takeLatest(SAGA_ACTIONS.CHECKOUT.VALIDATE_COUPON, checkoutSaga.validateCoupon),
    takeLatest(SAGA_ACTIONS.COLLECTION.GET_FOLLOWUNFOLLOW_COLLECTION, collectionSaga.getCollectionfollowunfollow),
    takeLatest(SAGA_ACTIONS.COLLECTION.SAVE_FOLLOW, collectionSaga.saveFollow),
    takeLatest(SAGA_ACTIONS.COLLECTION.SAVE_UNFOLLOW, collectionSaga.saveUnfollow),
    takeLatest(SAGA_ACTIONS.ORDERS.MY_ORDER, ordersSaga.getMyOrdersList),
    takeLatest(SAGA_ACTIONS.ORDERS.ORDER_DETAILS, ordersSaga.getOrder),
    takeLatest(SAGA_ACTIONS.ORDERS.ORDER_STATUSES, ordersSaga.getOrderStatuses),
    takeLatest(SAGA_ACTIONS.COMMON.GET_SITE_SETTINGS, commonSaga.getSiteSettings),

    takeLatest(SAGA_ACTIONS.CHECKOUT.BUY, checkoutSaga.buy),
    takeLatest(SAGA_ACTIONS.CHECKOUT.BUY_WITH_WALLET, checkoutSaga.buyWithWallet),
    takeLatest(SAGA_ACTIONS.CHECKOUT.ADD_CARD, checkoutSaga.addCard),
    takeLatest(SAGA_ACTIONS.CHECKOUT.GET_CARDS, checkoutSaga.getCards),
    takeLatest(SAGA_ACTIONS.CHECKOUT.BUY_WITH_CARD_COUPON, checkoutSaga.buyWithCardCoupon),
    takeLatest(SAGA_ACTIONS.PRODUCT.GET_MY_PRODUCTS, productSaga.getMyProducts),
    takeLatest(SAGA_ACTIONS.PRODUCT.GET_MY_PRODUCTS_ACTUAL, productSaga.getMyProductsActual),
    takeEvery(SAGA_ACTIONS.SITE_SETTINGS.GET_SITE_SETTINGS, siteSettingSaga.getSiteSettings),
    takeEvery(SAGA_ACTIONS.SITE_SETTINGS.POST_SITE_SETTINGS, siteSettingSaga.postSiteSettings),
    takeLatest(SAGA_ACTIONS.SITE_SETTINGS.ADD_CUSTODIAL_WALLET, siteSettingSaga.addCustodialWallet),
    takeLatest(SAGA_ACTIONS.SITE_SETTINGS.FETCH_CUSTODIAL_WALLET, siteSettingSaga.fetchCustodialWallet),

    takeLatest(SAGA_ACTIONS.PRODUCT.NFTS_GUEST, productSaga.getNftsGuest),

    takeLatest(SAGA_ACTIONS.GALLERY.LIST, gallerySaga.getGallery),
    takeEvery(SAGA_ACTIONS.GALLERY.INDUSTRY_LIST, gallerySaga.getIndustryList),
    takeLatest(SAGA_ACTIONS.GALLERY.ADD, gallerySaga.addGallery),
    takeLatest(SAGA_ACTIONS.GALLERY.EDIT, gallerySaga.updateGallery),
    takeLatest(SAGA_ACTIONS.GALLERY.DELETE, gallerySaga.deleteGallery),
    takeLatest(SAGA_ACTIONS.PRODUCT.DOWNLOADNFT, productSaga.downloadNFTs),
    takeLatest(SAGA_ACTIONS.COMMON.COUNTRIES, commonSaga.getCountries),
  ]);
}