import React, { useEffect, useState } from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2, PagesMenus } from "data/navigation";
import ncNanoId from "utils/ncNanoId";
import { useCmsApi } from "_common/hooks/api/cmsApi";

function Navigation() {

  const cmsApi = useCmsApi()

  const [menues, setMenues] = useState<any>(NAVIGATION_DEMO_2)
  const [pageMenues, setPageMenues] = useState<NavItemType[] | null>(null)
  const [cmsList, setCmsList] = useState<any>(null)

  const getCmsList = () => {
    // let params: any = {
    // 	usePaging: true,
    // 	pageSize: limit,
    // 	pageNumber: currentPage,
    // }
    // if (searchKey) {
    // 	params.searchText = searchKey
    // }
    // switch (filter?.radioBtnFiltrations?.Status) {
    // 	case 'name-active':
    // 		params.isActive = 1;
    // 		break;
    // 	case 'name-inactive':
    // 		params.isActive = 0;
    // 		break;
    // 	default:
    // }
    cmsApi.getCmsList({},
      (message: string, resp: any) => {
        // console.log({ message, resp })
        let tempArr: any = resp?.cmSdm || []

        tempArr.sort((a: any, b: any) => {
          if (a.orders > b.orders) return 1;
          if (a.orders < b.orders) return -1;
          return 0;
        });

        if (tempArr && tempArr.length > 0) {
          setCmsList(tempArr.filter((item: any) => {
            if (item?.header_Footer == 1 || item?.header_Footer == 3) {
              return item
            }
          }))
        }
      },
      (message: string) => {
        console.log(message)
      })
  }

  useEffect(() => {
    if (pageMenues) {
      setMenues([...NAVIGATION_DEMO_2, {
        id: "pages",
        name: "More",
        type: "dropdown",
        children: pageMenues,
      }])
    }
  }, [pageMenues])

  useEffect(() => {
    if (cmsList) {
      let tempCmsList: any = []
      cmsList.forEach((item: any) => {
        tempCmsList.push({
          id: ncNanoId(),
          href: `/page/${item?.cmsid}`,
          name: `${item?.title}`,
        })
      })
      setPageMenues([...PagesMenus, ...tempCmsList])
    }
  }, [cmsList])

  useEffect(() => {
    getCmsList()
  }, [])

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {menues.map((item: any) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
