import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "./ItemTypeImageIcon";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { HiOutlineEyeOff } from "react-icons/hi";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import AudioFile from "images/audio-file.png";
import VideoFile from "images/video-file.png";
import _3DFile from "images/3d-file.png";

const imgTypes = ['image/svg+xml','image/jpeg', 'image/png', 'image/gif','image/webp'];
const videoTypes = ['video/webm','video/mp4', 'video/ogg', 'video/webm'];
const audioTypes = ['audio/webm','audio/mpeg', 'audio/wav', 'audio/ogg'];
const THREE_D = ['model/gltf-binary','model/gltf+json'];

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	data: any;
	productBlurLimit?: number | null;
}

const ProductNFT: FC<CardNFTProps> = ({ data, className = "", productBlurLimit = null, isLiked }) => {

	const [ showBlurFile, setShowBlurFile ] = useState<boolean>(false)

	useEffect(() => {
		if ( productBlurLimit != null && +productBlurLimit <= data['reportedSpamCount'] ) {
			setShowBlurFile(true);
		}
	}, [])
	
	const checkThumbnailImagePath = (url:string) => {
		if (url.match(/\.(svg|jpg|jpeg|png|gif|webp)$/i)){
			return url
		} else {
			if(videoTypes.includes(data.mimeType)){
				return VideoFile
			} else if(audioTypes.includes(data.mimeType)){
				return AudioFile
			} else if (THREE_D.includes(data.mimeType)){
				return _3DFile
			} else {
				return url
			}
			
		}
	};

	const removeBlurEffect = () => {
		setShowBlurFile(false)
	}


	const getPrice = ()=>{
		if(data?.productPrice && data?.productPrice.length){
			let activePrice = data.productPrice.filter((price:any)=>price.isActive ==1);
			return activePrice.length?activePrice[0].cryptoStartPrice:'NA'
		} else {
			return 'NA';
		}
		
	}


	return (
		<div
			className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md ${className}`}
			data-nc-id="CardNFT"
		>
			<div className={"relative flex-shrink-0" + (showBlurFile ? ' spam-product-card' : '')}>
				<div>
					{
						showBlurFile && (
							<div className="spam-overlay spam-overlay-rounded-full">
								<HiOutlineEyeOff />
								<p className="text-sm mb-4">Inappropriate content.</p>
								<ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={removeBlurEffect}>view</ButtonSecondary>
							</div>
						) || ''
					}
					<NcImage
						containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
						src={data?checkThumbnailImagePath(data.productThumbUrl):''}
						className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
					/>
				</div>
				{/* {Math.random() > 0.5 ? (
          <ItemTypeVideoIcon className="absolute top-3 left-3 !w-9 !h-9" />
        ) : (
          <ItemTypeImageIcon className="absolute top-3 left-3 !w-9 !h-9" />
        )} */}
				{/*   <LikeButton
          liked={isLiked}
          className="absolute top-3 right-3 z-10 !h-9"
        /> */}
				<div className="absolute top-3 inset-x-3 flex"></div>
			</div>

			<div className="p-4 py-4 space-y-3">
				<div className="flex justify-between">
					<span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative">
						{data?.collectionName}
					</span>
					{data?.isFeatured == 1 && (<span className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100  relative">Featured</span>)}

				</div>
				<h2 className={`text-lg font-medium mt-0`}>
					{data?.productName}
				</h2>

				{/* <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div> */}

				<div className="flex justify-between items-end ">
					
					<Prices network={data?.network}  price={getPrice()} />
					{/* <div className="flex items-center text-sm text-neutral-500 dark:text-neutral-400">
            <ClockIcon className="w-4 h-4" />
            <span className="ml-1 mt-0.5">
              {Math.floor(Math.random() * 20) + 1} hours left
            </span>
          </div> */}
				</div>
			</div>

			<Link to={"/product/" + data?.productID} className="absolute inset-0"></Link>
		</div>
	);
};

export default ProductNFT;
