import React, { FC, useCallback, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import AvatarDropdown from "./AvatarDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import { useAuthStatus } from "_common/hooks/authHook";
import { BsCart } from "react-icons/bs";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCartSelector } from "_common/hooks/selectors/cartSelector";
import Select from "react-select";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';
import LanguageDropdown from "components/LanguageDropdown";

export interface MainNav2LoggedProps { }

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {

  const isAuth = useAuthStatus();
  const cart: any = useCartSelector();
  const commonApi = useCommonApi();
  const navigate = useNavigate()

  const [collections, setCollections] = useState<any>(null)
  const [products, setProducts] = useState<any>(null)
  const [customers, setCustomers] = useState<any>(null)
  const [groupedOptions, setGroupedOptions] = useState<any>(null)

  const dialCodeOptionUI = (props: any) => {
    const { innerProps, innerRef, data } = props;
    return (
      <div ref={innerRef} {...innerProps} className="global-search-options ml-4 cursor-pointer" >
        {data?.productName || data?.collectionName || (data?.firstName + " " + data?.lastName)}{" "}
        
        <span>{data?.productName ? data?.collectionName : null}</span>
        <span>{data?.collectionName ? data?.collectionType : null}</span>
        <span>{data?.customerCode ? data?.emailID : null}</span>
      </div>
    );
  }

  const getSearchList = (text: string) => {
    if (text && text.length > 0) {
      commonApi.getGlobalSearch({ "searchText": text },
        (message: string, resp: any) => {
          console.log(resp)
          if (resp.collections.length > 0) {
            setCollections(resp.collections.map((data: any) => {
              data.label = data.collectionName;
              data.value = data.collectionID;
              data.seachCheck = "collection";
              return data;
            }))
          }
          if (resp.products.length > 0) {
            setProducts(resp.products.map((data: any) => {
              data.label = data.productName;
              data.value = data.productID;
              data.seachCheck = "product";
              return data;
            }))
          }
          if (resp.customers.length > 0) {
            setCustomers(resp.customers.map((data: any) => {
              data.label = data.firstName + " " + data.lastName;
              data.value = data.customerID;
              data.seachCheck = "author";
              return data;
            }))
          }
        }, (message: string) => {
          console.log(message)
        })
    } else {
      setGroupedOptions(null)
    }
  }

  const debouncedChangeHandler = useCallback(
    debounce(getSearchList, 500)
    , []);

  // const debounce = (callBack: any, delay: number, data: string) => {
  //   let timer: any;
  //   return function (...args: any) {
  //     if (timer) { clearTimeout(timer) }
  //     timer = setTimeout(() => {
  //       console.log("called")
  //       callBack(data)
  //     }, delay)
  //   }
  // }

  // const betterSearchFunction = (data: string) => {
  //   debounce(getSearchList, 500, data)
  // }

  const handleChange = (data: any) => {
    if (data?.seachCheck == "collection") {
      navigate(`/${data?.seachCheck}/${data?.value}`)
    }
    if (data?.seachCheck == "product") {
      navigate(`/${data?.seachCheck}/${data?.value}`)
    }
    if (data?.seachCheck == "author") {
      navigate(`/${data?.seachCheck}/${data?.value}`)
    }
  }

  useEffect(() => {
    const tempOptions: any = [];
    if (collections) {
      tempOptions.push({
        label: "Collections",
        options: collections
      })
    }
    if (products) {
      tempOptions.push({
        label: "Products",
        options: products
      })
    }
    if (customers) {
      tempOptions.push({
        label: "Authors",
        options: customers
      })
    }
    setGroupedOptions(tempOptions)
  }, [collections, products, customers])

  return (
    <div className={`nc-MainNav2Logged relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />

          <div className="hidden sm:block flex-grow max-w-xs">
            <Select
              components={{ Option: dialCodeOptionUI }}
              className={"select-box"}
              options={groupedOptions ? groupedOptions : []}
              placeholder={"search"}
              onInputChange={(e) => debouncedChangeHandler(e)}
              onChange={(e) => handleChange(e)}
            // value={value}
            // defaultValue={null}
            />
          </div>

        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <Navigation />
            <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <div className="flex">
              <SwitchDarkMode />
              {/*<NotifyDropdown />*/}
            </div>
            <div>

              <ButtonSecondary href="/cart" sizeClass="px-4 py-2 sm:px-5 relative border-0" >
                <BsCart className="text-2xl mr-1" /> <span className="cart-count">{cart.length}</span>
              </ButtonSecondary>
            </div>
            <LanguageDropdown />            
            {/*
            <ButtonPrimary
              href="/page-upload-item"
              sizeClass="px-4 py-2 sm:px-5"
            >
              Go To Store
            </ButtonPrimary>
            */}
            <div></div>
            {!isAuth && (
              <ButtonPrimary href="/login" sizeClass="px-4 py-2 sm:px-5" >
                Login
              </ButtonPrimary>
            )}
            {isAuth && (
              <AvatarDropdown />
            )}
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            {/*<NotifyDropdown />*/}
            {isAuth && (
              <AvatarDropdown />
            )}
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
