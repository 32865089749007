import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1img.png";
import HIW2img from "images/HIW2img.png";
import HIW3img from "images/HIW3img.png";
import HIW4img from "images/HIW4img.png";
import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";
import rightImg from "images/SVG-subcribe2.png";

export interface SectionHowItWorkProps {
  className?: string;
  data?: any;
}

const DEMO_DATA = [
  {
    id: 1,
    img: HIW1img,
    imgDark: HIW1img,
    title: "Filter & Discover",
    desc: "Filter through our selection to find exactly what you want. Explore and discover new possibilities",
  },
  {
    id: 2,
    img: HIW2img,
    imgDark: HIW2img,
    title: "Connect wallet",
    desc: "Unlock the full potential of our platform by connecting your wallet to buy, sell, and trade NFTs",
  },
  {
    id: 3,
    img: HIW3img,
    imgDark: HIW3img,
    title: "Start trading",
    desc: "Trade your favorite NFTs instantly. Buy and sell NFTs with simple and intuitive trading platform",
  },
  {
    id: 4,
    img: HIW4img,
    imgDark: HIW4img,
    title: "Earn money",
    desc: "Earn money by minting and selling your unique NFT creations and unlock new revenue streams",
  },
];

const HomeSectionHowitsworks: FC<SectionHowItWorkProps> = ({
  className = "",
  data = null,
}) => {
  return (
    <div className="nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center xl:mt-24">
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <NcImage src={rightImg} />
      </div>
      <div className={`nc-SectionHowItWork  ${className}`} data-nc-id="SectionHowItWork">
        <div dangerouslySetInnerHTML={{__html: data?.HowItWorksHome?.value}}></div>
        {/*
        <div className="relative grid sm:grid-cols-2 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20">
          <img
            className="hidden md:block absolute inset-x-0 -top-1"
            src={VectorImg}
            alt="vector"
          />
          {data.map((item: typeof DEMO_DATA[number], index: number) => (
            <div
              key={item.id}
              className="relative flex flex-col items-center max-w-xs mx-auto"
            >
              <NcImage
                containerClassName="mb-5 sm:mb-10 lg:mb-20 max-w-[200px] mx-auto"
                src={item.img}
              />
              <div className="text-center mt-auto space-y-5">
                <Badge
                  name={`Step ${index + 1}`}
                  color={
                    !index
                      ? "blue"
                      : index === 1
                      ? "pink"
                      : index === 2
                      ? "yellow"
                      : "green"
                  }
                />
                <h3 className="text-lg font-semibold">{item.title}</h3>
                <span className="block text-neutral-500 dark:text-neutral-400">
                  {item.desc}
                </span>
              </div>
            </div>
          ))}
        </div>
        */}
      </div>
    </div>
  );
};

export default HomeSectionHowitsworks;
