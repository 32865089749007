import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "./ItemTypeImageIcon";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ClockIcon } from "@heroicons/react/outline";
import ItemTypeVideoIcon from "./ItemTypeVideoIcon";
import moment from 'moment'
import { useOrderStatusSelector } from "_common/hooks/selectors/commonSelector";
import { useOrdersApi } from "_common/hooks/api/ordersApi";
import { DATE_FORMAT } from "_common/_constant";

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	data: any;
}

const OrderThumb: FC<CardNFTProps> = ({ data, className = "", isLiked }) => {

	const orderApi = useOrdersApi();
	const orderStatuses = useOrderStatusSelector();

	const getOrderStatuses = () => {
		orderApi.getOrderStatuses({
		}, (message: string, resp: any) => {}, (message: string) => {})
	}


	const getPrice = () => {
		if (data.productPrice.length) {
			let activePrice = data.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}

	}

	const getOrderStatus = (statusID:any)=>{
		if (orderStatuses && orderStatuses.length) {
			let status = orderStatuses.filter((stat: any) => stat.statusID == statusID);
			return status.length ? status[0].statusName : 'NA'
		} else {
			return 'NA';
		}
	}

	// const getOrderStatusClass = (statusID:any)=>{
	// 	switch(statusID) {
	// 		case 1 : return 'text-yellow-800 bg-yellow-500';
	// 		case 2 : return 'text-green-800 bg-green-100';
	// 		case 3 : return 'text-red-800 bg-red-100';

	// 		default : return 'text-gray-800 bg-gray-100'
	// 	}
	// }

	const getOrderStatusClass = (statusID:any)=>{
		switch(statusID) {
			case 0 : return 'text-yellow-800 bg-yellow-500';
			case 1 : return 'text-green-800 bg-green-100';
			// case 3 : return 'text-red-800 bg-red-100';
			default : return 'text-gray-800 bg-gray-100'
		}
	}

	useEffect(() => {
		if (orderStatuses == null) {
			getOrderStatuses();
		}
	}, [orderStatuses])


	return (
		<div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md ${className}`} >
			<div className="relative flex-shrink-0 ">
				<div>
					<NcImage
						containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
						src={data?.productThumbUrl}
						className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
					/>
				</div>
				{/* {Math.random() > 0.5 ? (
          <ItemTypeVideoIcon className="absolute top-3 left-3 !w-9 !h-9" />
        ) : (
          <ItemTypeImageIcon className="absolute top-3 left-3 !w-9 !h-9" />
        )} */}
				{/* <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10 !h-9" /> */}
				<div className="absolute top-3 inset-x-3 flex"></div>
			</div>

			<div className="p-4 py-4 space-y-3">
				<div className="flex justify-between">
					<span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative">
						{/* {moment(data.orderDate).format('DD MMMM, YYYY')} */}
						{moment.utc(data.addedDate).local().format('DD MMMM, YYYY')}
					</span>
					<span className={"inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative relative " + getOrderStatusClass(data.isActive)}>{data.isActive == 1 ? "Active" : "In-Active"}</span>

				</div>
				<h2 className={`text-lg font-medium mt-0`}>
					#{data.orderNo}
				</h2>

				<div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div>

				<div className="flex justify-between items-end ">

					{/* <Prices network={data.network} price={getPrice()} />
					<div className="flex items-center text-sm text-neutral-500 dark:text-neutral-400">
						<ClockIcon className="w-4 h-4" />
						<span className="ml-1 mt-0.5">
							{Math.floor(Math.random() * 20) + 1} hours left
						</span>
					</div> */}
				</div>
			</div>

			<Link to={"/order/" + data.orderID} className="absolute inset-0"></Link>
		</div>
	);
};

export default OrderThumb;
