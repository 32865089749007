import React, { FC, useEffect, useRef, useState } from "react";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Switch from "shared/Switch/Switch";
import CheckBox from "shared/Checkbox/nativeCheckbox";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import FormItem from "components/FormItem";

import { Gallery_Form } from '../../_common/interfaces/FormProps'
import { GalleryFormSchemaValidation } from '../../utils/form-validation'
import { useGalleryApi } from '../../_common/hooks/api/GalleryApiHook'
import { useCollectionApi } from '../../_common/hooks/api/collectionApiHook'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import Textarea from "shared/Textarea/Textarea";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import { URLS } from "_config";

export interface GalleryAddUpdateProps {
  classNames?: string;
  handleCancel: () => void;
}

const DetailsGallery: FC<GalleryAddUpdateProps> = ({ handleCancel, classNames }) => {

    const { id: galleryId } = useParams();
    const user: any = useUserSelector()
    const { showLoader, hideLoader } = useAppLoader();
    const [ industryList, setIndustryList ] = useState<any[]>([])
    const [ collectionList, setCollectionList ] = useState<any[]>([])
    const [ galleryDetails, setGalleryDetails ] = useState<any>({})

    const { getGalleryList, getIndustryTypeList, galleryEdit, galleryAdd } = useGalleryApi();
    const { getCollections } = useCollectionApi();

    const navigate = useNavigate();

    const { register, control, getValues, setValue, watch, setError, resetField, handleSubmit, reset, formState: { errors } } = useForm<Gallery_Form>({
        defaultValues: {
            galleryName: '',
            galleryCode: '',
            galleryDescription: '',
            galleryIndustryType: null,
            wallCount: 1,
            adsBoxCount: 1,
            // collection: null,
            isPrivate: false,
            galleryPassword: '',
            isActive: false
        },
        resolver: yupResolver(GalleryFormSchemaValidation)
    });

    useEffect(() => {
        if ( galleryId ) {
            getGalleryDetails()
        }
        // handleIndustryList();
        // handleCollectionList();
    }, [])

    useEffect(() => {
        if ( galleryDetails && Object.keys(galleryDetails).length /*&& industryList.length*/ ) {
            resetIndustry(galleryDetails['galleryIndustryTypeID'])
            // const selectedIndustry = industryList.filter((e : any) => +e['value'] == +galleryDetails['galleryIndustryTypeID'])
            // const selectedCollection = collectionList.filter((e : any) => +e['value'] == +galleryDetails['collectionID'])

            reset({
                galleryName: galleryDetails['galleryName'],
                galleryCode: galleryDetails['galleryCode'],
                galleryDescription: galleryDetails['galleryDescription'],
                // galleryIndustryType: selectedIndustry,
                wallCount: galleryDetails['wallCount'],
                adsBoxCount: galleryDetails['adsBoxCount'],
                // collection: selectedCollection,
                isPrivate: !!galleryDetails['isPrivate'],
                galleryPassword: galleryDetails['galleryPassword'],
                isActive: !!galleryDetails['isActive'],
            })
        }
    }, [galleryDetails/*, industryList*/])

    const resetIndustry = (industryId: number) => {
        handleIndustryList({galleryIndustryTypeID: industryId}).then(resp => {
            if ( resp.length ) {
                setValue('galleryIndustryType', resp[0])
            }
        }).catch(err => {
            toast.error(err)
        })
    }

    const handleIndustryList = (inputParams: any): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                usePaging: true,
				pageSize: 5,
				pageNumber: 1,
                ...inputParams
            }
            getIndustryTypeList(params, (message: string, resp: any) => {
                const data = resp.industryTypes.map((e: any) => ({
                    label: e['industryName'],
                    value: +e['galleryIndustryTypeID']
                }))
                // setIndustryList(data);
                return resolve(data);
            }, (message: string) => {
                toast.error(message)
                return resolve([])
            })
        })
    }

    const handleCollectionList = () => {
        getCollections({}, (message: string, resp: any) => {
            const data = resp.collections.map((e: any) => ({
                label: e['collectionName'],
                value: +e['collectionID']
            }))
            setCollectionList(data);
        }, (message: string) => {
            toast.error(message)
        })
    }

    const getGalleryDetails = () => {
        const params = {
            galleryID: galleryId
        }
        showLoader()
        getGalleryList(params, (message: string, resp: any) => {
            hideLoader()
            if ( resp['metaGalleryDM'] && resp['metaGalleryDM'].length ) {
                setGalleryDetails(resp['metaGalleryDM'][0]);
            } else {
                toast.error('Gallery not found!')
                navigate(URLS.MY_GALLERY)
            }
        }, (message: string) => {
            hideLoader()
            toast.error(message)
            navigate(URLS.MY_GALLERY)
        })
    }

    const loadIndustryOptions = ( inputValue: string, callbackFn: (options: any[]) => void) => {
        setTimeout(async() => {
          const data = await handleIndustryList({searchText: inputValue})
          callbackFn(data);
        }, 1000);
    };
  
  
  const gallerySubmitted = (data: any) => {
    console.log('data: ', data)
    const params: any = {
        galleryName: data['galleryName'],
        galleryCode: data['galleryCode'],
        galleryDescription: data['galleryDescription'],
        galleryIndustryTypeID: data['galleryIndustryType'] && +data['galleryIndustryType']['value'] || 0,
        wallCount: data['wallCount'],
        adsBoxCount: data['adsBoxCount'],
        // collectionID: data['collection'] && +data['collection']['value'] || 0,
        isPrivate: +data['isPrivate'],
        galleryPassword: data['galleryPassword'],
        isActive: +data['isActive'],
        isCreatedByCustomer: 1
    }
    showLoader();
    if ( galleryDetails && galleryDetails['galleryID'] ) {
        params['galleryID'] = galleryDetails['galleryID'];
        galleryEdit(params, (message: string, resp: any) => {
            hideLoader()
            if ( resp.successful ) {
                toast.success(message)
                navigate('/my-gallery')
            } else {
                toast.error(message)
            }
        }, (message: string) => {
            hideLoader()
            toast.error(message)
        })
    } else {
        galleryAdd(params, (message: string, resp: any) => {
            hideLoader()
            if ( resp.successful ) {
                toast.success(message)
                navigate('/my-gallery')
            } else {
                toast.error(message)
            }
        }, (message: string) => {
            hideLoader()
            toast.error(message)
        })
    }
  }

  return (
    <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              { galleryDetails && galleryDetails['galleryID'] ? 'Update Gallery' : 'Create Gallery' }
            </h2>
            {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name, create your profile URL and
              manage other personal settings.
            </span> */}
          </div>
          
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form className="" onSubmit={handleSubmit(gallerySubmitted)}>
              <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                {/* ---- */}
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-5">
                      <FormItem label="Name" isRequired={true}>
                          <Input { ...register('galleryName') } autoComplete={'off'} placeholder={'Name'} tabIndex={1} />
                          { 
                              errors && errors.galleryName && errors.galleryName.message &&  
                              (<p className='text-red-400'>
                                  {errors.galleryName.message}
                              </p>)
                          }
                      </FormItem>
                      <FormItem label="Code" isRequired={true}>
                          <Input { ...register('galleryCode') } autoComplete={'off'} placeholder={'Code'} tabIndex={2} />
                          { 
                              errors && errors.galleryCode && errors.galleryCode.message &&  
                              (<p className='text-red-400'>
                                  {errors.galleryCode.message}
                              </p>)
                          }
                      </FormItem>
                      <FormItem label="Industry Type" isRequired={true}>
                          <Controller
                              name={"galleryIndustryType"}
                              control={control}
                              render={({ field: { value, onChange, onBlur } }) => (
                                <AsyncSelect 
                                    className={`mt-1`}
                                    cacheOptions 
                                    loadOptions={loadIndustryOptions} 
                                    placeholder={'Select industry'}
                                    value={value}
                                    defaultValue={value}
                                    // formatOptionLabel={formatOptionLabel}
                                    onChange={onChange}
                                    defaultOptions />
                              )}
                          />
                          { 
                              errors && errors.galleryIndustryType && errors.galleryIndustryType.message &&  
                              (<p className='text-red-400'>
                                  {errors.galleryIndustryType.message}
                              </p>)
                          }
                      </FormItem>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-5">
                      <FormItem label="Wall Count" isRequired={true}>
                          <Input { ...register('wallCount') } placeholder={'Wall Count'} autoComplete={'off'} tabIndex={4} />
                          { 
                              errors && errors.wallCount && errors.wallCount.message &&  
                              (<p className='text-red-400'>
                                  {errors.wallCount.message}
                              </p>)
                          }
                      </FormItem>
                      <FormItem label="Box Count" isRequired={true}>
                          <Input { ...register('adsBoxCount') } placeholder={'Box Count'} autoComplete={'off'} tabIndex={5} />
                          { 
                              errors && errors.adsBoxCount && errors.adsBoxCount.message &&  
                              (<p className='text-red-400'>
                                  {errors.adsBoxCount.message}
                              </p>)
                          }
                      </FormItem>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-5">
                        <FormItem label="Is Active">
                            <Switch label={""} {...register('isActive')} tabIndex={6} />
                            {
                                errors && errors.isActive && errors.isActive.message &&
                                (<p className='text-red-400'>
                                    {errors.isActive.message}
                                </p>)
                            }
                        </FormItem>
                        <FormItem label="Is Private">
                            <Switch label={""} {...register('isPrivate')} tabIndex={7} />
                            {
                                errors && errors.isPrivate && errors.isPrivate.message &&
                                (<p className='text-red-400'>
                                    {errors.isPrivate.message}
                                </p>)
                            }
                        </FormItem>
                        {
                            watch('isPrivate') ? (
                                <FormItem label="Password" isRequired={true}>
                                    <Input { ...register('galleryPassword') } autoComplete={'off'} placeholder={'Password'} tabIndex={8} type='password' />
                                    { 
                                        errors && errors.galleryPassword && errors.galleryPassword.message &&  
                                        (<p className='text-red-400'>
                                            {errors.galleryPassword.message}
                                        </p>)
                                    }
                                </FormItem>
                            ) : ''
                        }
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5">
                      <FormItem label="Description" isRequired={true}>
                          <Textarea rows={6} className="mt-1.5" placeholder="Description here .." {...register('galleryDescription')} tabIndex={9} />
                          {
                              errors && errors.galleryDescription && errors.galleryDescription.message &&
                              (<p className='text-red-400'>
                                  {errors.galleryDescription.message}
                              </p>)
                          }
                      </FormItem>
                  </div>

                      
                  <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                    <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                    <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
                  </div>
              </div>
          </form>
    </div>
  );
};

export default DetailsGallery;
