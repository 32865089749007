import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useSiteSettingsApi() {

  const callApi = useApiCall()

  const getSiteSettings = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SITE_SETTINGS.GET_SITE_SETTINGS, data, onSuccess, onError);
  }

  const postSiteSettings = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SITE_SETTINGS.POST_SITE_SETTINGS, data, onSuccess, onError);
  }

  const addCustodialWallet = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SITE_SETTINGS.ADD_CUSTODIAL_WALLET, data, onSuccess, onError);
  }

  const fetchCustodialWallet = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SITE_SETTINGS.FETCH_CUSTODIAL_WALLET, data, onSuccess, onError);
  }

  return {
    getSiteSettings: getSiteSettings,
    postSiteSettings: postSiteSettings,
    addCustodialWallet,
    fetchCustodialWallet
  }
}