import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus } from '../_common/hooks/authHook';
import { useStoreSettingsSelector } from '_common/hooks/selectors/storeSelector';
const PrivateRoutes = () => {
    const isAuth = useAuthStatus();
    const settings = useStoreSettingsSelector();
    const published:boolean = !!settings.config.isPublished;
    return (
        published?(isAuth?<Outlet/>:<Navigate to="/login" />):<Navigate to="/unpublished" />
    )
}

export default  PrivateRoutes;