import React from 'react';
import "@google/model-viewer";
import NcImage from "shared/NcImage/NcImage";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
      poster: any;
      alt: string;
      ar: any
    }
  }
}

const imgTypes = ['image/svg+xml','image/jpeg', 'image/png', 'image/gif','image/webp'];
const videoTypes = ['video/webm','video/mp4', 'video/ogg', 'video/webm'];
const audioTypes = ['audio/webm','audio/mpeg', 'audio/wav', 'audio/ogg'];
const THREE_D = ['model/gltf-binary','model/gltf+json'];

const DisplayFile = ({mimeType, link, placeholder, className}: any) => {
  return (
    <>
      {imgTypes.includes(mimeType) ? (
        <NcImage src={link} className={className} />
      ) : videoTypes.includes(mimeType) ? (
        <video width={'100%'} height="240" controls>
          <source src={link} type={mimeType} />
          Your browser does not support the video tag.
        </video>
      ) : audioTypes.includes(mimeType) ? (
        <audio controls>
          <source src={link} type={mimeType} />
          Your browser does not support the audio element.
        </audio>
      ) : THREE_D.includes(mimeType) && typeof window !== 'undefined' ? (
        <Model link={link} poster={placeholder}/>
      ) : null}
    </>
  );
};

export default DisplayFile;


export const Model = ({link, poster}: any) => (
  <div id="card">
    <model-viewer
      src={link}
      ios-src=""
      poster={poster}
      alt="A 3D model"
      shadow-intensity="1"
      camera-controls
      auto-rotate
      ar
    ></model-viewer>
  </div>
);