import React from "react";
import { BrowserRouter, Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from '../_config/privateRoutes';
import OpenRoutes from '../_config/openRoutes';
import { useStoreSettingsSelector, useThemeSelector } from "_common/hooks/selectors/storeSelector";
import { URLS } from "_config";
import { Helmet } from "react-helmet";


import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PayoutGroup from "containers/PayoutGroup/PayoutGroupList";
import PageCollection from "containers/PageCollection";
import Collection from "containers/Collection/CollectionList";
import AddUpdateCollection from "containers/Collection/addUpdateCollection";
import AddUpdatePayoutGroup from "containers/PayoutGroup/AddUpdatePayoutGroup";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import CategoryList from "containers/Category/CategoryList";
import AddUpdateCategory from "containers/Category/addUpdateCategory";
import PageStoreconfiguration from "containers/AccountSettings/StoreConfiguration/PageStoreconfiguration";
import PageCustomfieldmanage from "containers/ProductSettings/CustomFields/PageCustomfieldmanage";
import PageAddcustomfield from "containers/ProductSettings/CustomFields/PageAddcustomfield";
import PreSales from "containers/PreSales/PreSales";
import AddToPreSales from "containers/PreSales/AddToPreSales";
import Airdrops from "containers/Airdrops/Airdrops";
import AddToAirdrops from "containers/Airdrops/AddToAirdrops";




import ScrollToTop from "./ScrollToTop";


import Page404 from "containers/Page404/Page404";
import Unpublished from "containers/Unpublished/Unpublished";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageForgotpassword from "containers/PageForgotpassword/PageForgotpassword";
import PageSetforgottenpassword from "containers/PageSetforgottenpassword/PageSetforgottenpassword";


import SiteHeader_default from "themes/Normalize/containers/SiteHeader";
import SiteHeader_Normalize from "themes/Normalize/containers/SiteHeader";
import SiteHeader_Modern from "themes/Normalize/containers/SiteHeader";
import Footer from "shared/Footer/Footer";

import PageHome from "themes/default/containers/PageHome"; //Game Theme
import PageHome2 from "themes/Normalize/containers/PageHome2"; //Modern Theme
import PageHome3 from "themes/Modern/containers/PageHome3"; //3rd Theme

import PageCollectionDefault from "themes/Normalize/containers/PageCollectionNormalize";
import PageCollectionNormalize from "themes/Normalize/containers/PageCollectionNormalize";
import PageCollectionModern from "themes/Normalize/containers/PageCollectionNormalize";

import PageCollectionDetailDefault from "themes/Normalize/containers/PageCollectionDetailNormalize";
import PageCollectionDetailNormalize from "themes/Normalize/containers/PageCollectionDetailNormalize";
import PageCollectionDetailModern from "themes/Normalize/containers/PageCollectionDetailNormalize";

import PageProducts from "containers/ProductSettings/Products/PageProducts";
import Store_Default from "themes/Normalize/containers/store";
import Store_Normalize from "themes/Normalize/containers/store";
import Store_Modern from "themes/Normalize/containers/store";

import Cart_Default from "themes/Normalize/containers/cart";
import Cart_Normalize from "themes/Normalize/containers/cart";
import Cart_Modern from "themes/Normalize/containers/cart";

import PageProductAdd from "containers/ProductSettings/Products/PageProductAdd";

import ProductDetailPage_Default from "themes/Normalize/containers/NftDetailPage/ProductDetailPage";
import ProductDetailPage_Normalize from "themes/Normalize/containers/NftDetailPage/ProductDetailPage";
import ProductDetailPage_Modern from "themes/Normalize/containers/NftDetailPage/ProductDetailPage";

import NftDetailPage_Default from "themes/Normalize/containers/NftDetailPage/NftDetailsPage";
import NftDetailPage_Normalize from "themes/Normalize/containers/NftDetailPage/NftDetailsPage";
import NftDetailPage_Modern from "themes/Normalize/containers/NftDetailPage/NftDetailsPage";

import AuthorDetailPage_Default from "themes/Normalize/containers/authors/authorsDetails";
import AuthorDetailPage_Normalize from "themes/Normalize/containers/authors/authorsDetails";
import AuthorDetailPage_Modern from "themes/Normalize/containers/authors/authorsDetails";

import PageLoginDefault from "themes/default/containers/PageLoginDefault";

import Checkout_Normalize from "themes/Normalize/containers/Checkout/Checkout";
import Pages from "components/Pages";

import CreateCollection from "containers/Collection/addUpdateCollection";

import MyOrders_Default from "themes/Normalize/containers/MyOrders";
import MyOrders_Normalize from "themes/Normalize/containers/MyOrders";
import MyOrders_Modern from "themes/Normalize/containers/MyOrders";

import OrderDetails_Default from "themes/Normalize/containers/OrderDetails";
import OrderDetails_Normalize from "themes/Normalize/containers/OrderDetails";
import OrderDetails_Modern from "themes/Normalize/containers/OrderDetails";

import MyProducts_Default from "themes/Normalize/containers/MyProducts";
import MyProducts_Normalize from "themes/Normalize/containers/MyProducts";
import MyProducts_Modern from "themes/Normalize/containers/MyProducts";
import PageMycollection from "themes/Normalize/containers/PageMycollection";
import Gallery_Default from "themes/Normalize/containers/Gallery/Gallery";
import Gallery_Normalize from "themes/Normalize/containers/Gallery/Gallery";
import Gallery_Modern from "themes/Normalize/containers/Gallery/Gallery";
import MynftsPage from "themes/Normalize/containers/MynftsPage";
import ConnectWallet_Default from "themes/Normalize/components/Crypto/ConnectWallet";
import ConnectWallet_Normalize from "themes/Normalize/components/Crypto/ConnectWallet";
import ConnectWallet_Modern from "themes/Normalize/components/Crypto/ConnectWallet";
import ContactUs_Default from "themes/Normalize/containers/ContactUs";
import ContactUs_Normalize from "themes/Normalize/containers/ContactUs";
import ContactUs_Modern from "themes/Normalize/containers/ContactUs";
import PageCollectionGallery from "themes/Normalize/containers/PageCollectionGallery";
import FreeRoutes from "_config/freeRoutes";
import NftList from "themes/Normalize/containers/NftsList";

import MyBasket from "containers/Basket/BasketList";
import MyGallery from "containers/Gallery/GalleryList";
import GalleryAddUpdate from "containers/Gallery/AddUpdateGallery";
import AddUpdateGallery from "containers/Gallery/AddUpdateGallery";
import AddUpdateBasket from "containers/Basket/AddUpdateBasket";



const MyRoutes = () => {
	const theme = useThemeSelector();
	const storeSettings = useStoreSettingsSelector();
	const settings = useStoreSettingsSelector();
    const published:boolean = !!settings.config.isPublished;

	const header: any = [<SiteHeader_default />, <SiteHeader_Normalize />, <SiteHeader_Modern />];

	const homePage: any = [<PageHome />, <PageHome2 />, <PageHome3 />];
	const collectionPage: any = [<PageCollectionDefault />, <PageCollectionNormalize />, <PageCollectionModern />];
	const collectionDetailPage: any = [<PageCollectionDetailDefault />, <PageCollectionDetailNormalize />, <PageCollectionDetailModern />];
	const productDetailPage: any = [<ProductDetailPage_Default />, <ProductDetailPage_Normalize />, <ProductDetailPage_Modern />];
	const storePage: any = [<Store_Default />, <Store_Normalize />, <Store_Modern />];
	const cartPage: any = [<Cart_Default />, <Cart_Normalize />, <Cart_Modern />];
	const checkoutPage: any = [<Checkout_Normalize />, <Checkout_Normalize />, <Checkout_Normalize />];
	const loginPage: any = [<PageLoginDefault />];
	const accountPage: any = [<AccountPage />];
	const authorPage: any = [<AuthorDetailPage_Default />, <AuthorDetailPage_Normalize />, <AuthorDetailPage_Modern />];
	const myProductsPage: any = [<MyProducts_Default />, <MyProducts_Normalize />, <MyProducts_Modern />];
	const myOrdersPage: any = [<MyOrders_Default />, <MyOrders_Normalize />, <MyOrders_Modern />];
	const orderDetailsPage: any = [<OrderDetails_Default />, <OrderDetails_Normalize />, <OrderDetails_Modern />];
	const myCollectionPage: any = [<PageMycollection />];
	const galleryPage: any = [<Gallery_Default />, <Gallery_Normalize />, <Gallery_Modern />];
	const galleryAppPage: any = [<PageCollectionGallery />];
	const myNftsPage = [<MynftsPage />]
	const NftsPage = [<NftList />]
	const connectWallet = [<ConnectWallet_Default />, <ConnectWallet_Normalize />, <ConnectWallet_Modern />]
	const contactUsPage: any = [<ContactUs_Default />, <ContactUs_Normalize />, <ContactUs_Modern />];
	const nftDetailPage: any = [<NftDetailPage_Default />, <NftDetailPage_Normalize />, <NftDetailPage_Modern />];

	const getComponent = (pageName: string, components: any): any => {
		//let index: number = theme.pages[pageName].replace(/^theme-/, '');
		//return components[index] ? components[index] : components[0];
		if (components[theme.index]) {
			return (components[theme.index])
		} else {
			return (components[0])
		}
	}

	return (
		<BrowserRouter basename={process.env.NODE_ENV === "production" ? "" : ""} >
			<React.Fragment>
				<ScrollToTop />
				<Helmet>
					<link rel="icon" href={storeSettings?.config?.storeFaviconUrl} />
				</Helmet>
				{published? getComponent('header', header) :null}
				
				<Routes>

					<Route element={<OpenRoutes />} >
						<Route path={URLS.LOGIN} element={getComponent('login', loginPage)} />
						<Route path={URLS.SIGNUP} element={<PageSignUp />} />
						<Route path={URLS.FORGOT_PASSWORD} element={<PageForgotpassword />} />
						<Route path={URLS.RESET_PASSWORD} element={<PageSetforgottenpassword />} />
					</Route>

					<Route path={'/unpublished'} element={<Unpublished />} />


					<Route element={<FreeRoutes />} >
						<Route path={URLS.HOME} element={getComponent('home', homePage)} />
						<Route path={URLS.COLLECTIONS} element={getComponent('collections', collectionPage)} />
						<Route path={URLS.COLLECTION_DETAILS} element={getComponent('collectiondetail', collectionDetailPage)} />
						<Route path={URLS.PRODUCT_DETAILS} element={getComponent('productdetails', productDetailPage)} />
						<Route path={URLS.STORE} element={getComponent('products', storePage)} />
						<Route path={URLS.PAGE} element={<Pages />} />
						<Route path={URLS.CHECKOUT} element={getComponent('checkout', checkoutPage)} />
						<Route path={URLS.MY_BASKET} element={<MyBasket/>} />
						<Route path={URLS.BASKET_CREATE} element={<AddUpdateBasket/>} />
						<Route path={URLS.BASKET_EDIT} element={<AddUpdateBasket/>} />
						<Route path={URLS.MY_GALLERY} element={<MyGallery/>} />
						<Route path={URLS.GALLERY_CREATE} element={<AddUpdateGallery/>} />
						<Route path={URLS.GALLERY_EDIT} element={<AddUpdateGallery/>} />
						<Route path={URLS.CART} element={getComponent('cart', cartPage)} />
						<Route path={URLS.AUTHOR_DETAILS} element={getComponent('authordetail', authorPage)} />
						<Route path={'/account'} element={getComponent('account', accountPage)} />
						<Route path={'/my-nfts'} element={getComponent('myproducts', myProductsPage)} />

						<Route path={'/nfts'} element={getComponent('nfts', NftsPage)} />

						<Route path={'/my-collection'} element={getComponent('mycollection', myCollectionPage)} />
						<Route path={URLS.GALLERY} element={getComponent('gallery', galleryPage)} />
						<Route element={<Page404 />} />
						<Route path={URLS.MY_PRODUCTS} element={getComponent('mynfts', myNftsPage)} />
						<Route path={'/contact-us'} element={getComponent('contactus', contactUsPage)} />
						<Route path={'/nft/:id'} element={getComponent('nftdetails', nftDetailPage)} />
						<Route path={'/gallery-app/:id'} element={getComponent('galleryapp', galleryAppPage)} />
						<Route path={URLS.CONNECT_WALLET} element={getComponent('connectwallet', connectWallet)} />
					</Route>

					<Route element={<PrivateRoutes />} >
						<Route path={`create-collection/:id`} element={<CreateCollection />} />
						<Route path={`create-nft/:id`} element={<PageProductAdd />} />
						<Route path={URLS.MY_ORDERS} element={getComponent('myorders', myOrdersPage)} />
						<Route path={URLS.ORDER_DETAILS} element={getComponent('myorders', orderDetailsPage)} />
					</Route>

				</Routes>
				{published? <Footer /> :null}
				
			</React.Fragment>
		</BrowserRouter>
	);
};

export default MyRoutes;
