import React, { FC, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Web3 from 'web3';

import Input from "shared/Input/Input";
import { useAuthStatus } from "_common/hooks/authHook";
import { useProductApi } from '../../../../_common/hooks/api/productApiHook'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import { TransferNftForm } from "_common/interfaces/FormProps";
import { NftTransferFormSchemaValidation } from "utils/form-validation";
import FormItem from "components/FormItem";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { StateExtended } from "_common/interfaces/StateExtended";
import useMetaMask, { getLibrary } from "../../../../_common/provider/metamask-provider";

export interface TransferNftProps {
  className?: string;
  fromWallet: string;
  nft: any;
  collection: any;
  owners?: any;
  cancel: () => void;
}

const TransferNft: FC<TransferNftProps> = ({ className = "", fromWallet, nft = {}, collection={}, cancel, owners = []}) => {

  const { library } = useMetaMask()
  const { ethereum }: any = window;
  const user: any = useSelector((state: StateExtended) => state.user);
  const isAuth = useAuthStatus();
  const { nftTransfetWithCustodial, nftTransfetWithMetamask } = useProductApi();
  const { showLoader, hideLoader } = useAppLoader();

  const [totalNftOwn, setTotalNftOwn] = useState<number>(1)

  const { register, control, handleSubmit, reset, setValue, formState: { errors } } = useForm<TransferNftForm>({
    defaultValues: {
        cryptoAddress: '',
        totalOwn: 1,
        quantity: 1
    },
    resolver: yupResolver(NftTransferFormSchemaValidation)
  });

  const formSubmit = (data: any) => {
    if ( user && user.user && (user.user.isCustodialWalletEnabled === "true" || user.user.isCustodialWalletEnabled === true) && user.user.custodialWalletAddress ) {
      transferWithCustodial(data['cryptoAddress'], +data['quantity'])
    } else {
      transferNft(data['cryptoAddress'], +data['quantity'])
    }
  }

  const transferNft = async (toWallet: string, quantity: number) => {
    if (typeof window !== 'undefined') {
      if ( !Web3.utils.isAddress(toWallet) ) {
        toast.error('Invalid wallet address');
      } else {
        const web3 = new Web3(library.provider);
        const abi = collection && collection.contractAddress && collection.contractAddress.abi && JSON.parse(collection.contractAddress.abi) || null;

        let depContract = new web3.eth.Contract(abi, collection.address);
        let params: any = {
          from: String(fromWallet), //current user address
          to: collection?.address, //contract address
        };
        if ( nft['collectionTypeId'] == 1 ) {
          params['data'] = depContract.methods.safeTransferFrom(fromWallet, toWallet, nft.token).encodeABI();
        } else {
          params['data'] = depContract.methods.safeTransferFrom(fromWallet, toWallet, nft.token, quantity, []).encodeABI();
        }

        //trigger metamask
        web3.eth
          .sendTransaction(params)
          .on('transactionHash', (txn: string) => {
            transferWithMetamask(txn, toWallet, quantity)
          })
          .on('error', (err: any) => {
            if (err.code === 4001) {
              toast.error('Please accept the transaction to continue');
            } else {
              toast.error('Some error occurred, please try again');
            }
          });
      }
    }
	}

  const transferWithMetamask = (hash: string, toWallet: string, quantity: number) => {
    showLoader();
    let params = {
      transactionHash: hash,
      tokenID: nft.token,
      toAddress: toWallet,
      quantity: quantity,
      collectionID: collection['collectionID'],
      fromAddress: fromWallet
    };
    
    nftTransfetWithMetamask(params, (message: string, resp: any) => {
      hideLoader()
      if ( resp['successful'] ) {
        toast.success(message)
      } else {
        toast.error(message)
      }
    }, 
    (message: string, resp: any) => {
      hideLoader();
      toast.error(message)
    })
  }

  const transferWithCustodial = (toWallet: string, quantity: number) => {
    showLoader();
    let params = {
      tokenID: nft.token,
      toAddress: toWallet,
      quantity: quantity,
      collectionID: collection['collectionID'],
      fromAddress: fromWallet
    };
    
    nftTransfetWithCustodial(params, (message: string, resp: any) => {
      hideLoader()
      if ( resp['successful'] ) {
        reset();
        cancel();
        toast.success(message)
      } else {
        toast.error(message)
      }
    }, 
    (message: string, resp: any) => {
      hideLoader();
      toast.error(message)
    })
  }

  const handleCancel= () => {
    cancel();
  }

  useEffect(() => {
    if ( owners && owners.length ) {
      const ownerDetails = owners.filter((owner: any) => owner['customerID'] == user.user.userID)      
			if ( isAuth && ownerDetails.length ) {
				setValue('totalOwn', ownerDetails[0]['nftCount'])
        setTotalNftOwn(ownerDetails[0]['nftCount'])
			}
    }
  }, [owners])

  return (
        <div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
            <form onSubmit={handleSubmit(formSubmit)}>
                <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                    <>{console.log('errors: ', errors)}</>
                    <div className="grid grid-cols-1 sm:grid-cols-1 gap-10">
                        <FormItem label="Wallet Address" isRequired={true}>
                            <Input placeholder={"Enter wallet address"} autoComplete={'off'} {...register('cryptoAddress')} />
                            {
                                errors && errors.cryptoAddress && errors.cryptoAddress.message &&
                                (<p className='text-red-400'>
                                    {errors.cryptoAddress.message}
                                </p>)
                            }
                        </FormItem>
                        { nft && nft['collectionTypeId'] != 1 &&
                            (
                              <FormItem label="Quanity" isRequired={true}>
                                <Input type={'number'} placeholder={"Enter quantity"} autoComplete={'off'} {...register('quantity')} max={totalNftOwn} />
                                <span className="">Total: {totalNftOwn}</span>
                                
                                {
                                    errors && errors.quantity && errors.quantity.message &&
                                    (<p className='text-red-400'>
                                        {errors.quantity.message}
                                    </p>)
                                }
                              </FormItem>
                            ) || ''
                        }
                        
                    </div>
                </div>
                <div className={`mt-5 pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3`}>
                    <ButtonPrimary className="flex-1">Transfer</ButtonPrimary>
                    <ButtonSecondary type={"button"} className="flex-1" onClick={handleCancel}>Cancel</ButtonSecondary>
                </div>
            </form>
				</div>
  );
};

export default TransferNft;
