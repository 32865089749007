
import React, { useEffect, useState, FC } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

import { HiOutlineEyeOff } from 'react-icons/hi';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NftMoreDropdown from "components/NftMoreDropdown";
import { useUserSelector } from '../../../_common/hooks/selectors/userSelector'
import { CONTRACT_DEPLOYED_STATUS } from "_common/_constant";

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	data: any;
	onDelete?: any;
	selectedCollections?: number[];
	handleSelectCollections?: Function;
	deployContract?: Function;
	editFees?: Function;
	hideContents?: any;
	navigateDetails?: Function;
	viewBalance?: Function;
	depositBalance?: Function;
	withdrawBalance?: Function;
	sendLink?: Function;
	viewLinkBalance?: Function;
}

const MyCollectionCard: FC<CardNFTProps> = ({ 
	className = "", 
	isLiked, 
	data, 
	onDelete,
	handleSelectCollections = () => { },
	selectedCollections = [],
	deployContract = () => { },
	hideContents = {},
	navigateDetails = () => { },
	viewBalance = () => { },
	depositBalance = () => { },
	withdrawBalance = () => { },
	sendLink = () => { },
	viewLinkBalance = () => { },
}) => {

	const userDetails: any = useUserSelector();
	const navigate = useNavigate();
	const [hideCollectionContent, setHideCollectionContent] = useState<any>({})
	const [actions, setActions] = useState<any[]>([]);
	const [custodialWalletSettings, setCustodialWalletSettings] = useState<any>({
		isEnabled: false,
		custodialWalletAddress: ''
	})

	useEffect(() => {
		if ( hideContents && Object.keys(hideContents).length ) {
			const params = {
				...hideContents,
				sendLink: typeof hideContents['sendLink'] === 'function' ? hideContents['sendLink'](data) : hideContents['sendLink'],
				viewLinkBalance: typeof hideContents['viewLinkBalance'] === 'function' ? hideContents['viewLinkBalance'](data) : hideContents['viewLinkBalance'],
				deployContract: typeof hideContents['deployContract'] === 'function' ? hideContents['deployContract'](data) : hideContents['deployContract'],
				contractBalance: typeof hideContents['contractBalance'] === 'function' ? hideContents['contractBalance'](data, custodialWalletSettings) : hideContents['contractBalance'],
				withdrawContractBalance: typeof hideContents['withdrawContractBalance'] === 'function' ? hideContents['withdrawContractBalance'](data, custodialWalletSettings) : hideContents['withdrawContractBalance'],
				transferContractBalance: typeof hideContents['transferContractBalance'] === 'function' ? hideContents['transferContractBalance'](data, custodialWalletSettings) : hideContents['transferContractBalance'],
			}
			setHideCollectionContent(params)
		}
	}, [hideContents, custodialWalletSettings])

	useEffect(() => {
		const actions = [];
		if ( !hideCollectionContent['editIcon'] ) {
			actions.push({
				id: "update",
				name: "Edit",
				icon: "las la-pen",
				callBack: () => navigate(`/create-collection/${data.collectionID}?tab=Details`)
			})
		}
		if ( !hideCollectionContent['deleteIcon'] ) {
			actions.push({
				id: "customer_delete",
				name: "Delete",
				icon: "las la-trash",
				callBack: () => onDelete(data)
			})
		}
		if ( !hideCollectionContent['viewCollection'] ) {
			actions.push({
				id: "customer_view",
				name: "View",
				icon: "las la-eye",
				callBack: () => navigate("/collection/" + data.collectionID)
			})
		}
		if ( !hideCollectionContent['sendLink'] ) {
			actions.push({
				id: "send_contract_link",
				name: "Send Link",
				icon: "las la-wallet",
				callBack: () => sendLink()
			})
		}
		if ( !hideCollectionContent['viewLinkBalance'] ) {
			actions.push({
				id: "view_link_balance",
				name: "View Link Balance",
				icon: "las la-wallet",
				callBack: () => viewLinkBalance()
			})
		}
		if ( !hideCollectionContent['deployContract'] ) {
			actions.push({
				id: "deploy_contract",
				name: "Deploy Contract",
				icon: "las la-wallet",
				callBack: () => deployContract(data)
			})
		}
		if ( !hideCollectionContent['contractBalance'] ) {
			actions.push({
				id: "contract_balance",
				name: "View Contract Balance",
				icon: "las la-eye",
				callBack: () => viewBalance(data)
			})
		}
		if ( !hideCollectionContent['withdrawContractBalance'] ) {
			actions.push({
				id: "withdraw_contract_balance",
				name: "Withdraw Contract Balance",
				icon: "las la-wallet",
				callBack: () => withdrawBalance(data)
			})
		}
		if ( !hideCollectionContent['transferContractBalance'] ) {
			actions.push({
				id: "transfer_contract_balance",
				name: "Deposit Contract Balance",
				icon: "las la-wallet",
				callBack: () => depositBalance(data)
			})
		}
		setActions(actions);
	}, [hideCollectionContent])

	useEffect(() => {
		if (userDetails && Object.keys(userDetails).length) {
			setCustodialWalletSettings({
				isEnabled: userDetails['isCustodialWalletEnabled'] && (userDetails['isCustodialWalletEnabled'] === 'true' || userDetails['isCustodialWalletEnabled'] === true) || false,
				custodialWalletAddress: userDetails['custodialWalletAddress'] || ''
			})
		}
	}, [userDetails])

	return (

		<div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
			<div className="relative flex-shrink-0 spam-product-card-1">
				{/* 
				<div className="spam-overlay">
					<HiOutlineEyeOff />
					<p className="text-sm mb-4">Inappropriate content.</p>
					<ButtonSecondary className="pt-1 pb-1 py-0">view</ButtonSecondary>
				</div> 
				*/}
				<div>
					<NcImage
						containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 bg-gray-500 rounded-b-none"
						src={data.logoUrl}
						className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
					/>
				</div>
				<div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
					<div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-0">
						<NftMoreDropdown actions={actions}
						containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
						/>
					</div>
				</div>

			</div>
			<Link to={"/collection/" + data.collectionID} className={`block`}>
				<div className="p-4 py-5 space-y-2">
					<div className="flex justify-between">
						<h3 className={`inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative`}>{data.categoryName}</h3>
						{data?.preSale.length > 0 ?
							<h3 className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100  relative`}>Presale</h3>
							: null
						}
						{data?.genesis.length > 0 ?
							<h3 className={`inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100  relative`}>Genesis</h3>
							: null
						}
					</div>

					<div className="flex">
					{
						[...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) ? <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100  relative">Deployed</span> : ''
					}
					{
						data.isFetured ? <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100  relative">Featured</span> : ''
					}
					{
						data.isPublished ? <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100  relative">Published</span> : ''
					}
					</div>

					<h2 className={`text-base font-medium mb-1 mt-1`}>{data.blockchainNetwork}</h2>
					<h2 className={`text-lg font-bold mb-1 mt-1`}>{data.collectionName}</h2>
				</div>
			</Link>
		</div>




	);
};

export default MyCollectionCard;
