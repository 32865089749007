import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import Details from "components/Products/Details";
import Price from "components/Products/Price";
import Redemption from "components/Products/Redemption";

import PayoutGroup from "components/collection/PayoutGroup";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Select from "shared/Select/Select";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import Heading from "components/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";
import { IoSettings } from "react-icons/io5";

import { FaBeer } from 'react-icons/fa';
import { useSearchParams } from "react-router-dom";
import { AiFillEye, AiFillGift } from "react-icons/ai";
import { IoMdPricetags } from "react-icons/io";
import { BsFillFileEarmarkMedicalFill } from "react-icons/bs";
import { MdPermMedia } from "react-icons/md";
import PreviewSettings from "components/Products/PreviewSettings";
import Preview from "components/Products/Preview";
export interface Props {
	className?: string;
}

const PageProductAdd: FC<Props> = ({ className = "" }) => {

	const { id } = useParams();
	const commonApi = useCommonApi();
	const productApi = useProductApi();
	const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
	const { showLoader, hideLoader } = useAppLoader();
	const navigate = useNavigate();
	const [categories, setCategories] = useState<any>(null);
	const [category, setCategory] = useState<any>(null);

	const [searchParams, setSearchParams] = useSearchParams();
	const tab = searchParams.get('tab');

	//----------------
	const tabs: any = [
		{ name: 'Details', icon: <BsFillFileEarmarkMedicalFill /> , component: <Details onDelete={()=>{}} />, isDisabled: false},
		{ name: 'Price', icon: <IoMdPricetags />, component: <Price /> , isDisabled: ( id == 'new' )},
		{ name: 'Redeem', icon: <AiFillGift />, component: <Redemption /> , isDisabled: ( id == 'new' )},
		/*{ name: 'Preview', icon: <AiFillEye />, component: <Preview /> , isDisabled: ( id == 'new' )},*/
		{ name: 'Media', icon: <MdPermMedia />, component: <Preview /> , isDisabled: ( id == 'new' )}, 
		{ name: 'Preview Settings', icon: <IoSettings />, component: <PreviewSettings /> , isDisabled: ( id == 'new' )}
	]

	const getCategory = (id: any) => {
		productApi.getCategories({ categoryID: id },
			(message: string, resp: any) => {
				setCategory(resp.categorydm[0]);
				reset({
					categoryName: resp.categorydm[0].categoryName,
					parentCategoryID: resp.categorydm[0].parentCategoryID
				})
			}, (message: string) => {
				console.log(message);
			})
	}

	useEffect(() => {
		if (tab == null) {
			setSearchParams({ 'tab': tabs[0].name })
		}
	}, []);

	useEffect(() => {
		if (categories) {
			if (id == 'new') {
				reset({
					parentCategoryID: 0
				})
			} else {
				getCategory(id)
			}
		}
	}, [categories]);

	//------------------------------
	const renderHeading2 = () => {
		return (
			<div>				
				<Nav
					className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg"
					containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
				>
					{tabs.map((item: any, index: any) => (
						<NavItem2
							key={index}
							isActive={tab === item.name}
							onClick={() => {
								if(!item.isDisabled) {
									setSearchParams({ 'tab': item.name })}
								}
							}
						>
							<div className="flex items-center justify-center sm:space-x-2.5 text-xs sm:text-sm ">
								<span className="hidden sm:inline-block" /* dangerouslySetInnerHTML={{ __html: item.icon }} */>
                                    {item.icon}
                                </span>
								<span>{item.name}</span>
							</div>
						</NavItem2>
					))}
				</Nav>
			</div>
		);
	};


	return (
        <div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
			<Helmet>
				<title>{ id ? 'Update Product' : 'Create Product'}</title>
			</Helmet>
            <div className="container">
                <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl sm:text-4xl font-semibold">
                        { id ? 'Update Product' : 'Create New Product' }
                        </h2>
                        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                        You can set preferred display name, create your profile URL and
                        manage other personal settings.
                        </span>
                    </div>
                    {renderHeading2()}
                    <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
                    {tabs.map((_tab:any,i:any)=>{
                        if(tab == _tab.name){
                            return <React.Fragment key={i}>{_tab.component}</React.Fragment>
                        }
                    })}                    
                </div>
            </div>			    			
		</div>
	);
};

export default PageProductAdd;
