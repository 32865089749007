import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import OrderThumb from "../components/OrderThumb";
import SiteHeader from "./SiteHeader";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/outline";
import TabFilters from "components/Filters/TabFilters";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useBlockchainNetworksSelector, useOrderStatusSelector } from "_common/hooks/selectors/commonSelector";
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import { getMyOrdersList } from "app/sagas/ordersSaga";
import { useOrdersApi } from "_common/hooks/api/ordersApi";
import { toast } from "react-hot-toast";
import NoDataFound from "components/NoDataFound";
import nftbanner from "images/nft-bnr-2.jpg";

export interface PageSearchProps {
	className?: string;
}

//const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
const MyOrders = () => {

	const user: any = useUserSelector()
	const productApi = useProductApi();
	const collectionApi = useCollectionApi();
	const orderApi = useOrdersApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();

	const limit: number = 12;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [products, setProducts] = useState<any>(null);

	const [isOpen, setIsOpen] = React.useState(false);
	const [ddd, setDd] = React.useState(false);
	const [filter, setFilter] = useState<any>({});
	const [prevFilter, setPrevFilter] = useState<any>({});

	const blockchainNetworks = useBlockchainNetworksSelector();
	const [categories, setCategories] = useState<any>(null);
	const [filterCategory, setFilterCategory] = useState<any>([]);


	const [collections, setCollections] = useState<any>([]);
	const [filterCollections, setFilterCollections] = useState<any>([]);
	const [filterChain, setFilterChain] = useState<any>([]);
	const sortBy = [
		{ name: "Featured", id: "isFeatured" },
		{ name: "Reedamable", id: "isReedamable" }
	];


	const productsContainer: any = useRef();

	const orderStatuses = useOrderStatusSelector();
	const navigate = useNavigate();
	const [orders, setOrders] = useState<any>(null);


	const getOrderStatuses = () => {
		orderApi.getOrderStatuses({
		}, (message: string, resp: any) => {


			console.log('ORDER=> STATUS=>', resp)
		}, (message: string) => {

			toast.error('Invalid Order ID')

		})
	}

	const getOrders = () => {
		showLoader();
		orderApi.getOrder({}, (message: string, resp: any) => {
			// setOrders(resp.orders);
			hideLoader();
			console.log('ORDERS=>', resp.orders)
		}, (message: string) => {
			hideLoader();
		})
	}

	const getBlockchainNetworks = () => {
		commonApi.getBlockchainNetworks({}, (message: string, resp: any) => { }, (message: string, resp: any) => { console.log(message, resp) })
	}
	const getCollections = () => {
		collectionApi.getCollections({}, (message: string, resp: any) => {
			setCollections(resp.collections);
		}, (message: string) => {
			console.log(message);
		})
	}

	const getCategories = () => {
		let params: any = {
			usePaging: false,
			sortingBy: 'categoryName',
			sortingOrder: 'asc',
			parentCategoryID: 0
		}
		productApi.getCategories(params, (message: string, resp: any) => {
			if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('categorydm') && resp.categorydm.length > 0) {
				setCategories(resp.categorydm);
			}
		}, (message: string) => {
			console.log(message);
		});
	}

	const isBottom = (el: any) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	const trackScrolling = () => {
		setDd(true)
		if (productsContainer.current) {
			//console.log('Trakc Scroll : ',currentPage , totalPage)
			if (isBottom(productsContainer.current)) {
				//console.log('Trakc Scroll : Bottom Reached.',currentPage , totalPage);
				if (currentPage < totalPage) {
					setCurrentPage(currentPage + 1);
				} else {

					//console.log('SELF REMOVE LISTNER ----------------------------------------------------------------')
					document.removeEventListener('scroll', trackScrolling, true);
				}
			}
		} else {
			//console.log('Trakc Scroll : #ERROR : No Element Found!')
		}
	};

	const getMyOrdersList = () => {
		showLoader()
		orderApi.getMyOrdersList({},
			(message: string, resp: any) => {
				console.log({ message, resp })
				setOrders(resp.orderItems);
				hideLoader()
			}, (message: string) => {
				hideLoader()
				console.log(message)
			})
	}

	const getProducts = () => {
		//let listeners:any = getEventListeners(document);
		//console.log(listeners)
		//console.log('PRE REMOVE LISTNER ----------------------------------------------------------------')
		document.removeEventListener('scroll', trackScrolling, true);
		let params: any = {
			usePaging: true,
			pageSize: limit,
			addedBY: user?.userID,
			isCreatedByCustomer: 1,
			pageNumber: currentPage,
			searchText: searchKey
		}

		switch (filter?.radioBtnFiltrations?.sortBy) {
			case 'isFeatured':
				params.isFeatured = 1;
				break;
			case 'isReedamable':
				params.isReedamable = 1;
				break;
			default:
		}

		if (filter?.radioBtnFiltrations?.category) {
			params.categoryID = filter?.radioBtnFiltrations?.category
		}

		if (filter?.radioBtnFiltrations?.collection) {
			params.collectinID = filter?.radioBtnFiltrations?.collection
		}

		if (filter?.radioBtnFiltrations?.blockchainNetworks) {
			params.networkID = filter?.radioBtnFiltrations?.blockchainNetworks
		}
		/*console.log('params => ', params); */

		showLoader();
		productApi.getProducts(params, (message: string, resp: any) => {
			hideLoader();
			if (products) {
				setProducts([...products, ...resp.products]);
			} else {
				setProducts(resp.products);
			}
			setTotalPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	useEffect(() => {
		console.log(filter, prevFilter)
		if (JSON.stringify(filter) !== JSON.stringify(prevFilter)) {
			setPrevFilter(filter)
			if (products) {
				if (currentPage == 1) {
					setProducts(null)
				} else {
					setCurrentPage(1);
				}
			}
		}

	}, [filter])

	useEffect(() => {
		if (products) {
			if (currentPage == 1) {
				getProducts();
			} else {
				setCurrentPage(1);
			}
		}

	}, [searchKey])

	useEffect(() => {
		if (currentPage == 1) {
			setProducts(null)
		} else {
			getProducts();
		}

	}, [currentPage]);

	useEffect(() => {
		if (products) {
			if (!ddd) {
				document.addEventListener('scroll', trackScrolling, true);
			}
		} else {
			getProducts();
		}
	}, [products]);

	useEffect(() => {
		if (collections) {
			let options: any = [];
			collections.map((col: any, i: any) => { options.push({ name: col.collectionName, id: col.collectionID },) });
			setFilterCollections(options)
		}
	}, [collections])

	useEffect(() => {
		if (categories) {
			let options: any = [];
			categories.map((col: any, i: any) => { options.push({ name: col.categoryName, id: col.categoryID },) });
			setFilterCategory(options)
		}
	}, [categories]);

	useEffect(() => {
		if (blockchainNetworks) {
			let options: any = [];
			blockchainNetworks.map((col: any, i: any) => { options.push({ name: col.chainLabel, id: col.networkID },) });
			setFilterChain(options)
		} else {
			getBlockchainNetworks();
		}
	}, [blockchainNetworks])

	useEffect(() => {
		getMyOrdersList()
		if (orderStatuses == null) {
			getOrderStatuses();
		}
	}, [orderStatuses])

	// useEffect(() => {
	// 	getOrders();
	// }, [])

	return (
		<React.Fragment>
			<SiteHeader />
			<div className={`nc-PageSearch`} data-nc-id="PageSearch">
				<Helmet>
					<title>My Orders</title>
				</Helmet>

				<PageHeaderWithSearch title="My Orders" onSearch={setSearchKey} searchKey={searchKey} banner={nftbanner} />

				<div className="container-full py-16 lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28 pl-10 pr-10">
					<main>
						<div className={`flex flex-col relative`}>
							<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
								<span>{/* left side */}</span>
								<span className="flex flex-shrink-0 text-right items-center">
								</span>
							</div>
						</div>

						{/* LOOP ITEMS */}
						<div ref={productsContainer} className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-8 lg:mt-10 custom-xs-col-2">
							{orders?.map((order: any, i: any) => (
								<OrderThumb data={order} key={i} />
							))}
						</div>



						{orders?.length == 0 && (<NoDataFound />)}

						{/* PAGINATION */}
						{currentPage !== totalPage && products?.length != 0 && (
							<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
								<ButtonPrimary loading>Show me more</ButtonPrimary>
							</div>
						)}

					</main>


				</div>
			</div>
		</React.Fragment>
	);
};

export default MyOrders;
