import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import SiteHeader from "./SiteHeader";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import { BsCart2 } from "react-icons/bs";
import { useCartSelector } from "_common/hooks/selectors/cartSelector";
import NcImage from "shared/NcImage/NcImage";
import { store } from "app/store";
import { ACTIONS } from "_config";
import Prices from "../components/Prices";
import { MdOutlineDeleteOutline } from 'react-icons/md';
import nftbanner from "images/nft-bnr-2.jpg";
import CheckoutForm from "../components/checkout/checkoutForm";
import EmptyCart from "images/empty-cart.webp";
import { Link } from "react-router-dom";
import QuantityCounter from "../components/checkout/QuantityCounter";
import { useStoreSettingsSelector } from "_common/hooks/selectors/storeSelector";



const Store = () => {

	const productApi = useProductApi();
	const collectionApi = useCollectionApi();
	const commonApi = useCommonApi();
	const { showLoader, hideLoader } = useAppLoader();
	const cart: any = useCartSelector();
	const storeSettings = useStoreSettingsSelector();

	console.log('CART=> ', cart)

	const getPrice = (item: any) => {
		if (item.productPrice.length) {
			let activePrice = item.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice * item.__cart_quantity : 'NA'
		} else {
			return 'NA';
		}
	}

	const updateCartItemCount = (item: any, n: number) => {
		let _cart: any = cart.map((_item: any) => {
			if (_item.productID == item.productID) {
				return { ...item, __cart_quantity: n }
			}

			return item;
		});
		store.dispatch({
			type: ACTIONS.CART.UPDATE,
			payload: {
				items: _cart,
			}
		});
	}


	const removeFromCart = (i: any) => {
		let _cart: any = JSON.parse(JSON.stringify(cart));
		_cart.splice(i, 1);
		store.dispatch({
			type: ACTIONS.CART.UPDATE,
			payload: {
				items: _cart,
			}
		});
	}

	return (
		<React.Fragment>
			<SiteHeader />
			<div className={`nc-PageSearch`} data-nc-id="PageSearch">
				<Helmet>
					<title>Cart</title>
					<meta name="title" content={storeSettings.settings?.SEO?.Metatags?.TitleMetatag?.appValue} />
					<meta name="description" content={storeSettings.settings?.SEO?.Metatags?.DescriptionMetatag?.appValue} />
					<meta name="keywords" content={storeSettings.settings?.SEO?.Metatags?.KeywordMetatag?.appValue} />
				</Helmet>

				<div className={`nc-HeadBackgroundCommon h-24 2xl:h-28 pt-6 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `} >
					<NcImage containerClassName="absolute inset-0" src={nftbanner} className="object-cover w-full h-full" />
					<h1 className={"text-2xl sm:text-3xl lg:text-4xl font-semibold text-center relative"}>Cart</h1>
				</div>
				<div className="container py-1 lg:pb-28 lg:pt-2 space-y-1 lg:space-y-28">
					<main>
						{cart.length > 0 ?


							<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14 pt-4">
								<div className="space-y-8 lg:space-y-10">
									<div className="grid sm:grid-cols-1 gap-x-8 gap-y-8  mt-8 lg:mt-4 lg:mb-4 cart-row-width">
										{cart.map((item: any, i: any) => (
											<div key={i} className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 flex focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200">
												<div className="flex items-center w-full">
													<div className="w-48 custom-fld-icon mr-4">
														<div className="">
															<Link to={"/product/" + item.productID} className="">
																<NcImage
																	src={item?.productThumbUrl}
																	containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
																/>
															</Link>
														</div>
													</div>
													<div className="w-full">
														<div className="flex justify-between">
															<h3 className="text-sm font-medium category-name"></h3>
															<h3 className="text-sm font-medium"></h3>
														</div>
														<h2 className="font-semibold mb-1 flex items-center text-2xl">{item.productName}</h2>
														<h2 className="font-semibold mb-1 flex items-center">Quantity <QuantityCounter className="ml-2" quantity={item.__cart_quantity} max={item.availabeQty} onChange={(n: number) => { updateCartItemCount(item, n) }} /></h2>
														<h2 className="font-semibold mb-1 flex items-center">Collection- <span className="font-normal ml-2">{item.collectionName}</span></h2>
														<Prices network={item.network} size="sm" price={getPrice(item)} className="flex mt-5" />
													</div>
													<div className="flex justify-between pt-1.5">
														<div className="option">
															<div className="h-14 px-1 md:h-12 w-full md:w-12 rounded-full bg-red-500 cursor-pointer hover:bg-red-600 flex items-center justify-center text-neutral-50 focus:outline-none cart-delete" onClick={() => { removeFromCart(i) }}><MdOutlineDeleteOutline className="w-24" /></div>
														</div>
													</div>
												</div>
											</div>
										))}

									</div>
								</div>
								<div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
									{cart.length > 0 ? <CheckoutForm products={cart} /> : null}
									{/* hi */}
								</div>
							</div>


							:
							<div className="flex items-center bg-gray-100 dark:bg-neutral-800 rounded-xl mt-12 py-6 px-6 flex">
								<NcImage src={EmptyCart} containerClassName="w-2/5" />
								<p className="flex items-center text-4xl ">Your cart is empty</p>
							</div>
						}
					</main>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Store;
