import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { HiOutlineEyeOff } from "react-icons/hi";
import { BiLink } from "react-icons/bi";

export interface UnlockableContentModalProps {
  className?: string;
  message: string;
  cancel: () => void;
}

const UnlockableContentModal: FC<UnlockableContentModalProps> = ({ className = "", message = '', cancel}) => {

  return (
        <div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
            <div className={""}>
                <div>
                  {message}
                </div>
            </div>
				</div>
  );
};

export default UnlockableContentModal;
