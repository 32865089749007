import React, { FC, useEffect, useState, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Web3 from 'web3';
import { toast } from "react-hot-toast";

import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";

import useMetaMask from "../../../../_common/provider/metamask-provider";
import { METAMASK_KEYS, CHAIN_ADD_CONFIG } from '../../../../_common/_constant'
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook'
import { useUserCryptoDetails } from '../../../../_common/hooks/selectors/userSelector'
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

export interface ContractBalanceProps {
  className?: string;
  changeChainId: number;
  cancel: Function;
  type: string;
  item?: any
}

const ContractBalance: FC<ContractBalanceProps> = ({ className = "", changeChainId, cancel, type, item = null }) => {
  const { connect, disconnect, isLoading, isActive, chainId, account, addNetwork, switchNetwork, isConnected, authenticateWallet } = useMetaMask()
  const { ethereum }: any = window;
  const inputRef: any | null = useRef(null)
  
  const { connectWallet } = useUserApi()
  const { getCollections } = useCollectionApi();
  const cryptoDetails = useUserCryptoDetails()

  const [bal, setBal] = useState<string>('');
  const [collectionDetails, setCollectionDetails] = useState<any>({})
  const [amount, setAmount] = useState<number>(0)
  const [errMsg, setErrMsg] = useState<string>('')
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const { showLoader, hideLoader } = useAppLoader();

  useEffect(() => {
    if ( item && Object.keys(item) ) {
      getSingleCollection();
    }
  }, [item]);
  useEffect(() => {
    getDetails();
  }, [collectionDetails]);

  const getSingleCollection = () => {
    showLoader();
    getCollections({ collectionID: item['collectionID'] }, (message: string, resp: any) => {
      hideLoader();
			if (resp && resp.collections && resp.collections.length) {
        setCollectionDetails(resp.collections[0]);
			} else {
        toast.error('Collection not found!')
        cancel();
			}
		}, (message: string, resp: any) => {
      hideLoader();
      toast.error(message)
		})
  }

  const getDetails = () => {
    if ( collectionDetails && collectionDetails['contractAddress'] && collectionDetails['contractAddress']['address'] ) {
      const web3 = new Web3(ethereum);
      web3.eth.getBalance(collectionDetails['contractAddress']['address']).then((balance) => {
        setBal(parseFloat(web3.utils.fromWei(balance, 'ether')).toFixed(4));
      }).catch((err: any) => {
        console.log('err: ', err)
      });
    }
  }; 

  const submitHandler = () => {
    if ( isNaN(amount) || ( !isNaN(amount) && amount <= 0 ) ) {
      setErrMsg('Invalid amount!')
    } else {
      console.log('amount: ', amount)
      setErrMsg('')
      if (typeof window !== 'undefined' && ethereum) {
        ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((accounts: string[]) => {
            type === 'depositBalance' ? depositBalance(accounts[0]) : withdrawBalance(accounts[0]);
          })
          .catch((err: any) => {
            console.log('submitHandler err: ', err)
            if (err.code === -32002) {
              toast.error('Please open the metamask extension to login');
            } else if (err.code === 4001) {
              toast.error(
                'Please accept the connection in the metamask extension',
              );
            } else {
              toast.error('Some error occurred, please try again');
            }
          });
      }
    }
  } 

  const withdrawBalance = (fromAccount: string) => {
    if (amount > parseFloat(bal)) {
      toast.error('Cannot withdraw more than current balance');
      return;
    }

    const web3: any = new Web3(ethereum);
    const abi = collectionDetails && collectionDetails.contractAddress && collectionDetails.contractAddress.abi ? JSON.parse(collectionDetails.contractAddress.abi) : null;
    const contractAddress = collectionDetails && collectionDetails.contractAddress && collectionDetails.contractAddress.address || null;

    if ( abi && contractAddress ) {
      console.log('amount: ', amount, '  ', typeof amount, ' ', web3.utils.toWei(String(amount), 'ether'))
      setDisableButton(true)
      let depContract = new web3.eth.Contract(abi, contractAddress);
      let params = {
        from: fromAccount,
        to: contractAddress,
        type: '0x2',
        value: web3.utils.toWei(String(amount), 'ether'),
        data: depContract.methods.withdrawFunds(web3.utils.toWei(String(amount), 'ether')).encodeABI(),
      };

      web3.eth
        .sendTransaction(params)
        .on('transactionHash', function (hash: string) {})
        .on('receipt', (receipt: any) => {
          toast.success('Withdraw successful');
          getDetails();
          setDisableButton(false)
          if ( inputRef ) {
            inputRef.current.value = ''
          }
        })
        .on('error', (error: any) => {
          toast.error('Some error occurred, please try again');
          setDisableButton(false)
        });
    } else {
      toast.error('ABI or contract address missing');
    }
  };

  const depositBalance = (fromAccount: string) => {
    const web3: any = new Web3(ethereum);
    const abi = collectionDetails && collectionDetails.contractAddress && collectionDetails.contractAddress.abi ? JSON.parse(collectionDetails.contractAddress.abi) : null;
    const contractAddress = collectionDetails && collectionDetails.contractAddress && collectionDetails.contractAddress.address || null;

    if ( abi && contractAddress ) {
      setDisableButton(true)
      let depContract = new web3.eth.Contract(abi, contractAddress);

      let params = {
        from: fromAccount,
        to: contractAddress,
        value: web3.utils.toWei(String(amount), 'ether'),
        data: depContract.methods.transferFund(web3.utils.toWei(String(amount), 'ether'), fromAccount).encodeABI(),
        type: '0x2',
      };
  
      web3.eth
        .sendTransaction(params)
        .on('transactionHash', function (hash: string) {})
        .on('receipt', (receipt: any) => {
          toast.success('Deposit successful');
          getDetails();
          setDisableButton(false)
          if ( inputRef ) {
            inputRef.current.value = ''
          }
        })
        .on('error', (err: any) => {
          toast.error('Some error occurred, please try again');
          setDisableButton(false)
        });
    } else {
      toast.error('ABI or contract address missing');
    }
  };


  return (
    <div className={`nc-PageConnectWallet ${className}`} data-nc-id="PageConnectWallet">
      <div className="container">
        <div className="my-6 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              { type === 'viewBalance' ? 'View Balance' : type === 'depositBalance' ? 'Deposit Balance' : 'Withdraw Balance' }
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              <h6>Current Balance: <strong>{bal}</strong></h6>
              <h6>Chain: <strong>{collectionDetails['chainLabel']}</strong></h6>
            </span>
            <div className="mt-4">
              {
                  type != 'viewBalance' && (
                    <label className="block">
                        <Label className="block mb-2">Amount <span className="text-red-600">*</span></Label>
                        <Input placeholder={`Amount`} type="number" className="mt-1" onChange={(e: any) => setAmount(e.target.value)} ref={inputRef} />
                        {
                          errMsg && (<p className='text-red-400'>{errMsg}</p>) || ''
                        }
                    </label>
                  ) || ''
              }
            </div>
          </div>
          <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
            {
              type != 'viewBalance' && (<ButtonPrimary disabled={disableButton} className="flex-1" type="button" onClick={() => submitHandler()}>Submit</ButtonPrimary>) || ''
            }            
            <ButtonSecondary className="flex-1" type="button" onClick={() => cancel()}>Cancel</ButtonSecondary>
          </div>

          {
            disableButton && (<div className="pt-2 flex justify-center">
              <p className="">Please wait, until transaction is completed ..</p>
            </div>) || ''
          }
        </div>
      </div>
    </div>
  );
};

export default ContractBalance;
