import React, { FC } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

import { HiOutlineEyeOff } from 'react-icons/hi';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NftMoreDropdown from "components/NftMoreDropdown";

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	data: any;
	onDelete?: any
}

const CollectionCard: FC<CardNFTProps> = ({ className = "", isLiked, data, onDelete }) => {
	// useEffect(() => {
	//console.log('collection-card', data)
	// }, [])

	const navigate = useNavigate()

	return (

		<div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
			<div className="relative flex-shrink-0 spam-product-card-1">
				{/* 
				<div className="spam-overlay">
					<HiOutlineEyeOff />
					<p className="text-sm mb-4">Inappropriate content.</p>
					<ButtonSecondary className="pt-1 pb-1 py-0">view</ButtonSecondary>
				</div> 
				*/}
				<div>
					<Link to={"/collection/" + data.collectionID} className={`block`}>
						<NcImage
							containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 bg-gray-500 rounded-b-none"
							src={data.logoUrl}
							className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
						/>
					</Link>
				</div>
				<div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
				</div>

			</div>
			<Link to={"/collection/" + data.collectionID} className={`block`}>
				<div className="p-4 py-5 space-y-2">
					<div className="flex justify-between">
						<h3 className={`inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative`}>{data.categoryName}</h3>
						{data?.preSale.length > 0 ?
							<h3 className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100  relative`}>Presale</h3>
							: null
						}
						{data?.genesis.length > 0 ?
							<h3 className={`inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-pink-800 bg-pink-100  relative`}>Genesis</h3>
							: null
						}
					</div>

					<h2 className={`text-base font-medium mb-1 mt-1`}>{data.blockchainNetwork}</h2>
					<h2 className={`text-lg font-bold mb-1 mt-1`}>{data.collectionName}</h2>
				</div>
			</Link>
		</div>




	);
};

export default CollectionCard;
