import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";

export interface LikeButtonProps {
  className?: string;
  isAuth?: boolean;
  id?: string;
  type?: number;
  favouriteCount?: number;
  callBackFn?: any;
}

const FavouriteButton: React.FC<LikeButtonProps> = ({
  className,
  id,
  type,
  isAuth,
  favouriteCount = 0,
  callBackFn,
}) => {
  const [isFavourite, setIsFavourite] = useState(false);

  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any = useUserSelector()

  const favouriteClicked = (isFavourite: number) => {
    showLoader()
    commonApi.postFavorite({
      "itemType": type,
      "itemID": id,
      "isFavourite": isFavourite
    },
      (message: string, resp: any) => {
        toast.success(message)
        hideLoader()
        getFavorite()
        callBackFn()
      },
      (message: string) => {
        toast.error(message)
        hideLoader()
      })
  }

  const handleOnCLickFavourite = () => {
    if (!isFavourite) {
      if (id) {
        favouriteClicked(1)
        setIsFavourite(!isFavourite)
      }
    } else {
      if (id) {
        favouriteClicked(0)
        setIsFavourite(!isFavourite)
      }
    }
  }

  const getFavorite = () => {
    showLoader()
    commonApi.getFavorite({
      "itemType": type,
      "itemID": id,
			"addedBy": (isAuth) ? user?.userID: null,
			authrequired: isAuth
    }, (message: string, resp: any) => {
      console.log(resp)
      if (resp?.favourites && resp?.favourites.length > 0) {
        setIsFavourite(resp.favourites[0]?.favouriteDetails[0]?.isFavourite)
      }
      hideLoader()
    },
      (message: string) => {
        console.log(message)
        hideLoader()
      })
  }

  useEffect(() => {
    if (id) {
      getFavorite()
    }
  }, [id])

  return (
    <button
      className={`px-3.5 h-10 flex items-center justify-center px-0 rounded-full like-button ${className}`}
      onClick={isAuth ? handleOnCLickFavourite : () => toast.error('please login to to save as  favourite')}
    >
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
        <path
          d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
          stroke="currentColor"
          fill={isAuth && isFavourite ? "#ef4444" : "none"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="ml-2 text-sm">{favouriteCount}</span>
    </button>
  );
};

export default FavouriteButton;
