import React, { useRef, Suspense, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber'
import { Physics } from '@react-three/cannon';
import { Stars, Sky, OrbitControls, KeyboardControls, /* Stats */ } from "@react-three/drei";

import Moon from './Moon/Moon';
import Building from './Building/Building';
import Ground from './Ground/Ground';
import Art from './Art/Art';
import Furniture from './Furniture/Furniture';
import Camera from './Camera/Camera';
import Player from './Player/Player';
import Lights from './Lights/Lights';

const Gallery = () => {
	const [ready, setReady] = useState(false);
	const [night, setNight] = useState(true);
	const [performance, setPerformance] = useState(true);

	const canvas: any = useRef();

	useEffect(() => {
		const handleKeyDown = (e: any) => {
			switch (e.code) {
				case "KeyN":
					setNight(!night)
					return;
				case "KeyP":
					setPerformance(!performance)
					return;
				default: return;
			}
		}

		document.addEventListener("keydown", handleKeyDown)
		return () => {
			document.removeEventListener("keydown", handleKeyDown)
		}
	}, [night, performance]);

	useEffect(() => {
		console.log('event.......', document.pointerLockElement)
		const handleLockchange = (e: any) => {
			if (document.pointerLockElement === null) {
				setReady(false)
			} else {
				setReady(true)
			}
		}

		document.addEventListener("pointerlockchange", handleLockchange);
		return () => {
			document.removeEventListener("pointerlockchange", handleLockchange);
		}
	});

	useEffect(() => {
		console.log('ready=>', ready)
	}, [ready])




	return (
		<React.Fragment>
			<div className={`nc-AuthorPage`} data-nc-id="AuthorPage">
				<Helmet>
					<title>Gallery</title>
				</Helmet>
				{/* <Loading/> */}

				<div className="w-full">
					<KeyboardControls
						map={[
							{ name: "forward", keys: ["ArrowUp", "w", "W"] },
							{ name: "backward", keys: ["ArrowDown", "s", "S"] },
							{ name: "left", keys: ["ArrowLeft", "a", "A"] },
							{ name: "right", keys: ["ArrowRight", "d", "D"] },
							{ name: "jump", keys: ["Space"] },
						]}>
						<Canvas
							ref={canvas}
							style={{ height: 'calc(100vh - 88px)' }}
							onCreated={({ gl }) => {
								gl.shadowMap.enabled = true
								gl.shadowMap.type = THREE.PCFSoftShadowMap
							}}
						>
							<Camera fov={60} />

							{night ?
								<>
									<Stars />
									<Suspense fallback={null}>
										<Moon />
									</Suspense>
									<fog attach="fog" args={["#272730", 30, 250]} />
								</>
								:
								<>
									<Sky sunPosition={[110, 170, -250]} />
									<fog attach="fog" args={["#f0f4f5", 30, 250]} />
								</>
							}

							<Lights night={night} performance={performance} />

							<Physics gravity={[0, -30, 0]}>
								<Suspense fallback={null}>
									<Ground />
									{/* <Building /> */}
									<Art />
									{/* <Furniture /> */}
								</Suspense>
								<Player />
							</Physics>
							{/* <Stats  showPanel={0} /> */}
						</Canvas>
					</KeyboardControls>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Gallery