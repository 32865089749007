import { getPriceFormat, getPriceIconUrl } from "_common/functions";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";

interface props {
    product:any
}

const EditionHistory:FC<props> = ({product})=>{
    const [list,setList] = useState<any[]| null>(null);


    const showCartPrice = (network:any,price: any) => {
		return (
			<div className="flex items-center">
				<NcImage
					className="object-contain"
					src={getPriceIconUrl(network)}
					containerClassName="nc-NcImage flex aspect-w-11 aspect-h-12 p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0"
				/>
				<span className="font-medium text-md">{getPriceFormat(network,price)}</span>
			</div>
		)
	}

    useEffect(()=>{
        if(product && product.productEditionHistory){
            let _list:any[] = []; 
            product.productEditionHistory.map((edition:any)=>{
                _list.push(edition)
            });
            setList(_list);
        }
    },[product])


    return (
        <>
            {list?.length ?
                <div className="pb-9 pt-6">
                    <p className="text-lg font-semibold mb-3">Edition History</p>
                    <div className="cstm-table-height cstm-scrollbar">
                        <table className="w-full table-fixed">
                            <thead>
                                <tr>
                                    <th className="text-left p-1">Type</th>
                                    <th className="text-left p-1">Owned By</th>
                                    <th className="text-left p-1">To User</th>
                                    <th className="text-left p-1">Date</th>
                                    <th className="text-left p-1">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((edition: any, i: any) => (
                                    <tr key={i} className="border-t">
                                        <td className="p-1 py-2">
                                            <p>{edition.activityName}</p>
                                        </td>
                                        <td className="p-1 py-2">
                                            <p>{edition.addedByName}</p>
                                        </td>
                                        <td className="p-1 py-2">
                                            <p>{edition.toUser}</p>
                                        </td>
                                        <td className="p-1 py-2">
                                            {/* <p>{moment(edition.addedDate).fromNow()}</p> */}
                                            <p className="">{moment(edition.addedDate).format('DD MMM,YYYY')}</p>
                                        </td>
                                        <td className="p-1 py-2">
                                            <p>{showCartPrice(edition.network,edition.cryptoPrice)}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                : null}
        </>
    )
}


export default EditionHistory;