import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Web3 from 'web3';
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import CardNFT from "components/CardNFT";
import SiteHeader from "containers/SiteHeader";
import { Link } from "react-router-dom";
import LikeButton from "components/LikeButton";
import NcImage from "shared/NcImage/NcImage";
import { toast } from "react-hot-toast";
import nftbanner from "images/nft-bnr-1.jpg";
import { nftsImgs } from "contains/fakeData";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import CollectionCard from "../components/CollectionCard";
import { getCategories } from "app/sagas/productSaga";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import NoDataFound from "components/NoDataFound";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import MyCollectionCard from "../components/MyCollectionCard";
import { CONTRACT_DEPLOYED_STATUS, USER_TYPE } from '../../../_common/_constant'
import { CONTRACT_META_BASE_PATH, API_NODE_API_KEY, CONTRACT_DEPLOY_NEW_TOKEN_URL, CONTRACT_DEPLOY_NEW_CONTRACT_URL, CONTRACT_DEPLOY_ORDER_API_BASE_URL, CONTRACT_DEPLOY_REDEEM_API_BASE_URL, CONTRACT_DEPLOY_PRESALE_API_BASE_URL } from "../../../_config/index"
import useMetaMask from "_common/provider/metamask-provider";
import { useStoreSettingsSelector } from '../../../_common/hooks/selectors/storeSelector'
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import { useUserSelector } from "_common/hooks/selectors/userSelector";

import SwitchNetwork from "../components/Crypto/SwitchNetwork";
import ConnectWallet from "../components/Crypto/ConnectWallet";
import ContractBalance from "../components/Crypto/ContractBalance";
import LinkBalance from "../components/Crypto/LinkBalance";
import NcCustomModal from "components/Modal/NcCustomModal";

export interface PageSearchProps {
	className?: string;
}
const CollectionCardViewSection = {
	toSelectCheckbox: false,
	editIcon: false,
	sendLink: (data: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']),
	viewLinkBalance: (data: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']),
	deployContract: (data: any) => [...CONTRACT_DEPLOYED_STATUS.INITIATE, ...CONTRACT_DEPLOYED_STATUS.IN_PROCESS, ...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']),
	contractBalance: (data: any, custodialWalletSettings: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) || custodialWalletSettings['isEnabled'],
	withdrawContractBalance: (data: any, custodialWalletSettings: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) || custodialWalletSettings['isEnabled'],
	transferContractBalance: (data: any, custodialWalletSettings: any) => ![...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+data['contractStatus']) || custodialWalletSettings['isEnabled'],
	editFees: true,
	deleteIcon: false,
	viewCollection: false
}

//const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
const PageMycollection = () => {
	const { chainId, account } = useMetaMask()
	const { ethereum }: any = window;
	
	const userDetails: any = useUserSelector()
	const collectionApi = useCollectionApi();
	const productApi = useProductApi();
	const commonApi = useCommonApi();
	const storeSettings = useStoreSettingsSelector()
	const blockchainNetworks = useBlockchainNetworksSelector();

	const { showLoader, hideLoader } = useAppLoader();

	const limit: number = 12
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [collections, setCollections] = useState<any>(null);

	const [isOpen, setIsOpen] = React.useState(false);
	const [ddd, setDd] = React.useState(false);
	const [filter, setFilter] = useState<any>({});
	const [blockchainNetworksOption, setBlockchainNetworksOption] = useState<any[]>([]);
	const [blockchainFilter, setBlockchainFilter] = useState<any>(null);
	const [categories, setCategories] = useState<any>(null);
	const [selectedCategory, setSelectedCategory] = useState<any>(null);
	const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
	const [isFiatEnabled, setIsFiatEnabled] = useState<boolean>(false)
	const [custodialWalletSettings, setCustodialWalletSettings] = useState<any>({
		isEnabled: false,
		custodialWalletAddress: ''
	})

	const [isShowModal, setIsShowModal] = useState(false);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [modalContent, setModalContent] = useState<any>('')

	const sortBy = [
		{ name: "Featured", id: "isFeatured" },
		{ name: "Reedamable", id: "isReedamable" }
	];


	const collectionsContainer: any = useRef();

	const isBottom = (el: any) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	const trackScrolling = () => {
		console.log('Traking ....',)
		setDd(true)
		if (collectionsContainer.current) {
			console.log('Trakc Scroll : ', currentPage, totalPage)
			if (isBottom(collectionsContainer.current)) {
				console.log('Trakc Scroll : Bottom Reached.', currentPage, totalPage);
				if (currentPage < totalPage) {
					// setCurrentPage(currentPage + 1);
				} else {

					console.log('SELF REMOVE LISTNER ----------------------------------------------------------------')
					document.removeEventListener('scroll', trackScrolling, true);
				}
			}
		} else {
			console.log('Trakc Scroll : #ERROR : No Element Found!')
		}
	};

	const getCollections = () => {
		//let listeners:any = getEventListeners(document);
		//console.log(listeners)
		//console.log('PRE REMOVE LISTNER ----------------------------------------------------------------')
		//document.removeEventListener('scroll', trackScrolling, true);
		//console.log("Hoola bila user", user);
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage,
			searchText: searchKey,
            isCreatedByCustomer: 1,
			addedBy: userDetails.userID
		}

		switch (filter?.radioBtnFiltrations?.sortBy) {
			case 'name:asc':
				params.sortingBy = 'collectionName';
				params.sortingOrder = 'asc';
				break;
			case 'name:desc':
				params.sortingBy = 'collectionName';
				params.sortingOrder = 'desc';
				break;
			default:
		}

		if (filter?.radioBtnFiltrations?.BlockChain) {
			blockchainFilter && blockchainFilter.length > 0 &&
				blockchainFilter.forEach((item: any) => {
					if (item.id == filter?.radioBtnFiltrations?.BlockChain) {
						params.blockchainNetworkID = item.id;
						return
					}
				})
		}

		if (selectedCategory) {
			params['categoryID'] = selectedCategory;
		}
		// console.log('params => ', params);

		showLoader();
		collectionApi.getMyCollections(params, (message: string, resp: any) => {
			hideLoader();
			if (collections) {
				setCollections([...collections, ...resp.collections]);
			} else {
				setCollections(resp.collections);
			}
            if (Math.ceil(resp.totalRecord / limit) > 0)
			    setTotalPage(Math.ceil(resp.totalRecord / limit));
            else
                setTotalPage(1);
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getCategories = () => {
		let params: any = {
			usePaging: false,
			sortingBy: 'categoryName',
			sortingOrder: 'asc',
			parentCategoryID: 0
		}
		setIsLoadingMore(true)
		productApi.getCategories(params, (message: string, resp: any) => {
			setIsLoadingMore(false)
			if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('categorydm') && resp.categorydm.length > 0) {
				resp.categorydm.unshift({
					categoryID: '',
					categoryName: 'All'
				})
				setCategories(resp.categorydm);
			}
		}, (message: string) => {
			setIsLoadingMore(false)
			console.log(message);
		});
	}

	const deleteCollection = (id: any) => {
		return new Promise((resolve: any, reject: any) => {
			collectionApi.deleteCollection({
				collectionID: id
			}, (message: string, resp: any) => {
				resolve(message)
			}, (message: string) => {
				reject(message);
			})
		})
	}

	const collectionDeletehandler = (collection: any) => {
		console.log(collection)
		toast((t) => (
			<span>
				Are you sure?
				<br />
				You want to <b className="text-danger">delete</b> Collection{" "}
				<b>{collection.collectionName}</b>
				<br />
				<hr className="mb-3 mt-3" />
				<div className="align-middle flex justify-end">
					<ButtonPrimary className="bg-black m-1 sm:h-9" onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
					<ButtonPrimary
						className="bg-red-500 m-1 sm:h-9"
						onClick={() => {
							toast.dismiss(t.id);
							toast.promise(
								deleteCollection(collection.collectionID), {
								loading: "Deleting...",
								success: (msg) => {
									getCollections();
									return <b>{collection.collectionName} Deleted Successfully!</b>
								},
								error: (msg) => { return <b>{msg}</b> },
							}, { duration: 2000 });
						}}
					>
						Delete
					</ButtonPrimary>

				</div>
			</span>
		),
			{ duration: 95000 }
		);
	}

	const getblockChainNetwork = () => {
		commonApi.getBlockchainNetworks({},
			(message: string, resp: any) => {
				console.log({ message, resp })
				let list: any = []
				resp.networks.forEach((item: any) => {
					let tempObj = { name: `${item.chainName}`, id: `${item.networkID}` }
					list.push(tempObj)
				})
				setBlockchainFilter(list)
			}, (message: string) => {
				console.log(message)
			})
	}

	useEffect(() => {
		if (collections) {
			if (currentPage == 1) {
				getCollections();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter])

	useEffect(() => {
		if (collections) {
			if (currentPage == 1) {
				getCollections();
			} else {
				setCurrentPage(1);
			}
		}

	}, [searchKey])

	useEffect(() => {
		if (currentPage == 1) {
			getCollections();
		} else {
			setCurrentPage(1);
		}
	}, [selectedCategory])

	useEffect(() => {
		if (currentPage == 1) {
			setCollections(null)
		} else {
			getCollections();
		}

	}, [currentPage]);

	useEffect(() => {
        //console.log("XXXSSS", collections);
		if (collections) {
			// console.log('Collections......', collections)

			if (!ddd) {
				// console.log('ADD EVENT LISTNER');
                if (collections.length > 0) {
					// document.addEventListener('scroll', trackScrolling, true);
				}
			}

		} else {
			getCollections();
		}
	}, [collections])

	useEffect(() => {
		if (userDetails && Object.keys(userDetails).length) {
			setCustodialWalletSettings({
				isEnabled: userDetails['isCustodialWalletEnabled'] && (userDetails['isCustodialWalletEnabled'] === 'true' || userDetails['isCustodialWalletEnabled'] === true) || false,
				custodialWalletAddress: userDetails['custodialWalletAddress'] || ''
			})
		}
	}, [userDetails])

	useEffect(() => {
		if (storeSettings && storeSettings['config'] && storeSettings['config']['isFiatEnable'] == 1) {
			setIsFiatEnabled(true)
		} else {
			setIsFiatEnabled(false)
		}
	}, [storeSettings])
	useEffect(() => {
		setBlockchainNetworksOption(blockchainNetworks)
	}, [blockchainNetworks])

	const handleDeployContract = (collection: any, _account: string, _chainId: number) => {
		if (collection && collection.deployContract === 1) {
			if (isFiatEnabled) {
				handleFetchAbi(collection);
			} else {
				handleWalletConnect(collection, _account, _chainId, () => handleFetchAbi(collection))
			}
		} else {
			toast.error('Shared contract can\'t be deployed again!')
		}
	};

	const handleFetchAbi = (collection: any) => {
		const network = blockchainNetworksOption.find((e: any) => collection['blockchainNetworkID'] == e['networkID'])
		const store = userDetails && userDetails['store'] && userDetails['store'].length && userDetails['store'][0] || null;
		const storeId = store['storeID'] && store['storeID'].split('-')[0] || null;
		if (network && storeId) {
			showLoader();
			const params = {
				chainName: collection['chainName'],
				web3Url: network['web3Url'],
				contractType: collection['collectionTypeID'] === 2 ? "ERC1155" : 'ERC721',
				className: collection['className'],
				shortName: collection['shortName'],
				name: collection['collectionName'],
				uuid: collection['collectionID'],
				storeId: store['type'] != 'Marketplace' ? +storeId : null,
				marketplaceId: store['type'] === 'Marketplace' ? +storeId : null,
				collectionId: collection['collectionID']
			}
			collectionApi.fetchContractAbi(params, (message: string, resp: any) => {
				if (resp.data && resp.data.bytecode && resp.data.abi) {
					if (custodialWalletSettings['isEnabled'] && custodialWalletSettings['custodialWalletAddress']) {
						showLoader();
						const deployParams = {
							byteCode: resp.data.bytecode,
							abi: JSON.stringify(resp.data.abi),
							collectionID: collection['collectionID'],
							custodialWalletAddress: custodialWalletSettings['custodialWalletAddress'],
							userType: USER_TYPE.CUSTOMER
						}
						collectionApi.deployContractThroughAPI(deployParams,
						(message: string, resp: any) => {
							hideLoader();
							toast.success('Deployment initiated, once completed you will get contract address in details page')
							const gotIndex = collections.findIndex((collection: any) => +collection['collectionID'] == +collection['collectionID'])
							const collectionsClone = JSON.parse(JSON.stringify(collections))
							if (gotIndex != -1) {
								collectionsClone.splice(gotIndex, 1, { ...collection, contractStatus: resp['deployContractStatusID'] })
							} else {
								collectionsClone.splice(gotIndex, 1)
							}
							setCollections(collectionsClone)
						},
						(message: string, resp: any) => {
							hideLoader();
							toast.error(message);
						});
					} else {
						hideLoader();
						doTransaction(collection, resp.data.abi, resp.data.bytecode);
					}
				} else {
					toast.error('ABI and bytecode required!')
				}
			}, (message: string, resp: any) => {
				hideLoader();
				toast.error(message)
			})
		} else {
			toast.error('Network and store are required!')
		}
	}

	const doTransaction = (collection: any, abi: any, bytecode: string) => {
		try {
			if ( blockchainNetworks && blockchainNetworks.length ) {
				const network = blockchainNetworks.find((e: any) => e['networkID'] === collection['blockchainNetworkID'] )
				if ( network ) {
					var newTokenURL = CONTRACT_DEPLOY_NEW_TOKEN_URL + collection['collectionID'] + "/" + collection['chainName'] + "/";
					if (collection['collectionTypeID'] == 2)//1155
					{
						newTokenURL += "{id}";
					}

					const newContractURL = CONTRACT_DEPLOY_NEW_CONTRACT_URL + collection['collectionID'];
					const _linkToken = network['linkToken']
					const _oracle = network['oracle']
					const _jobId = network['jobId']
					const _OrderAPIBaseUrl = CONTRACT_DEPLOY_ORDER_API_BASE_URL
					const _RedeemAPIBaseUrl = CONTRACT_DEPLOY_REDEEM_API_BASE_URL
					const _PresaleAPIBaseUrl = CONTRACT_DEPLOY_PRESALE_API_BASE_URL

					// string memory newTokenURL, string memory newContractURL, address _linkToken, address _oracle, string memory _jobId, string memory _OrderAPIBaseUrl, string memory _RedeemAPIBaseUrl, string memory _PresaleAPIBaseUrl

					showLoader();
					let web3 = new Web3(ethereum);
					ethereum
						.request({ method: 'eth_requestAccounts' })
						.then((accounts: string[]) => {
							new web3.eth.Contract(abi)
								.deploy({
									data: bytecode,
									arguments: [newTokenURL, newContractURL, _linkToken, _oracle, _jobId, _OrderAPIBaseUrl, _RedeemAPIBaseUrl, _PresaleAPIBaseUrl]
									/*arguments: ["https://nftwardsv2.dreamztesting.com/", "https://nftwardsv2.dreamztesting.com/",
										"0x326C977E6efc84E512bB9C30f76E30c160eD06FB", "0xDCb5a0a1AdDDc246a8455d9CA03ce150F95dd056", "ac704ed51e1b47cda7c220f75e5c684e", "", "", ""]*/
								})
								.send({ from: accounts[0] })
								.on('error', function (err: any) {
									hideLoader();
									toast.error('An unexpected error has occurred, please try again');
								})
								.on('transactionHash', function (txn: string) {
									hideLoader();
									handleContractDeploySaveTxn(collection.collectionID, txn, abi, bytecode)
								});
						});
				} else {
					toast.error('Network not found!')
				}
			} else {
				toast.error('Network list empty!')
			}
		} catch (err: any) {
			console.log('err: ', err)
			hideLoader();
			toast.error(err.message)
		}
	}

	const handleContractDeploySaveTxn = (id: number, hash: string, abi: any, bytecode: string) => {
		const params = {
			transactionHash: [{
				itemID: id,
				hashToken: hash,
				itemTypeID: 0,
				collectionID: id,
				byteCode: bytecode,
				abi: JSON.stringify(abi)
			}]
		}
		showLoader();
		commonApi.saveContractDeployTxn(params, (message: string, resp: any) => {
			hideLoader();
			toast.success(message)
		}, (message: string, resp: any) => {
			hideLoader();
			toast.error(message)
		})
	}

	const handleWalletConnect = async (collection: any, _account: string, _chainId: number, callBack: Function) => {
		if (typeof window !== 'undefined') {
			if (!ethereum) {
				toast.error('Please install the metamask extension');
			} else {
				if (_account) {
					console.log('_account: ', _account, ' _chainId: ', _chainId)
					if (_chainId === collection['chainID']) {
						closeModal();
						callBack();
						// handleFetchAbi(collection);
					} else {
						setModalContent(<SwitchNetwork changeChainId={collection['chainID']} callback={handleWalletConnect} item={collection} cancel={closeModal} onSuccess={callBack} />)
						showModal('Switch Network');
					}
				} else {
					setModalContent(<ConnectWallet cancel={closeModal} callback={handleWalletConnect} item={collection} onSuccess={callBack} />)
					showModal('Connect Wallet')
				}
			}
		}
	}

	const handleViewContractBalance = (collection: any, type: string) => {
		handleWalletConnect(collection, account, chainId, () => showModalContractBalance(collection, type))
	}

	const showModalContractBalance = (collection: any, type: string) => {
		setModalContent(<ContractBalance type={type} changeChainId={collection['chainID']} item={collection} cancel={closeModal} />)
		showModal('Contract Balance');
	}

	const handleViewLinkBalance = (collection: any, type: string) => {
		handleWalletConnect(collection, account, chainId, () => showModalLinkBalance(collection, type))
	}

	const showModalLinkBalance = (collection: any, type: string) => {
		setModalContent(<LinkBalance type={type} changeChainId={collection['chainID']} item={collection} cancel={closeModal} />)
		showModal('Chain Link');
	}

	const showModal = (title: string) => {
		setIsShowModal(true)
		setModalTitle(title)
	}
	const closeModal = () => {
		setIsShowModal(false);
		setModalTitle('')
		setModalContent('')
	}

	useEffect(() => {
		getCategories();
		getblockChainNetwork();
	}, []);

	// useEffect(() => { console.log({ blockchainFilter }) }, [blockchainFilter])

	return (
		<React.Fragment>
			<div className={`nc-PageSearch`} data-nc-id="PageSearch">
				<Helmet>
					<title>My Collections</title>
				</Helmet>

				{/* <div className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `} data-nc-id="HeadBackgroundCommon">
					<h1 className={"text-2xl sm:text-3xl lg:text-4xl font-semibold text-center pt-3"}>My Collections</h1>
				</div> */}
				{/* <div className="container">
					<header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
						<form className="relative w-full " method="post">
							<label
								htmlFor="search-input"
								className="text-neutral-500 dark:text-neutral-300"
							>
								<span className="sr-only">Search all icons</span>
								<Input
									className="shadow-lg border-0 dark:border"
									id="search-input"
									type="search"
									placeholder="Type your keywords"
									sizeClass="pl-14 py-5 pr-5 md:pl-16"
									rounded="rounded-full"
								/>
								<ButtonCircle
									className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
									size=" w-11 h-11"
									type="submit"
								>
									<i className="las la-arrow-right text-xl"></i>
								</ButtonCircle>
								<span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
									<svg
										className="h-5 w-5"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M22 22L20 20"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</label>
						</form>
					</header>
				</div> */}

				<PageHeaderWithSearch title="My Collections" onSearch={(data: string) => setSearchKey(data)} searchKey={searchKey} banner={nftbanner} />

				<div className="container-full py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28 pl-10 pr-10">
					<main>
						{/* FILTER */}
						<HeaderFilterSearchPage tabnavdata={categories} blockchainFilter={blockchainFilter}
							onChangeCategory={(CategoryID: any) => {
								setCollections([])
								setSelectedCategory(CategoryID);
							}}
							onApplyFilter={(data) => {
								setFilter(data);
							}}
						/>

						{/* LOOP ITEMS */}
						<div ref={collectionsContainer} className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-8 lg:mt-10 custom-xs-col-2">
							{collections?.map((collection: any, index: any) => (
								<MyCollectionCard data={collection} key={index} deployContract={() => handleDeployContract(collection, account, chainId)} onDelete={collectionDeletehandler} hideContents={CollectionCardViewSection} viewBalance={() => handleViewContractBalance(collection, 'viewBalance')} withdrawBalance={() => handleViewContractBalance(collection, 'withdrawBalance')} depositBalance={() => handleViewContractBalance(collection, 'depositBalance')} sendLink={() => handleViewLinkBalance(collection, 'sendLink')} viewLinkBalance={() => handleViewLinkBalance(collection, 'viewBalance')} />
							))}
						</div>

						{collections?.length == 0 && (<NoDataFound />)}

						{currentPage !== totalPage && (
							<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
								{/* <ButtonPrimary loading={isLoadingMore}>Show me more</ButtonPrimary> */}
							</div>
						)}
					</main>
				</div>
			</div>
			<NcCustomModal contentExtraClass="max-w-3xl" modalTitle={modalTitle} modalStatus={isShowModal} closeModal={closeModal}>
				{modalContent}
			</NcCustomModal>
		</React.Fragment>
	);
};

export default PageMycollection;