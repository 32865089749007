import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function usePayoutGroupApi() {

  const callApi = useApiCall()

  const getPayoutGroups = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYOUT_GROUP.GET_PAYOUT_GROUPS, data, onSuccess, onError);
  }

  const addPayoutGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYOUT_GROUP.ADD_PAYOUT_GROUP, data, onSuccess, onError);
  }

  const updatePayoutGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYOUT_GROUP.UPDATE_PAYOUT_GROUP, data, onSuccess, onError);
  }

  return {
    getPayoutGroups,
    addPayoutGroup,
    updatePayoutGroup
  }
}