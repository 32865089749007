import React from 'react'
import { useAppLoaderSelector } from '../hooks/selectors/loaderSelector';

import './appLoader.scss';

function AppLoader() {
    const show = useAppLoaderSelector();
    return show ? <div className="loader">
        <div className="app-loader-container">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", background: "transparent", display: "block" }} width="80px" height="80px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">

                    <defs>
                        <filter id="ldio-29bop51og4d-filter" x="-100%" y="-100%" width="300%" height="300%" colorInterpolationFilters="sRGB">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10"></feGaussianBlur>
                            <feComponentTransfer result="cutoff">
                                <feFuncA type="linear" slope="125" intercept="-75"></feFuncA>
                            </feComponentTransfer>
                        </filter>
                    </defs>
                    <g filter="url(#ldio-29bop51og4d-filter)">
                        <circle cx="30" cy="50" r="10" fill="#8851e2">
                            <animate attributeName="r" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="18;25;18" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
                            <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="20;27;20" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
                        </circle>
                        <circle cx="70" cy="50" r="10" fill="#bb94fc">
                            <animate attributeName="r" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="25;18;25" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
                            <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="66;73;66" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
                        </circle>
                    </g>





                </svg>
            </div>
        </div>
    </div> : null;
}

export default AppLoader