import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { User } from '../../../_common/interfaces/models/user';
import { ACTIONS } from '../../../_config'

interface CryptoDetails {
  walletAddress: string,
  chainID: number
}
export interface UserReducer {
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  isExpired: any;
  isAuthenticated: boolean;
  cryptoDetails: CryptoDetails[];
}

const initialState: UserReducer = {
  user: null,
  isExpired: false,
  token: null,
  refreshToken: null,
  isAuthenticated: false,
  cryptoDetails: []
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        isAuthenticated: true,
        isExpired: false//action.payload.user.isExpired,
      };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        refreshToken: null,
        isAuthenticated: false,
        isExpired: false,
      };
    case ACTIONS.USER.CONNECT_WALLET:
        return {
          ...state,
          cryptoDetails: action.payload.map((e: any) => ({
            walletAddress: e['walletAddress'],
            chainID: e['chainID']
          }))
        };
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
