import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";

export interface LikeButtonProps {
  className?: string;
  liked?: boolean;
  type: any;
  _id: any;
  likeCount?: number;
  getProduct?: any;
  isAuth?: boolean;
}

const LikeButton: React.FC<LikeButtonProps> = ({ className, type, _id, likeCount = 0, getProduct, isAuth }) => {

  const user: any = useUserSelector();
  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader();
  const [isLiked, setIsLiked] = useState(false);

  const handleOnCLickLikeUnlike = () => {
    showLoader()
    commonApi.postLikeUnlike({
      "itemType": getItemTypeID(),
      "itemID": _id,
      "isLike": isLiked ? 0 : 1
    },
      (message: string, resp: any) => {
        toast.success(message)
        getLikeUnlike()
        getProduct()
      },
      (message: string) => {
        toast.error(message)
        hideLoader()
      })
  }

  const getItemTypeID = () => {
    switch (type) {
      case 'collection': return 1;
      case 'product': return 2;
      case 'NFT': return 3;
      case 'customer': return 9;
      default: return 0;

    }
  }

  const getLikeUnlike = () => {
    showLoader()
    commonApi.getLikeUnlike({
      "itemType": getItemTypeID(),
      "itemID": _id,
      "addedBy": (isAuth) ? user?.userID : null,
			authrequired: isAuth
    },
      (message: string, resp: any) => {
        // let myLike = resp.likeUnlikes.filter((like:any) => like.addedBy == user.userID);
        if (resp?.likeUnlikes && resp?.likeUnlikes.length > 0) {
          setIsLiked(resp.likeUnlikes[0]?.likeDetails[0]?.isLiked)
        } else {
          setIsLiked(false)
        }
        hideLoader()
      },
      (message: string) => {
        console.log(message)
        hideLoader()
      })
  }

  useEffect(() => {
    getLikeUnlike()
  }, [])



  return (
    <button
      className={`bg-black/50 px-3.5 h-10 flex items-center justify-center rounded-full text-white ${className}`}
      onClick={isAuth ? handleOnCLickLikeUnlike : () => toast.error('please login to like')}
    >
      {isLiked ?
        <AiFillLike style={{ color: '#ef4444' }} className="w-5 h-5" /> :
        <AiOutlineLike style={{ color: '#ffffff' }} className="w-5 h-5" />
      }
      <span className="ml-2 text-sm">{likeCount}</span>
    </button>
  );
};

export default LikeButton;
