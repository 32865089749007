import axios from 'axios';
import { useUserRefreshTokenSelector, useUserTokenSelector } from '../../../_common/hooks/selectors/userSelector';
import { STORAGE, STORE_STORAGE, URLS, API_URL } from '../../../_config';

const getToken = () => {
  const token = localStorage.getItem(STORE_STORAGE);
  return token
}

const getUserToken = () => {
  if (localStorage.getItem(STORAGE)) {
    let user = JSON.parse(localStorage.getItem(STORAGE));
    return user.responseToken;
  }
  return null;
}

/*const getRefreshToken = () => {
  const token = useUserRefreshTokenSelector()
  return token;
}*/

const AuthRedirect = () => {

}

function refreshToken() {
  let user = JSON.parse(localStorage.getItem(STORAGE));
  const token = user.refreshToken;

  if (token) {
    return axios.post(API_URL.USER.REFRESH_TOKEN, {
      refreshToken: token,
    });
  } else {

  }
}

/* axios.interceptors.response.use((response) => { // Any status code from range of 2xx
  console.log({response});
    // Do something with response data
    return response;
  },  async (err) => {
    // console.log('err: ', err)
    const originalConfig = err.config;

    console.log('API ERROR RESPONSE => ',err)

    if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const resp = await refreshToken();
            console.log('resp: ', resp)
            // const { accessToken } = rs.data;
            // window.localStorage.setItem("accessToken", accessToken);
            // instance.defaults.headers.common["x-access-token"] = accessToken;

            return axios.interceptors(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }

            return Promise.reject(_error);
          }
        }

        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }
    }

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const resp = refreshToken()
          console.log('resp: ', resp)
          return instance(originalConfig);
        } catch(err) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }); */


function addAuth(headers, isAuth, isAdaptive) {
  if (isAuth) {
    const token = isAdaptive ? getUserToken() : getToken();
    if (token) {
      //headers.Accept = 'application/json';
      //headers.ContentType = 'application/x-www-form-urlencoded';
      headers.Authorization = `Bearer ${token}`;
      return headers;
    }
  }
  return headers;
}


export class CallApi {
  static GET(url, params, isAuth, isAdaptive) {
    return new Promise((resolve, reject) => {
      let headers = {};
      headers = addAuth(headers, isAuth, isAdaptive);
      return axios({ method: 'GET', url, headers, params }).then(success => {
        return resolve(success);
      }).catch(error => {
        if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
          localStorage.removeItem(STORAGE);
          window.location.href = URLS.LOGIN;
        }
        return reject(error.response);
      });
    });
  }

  static POST(url, data, isAuth, isAdaptive, apiKey = '') {
    return new Promise((resolve, reject) => {
      let headers = {};
      headers = addAuth(headers, isAuth, isAdaptive);
      if ( apiKey ) {
        headers.api_key = apiKey;
      }
      
      return axios({
        method: 'POST',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
            localStorage.removeItem(STORAGE);
            window.location.href = URLS.LOGIN;
          }
          return reject(error.response);
        });
    });
  }

  static PUT(url, data, isAuth, isAdaptive) {
    return new Promise((resolve, reject) => {
      let headers = {};
      headers = addAuth(headers, isAuth, isAdaptive);
      return axios({
        method: 'PUT',
        url,
        headers,
        data,
      }).then(success => {
        return resolve(success);
      }).catch(error => {

        if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
          localStorage.removeItem(STORAGE);
          window.location.href = URLS.LOGIN;
        }
        return reject(error.response);
      });
    });
  }

  static DELETE(url, data, isAuth, isAdaptive) {
    return new Promise((resolve, reject) => {
      let headers = {};
      headers = addAuth(headers, isAuth, isAdaptive);
      return axios({
        method: 'DELETE',
        url,
        headers,
        data,
      }).then(success => {
        return resolve(success);
      }).catch(error => {
        if (error.response && error.response.status === 401 && window.location.pathname != URLS.LOGIN) {
          localStorage.removeItem(STORAGE);
          window.location.href = URLS.LOGIN;
        }
        return reject(error.response);
      });
    });
  }
}
