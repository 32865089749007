import { FC } from "react";

interface props {
    product:any
}

const EditionDetails:FC<props> = ({product})=>{

    return (
        <>
            {product?.isEdition ?
                <div className="pb-9 pt-6">
                    <p className="text-lg font-semibold mb-3">Edition Details</p>
                    <div className="flex rounded-lg p-4 shadow-md border">
                        <div className="flex-1">
                            <p className="font-semibold">Edition ID</p>
                            <p>{product.editionId}</p>
                        </div>
                        <div className="flex-1">
                            <p className="font-semibold">Edition Number</p>
                            <p>{product.editionNumber}</p>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}


export default EditionDetails;