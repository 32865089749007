import React, { InputHTMLAttributes } from "react";
import  { getPriceIconUrl } from '_common/functions';

import NcImage from "shared/NcImage/NcImage";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  network: string;
}

const InputPrice = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-lg",
      children,
      network,
      ...args
    },
    ref
  ) => {    

    return (
      <div className="input-fld-price">
        {network ?
          <NcImage 
            containerClassName="flex aspect-w-11 aspect-h-12 w-full p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0 price-net-icon"
            src={getPriceIconUrl(network)} 
            className="object-contain w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform" 
          />: <div className="flex aspect-w-11 aspect-h-12 w-full pt-3 pb-3 mr-0 h-0 rounded-3xl overflow-hidden z-0"></div>
        }
        <input
          ref={ref}
          type={'tel'}
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...args}
        />
      </div>
    );
  }
);

export default InputPrice;
