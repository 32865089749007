import FormItem from 'components/FormItem';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import { useAppLoader } from '_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';
import { useUserApi } from '_common/hooks/api/UserApiHook';
import { addHttps } from '_common/functions';

interface IncomingProps {
  user: any;
  getUserDetails: any;
  userDetaits: any
}

const UpdateSocialMediaLink = ({ user, getUserDetails, userDetaits }: IncomingProps) => {

  const userApi = useUserApi()
  const { showLoader, hideLoader } = useAppLoader()

  const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm()

  // console.log({user, userDetaits})

  const onSubmit = (data: any) => {
    let params: any = {
      "customerID": userDetaits?.customerID,
      "siteSettings": [
        {
          "appKey": "FacebookSocial",
          "appValue": data && data.FacebookSocial && addHttps(data.FacebookSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "TwitterSocial",
          "appValue": data && data.TwitterSocial && addHttps(data.TwitterSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "DiscordSocial",
          "appValue": data && data.DiscordSocial && addHttps(data.DiscordSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "InstagramSocial",
          "appValue": data && data.InstagramSocial && addHttps(data.InstagramSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "TelegramSocial",
          "appValue": data && data.TelegramSocial && addHttps(data.TelegramSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "TiktokSocial",
          "appValue": data && data.TiktokSocial && addHttps(data.TiktokSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "SnapchatSocial",
          "appValue": data && data.SnapchatSocial && addHttps(data.SnapchatSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "WebsiteSocial",
          "appValue": data && data.WebsiteSocial && addHttps(data.WebsiteSocial) || '',
          "typeID": 2
        },
        {
          "appKey": "EmailSocial",
          "appValue": data?.EmailSocial || '',
          "typeID": 2
        },
      ]
    }
    console.log({ data, params })
    showLoader()
    userApi.updateSocial(params,
      (message: string, resp: any) => {
        console.log({ resp, message })
        toast.success(message)
        getUserDetails()
        hideLoader()
      }, (message: string) => {
        console.log({ message })
        toast.error(message)
        hideLoader()
      })
  }


  useEffect(() => {
    reset({
      FacebookSocial: userDetaits?.customerSocialSettings[6]?.appValue || '',
      TwitterSocial: userDetaits?.customerSocialSettings[1]?.appValue || '',
      DiscordSocial: userDetaits?.customerSocialSettings[4]?.appValue || '',
      InstagramSocial: userDetaits?.customerSocialSettings[2]?.appValue || '',
      TelegramSocial: userDetaits?.customerSocialSettings[3]?.appValue || '',
      TiktokSocial: userDetaits?.customerSocialSettings[7]?.appValue || '',
      SnapchatSocial: userDetaits?.customerSocialSettings[5]?.appValue || '',
      WebsiteSocial: userDetaits?.customerSocialSettings[8]?.appValue || '',
      EmailSocial: userDetaits?.customerSocialSettings[0]?.appValue || ''
    })
  }, [userDetaits])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mt-8">
          <FormItem label="Facebook">
            <Controller
              name={`FacebookSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Twitter">
            <Controller
              name={`TwitterSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
            {
              errors && errors.TwitterSocial && errors.TwitterSocial?.type == "required" ?
                <p className='text-red-400'>Required</p> : ''
            }
          </FormItem>
          <FormItem label="Discord">
            <Controller
              name={`DiscordSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Instagram">
            <Controller
              name={`InstagramSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Telegram">
            <Controller
              name={`TelegramSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Tiktok">
            <Controller
              name={`TiktokSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Snapchat">
            <Controller
              name={`SnapchatSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Website">
            <Controller
              name={`WebsiteSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
          <FormItem label="Email">
            <Controller
              name={`EmailSocial`}
              control={control}
              render={({ field }) => <Input
                {...field}
                placeholder={''}
                autoComplete={'off'}
                required={false}
              />}
            />
          </FormItem>
        </div>

        <div className="pt-2 m-4">
          <ButtonPrimary className="w-full">Update</ButtonPrimary>
        </div>
      </form>
    </React.Fragment>
  )
}

export default UpdateSocialMediaLink