import { CustomLink } from "data/types";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

const DEMO_PAGINATION: CustomLink[] = [
  {
    label: "1",
    href: "#",
  },
  {
    label: "2",
    href: "#",
  },
  {
    label: "3",
    href: "#",
  },
  {
    label: "4",
    href: "#",
  },
];

export interface PaginationProps {
  className?: string;
  currentPage:number;
  totalPage:number;
  setCurrentPage:(n:number) => void;
}

const Pagination: FC<PaginationProps> = ({ className = "" , currentPage, totalPage, setCurrentPage}) => {

  const [show,setShow] = useState<boolean>(false);
  const [pages,setPages] = useState<any>([]);


  const renderItem = (pag: number, index: number) => {
    if (pag == currentPage) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <span
        onClick={()=>{setCurrentPage(pag)}}
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
      >
        {pag}
      </span>
    );
  };






  useEffect(()=>{
    if(currentPage && totalPage){

      let start:number = currentPage - 2 ;
      let end:number = currentPage + 2 ;

      if (start < 1) {
        start = 1;
      } 
      if (end >= totalPage) {
        end = totalPage;
      }

      let node:any = [];
      for(let i = start; i<= end; i++){
        node.push(i)
      }
      setPages(node);
      setShow(true);
    } else {
      setShow(false);
    }
    
  },[currentPage,totalPage])

  return (
    <React.Fragment>
      {show ?
        <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}    >
          {pages.map(renderItem)}
        </nav>
        : null}
    </React.Fragment>
    
  );
};

export default Pagination;
