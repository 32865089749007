import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import { nftsCatImgs } from "contains/fakeData";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";

export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: any;
  settings?: any;
}

const ntfsCatNames = [
  "Arts",
  "Entertainment",
  "Music",
  "News",
  "Science",
  "Sports",
  "Technology",
];

const HomeActiveCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "Browse by Category",
  subHeading = "Explore the NFTs in the most featured categories.",
  className = "",
  itemClassName = "",
  categories = [],
  settings = null
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 5,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 3.4,
        },
        768: {
          gap: 20,
          perView: 3,
        },
        640: {
          gap: 20,
          perView: 2.3,
        },
        500: {
          gap: 20,
          perView: 1.4,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc={settings && settings.StoreBrowsByCategoriesTextHome.value ? settings.StoreBrowsByCategoriesTextHome.value : subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {categories.map((item: any, index: number) => (
              <li key={'cat' + index} className={`glide__slide ${itemClassName}`}>
                <Link
                  to={`/store?categoryID=${item.categoryID}&collectionID=&blockchainNetworkID=&searchText=`}
                  className={`nc-CardCategory5 flex flex-col ${className}`}
                  data-nc-id="CardCategory5"
                >
                  <div
                    className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}
                  >
                    <NcImage
                      src={item.categoryIconUrl}
                      className="object-cover w-full h-full rounded-2xl"
                    />
                    <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
                  </div>
                  <div className="mt-4 flex items-center">                    
                    <div className="ml-3">
                      <h2
                        className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium`}
                      >
                        {item.categoryName}
                      </h2>
                      {/*
                      <span
                        className={`block mt-1 text-sm text-neutral-6000 dark:text-neutral-400`}
                      >
                        {Math.floor(Math.random() * 1000 + 1000)} NFTs
                      </span>
                      */}
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeActiveCategories;
