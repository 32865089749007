import FormItem from 'components/FormItem'
import React, { InputHTMLAttributes, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import InputMask from 'react-input-mask'
import { useCreditCardValidator, images } from 'react-creditcard-validator'
import useMetaMask from "../../../../_common/provider/metamask-provider";
import { AiOutlineEye } from 'react-icons/ai'
import { BsEyeSlash } from 'react-icons/bs'
import { toast } from "react-hot-toast";
// import SwitchNetwork from '../../../Normalize/containers/SwitchNetwork'
// import ConnectWallet from '../../../Normalize/containers/ConnectWallet'
import SwitchNetwork from "../Crypto/SwitchNetwork";
import ConnectWallet from "../Crypto/ConnectWallet";
import { useUserCryptoDetails } from '../../../../_common/hooks/selectors/userSelector'
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import NcCustomModal from 'components/Modal/NcCustomModal'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
    placeOrder?: any
    totalPayableAmount?: number;
    showCartPrice?: any;
    coupon?: any;
    product: any;
}

const WalletPayment = ({
    className = "block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800",
    sizeClass = "h-11 px-4 py-3",
    fontClass = "text-sm font-normal",
    rounded = "rounded-lg",
    placeOrder,
    totalPayableAmount,
    showCartPrice,
    product,
    coupon,
}: InputProps
) => {

    const { chainId, account } = useMetaMask()
    const { register, handleSubmit, formState: { errors }, control } = useForm()

    const {
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getCardImageProps,
        meta: { erroredInputs }
    } = useCreditCardValidator();

    const cryptoDetails = useUserCryptoDetails()
    const [view, setView] = useState<boolean>(false)
    const [modalContent, setModalContent] = useState<any>('')
    const [isShowWalletModal, setIsShowWalletModal] = useState(false);

    const walletSubmit = () => {
        if ( account && cryptoDetails && cryptoDetails.length && cryptoDetails[0].walletAddress ) {
            const params = {
                walletAddress: cryptoDetails[0].walletAddress,
                paymentMethod: 1,
                // isCouponUsed: 0,
                // couponCode: null,
                conversionRate: 1,
                taxAmount: 0,
                buyerCryptoAddress: cryptoDetails[0].walletAddress,
                /*isCard: false,
                cardNo: "",
                nameOnCard: "",
                cvv: 0,
                expiry: "",
                isSaveCard: false,
                stripeCustomerID: "",
                stripeCardID: ""*/
            }
            placeOrder('wallet', params)
        } else {
            toast.error('Please connect your wallet first')
        }
    }

    const showWalletModal = () => {
		setIsShowWalletModal(true);
	}
	const closeWalletModal = () => {
		setModalContent('')
		setIsShowWalletModal(false);
	}

    const handleWalletConnect = async (product: any, _account: string, _chainId: number) => {
		if (_account) {
            if (+_chainId === +product['chainID']) {
                closeWalletModal();
            } else {
                setModalContent(<SwitchNetwork changeChainId={product['chainID']} callback={handleWalletConnect} cancel={closeWalletModal} item={product} />)
                showWalletModal();
            }
        } else {
            setModalContent(<ConnectWallet cancel={closeWalletModal} callback={handleWalletConnect} item={product} />)
            showWalletModal()
        }
	}


    return (
        <React.Fragment>
            <>
                {
                    !account && (
                        <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                            <div className="grid grid-cols-1 sm:grid-cols-1 gap-10">
                                <ButtonSecondary type={"button"} onClick={() => handleWalletConnect(product, account, chainId)}>Connect Wallet</ButtonSecondary>
                            </div>
                        </div>
                    ) || (
                        <>
                            <div><p>Connected Account: {cryptoDetails[0]?.walletAddress}</p></div>
                            <div className="flex mt-5 justify-center">
                                {totalPayableAmount != 0 && (<ButtonPrimary type="button" onClick={walletSubmit}>
                                    Pay &nbsp; {showCartPrice(totalPayableAmount)}
                                </ButtonPrimary>)}
                            </div>
                        </>
                    )
                }
                <NcCustomModal contentExtraClass="max-w-3xl" modalTitle="Connect Wallet" modalStatus={isShowWalletModal} closeModal={closeWalletModal}>
                    {modalContent}
                </NcCustomModal>
            </>
        </React.Fragment>
    )
}

export default WalletPayment