import Label from 'components/Label/Label';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import InputFile from 'shared/InputFile/InputFile';
import { useCommonApi } from '_common/hooks/api/common/commonApiHook';
import { useUserApi } from '_common/hooks/api/UserApiHook';
import { useAppLoader } from '_common/hooks/common/appLoaderHook';

interface IncomingProps {
    user: any;
    getUserDetails: any;
    userDetaits: any
}

const UpdateBannerImg = ({ user, getUserDetails, userDetaits }: IncomingProps) => {

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [bannerImageLink, setBannerImageLink] = useState<any>('')

    const { register, handleSubmit, setValue } = useForm()

    const mapCategoryImage = (file: any, type: string) => {
        return new Promise((resolve: any, reject: any) => {
            console.log("loggedIn user", file, type);
            let data: any = new FormData();
            data.append("", file);
            data.append("ItemId", user.userID);
            data.append("ItemType", 9);
            data.append("FileType", type);

            commonApi.fileUpload(data,
                (message: string, resp: any) => {
                    console.log('f complete')
                    resolve(message)
                }, (message: string) => {
                    console.log('f failed')
                    reject(message)
                })
        })
    }

    const handleUpdateBannerImage = (data: any) => {
        console.log({ data })
        if (data.bannerUrl[0]) {
            showLoader()
            mapCategoryImage(data.bannerUrl[0], "banner").then((msg: any) => {
                toast.success(msg);
                hideLoader();
                getUserDetails()
            }).catch((msg: any) => {
                toast.success(msg);
                hideLoader();
                getUserDetails()
            });
        } else { 
            toast.error('Please change or select an image to update')
        }
    }

    useEffect(() => {
        if (userDetaits) {
            setBannerImageLink(userDetaits.bannerImageUrl)
        }
    }, [userDetaits])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(handleUpdateBannerImage)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                    <label className="block">
                        <InputFile {...register("bannerUrl", { required: false })} uploadedUrl={bannerImageLink} onCanPlayCapture={(e: any) => { if (e == null) { setValue('bannerUrl', ''); } }} />
                    </label>
                </div>
                <div className="pt-2 m-4">
                    <ButtonPrimary className="w-full">Update</ButtonPrimary>
                </div>
            </form>
        </React.Fragment>
    )
}

export default UpdateBannerImg