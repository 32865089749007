import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Transition } from "@headlessui/react";
import { AiOutlinePlus, AiFillMacCommand} from "react-icons/ai";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import FormItem from "components/FormItem";
import { Basket_Form } from '../../_common/interfaces/FormProps'
import { BasketFormSchemaValidation } from '../../utils/form-validation'
import { useUserApi } from '../../_common/hooks/api/UserApiHook'
import { useProductApi } from '../../_common/hooks/api/productApiHook'
import Textarea from "shared/Textarea/Textarea";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useBasketApi } from "_common/hooks/api/basketApiHook";
import { useMetaGalleryApi } from "_common/hooks/api/metaGalleryApiHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import NcImage from "shared/NcImage/NcImage";
import ButtonDanger from "shared/Button/ButtonDanger";
import { BsFillTrashFill } from "react-icons/bs";
import { useUserSelector } from "_common/hooks/selectors/userSelector";

export interface Props {
	className?: string;
}

const AddUpdateBasket: FC<Props> = ({ className = "" }) => {

	const user: any = useUserSelector()
	const { id: basketID } = useParams();
	const navigate = useNavigate();

	const basketAPI = useBasketApi();
	const metaGalleryAPI = useMetaGalleryApi();
	const collectionAPI = useCollectionApi();
	const userAPI = useUserApi();
	const productAPI = useProductApi();

	const { showLoader, hideLoader } = useAppLoader();
	const [galleries, setGalleries] = useState([]);
	const [basketDetails, setBasketDetails] = useState<any>(null);
	const [isOpenProductFilter, setIsOpenProductFilter] = useState<boolean>(false)

	// const [selectedAuthorType, setSelectedAuthorType] = useState<any>(authorType[0])
	// const [selectedAuthor, setSelectedAuthor] = useState<any>(null)
	const [selectedCollection, setSelectedCollection] = useState<any>(null)
	const [selectedProduct, setSelectedProduct] = useState<any>(null)
	const [errMsg, setErrMsg] = useState<string>('')

	const { register, control, getValues, setValue, watch, setError, resetField, handleSubmit, reset, formState: { errors } } = useForm<Basket_Form>({
		defaultValues: {
			galleryID: null,
			galleryBasketName: '',
			galleryBasketDescription: '',
			basketType: 2,
			isCreatedByCustomer: 0,
			basketItems: []
		},
		resolver: yupResolver(BasketFormSchemaValidation)
	});

	const { fields: basketItemsFields, append: basketItemsAppend, remove: basketItemsRemove } = useFieldArray({
		control,
		name: "basketItems"
	});

	const AddBusketItem = () => {
		if (selectedProduct) {
			const basketItems = getValues('basketItems');
			const gotIndex = basketItems.findIndex((item: any) => item['item']['value'] === selectedProduct['value'])
			if (gotIndex == -1) {
				setErrMsg('')
				basketItemsAppend({ item: selectedProduct })
			} else {
				setErrMsg('Duplicate product selected')
			}
		} else {
			setErrMsg('Select product')
		}
	}

	useEffect(() => {
		setSelectedProduct(null);
	}, [selectedCollection])

	const getMetaGalleries = () => {
		metaGalleryAPI.getMetaGallery({addedBy:user.userID}, (message: string, resp: any) => {
			setGalleries(resp.metaGalleryDM.map((gallery: any) => {
				gallery.label = gallery.galleryName + '|' + gallery.galleryCode;
				gallery.value = gallery.galleryID;
				return gallery;
			}))
		}, (message: string) => { })
	}

	const getBasketDetails = () => {
		const params = {
			galleryBasketID: basketID
		}
		showLoader()
		basketAPI.getBasket(params, (message: string, resp: any) => {
			hideLoader()
			if (resp['galleryBaskets'] && resp['galleryBaskets'].length) {
				setBasketDetails(resp['galleryBaskets'][0]);
			} else {
				toast.error('Basket not found!')
				navigate('/baskets')
			}
		}, (message: string) => {
			hideLoader()
			toast.error(message)
			navigate('/baskets')
		})
	}

	const handleCollections = (inputParams: any): Promise<any> => {
		return new Promise<any>((resolve, reject) => {
			collectionAPI.getCollections({
				usePaging: true,
				pageSize: 10,
				pageNumber: 1,
				...inputParams
			}, (message: string, resp: any) => {
				let data: any = resp.collections.map((collection: any, i: number) => {
					collection.label = collection.collectionName;
					collection.value = collection.collectionID;
					return collection;
				});
				resolve(data);
			}, (message: string) => {
				toast.error(message)
				resolve([])
			})
		})
	}

	const loadCollections = (inputValue: string, callbackFn: (options: any[]) => void) => {
		// if (selectedAuthorType && selectedAuthorType['value'] == 2 && !selectedAuthor) {
		// callbackFn([]);
		// } else {
		// if (selectedAuthor) {
		// }
		let params: any = { searchText: inputValue }
		params.isCreatedByCustomer = 1;
		params.addedBy = user.userID;
		handleCollections(params).then(data => {
			callbackFn(data);
		}).catch(err => {
			callbackFn([]);
		})
	};

	const handleProducts = (inputParams: any): Promise<any> => {
		return new Promise<any>((resolve, reject) => {
			productAPI.getProducts({
				usePaging: true,
				pageSize: 10,
				pageNumber: 1,
				...inputParams
			}, (message: string, resp: any) => {
				let data: any = resp.products.map((product: any) => {
					product.label = product['productName'];
					product.value = +product['productID'];
					return product
				});
				resolve(data);
			}, (message: string) => {
				toast.error(message);
				resolve([]);
			})
		})
	}

	const loadProducts = (inputValue: string, callbackFn: (options: any[]) => void) => {
		if (!selectedCollection) {
			callbackFn([]);
		} else {
			let params: any = { searchText: inputValue, isCreatedByCustomer: 1, addedBy: user.userID }
			if (selectedCollection) {
				params.collectionID = selectedCollection.value;
				
			}
			// if (selectedAuthor) {
			// }
			handleProducts(params).then(data => {
				callbackFn(data);
			}).catch(err => {
				callbackFn([]);
			})
		}

	};

	const submitBasket = (data: any) => {
		let params: any = {
			"galleryID": +data.galleryID.value,
			"galleryBasketName": data.galleryBasketName,
			"galleryBasketDescription": data.galleryBasketDescription,
			"basketType": 2,
			"isCreatedByCustomer": 1,
			"basketItems": data.basketItems.map((item: any) => ({ itemID: +item.item.value }))
		}
		if (basketID) {
			params.galleryBasketID = basketID;
		}

		showLoader();
		basketAPI.addBasket(params, (message: string, resp: any) => {
			hideLoader()
			if (resp.successful) {
				toast.success(message)
				navigate('/my-basket')
			} else {
				toast.error(message)
			}
		}, (message: string) => {
			hideLoader()
			toast.error(message)
		})
	}

	const handleRedirect = () => {
		navigate('/my-basket')
	}

	useEffect(() => {
		if (basketDetails && Object.keys(basketDetails).length && galleries.length) {
			const selectedGallery = galleries.filter((e: any) => +e['value'] == +basketDetails['galleryID'])
			reset({
				galleryID: selectedGallery.length ? selectedGallery[0] : null,
				galleryBasketName: basketDetails.galleryBasketName,
				galleryBasketDescription: basketDetails.galleryBasketDescription,
				basketType: 2,
				isCreatedByCustomer: 1,
				basketItems: basketDetails['galleryProducts'].map((item: any) => ({
					item: {
						...item,
						label: item['productName'],
						value: +item['productID']
					}
				}))
			});
		}
	}, [galleries, basketDetails]);

	useEffect(() => {
		if (basketID) {
			getBasketDetails()
		}
		getMetaGalleries();
	}, []);

	return (
		<div className={`nc-PageUploadItem ${className}`} data-nc-id="PageUploadItem">
			<Helmet>
				<title>Create New Basket</title>
			</Helmet>
			<div className="container">
				<div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
					{/* HEADING */}
					<div className="max-w-2xl">
						<h2 className="text-3xl sm:text-4xl font-semibold">
							{basketID ? 'Update Basket' : 'Create Basket'}
						</h2>
					</div>

					<div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
					<form className="" onSubmit={handleSubmit(submitBasket)}>
						<div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
							<div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5">
								<FormItem label="Select Gallery" isRequired={true}>
									<Controller
										name={"galleryID"}
										control={control}

										render={({ field: { value, onChange, onBlur } }) => (
											<Select
												className={`mt-1`}
												classNamePrefix="select"
												options={galleries}
												placeholder={"Select .."}
												onChange={onChange}
												value={value}
												defaultValue={value}
												tabIndex={1}
											/>
										)}
									/>
									{
										errors && errors.galleryID && errors.galleryID.message &&
										(<p className='text-red-400'>
											{errors.galleryID.message}
										</p>) || ''
									}
								</FormItem>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">
								<FormItem label="Basket Name" isRequired={true}>
									<Input {...register('galleryBasketName')} autoComplete={'off'} placeholder={'Name'} tabIndex={2} />
									{
										errors && errors.galleryBasketName && errors.galleryBasketName.message &&
										(<p className='text-red-400'>
											{errors.galleryBasketName.message}
										</p>)
									}
								</FormItem>

								<FormItem label="Basket Description" isRequired={true}>
									<Textarea rows={6} className="mt-1.5" placeholder="Description here .." {...register('galleryBasketDescription')} tabIndex={3} />
									{
										errors && errors.galleryBasketDescription && errors.galleryBasketDescription.message &&
										(<p className='text-red-400'>
											{errors.galleryBasketDescription.message}
										</p>)
									}
								</FormItem>
							</div>


							<div className={"accordion-item-wrapper mb-4"}>
								<div className={"accordion-title flex items-center justify-between"}>
									<div className="text-lg font-bold flex gap-2"><AiFillMacCommand className="text-2xl mt-1" /> <span className="flex flex-col"><p>Products</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Textual traits that show up as rectangles</small></span></div>
									<ButtonSecondary
										className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
										sizeClass={"py-2.5"}
										type="button"
										onClick={() => {
											setIsOpenProductFilter(!isOpenProductFilter)
										}}
									>
										<span>
											<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpenProductFilter ? "rotate-180" : ""}`} aria-hidden="true" />
										</span>
									</ButtonSecondary>
								</div>
								<Transition
									show={isOpenProductFilter}
									enter="transition-opacity duration-150"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="transition-opacity duration-150"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className={"accordion-body"}>
										<div>
											<div className="flex items-center justify-between mb-4 mt-4">
												{/* <h4 className="text-lg font-bold">&nbsp;</h4> */}
												{/* <div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={AddBusketItem}><AiOutlinePlus className="mr-2" /> Add New</div> */}
											</div>
											<div className="lg:flex gap-3 sm:gap-3 items-start mb-4">
												<div className="lg:w-2/5 w-full mb-2">
													<AsyncSelect
														className={`mt-1`}
														loadOptions={loadCollections}
														placeholder={'Select collection'}
														value={selectedCollection}
														defaultValue={selectedCollection}
														onChange={setSelectedCollection}
														defaultOptions />
												</div>
												<div className="lg:w-80 w-full mb-2">
													<AsyncSelect
														key={`product_${selectedCollection && selectedCollection['value'] || -1}`}
														className={`mt-1`}
														loadOptions={loadProducts}
														placeholder={'Select product'}
														value={selectedProduct}
														defaultValue={selectedProduct}
														onChange={setSelectedProduct}
														defaultOptions />
													{
														errMsg ? (<p className='text-red-400'>
															{errMsg}
														</p>) : ''
													}
												</div>
												<div className="relative flex-shrink-0 flex mb-2">
													<div className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 flex-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={AddBusketItem}><AiOutlinePlus className="mr-2" /> Add Product</div>
												</div>
											</div>

										</div>
									</div>
								</Transition>
							</div>

							<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10  mt-8 lg:mt-10 custom-xs-col-2 cstm-product-basket cstm-scrollbar">
								{basketItemsFields.map((field: any, index: any) => (
									<div className="nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md" key={field.id}>
										<div className="relative flex-shrink-0 ">
											<div>
												<NcImage
													containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
													src={(basketItemsFields[index].item as any)['productThumbUrl']}
													className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
												/>
											</div>
											<div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
												<span className="ml-3"></span>
												<div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-0">
													<ButtonDanger type="button" onClick={() => { basketItemsRemove(index) }} ><BsFillTrashFill /></ButtonDanger>
												</div>
											</div>
										</div>
										<div className="p-4 py-5 space-y-3">
											<div className="flex justify-between">
												<h3 className={`text-sm font-medium category-name`}>{(basketItemsFields[index].item as any)['collectionName']}</h3>
											</div>
											<h2 className={`text-lg font-medium mb-3`}>{(basketItemsFields[index].item as any)['productName']}</h2>
										</div>
									</div>
								))}
							</div>

							<div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
								<ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
								<ButtonSecondary className="flex-1" type="button" onClick={handleRedirect}>Cancel</ButtonSecondary>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddUpdateBasket;
