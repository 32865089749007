import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { BsInstagram, BsGlobe, BsFacebook } from "react-icons/bs";
import { FaSnapchat, FaTiktok } from "react-icons/fa";
import { AiOutlineMail, AiFillTwitterCircle } from "react-icons/ai";
import { ImTelegram } from "react-icons/im";
import { SiDiscord } from "react-icons/si";
import { addHttps } from "_common/functions";
// import parse from 'html-react-parser';

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socialsList?: any;
  isAuthorType?: boolean;
}


const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socialsList = [],
  isAuthorType = false
}) => {

  const renderIcon = (data: any) => {
    switch (data.toLocaleLowerCase()) {
      case 'facebook':
        return <BsFacebook />
      case 'twitter':
        return <AiFillTwitterCircle />
      case 'instagram':
        return <BsInstagram />
      case 'discord':
        return <SiDiscord />
      case 'email':
        return <AiOutlineMail />
      case 'website':
        return <BsGlobe />
      case 'telegram':
        return <ImTelegram />
      case 'tiktok':
        return <FaTiktok />
      default:
        return
    }
  }
  const renderIconTwo = (data: any) => {
    switch (data) {
      case 'FacebookSocial':
        return <BsFacebook />
      case 'TwitterSocial':
        return <AiFillTwitterCircle size={30} />
      case 'InstagramSocial':
        return <BsInstagram />
      case 'DiscordSocial':
        return <SiDiscord />
      case 'EmailSocial':
        return <AiOutlineMail />
      case 'WebsiteSocial':
        return <BsGlobe />
      case 'TelegramSocial':
        return <ImTelegram />
      case 'TiktokSocial':
        return <FaTiktok />
      case 'SnapchatSocial':
        return <FaSnapchat />
      default:
        return
    }
  }

  const generateLink = (key: string, value: string) => {
    if ( key == 'EmailSocial' ) {
      return 'mailto:' + value
    } else {
      const url = addHttps(value)
      return url;
    }
  }

  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socialsList && socialsList.length > 0 && socialsList.map((item: any, i: number) => (
        item?.socialKeyValue.length > 0 &&
        <a
          key={i}
          className={`${itemClass}`}
          href={generateLink(item?.socialKey, item?.socialKeyValue)}
          target="_blank"
          rel="noopener noreferrer"
          title={item?.socialKey}
        >
          <>
            {isAuthorType ?
              renderIconTwo(item?.socialKey)
              :
              renderIcon(item?.socialKey)
            }
          </>
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
