import React, { InputHTMLAttributes } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
   onChange: (...args: any) => void;
   onBlur?: () => void;
   value: Date | undefined | null | any;
   name: string;
   placeholder?: string;
   error?: any;
   id?: string;
   maxDate?: Date;
   minDate?: Date;
   startDate?: Date;
   endDate?: Date;
   disabled?: boolean;
   dark?: boolean;
   dateFormat?: string;
   selectsRange?: boolean;
   inline?: boolean;
   showTimeSelect?: boolean;
   isClearable?: boolean;
   minTime?: Date;
   maxTime?: Date;
   filterTime?: any;
   sizeClass?: string;
   fontClass?: string;
   rounded?: string;
   className?: string
}

const DateInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      onBlur,
      value,
      name,
      placeholder,
      error,
      filterTime,
      id,
      maxDate,
      minDate,
      startDate,
      endDate,
      disabled,
      minTime,
      maxTime,
      dark,
      dateFormat = 'MM/dd/yyyy',
      selectsRange = false,
      inline = false,
      showTimeSelect = false,
      isClearable = false,
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-2xl",
      className = "",
    },
    ref
  ) => {
    return (
      <div className={''}>
         <DatePicker 
         selected={value}
         onChange={onChange}
         onBlur={onBlur}
         placeholderText={placeholder}
         name={name}
         id={id}
         disabled={disabled}
         maxDate={maxDate}
         onFocus={() => {}}
         minDate={minDate}
         startDate={startDate}
         endDate={endDate}
         minTime={minTime}
         maxTime={maxTime}
         showYearDropdown
         dateFormat={dateFormat}
         selectsRange={selectsRange}
         inline={inline}
         showTimeSelect={showTimeSelect}
         isClearable={isClearable}
         className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 ${rounded} ${fontClass} ${sizeClass} ${className}`}
         autoComplete={'off'}
         timeIntervals={1}
         filterTime={filterTime}
         />
      </div>
    );
  }
);

export default DateInput;
