import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CardNFT from "components/CardNFT";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import collectionBanner from "images/nfts/collectionBanner.png";
import { nftsImgs } from "contains/fakeData";
import Details from "components/collection/Details";
import Socials from "components/collection/Socials";
import PreSale from "components/collection/PreSale";
import Genesis from "components/collection/Genesis";
import PayoutGroup from "components/collection/PayoutGroup";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import TabFilters from "components/TabFilters";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useNavigate, useParams } from "react-router";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Select from "shared/Select/Select";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { URLS } from "_config";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import Heading from "components/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";

import { FaBeer } from 'react-icons/fa';
import { useSearchParams } from "react-router-dom";
import PageHeader from "shared/PageHeader/PageHeader";

export interface Props {
	className?: string;
}

const AddUpdateCategory: FC<Props> = ({ className = "" }) => {

	const { id } = useParams();
	const commonApi = useCommonApi();
	const productApi = useProductApi();
	const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
	const { showLoader, hideLoader } = useAppLoader();
	const navigate = useNavigate();
	const [categories, setCategories] = useState<any>(null);
	const [category, setCategory] = useState<any>(null);

	const [searchParams, setSearchParams] = useSearchParams();
	const tab = searchParams.get('tab');

	//----------------
	const tabs: any = [
		{ name: 'Details', icon: <FaBeer /> , component: <Details onDelete={()=>{alert('on delete')}} />, isDisabled: false},
		{ name: 'Socials', icon: <FaBeer />, component: <Socials /> , isDisabled: (id == 'new') },
		/* { name: 'Presale', icon: <FaBeer />, component: <PreSale /> , isDisabled: (id == 'new') },
		{ name: 'Genesis Drop', icon: <FaBeer />, component: <Genesis /> , isDisabled: (id == 'new')},
		{ name: 'Payout Group', icon: <FaBeer />, component: <PayoutGroup />, isDisabled: (id == 'new') }, */
	]


	const getCategories = () => {
		productApi.getCategories({}, (message: string, resp: any) => {
			setCategories([{
				label: '(root)',
				value: 0
			}, ...resp.categorydm.map((cat: any) => {
				cat.label = cat.categoryName;
				cat.value = cat.categoryID;
				return cat;
			})]);
		}, (message: string) => {
			console.log(message);
		})
	}


	const mapCategoryImage = (id: any, file: any) => {
		return new Promise((resolve: any, reject: any) => {
			let data: any = new FormData();
			data.append("", file);
			data.append("ItemId", id);
			data.append("ItemType", 3);
			data.append("FileType", 'icon');

			commonApi.fileUpload(data,
				(message: string, resp: any) => {
					resolve(message)
				}, (message: string) => {
					reject(message)
				})
		})

	}


	/* const addUpdateCategory = (data: any) => {


		showLoader()
		let params: any = {
			"categoryID": id == 'new' ? 0 : id,
			"categoryName": data.categoryName,
			"parentCategoryID": data.parentCategoryID,
			"categoryIconUrl": ''
		}
		productApi.addUpdateCategory(params,
			(message: string, resp: any) => {
				if (data.categoryIconUrl.length) {
					mapCategoryImage(resp.returnID, data.categoryIconUrl[0]).then((msg: any) => {
						hideLoader();
						toast.success(message);
						navigate(URLS.CATEGORIES)
					}).catch((msg: any) => {
						toast.success(message);
						toast.error(msg);
						navigate(URLS.CATEGORIES)
						hideLoader();
					})
				} else {
					hideLoader();
					toast.success(message);
					navigate(URLS.CATEGORIES)
				}

			}, (message: string) => {
				hideLoader();
				toast.error(message)
			})
	}
 */
	const getCategory = (id: any) => {
		productApi.getCategories({ categoryID: id },
			(message: string, resp: any) => {
				setCategory(resp.categorydm[0]);
				reset({
					categoryName: resp.categorydm[0].categoryName,
					parentCategoryID: resp.categorydm[0].parentCategoryID
				})
			}, (message: string) => {
				console.log(message);
			})
	}

	const renderTabBar = () => {
		return (
			<div>
				<Nav
					className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg"
					containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
				>
					{tabs.map((item: any, index: any) => (
						<NavItem2
							key={index}
							isActive={tab === item.name}
							onClick={() => {
								if (!item.isDisabled) {
									setSearchParams({ 'tab': item.name })
								}
							}
							}
						>
							<div className="flex items-center justify-center sm:space-x-2.5 text-xs sm:text-sm ">
								<span
									className="hidden sm:inline-block"
								/* dangerouslySetInnerHTML={{ __html: item.icon }} */
								>{item.icon}</span>
								<span>{item.name}</span>
							</div>
						</NavItem2>
					))}
				</Nav>
			</div>
		);
	};

	useEffect(() => {
		if (tab == null) {
			setSearchParams({ 'tab': tabs[0].name })
		}
	}, []);

	useEffect(() => {
		if (categories) {
			if (id == 'new') {
				reset({
					parentCategoryID: 0
				})
			} else {
				getCategory(id)
			}
		}
	}, [categories]);



	return (
		<div className={`nc-PageCollection  ${className}`} data-nc-id="CategoryList" >
			<Helmet>
				<title>{id == 'new' ? 'Add' : 'Update'} Collection</title>
			</Helmet>
			

		

			
			<div className="container relative">
				<div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
					<PageHeader title={(id == 'new' ? 'Add' : 'Update') + ' Collection'} />
					<div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
					{renderTabBar()} 
					{tabs.map((_tab: any, i: any) => {
						if (tab == _tab.name) {
							return <React.Fragment key={i}>{_tab.component}</React.Fragment>
						}
					})}
				</div>
				
			</div>
		</div>
	);
};

export default AddUpdateCategory;
