import { useSelector } from 'react-redux'
import { User } from '../../interfaces/models/user'
import { StateExtended } from '../../interfaces/StateExtended'


export function useBlockchainNetworksSelector() {
    const blockchainNetworks: any | null = useSelector((state: StateExtended) => state.common.blockchainNetworks)
    return blockchainNetworks
}

export function useDateRangeTypeSelector() {
    const dateRangeTypes: any | null = useSelector((state: StateExtended) => state.common.dateRangeTypes)
    return dateRangeTypes
}

export function useOrderStatusSelector() {
    const orderStatus: any | null = useSelector((state: StateExtended) => state.common.orderStatus)
    return orderStatus
}

export function useSiteSettingsSelector() {
    const settings: any | null = useSelector((state: StateExtended) => state.common.settings)
    return settings
}