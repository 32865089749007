import React, { FC, useEffect, useRef, useState } from "react";
import * as rdd from 'react-device-detect';
import Web3 from 'web3';
import {getPriceIconUrl, getPriceFormat} from '_common/functions'
import NcImage from "shared/NcImage/NcImage";
import { useNavigate, useParams } from "react-router-dom";
import { useCheckoutApi } from "_common/hooks/api/checkoutApi";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Tab } from "@headlessui/react";
import NoDataFoundSmall from "components/NoDataFoundSmall";
import { useUserCryptoDetails, useUserSelector } from '../../../../_common/hooks/selectors/userSelector'
import useMetaMask from "../../../../_common/provider/metamask-provider";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { toast } from "react-hot-toast";
import { ACTIONS } from "_config";
import { store } from "app/store";
import CardPayment from "./CardPayment";
import WalletPayment from "./WalletPayment";
import { TXN_HASH_TYPE } from "_common/_constant";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";

const browserObject = {
	browserName: rdd['browserName'],
	browserVersion: rdd['browserVersion'],
	deviceType: rdd['deviceType'],
	engineName: rdd['engineName'],
	engineVersion: rdd['engineVersion'],
	fullBrowserVersion: rdd['fullBrowserVersion'],
	getUA: rdd['getUA'],
	isAndroid: rdd['isAndroid'],
	isBrowser: rdd['isBrowser'],
	isChrome: rdd['isChrome'],
	isChromium: rdd['isChromium'],
	isConsole: rdd['isConsole'],
	isDesktop: rdd['isDesktop'],
	isEdge: rdd['isEdge'],
	isEdgeChromium: rdd['isEdgeChromium'],
	isElectron: rdd['isElectron'],
	isEmbedded: rdd['isEmbedded'],
	isFirefox: rdd['isFirefox'],
	isIE: rdd['isIE'],
	isIOS: rdd['isIOS'],
	isIOS13: rdd['isIOS13'],
	isIPad13: rdd['isIPad13'],
	isIPhone13: rdd['isIPhone13'],
	isIPod13: rdd['isIPod13'],
	isLegacyEdge: rdd['isLegacyEdge'],
	isMIUI: rdd['isMIUI'],
	isMacOs: rdd['isMacOs'],
	isMobile: rdd['isMobile'],
	isMobileOnly: rdd['isMobileOnly'],
	isMobileSafari: rdd['isMobileSafari'],
	isOpera: rdd['isOpera'],
	isSafari: rdd['isSafari'],
	isSamsungBrowser: rdd['isSamsungBrowser'],
	isSmartTV: rdd['isSmartTV'],
	isTablet: rdd['isTablet'],
	isWearable: rdd['isWearable'],
	isWinPhone: rdd['isWinPhone'],
	isWindows: rdd['isWindows'],
	isYandex: rdd['isYandex'],
	mobileModel: rdd['mobileModel'],
	mobileVendor: rdd['mobileVendor'],
	osName: rdd['osName'],
	osVersion: rdd['osVersion']
}


interface Props {
	className?: string;
	price?: any;
	network?: any;
	contentClass?: string;
	labelTextClassName?: string;
	labelText?: string;
	size?: string;

	products: any[any];
}

const CheckoutForm: FC<Props> = ({
	products = null,
	className = "pt-3",
	price = "000",
	size = "sm",
	network,
	contentClass = "py-1.5 md:py-2 px-2.5 md:px-3.5 text-sm sm:text-base font-semibold",
	labelTextClassName = "bg-white",
	labelText = "Price",
}) => {

	const { ethereum }: any = window;
	const { id } = useParams();
	const user: any = useUserSelector();
	const commonApi = useCommonApi();
	const checkoutApi = useCheckoutApi();
	const { getCollections } = useCollectionApi();
	const { showLoader, hideLoader } = useAppLoader();

	const { chainId, account } = useMetaMask();
	
	const [couponStatus, setCouponStatus] = useState<boolean | null>(null);
	const [_coupon, setCoupon] = useState<any>(null);
	const cryptoDetails = useUserCryptoDetails();

	const [orderItems, setOrderItems] = useState<any>(null);
	
	const [collectionDetails, setCollectionDetails] = useState<any>({})
	const { getDateRangeTypes, saveContractTxn } = useCommonApi();

	

	const navigate = useNavigate();
	const [totalProductPrice, setTotalProductPrice] = useState<number>(0);
	const [discount, setDiscount] = useState<number>(0);
	const [totalPayableAmount, setTotalPayableAmount] = useState<number>(0);
	const [TABS, setTABS] = useState<string[]>([])
	const coupon: any = useRef();

	const [countries, setCountries] = useState<any>(null);

	// const TABS = ["Credit Card", "Wallet"];
	// const TABS = ["Credit Card"];




	const renderTabCreditCard = () => {
		// return (<NoDataFoundSmall />);
		return (<CardPayment
			placeOrder={placeOrder}
			totalPayableAmount={totalPayableAmount}
			showCartPrice={showCartPrice}
			coupon={_coupon}
			countries={countries}
		/>);
	};

	const renderTabWallet = () => {
		return (<WalletPayment
			product={ products && products.length ? products[0] : {} }
			placeOrder={placeOrder}
			totalPayableAmount={totalPayableAmount}
			showCartPrice={showCartPrice}
			coupon={_coupon}
		/>);
	};

	const renderTabStats = () => {
		return (<NoDataFoundSmall />);
	};

	const renderTabItem = (item: string) => {
		switch (item) {
			case "Credit Card":
				return renderTabCreditCard();

			case "Wallet":
				return renderTabWallet();

			case "Stats":
				return renderTabStats();

			default:
				return null;
		}
	};

	const validateCoupon = () => {
		setCoupon(null);
		setCouponStatus(false);
		if (coupon.current && coupon.current.value != '') {
			console.log(coupon.current.value)
			checkoutApi.validateCoupon({
				couponCode: coupon.current.value,
				productID: id
			}, (message: any, resp: any) => {
				if (resp.coupons.length > 0) {
					setCoupon(resp.coupons[0]);
					setCouponStatus(true);
				} else {
					toast.error(message);
					// invalid...
					setCouponStatus(false);
				}
			}, (message: any) => {
				toast.error(message);
				console.log(message);
			})
		}
	};


	const placeOrder = (type:string, cardData?: any) => {
		console.log('My Order Items => ', orderItems)
		switch(type){
			case 'wallet' :
				if ( collectionDetails && Object.keys(collectionDetails).length ) {
					makeWalletPayment(cardData);
				} else {
					toast.error('Collection not found!')
				}
			break;
			case 'coupon':
				let params:any = {
					"paymentMethod": 1,
					"isCouponUsed": 1,
					"couponCode": _coupon.couponCode,
					"currency": products[0].network,
					"conversionRate": 1,
					"taxAmount": 0,
					"orderItems": orderItems,
					"addedBy": user.userID,
					"browserObject": JSON.stringify(browserObject)
				};
				
				showLoader();
				checkoutApi.buy(params, (message: any, resp: any) => {
					console.log(resp, message);
					store.dispatch({
						type: ACTIONS.CART.UPDATE,
						payload: {
							items: [],
						}
					});
					navigate('/order/' + resp.returnID + '?thanks')
					hideLoader();
				}, (message: any) => {
					toast.error(message);
					hideLoader();
				})

			break;
			default : 
				console.log('place order by card or card-and-coupon ');
				let data:any = {
					"paymentMethod":2,
					"isCouponUsed": couponStatus?1:0,
					"couponCode": couponStatus?_coupon.couponCode:'',
					"currency": products[0].chainCode,
					"conversionRate": 1,
					"taxAmount": 0,
					"orderItems": orderItems,
					"addedBy": user.userID,
					"browserObject": JSON.stringify(browserObject),
					"buyerCryptoAddress": "", ...cardData
					/* "isCard": true,
					"cardNo": "string",
					"nameOnCard": "string",
					"cvv": 0,
					"expiry": "string",
					"isSaveCard": false,
					"stripeCustomerID": "",
					"stripeCardID": "" */
				}

				console.log('CARD DATA PARAM=>', data);

				showLoader();
				checkoutApi.buyWithCardCoupon(data, (message: any, resp: any) => {
					console.log(resp, message);
					store.dispatch({
						type: ACTIONS.CART.UPDATE,
						payload: {
							items: [],
						}
					});
					navigate('/order/' + resp.returnID + '?thanks')
					hideLoader();
				}, (message: any) => {
					toast.error(message);
					hideLoader();
				})

		}
		
		
	}

	const makeWalletPayment = (cardDate: any) => {
		const params = {
			...cardDate,
			isCouponUsed: couponStatus?1:0,
			couponCode: couponStatus?_coupon.couponCode:'',
			addedBy: user.userID,
			currency: products[0].network,
			orderItems: orderItems,
			browserObject: JSON.stringify(browserObject)
		}
		console.log('makeWalletPayment params: ', params)
		showLoader();
		checkoutApi.buyWithMetamask(params, (message: any, resp: any) => {
			hideLoader();
			if ( resp && resp['successful'] ) {
				completeWalletPaymentWithNetwork(resp)
			} else {
				toast.error('Error to purchase the products')
			}
		}, (message: any) => {
			console.log(message);
			toast.error(message)
			hideLoader();
		})
	}

	const completeWalletPaymentWithNetwork = (resp: any) => {
		const web3: any = new Web3(ethereum);
		const abi = collectionDetails && collectionDetails.contractAddress && collectionDetails.contractAddress.abi ? JSON.parse(collectionDetails.contractAddress.abi) : null;
		const contractAddress = collectionDetails && collectionDetails.contractAddress && collectionDetails.contractAddress.address || null;
		var txnType = 0;
		const totalPrice = Web3.utils.toWei(totalPayableAmount.toFixed(6), 'ether');
		const editionIds = products.map((e: any) => e['editionId'])
		const quantities = products.map((e: any) => e['__cart_quantity'])
		const orderId = ''+resp['returnID'];
		console.log("xxxx Order", resp)
		const tokenList = products.map((product: any) => {
			const gotIndex = resp['tokenList'].findIndex((e: any) => +e['productID'] == product['productID'])
			if ( gotIndex != -1 ) {
				return +resp['tokenList'][gotIndex]['token']
			}
		})
		console.log('tokenList: ', tokenList, ' quantities: ', quantities)

		showLoader();

		if ( abi && contractAddress ) {
			let depContract = new web3.eth.Contract(abi, contractAddress);
			let params: any = {
				from: cryptoDetails[0].walletAddress,
				to: contractAddress,
				value: totalPrice
			};

			if ( collectionDetails['collectionTypeID'] == 1 && products[0]['isEdition'] == 0 ) {
				// function mintNFT(string calldata orderId, uint256 amount, address _receiver)
				txnType = TXN_HASH_TYPE.WALLET_PURCHASE_721_WITHOUT_EDITION;

				params['data'] = depContract.methods.mintNFT(''+orderId, totalPrice, cryptoDetails[0].walletAddress).encodeABI()
			} else if ( collectionDetails['collectionTypeID'] == 1 && products[0]['isEdition'] != 0 ) {
				// function buyEdition(uint256[] calldata editionId, uint256[] calldata qty, address[] calldata receiver) 
				txnType = TXN_HASH_TYPE.WALLET_PURCHASE_721_WITH_EDITION;

				params['data'] = depContract.methods.buyEdition(editionIds, quantities, [cryptoDetails[0].walletAddress]).encodeABI()
			} else {
				// function mintNFT(uint256[] calldata tokenIds, uint256[] calldata qty, address receiver, string calldata orderId)
				txnType = TXN_HASH_TYPE.WALLET_PURCHASE_1155;

				params['data'] = depContract.methods.mintNFT(tokenList, quantities, cryptoDetails[0].walletAddress, ''+orderId).encodeABI()
			}

			console.log('totalPayableAmount: ', totalPrice, ' address: ', cryptoDetails[0].walletAddress, ' txnType: ', txnType, ' params: ', params)

			web3.eth
				.sendTransaction(params)
				.on('transactionHash', function (hash: string) {
					hideLoader();
					console.log("Sao paulo", collectionDetails, resp)
					handleSaveTxn(txnType, hash, collectionDetails.collectionID)
				})
				/*.on('receipt', (receipt: any) => {
					hideLoader();
				})*/
				.on('error', (error: any) => {
					hideLoader();
					toast.error('Some error occurred, please try again');
				});
		} else {
			hideLoader();
			toast.error('ABI or contract address missing');
		}
	}

	const handleSaveTxn = (txnType: number, hash: string, CollectionID:any = 0) => {
		const params = {
			transactionHash: [{
				// itemID: id,
				hashToken: hash,
				itemTypeID: txnType,
				collectionID: CollectionID
			}]
		}
		showLoader();
		saveContractTxn(params, (message: string, resp: any) => {
			hideLoader();
			toast.success('Transaction saved successfully')
			store.dispatch({
				type: ACTIONS.CART.UPDATE,
				payload: {
					items: [],
				}
			});
			navigate('/order/' + resp.returnID + '?thanks')
		}, (message: string, resp: any) => {
			hideLoader();
			toast.error(message)
		})
	}

	const getSingleCollection = (collectionId: number) => {
		showLoader();
		getCollections({ collectionID: collectionId }, (message: string, resp: any) => {
		  	hideLoader();
			if (resp && resp.collections && resp.collections.length) {
				setCollectionDetails(resp.collections[0]);
			} else {
				toast.error('Collection not found!')
			}
		}, (message: string, resp: any) => {
			hideLoader();
			toast.error(message)
		})
	}

	const getProductUnitPrice = (product: any) => {
		if (product.productPrice.length) {
			let activePrice = product.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice : 0
		} else {
			return 0;
		}
	}

	const getProductPrice = (product: any) => {
		return getProductUnitPrice(product) *  product.__cart_quantity;
	}

	const getTotalProductsPrice = () => {
		let price: number = 0;
		products.map((prod: any) => {
			price += +getProductPrice(prod);
		})
		return price;
	}

	const getCouponDiscount = () => {
		let discount: number = 0;
		if (couponStatus) {
			if (_coupon.discountType == 2) {
				// percentage discount --------------------------------
				console.log(_coupon.discountPercentage);
				discount += totalProductPrice / 100 * _coupon.discountPercentage
			} else {
				// Flat discount
				discount += _coupon.discountValue;
			}
		}

		return discount;
	}

	const getDiscount = () => {
		let discount: number = 0;
		discount += getCouponDiscount();
		setTotalPayableAmount(totalProductPrice - discount);
		setDiscount(discount);
	}

	const resetCoupon = () => {
		setCouponStatus(false);
		setCoupon(null)
	}

	const showCartPrice = (price: any) => {
		return (
			<div className="flex items-center">
				<NcImage
					className="object-contain"
					src={getPriceIconUrl(products[0].network)}
					containerClassName="nc-NcImage flex aspect-w-11 aspect-h-12 p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0"
				/>
				<span className="font-medium text-md">{getPriceFormat(products[0].network,price)}</span>
			</div>
		)
	}

	const loadCountries = () => {
        showLoader();
        commonApi.getCountries({}, (message: string, resp: any) => {
            hideLoader();
            if (resp.code === 200 && resp.successful === true) {
                resp.countries = resp.countries.map((o: any) => {
                    return {
                        label: o.countryName,
                        value: o.twoLetterIsoCode
                    }
                });
                setCountries(resp.countries);
            }
        }, (message: string) => {
            hideLoader();
        });

    }


	useEffect(() => {
		if (products) {
			setTotalProductPrice(getTotalProductsPrice());
			setTotalPayableAmount(getTotalProductsPrice());
			getSingleCollection(products[0]['collectionID'])
			if ( products.length && products[0]['chainID'] != 0 ) {
				setTABS(["Wallet"])
			} else if ( products.length && products[0]['chainID'] == 0 ) {
				setTABS(["Credit Card"])
			}
			setOrderItems(products?.map((product: any, i: any) => {
				return {
					"collectionID": product.collectionID,
					"productID": product.productID,
					"quantity": product.__cart_quantity,
					"rate": getProductUnitPrice(product),
					"cryptoRate": getProductUnitPrice(product)
				}
			}));
		}
	}, [products]);

	useEffect(() => {
		if (totalProductPrice) {
			getDiscount();
		}
	}, [totalProductPrice])


	useEffect(() => {
		if (totalProductPrice) {
			getDiscount();
		}

	}, [_coupon, totalProductPrice]);

	useEffect(() => {
        loadCountries();
    }, [])
	



	return (
		<div className={`${className}`}>
			<div className={`mt-4 mb-4 border border-neutral-200  dark:text-neutral-100 focus:border-blue-500 focus:outline-none focus:shadow-outline-blue px-3 py-4 relative rounded-xl sm:px-5 ${(couponStatus ? 'bg-green-100 border-green-500' : 'dark:bg-neutral-800 dark:border-neutral-700')}`}>
				{couponStatus ?
					<div className="text-gray-800">
						<p className="font-semibold text-2xl text-green-500">{_coupon.couponCode}</p>
						<p>{_coupon.couponName}</p>
						<div className="flex"><p className="mr-3">Discount:</p> {showCartPrice(getCouponDiscount())}</div>
						<div className="border-b border-neutral-300 dark:border-neutral-700 mb-3 mt-3"></div>
						<p onClick={resetCoupon} className="cursor-pointer text-sm">Use another Coupon</p>
					</div>
					:
					<div className="">
						<label className="block mb-2">
							<Label>Apply Coupon Code</Label>
							<div className="flex gap-4 mt-2">
								<Input
									ref={coupon}
									placeholder="Example Doe"
									type="text"
									className="mt-1"
								/>
								<ButtonPrimary type="button" onClick={validateCoupon}>Validate</ButtonPrimary>
							</div>

						</label>

					</div>
				}

			</div>
			<div className="flex items-center justify-between">
				<p className="font-medium text-md">Cart Sub Total</p>
				{showCartPrice(totalProductPrice)}
			</div>
			<div className="border-b border-neutral-200 dark:border-neutral-700 mb-3 mt-3"></div>
			<div className="flex items-center justify-between">
				<p className="font-medium text-md">Discount</p>
				{showCartPrice(discount)}
			</div>
			<div className="border-b border-neutral-200 dark:border-neutral-700 mb-3 mt-3"></div>
			<div className="flex items-center justify-between">
				<p className="font-bold text-md">Total Payable Amount</p>
				{showCartPrice(totalPayableAmount)}
			</div>

			{totalPayableAmount != 0 /*&& products[0].chainID == 0*/ &&
				<React.Fragment>
					<div className="border-b border-neutral-200 dark:border-neutral-700 mb-3 mt-3"></div>
					<p className="font-medium font-semibold mb-3 mt-5 mb-4 text-2xl text-md">Payment Method</p>
					<div className="w-full pdx-2 sm:px-0">
						<Tab.Group>
							<Tab.List className="flex justify-start pd-1 space-x-2.5 rounded-full bordedr border-neutral-300 dark:border-neutral-500">
								{TABS.map((tab) => (
									<Tab
										key={tab}
										className={({ selected }) =>
											`px-3.5 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm leading-5 font-medium rounded-full focus:outline-none focus:ring-2 ring-primary-300 ${selected
												? "bg-neutral-900 dark:bg-neutral-100 text-white dark:text-neutral-900"
												: "text-neutral-700 dark:text-neutral-300 bg-neutral-100/70 dark:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100"
											}`
										}
									>
										{tab}
									</Tab>
								))}
							</Tab.List>
							<Tab.Panels className="mt-6 p-7 rounded-2xl shadow-md">
								{TABS.map((tab, idx) => (
									<Tab.Panel
										key={idx}
										className={
											"rounded-xl focus:outline-none ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 "
										}
									>
										{renderTabItem(tab)}
									</Tab.Panel>
								))}
							</Tab.Panels>
						</Tab.Group>
					</div>
				</React.Fragment>
			}

			<div className="flex mt-5 justify-center mb-10">
				{couponStatus && totalPayableAmount == 0 && (<ButtonPrimary type="button" onClick={() => placeOrder('coupon')}>Place Your Order</ButtonPrimary>)}
			</div>
		</div>
	);
};

export default CheckoutForm;
