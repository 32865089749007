import Label from "components/Label/Label";
import React, { ReactNode, useEffect, useState } from "react";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const LanguageDropdown: FC<any> = () => {
    const { register, control, handleSubmit, getValues, setFocus, watch, reset, setValue, formState: { errors } } = useForm()
    const [activelang, setActivelang] = useState((document.cookie.replace(/(?:(?:^|.*;\s*)googtrans\s*\=\s*([^;]*).*$)|^.*$/, "$1")? document.cookie.replace(/(?:(?:^|.*;\s*)googtrans\s*\=\s*([^;]*).*$)|^.*$/, "$1").replace('/en/', ''): 'en') || 'en')
    const [langList] = useState([
        { value: "af", label: "Afrikaans" },
        { value: "sq", label: "Albanian" },
        { value: "am", label: "Amharic" },
        { value: "ar", label: "Arabic" },
        { value: "hy", label: "Armenian" },
        { value: "as", label: "Assamese" },
        { value: "ay", label: "Aymara" },
        { value: "az", label: "Azerbaijani" },
        { value: "bm", label: "Bambara" },
        { value: "eu", label: "Basque" },
        { value: "be", label: "Belarusian" },
        { value: "bn", label: "Bengali" },
        { value: "bho", label: "Bhojpuri" },
        { value: "bs", label: "Bosnian" },
        { value: "bg", label: "Bulgarian" },
        { value: "my", label: "Burmese" },
        { value: "ca", label: "Catalan" },
        { value: "ceb", label: "Cebuano" },
        { value: "ny", label: "Chichewa" },
        { value: "zh-CN", label: "Chinese (Simplified)" },
        { value: "zh-TW", label: "Chinese (Traditional)" },
        { value: "co", label: "Corsican" },
        { value: "hr", label: "Croatian" },
        { value: "cs", label: "Czech" },
        { value: "da", label: "Danish" },
        { value: "dv", label: "Dhivehi" },
        { value: "doi", label: "Dogri" },
        { value: "nl", label: "Dutch" },
        { value: "en", label: "English" },
        { value: "eo", label: "Esperanto" },
        { value: "et", label: "Estonian" },
        { value: "ee", label: "Ewe" },
        { value: "tl", label: "Filipino" },
        { value: "fi", label: "Finnish" },
        { value: "fr", label: "French" },
        { value: "fy", label: "Frisian" },
        { value: "gl", label: "Galician" },
        { value: "ka", label: "Georgian" },
        { value: "de", label: "German" },
        { value: "el", label: "Greek" },
        { value: "gn", label: "Guarani" },
        { value: "gu", label: "Gujarati" },
        { value: "ht", label: "Haitian Creole" },
        { value: "ha", label: "Hausa" },
        { value: "haw", label: "Hawaiian" },
        { value: "iw", label: "Hebrew" },
        { value: "hi", label: "Hindi" },
        { value: "hmn", label: "Hmong" },
        { value: "hu", label: "Hungarian" },
        { value: "is", label: "Icelandic" },
        { value: "ig", label: "Igbo" },
        { value: "ilo", label: "Ilocano" },
        { value: "id", label: "Indonesian" },
        { value: "ga", label: "Irish Gaelic" },
        { value: "it", label: "Italian" },
        { value: "ja", label: "Japanese" },
        { value: "jw", label: "Javanese" },
        { value: "kn", label: "Kannada" },
        { value: "kk", label: "Kazakh" },
        { value: "km", label: "Khmer" },
        { value: "rw", label: "Kinyarwanda" },
        { value: "gom", label: "Konkani" },
        { value: "ko", label: "Korean" },
        { value: "kri", label: "Krio" },
        { value: "ku", label: "Kurdish (Kurmanji)" },
        { value: "ckb", label: "Kurdish (Sorani)" },
        { value: "ky", label: "Kyrgyz" },
        { value: "lo", label: "Lao" },
        { value: "la", label: "Latin" },
        { value: "lv", label: "Latvian" },
        { value: "ln", label: "Lingala" },
        { value: "lt", label: "Lithuanian" },
        { value: "lg", label: "Luganda" },
        { value: "lb", label: "Luxembourgish" },
        { value: "mk", label: "Macedonian" },
        { value: "mai", label: "Maithili" },
        { value: "mg", label: "Malagasy" },
        { value: "ms", label: "Malay" },
        { value: "ml", label: "Malayalam" },
        { value: "mt", label: "Maltese" },
        { value: "mi", label: "Maori" },
        { value: "mr", label: "Marathi" },
        { value: "mni-Mtei", label: "Meiteilon (Manipuri)" },
        { value: "lus", label: "Mizo" },
        { value: "mn", label: "Mongolian" },
        { value: "ne", label: "Nepali" },
        { value: "no", label: "Norwegian" },
        { value: "or", label: "Odia (Oriya)" },
        { value: "om", label: "Oromo" },
        { value: "ps", label: "Pashto" },
        { value: "fa", label: "Persian" },
        { value: "pl", label: "Polish" },
        { value: "pt", label: "Portuguese" },
        { value: "pa", label: "Punjabi" },
        { value: "qu", label: "Quechua" },
        { value: "ro", label: "Romanian" },
        { value: "ru", label: "Russian" },
        { value: "sm", label: "Samoan" },
        { value: "sa", label: "Sanskrit" },
        { value: "gd", label: "Scots Gaelic" },
        { value: "nso", label: "Sepedi" },
        { value: "sr", label: "Serbian" },
        { value: "st", label: "Sesotho" },
        { value: "sn", label: "Shona" },
        { value: "sd", label: "Sindhi" },
        { value: "si", label: "Sinhala" },
        { value: "sk", label: "Slovak" },
        { value: "sl", label: "Slovenian" },
        { value: "so", label: "Somali" },
        { value: "es", label: "Spanish" },
        { value: "su", label: "Sundanese" },
        { value: "sw", label: "Swahili" },
        { value: "sv", label: "Swedish" },
        { value: "tg", label: "Tajik" },
        { value: "ta", label: "Tamil" },
        { value: "tt", label: "Tatar" },
        { value: "te", label: "Telugu" },
        { value: "th", label: "Thai" },
        { value: "ti", label: "Tigrinya" },
        { value: "ts", label: "Tsonga" },
        { value: "tr", label: "Turkish" },
        { value: "tk", label: "Turkmen" },
        { value: "ak", label: "Twi" },
        { value: "uk", label: "Ukrainian" },
        { value: "ur", label: "Urdu" },
        { value: "ug", label: "Uyghur" },
        { value: "uz", label: "Uzbek" },
        { value: "vi", label: "Vietnamese" },
        { value: "cy", label: "Welsh" },
        { value: "xh", label: "Xhosa" },
        { value: "yi", label: "Yiddish" },
        { value: "yo", label: "Yoruba" },
        { value: "zu", label: "Zulu" }
    ])
    useEffect(() => {
        console.log("Activate language", activelang);
        if (activelang) {
            const row = langList.filter((o:any) => o.value === activelang);
            if (row.length > 0) {
                setValue('clang', row[0]);
            }
        }        
    }, []);
    return (
        <div className={``}>
            <Controller
                name={"clang"}
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                    <Select
                        className={`mt-1 rounded-2xl notranslate gt-dd-ml-xx`}
                        options={langList}
                        placeholder={"Select Language"}
                        onChange={(e) => {
                            onChange(e);
                            document.cookie = 'googtrans=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            document.cookie = 'googtrans=; Path=/; Domain=.nftwards.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                            setTimeout(() => {
                                document.cookie = 'googtrans=' + ('/en/' + e.value) + '; Path=/;';
                                window.location.reload();
                            })
                            console.log("selected value", e.value);
                        }}
                        value={value}
                        defaultValue={value}
                    />
                )}
            />
            <div className={`hidden`} id="google_translate_element"></div>
        </div>
    );
};

export default LanguageDropdown;
