import { ActionExtended } from '../../_common/interfaces/ActionExtended';
import { ACTIONS, STORAGE_CART } from '../../_config'

export interface CartReducer {
	items: any;
}

const initialState: CartReducer = {
	items: []
};

const cartReducer = (state = initialState, action: ActionExtended) => {
	switch (action.type) {
		case ACTIONS.CART.UPDATE:
			localStorage.setItem(STORAGE_CART,JSON.stringify(action.payload.items))
			return {
				...state,
				items: action.payload.items,
			};
		default:
			return state;
	}
};

export default cartReducer;
