import React, {useEffect} from "react";
import ReactPixel from 'react-facebook-pixel';
import MyRouter from "routers/index";

import { useStoreSettingsSelector } from './_common/hooks/selectors/storeSelector'

function App() {
  const { settings } = useStoreSettingsSelector();

  useEffect(() => {
    if ( settings && settings['Analytics'] && settings['Analytics']['FacebookPixelAnalytics'] && settings['Analytics']['FacebookPixelAnalytics']['appValue'] ) {
      console.log('FB pixel initiated: ', settings['Analytics']['FacebookPixelAnalytics']['appValue'])
      ReactPixel.init(settings['Analytics']['FacebookPixelAnalytics']['appValue']);
    }
  }, [settings])

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
