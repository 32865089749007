import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const signup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  }

  const registerStepOne = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP1, data, onSuccess, onError);
  }

  const registerStepTwo = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP2, data, onSuccess, onError);
  }

  const registerStepThree = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP3, data, onSuccess, onError);
  }

  const registerStepFour = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER.STEP4, data, onSuccess, onError);
  }

  const loginstep1 = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGINSTEP1, data, onSuccess, onError);
  }

  const forgotpassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const setforgottenpassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }

  const getStoreConfiguration = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.STORE.CONFIG, data, onSuccess, onError);
  }

  const saveStoreConfiguration = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.STORE.CONFIG_SAVE, data, onSuccess, onError);
  }

  const getUserProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_USER_PROFILE, data, onSuccess, onError);
  }

  const updateProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.SAVE_PROFILE, data, onSuccess, onError);
  }

  const changePassword = (data:any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PASSWORD, data, onSuccess, onError);
  }

  const updateSocial = (data:any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_SOCIAL, data, onSuccess, onError);
  }

  const connectWallet = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CONNECT_WALLET, data, onSuccess, onError);
  }

  const getAdminUserProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_ADMIN_USER_PROFILE, data, onSuccess, onError);
  }
  
  return {
    login: login,
    signup: signup,
    logout: logout,
    registerStepOne: registerStepOne,
    registerStepTwo: registerStepTwo,
    registerStepThree: registerStepThree,
    registerStepFour: registerStepFour,
    loginstep1: loginstep1,
    forgotpassword: forgotpassword,
    setforgottenpassword: setforgottenpassword,
    getStoreConfiguration: getStoreConfiguration,
    saveStoreConfiguration: saveStoreConfiguration,
    getUserProfile: getUserProfile,
    updateProfile: updateProfile,
    changePassword: changePassword,
    updateSocial: updateSocial,
    getAdminUserProfile: getAdminUserProfile,
    connectWallet
  }
}