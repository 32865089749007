import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ItemTypeDeleteIcon from "../../components/ItemTypeDeleteIcon";
import { RiFolderSettingsLine } from "react-icons/ri";
import Select from 'react-select'
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Checkbox from "shared/Checkbox/Checkbox";
import Switch from "shared/Switch/Switch";
import InputFile from "shared/InputFile/InputFile";
import Radio from "shared/Radio/nativeRadio";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useCollectionTypesSelector } from "_common/hooks/selectors/collectionSelector";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";
import moment from "moment";
import { utcToLocal, localToUtc, CheckNumber, getSelectedOption } from "_common/functions";
import { DATE_FORMAT } from "_common/_constant";
import { CONTRACT_DEPLOYED_STATUS, NETWORK_URLS } from "_common/_constant";
import { MdDelete, MdAddBox, MdOutlineMonetizationOn } from 'react-icons/md';
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import DateInput from "shared/Date/DateInput";
import FormItem from "components/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { BiCalendar, BiExtension, BiSearchAlt } from "react-icons/bi";
import { Transition } from "@headlessui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { useSiteSettingsApi } from "_common/hooks/api/siteSettingsApiHook";

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	onDelete?: any
}

const Details: FC<CardNFTProps> = ({ className = "", isLiked, onDelete }) => {

	const { id } = useParams();
	const { showLoader, hideLoader } = useAppLoader();
	const navigate = useNavigate();
	//const collectionTypes = useCollectionTypesSelector();
	//const blockchainNetworks = useBlockchainNetworksSelector();
	const collectionApi = useCollectionApi();
	const commonApi = useCommonApi();
	const productApi = useProductApi();
	const siteSettingsApi = useSiteSettingsApi();
	

	const { register, control, handleSubmit, reset, watch, getValues, setFocus, setValue, formState: { errors } } = useForm({});

	const [collectionTypesOption, setCollectionTypesOption] = useState<any[] | null>(null);
	const [blockchainNetworksOption, setBlockchainNetworksOption] = useState<any[] | null>(null);
	const [categoriesOption, setCategoriesOption] = useState<any>(null);
	const [custodialSettings, setCustodialSettings] = useState<any>(null);

	const [creatorFee, setCreatorFee] = useState<any>([]);
	const [collection, setCollection] = useState<any>(null);
	const [dropSection, setDropSection] = useState<boolean>(false)
	const [imageSection, setImageSection] = useState<boolean>(false)
	const [dateSection, setDateSection] = useState<boolean>(false)
	const [creatorEarningOpen, setCreatorEarningOpen] = useState<boolean>(false)
	const [searchEngineOpen, setSearchEngineOpen] = useState<boolean>(false)
	const [othersOpen, setOthersOpen] = useState<boolean>(false)
	const [contractTxnUrl, setContractTxnUrl] = useState<string>('')
	const [contractUrl, setContractUrl] = useState<string>('')

	const [galleryEnable, setGalleryEnable] = useState<boolean>(false);
	const [gallerycollapsopen, setGallerycollapsopen] = useState<boolean>(false);

	const [fiatoptions] = useState([
		{ value: 0, label: 'Crypto' },
		{ value: 1, label: 'Fiat' },
		{ value: 2, label: 'Both' }
	])

	const apiParams = {
		"collectionID": 0,
		"collectionName": "string",
		"shortName": "string",
		"categoryID": 0,
		"slug": "string",
		"className": "string",
		"collectionTypeID": 0,
		"blockchainNetworkID": 0,
		"deployContract": 0,
		"releaseDate": "2022-12-01T12:54:29.794Z",
		"revealDate": "2022-12-01T12:54:29.794Z",
		"about": "string",
		"description": "string",
		"externalLink": "string",
		"logoUrl": "string",
		"bannerUrl": "string",
		"featureImageUrl": "string",
		"isPublished": true,
		"isFetured": true,
		"creatorFee": []
		/*
		"creatorFee": [
			{
				"earningWalletAddress": "string",
				"earningPercentage": 0
			}
		]
		*/
	}

	const { fields: creatorFeeFiels, append: creatorFeesAppend, remove: creatorFeesRemove } = useFieldArray({
		control,
		name: "creatorFees"
	});

	const addNewCreatorFee = () => {
		creatorFeesAppend({
			earningWalletAddress: '',
			earningPercentage: 0
		})
	}

	const mapCategoryImage = (id: any, file: any, type: string) => {
		return new Promise((resolve: any, reject: any) => {
			let data: any = new FormData();
			data.append("", file);
			data.append("ItemId", id);
			data.append("ItemType", 1);
			data.append("FileType", type);

			commonApi.fileUpload(data,
				(message: string, resp: any) => {
					console.log('f complete')
					resolve(message)
				}, (message: string) => {
					console.log('f failed')
					reject(message)
				})
		})

	}

	const mapCategoryImages = (id: any, data: any) => {
		return new Promise((resolve: any, reject: any) => {
			let logoUrl: any = null;
			let bannerUrl: any = null;
			let featureImageUrl: any = null;

			if (data.logoUrl[0]) {
				mapCategoryImage(id, data.logoUrl[0], 'logo').then(() => { logoUrl = true; }).catch(() => { logoUrl = false; });
			} else { logoUrl = true }

			if (data.bannerUrl[0]) {
				mapCategoryImage(id, data.bannerUrl[0], 'banner').then(() => { bannerUrl = true; }).catch(() => { bannerUrl = false; });
			} else { bannerUrl = true }

			if (data.featureImageUrl[0]) {
				mapCategoryImage(id, data.featureImageUrl[0], 'featureImage').then(() => { featureImageUrl = true; }).catch(() => { featureImageUrl = false; });
			} else { featureImageUrl = true }

			let timer: any = setInterval(() => {
				console.log('Waiting for upload...')
				if (logoUrl !== null && featureImageUrl !== null && bannerUrl !== null) {
					clearInterval(timer)
					resolve();
				}
			}, 200)

		})

	}



	

	const getCollection = () => {
		showLoader()
		collectionApi.getCollections({
			collectionID: id
		}, (message: string, resp: any) => {
			hideLoader()
			if (resp.code == 204) {
				toast.error('No Data Found')
				navigate('/collection')
			} else {
				setCollection(resp.collections[0]);
				creatorFeesRemove();
				//console.log('xxxx', resp.collections[0]['creatorFee'].length);
				setTimeout(() => {
					if (resp.collections[0]['creatorFee'].length > 0) {
						for (let i = 0; i < resp.collections[0].creatorFee.length; i++) {
							creatorFeesAppend({
								earningWalletAddress: resp.collections[0].creatorFee[i].earningWalletAddress,
								earningPercentage: resp.collections[0].creatorFee[i].earningPercentage
							})
						}
						/*
						resp.collections[0].creatorFee.map((o: any) => creatorFeesAppend({
							earningWalletAddress: o['earningWalletAddress'],
							earningPercentage: o['earningPercentage']
						}, { shouldFocus: false }))
						*/
					}
				}, 1000)

				/*
				resp.collections[0]['creatorFee'].length ? resp.collections[0]['creatorFee'].map((e: any) => creatorFeesAppend({
					earningWalletAddress: e['earningWalletAddress'],
					earningPercentage: e['earningPercentage']
				}, { shouldFocus: false })) : creatorFeesAppend({
					earningWalletAddress: '',
					earningPercentage: 0
				}, { shouldFocus: false })
				*/
			}
		}, (message: string, resp: any) => {
			hideLoader()
		})
	}

	const onSubmit = (data: any) => {

		let _creatorFee: any = [];
		creatorFee.map((fee: any) => {
			if (!fee.deleted) {
				_creatorFee.push({
					earningWalletAddress: data[`earningWalletAddress${fee.id}`],
					earningPercentage: parseFloat(data[`earningPercentage${fee.id}`])
				})
			}
		})

		let params: any = {
			collectionID: id == 'new' ? 0 : id,
			collectionName: data.collectionName,
			shortName: data.shortName,
			categoryID: data.categoryID.value,
			//slug: "",
			className: data.className,
			collectionTypeID: data.collectionTypeID.value,
			blockchainNetworkID: data.blockchainNetworkID.value,
			deployContract: parseInt(data.deployContract),
			releaseDate: localToUtc(data.releaseDate),
			revealDate: localToUtc(data.revealDate),
			about: data.about,
			description: data.description,
			externalLink: data.externalLink,
			isCreatedByCustomer: 1,
			//logoUrl: "",
			//bannerUrl: "",
			//featureImageUrl: "",
			isPublished: data.isPublished,
			isFetured: data.isFetured,
			isFiat: Math.abs(data.isFiat.value),
			// creatorFee: _creatorFee,
			creatorFee: data.hasOwnProperty('creatorFees') && data.creatorFees ? data['creatorFees'].filter((e: any) => e['earningWalletAddress']) : [],
			metaTitle: data.metaTitle || '',
			metaDescription: data.metaDescription || '',
			galleryCode: data.galleryCode,
			isPrivate: data.isPrivate === true ? 1 : 0,
			galleryPassword: data.galleryPassword
		}

		showLoader()
		collectionApi.addUpdateDetails(params,
			(message: string, resp: any) => {
				mapCategoryImages(resp.returnID, data).then((msg: any) => {
					hideLoader();
					toast.success(message);
					navigate(`/create-collection/${resp.returnID}?tab=Socials`)
				}).catch((msg: any) => {
					hideLoader();
					toast.success(message);
					navigate(`/create-collection/${resp.returnID}?tab=Socials`)
				})
			}, (message: string) => {
				hideLoader();
				toast.error(message)
			})
	}


	const getCollectionTypes_new = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			collectionApi.getCollectionTypes({}, (message: string, resp: any) => {
				if (resp.code === 200 && resp.successful === true) {
					resolve(resp.collectionTypes)
				} else {
					reject(resp);
				}
			}, (message: string) => {
				reject(message)
			})
		})
	}

	const getBlockchainNetworks_new = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			commonApi.getBlockchainNetworks({}, (message: string, resp: any) => {
				if (resp.code === 200 && resp.successful === true) {
					resolve(resp.networks)
				} else {
					reject(resp);
				}
			}, (message: string, resp: any) => {
				console.log(message, resp)
				reject(message)
			})
		});
	}

	const getCategories_new = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			productApi.getCategories({}, (message: string, resp: any) => {
				if (resp.code === 200 && resp.successful === true) {
					resolve(resp.categorydm)
				} else {
					reject(resp);
				}
				/*
				setCategoriesOption(resp.categorydm.map((cat: any) => {
					cat.label = cat.categoryName;
					cat.value = cat.categoryID;
					return cat;
				}));
				*/
			}, (message: string) => {
				console.log(message);
				reject(message)
			});
		});
	}

	const getCustodialSettings = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			siteSettingsApi.fetchCustodialWallet({}, (message: string, resp: any) => {
				resolve(resp);
			}, (message: string) => {
				reject(message);
			})
		})
	}

	const loadSiteSettings = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			siteSettingsApi.getSiteSettings({}, (message: string, resp: any) => {
				if (resp.code === 200 && resp.successful === true) {
					
					const settings = resp.siteSettings.filter((o: any) => (o.siteGroupName.trim() === 'Metaverse'));
					if (settings.length > 0 && settings[0].keyValue[0].appKey === 'IsEnableMetaverse' && settings[0].keyValue[0].appValue === 'true') {
						resolve(true);
					} else {
						resolve(false);
					}
				} else {
					resolve(false);
				}
			}, (message: string) => {
				reject(false)
			})
		})
	}


	useEffect(() => {
		showLoader();
		const ApisAre = Promise.all([
			getCollectionTypes_new(),
			getBlockchainNetworks_new(),
			getCategories_new(),
			getCustodialSettings(),
			loadSiteSettings()
		]).then((data: any) => {
			console.log("Datas are", data);
			if (data && data[0]) {
				const options = data[0].map((o: any) => {
					return {
						value: o.collectionTypeID,
						label: o.collectionType
					}
				})
				setCollectionTypesOption(options)
			}

			if (data && data[1]) {
				const networks = data[1].map((opt: any) => {
					return {
						value: opt.networkID,
						label: opt.chainLabel
					}
				})
				setBlockchainNetworksOption(networks)
			}

			if (data && data[2]) {
				setCategoriesOption(data[2].map((cat: any) => {
					return {
						label: cat.categoryName,
						value: cat.categoryID
					};
				}))
			}

			if (data && data[3]) {
				setCustodialSettings(data[3])
			}

			if (data && data[4]) {
				setGalleryEnable(data[3])
			}
			return true
		}).then(() => {
			hideLoader();
			if (id !== 'new') {
				setCreatorFee([])
				getCollection()
			}
		}).catch((err: any) => {
			hideLoader();
			console.log('err: ', err)
		});

		/*
		getCategories();
		if (id !== 'new') {
			setCreatorFee([])
			getCollection()
		}
		*/
	}, [])



	const addCreatorFee = () => {
		setCreatorFee([...creatorFee, { id: creatorFee.length, deleted: false }])
	}

	const removeCreatorFee = (id: any) => {
		let c: any = [];
		creatorFee.map((fee: any) => {
			if (fee.id == id) {
				fee.deleted = true;
			}
			c.push(fee)
		})
		setCreatorFee(c);
	}

	const handleCancel = () => {
		navigate('/collection')
	}

	const redirectContractTxn = () => {
		if (contractTxnUrl) {
			window.open(contractTxnUrl, '_blank');
		}
	}

	const redirectContract = () => {
		if (contractUrl) {
			window.open(contractUrl, '_blank');
		}
	}


	useEffect(() => {
		if (id !== 'new' && collection && categoriesOption && collectionTypesOption && blockchainNetworksOption) {
			console.log('collection =>', collection);


			let fees: any = [];
			collection.creatorFee.map((fee: any, i: any) => {
				fees.push({ id: i, deleted: false, data: fee })
			});

			console.log('creatorFee', fees);

			setCreatorFee(fees);

			if ([...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) && collection['chainID'] && collection['transactionHash']) {
				const url = NETWORK_URLS['' + collection['chainID']] + '/tx/' + collection['transactionHash']
				setContractTxnUrl(url)
			}
			if ([...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) && collection['chainID'] && collection['contractAddress'] && collection['contractAddress']['address']) {
				const url = NETWORK_URLS['' + collection['chainID']] + '/address/' + collection['contractAddress']['address']
				setContractUrl(url)
			}

			const collectionFiatRow = fiatoptions.filter((o: any) => Math.abs(o.value) === Math.abs(collection.isFiat));

			let isFiat = collectionFiatRow.length > 0 ? collectionFiatRow[0] : null
			if (!custodialSettings || (custodialSettings && custodialSettings.enableCustodialWallet === false)) {
				isFiat = { value: 0, label: 'Crypto' }
			}

			reset({
				//"collectionID": 0,
				"collectionName": collection.collectionName,
				"shortName": collection.shortName,
				"categoryID": getSelectedOption(categoriesOption, ['value'], collection.categoryID),
				//"slug": "string",
				"className": collection.className,
				"collectionTypeID": getSelectedOption(collectionTypesOption, ['value'], collection.collectionTypeID),
				"blockchainNetworkID": getSelectedOption(blockchainNetworksOption, ['value'], collection.blockchainNetworkID),
				"deployContract": collection.deployContract.toString(),
				"releaseDate": utcToLocal(collection.releaseDate),
				"revealDate": utcToLocal(collection.revealDate),
				"about": collection.about,
				"description": collection.description,
				"externalLink": collection.externalLink,
				//"logoUrl": "string",
				//"bannerUrl": "string",
				///"featureImageUrl": "string",
				"isPublished": collection.isPublished,
				"isFetured": collection.isFetured,
				"isFiat": isFiat,
				"metaTitle": collection.metaTitle || '',
				"metaDescription": collection.metaDescription || '',
				"galleryCode": collection.galleryCode || '',
				"isPrivate": Math.abs(collection.isPrivate) === 1 || false,
				"galleryPassword": collection.galleryPassword || '',
				// "creatorFee": [
				//{
				//"earningWalletAddress": "string",
				//"earningPercentage": 0
				//}
				//] 
			})
		} else {
			//console.log("custodialSettings", custodialSettings, custodialSettings?.enableCustodialWallet);
			if (!custodialSettings || (custodialSettings && custodialSettings.enableCustodialWallet === false)) {
				const isFiat = { value: 0, label: 'Crypto' }
				reset({
					isFiat: isFiat
				})
			}
		}
	}, [collection, categoriesOption, collectionTypesOption, blockchainNetworksOption, custodialSettings])
	/*
	useEffect(() => {
		if (collectionTypes) {
			let options: any[] = [];
			JSON.parse(JSON.stringify(collectionTypes)).map((type: any) => {
				type.label = type.collectionType;
				type.value = type.collectionTypeID;
				options.push(type);
			})
			setCollectionTypesOption(options)
		} else {
			getCollectionTypes()
		}
	}, [collectionTypes]);
	
	useEffect(() => {
		if (blockchainNetworks) {
			let options: any[] = [];
			JSON.parse(JSON.stringify(blockchainNetworks)).map((opt: any) => {
				opt.label = opt.chainLabel;
				opt.value = opt.networkID;
				options.push(opt);
			})
			setBlockchainNetworksOption(options)
		} else {
			getBlockchainNetworks()
		}
	}, [blockchainNetworks]);
	*/
	useEffect(() => {
		const firstError = (Object.keys(errors) as Array<keyof typeof errors>).reduce<keyof typeof errors | null>((field, a) => {
			const fieldKey = field as keyof typeof errors;
			return !!errors[fieldKey] ? fieldKey : a;
		}, null);
		console.log("firstError", firstError);
		if (firstError) {
			/*
			switch (firstError) {
				case 'productBoosts':
				case 'productDates':
					setBoostcollapseopen(true)
					break;
				case 'productStat':
					setStatscollapseopen(true)
					break;
				case 'productLevel':
					setLevelcollapseopen(true)
					break;
				case 'productProperty':
					setPropertycollapseopen(true)
					break;
				case 'unlockableContent':
				case 'isUnlockableContent':
					setSettingscollapseopen(true)
					break;
				case 'category':
					setCategorycollapseopen(true)
					break;
				case 'productFile':
				case 'productThumb':
					setImagecollapseopen(true)
					break;
				case 'editionNumber':
					setEditionOpen(true)
					break;
			}
			*/
			setFocus(firstError);
		}
	}, [errors, setFocus]);





	/* 
	...
	1. Category, Blockchain, Collection
	2. images1
	3. Dates
	4. Wallet and Sales
	.....
	
	
	*/

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5">

				<label className="block">
					<Label className="block mb-2">Display Name <span className="text-red-600">*</span></Label>
					<Input placeholder={`Display name`} type="text" className="mt-1" {...register(`collectionName`, { required: true, pattern: /^[a-zA-Z0-9 ]+$/ })} />
					{errors && errors.collectionName &&
						(
							errors.collectionName.type === 'required' && (
								<p className='text-red-400'>Display name required</p>
							) ||
							errors.collectionName.type === 'pattern' && (
								<p className='text-red-400'>Only alphanumeric characters</p>
							) || ''
						) ||
						''}
				</label>

				<label className="block">
					<Label className="block mb-2">Contract Name <span className="text-red-600">*</span></Label>
					<Input placeholder={`Contract name`} type="text" className="mt-1" {...register(`shortName`, { required: true, pattern: /^[a-zA-Z0-9]+$/ })} readOnly={collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? true : false} />
					{errors && errors.shortName &&
						(
							errors.shortName.type === 'required' && (
								<p className='text-red-400'>Contract name required</p>
							) ||
							errors.shortName.type === 'pattern' && (
								<p className='text-red-400'>Only alphanumeric characters</p>
							) || ''
						) ||
						''}
				</label>

				<label className="block">
					<Label className="block mb-2">Contract Symbol<span className="text-red-600">*</span></Label>
					<Input placeholder={`Contract symbol`} type="text" className="mt-1" {...register(`className`, { required: true, pattern: /^[A-Z]([A-Za-z0-9]*){1,20}$/ })} readOnly={collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? true : false} />
					{errors && errors.className &&
						(
							errors.className.type === 'required' && (
								<p className='text-red-400'>Contract symbol required</p>
							) ||
							errors.className.type === 'pattern' && (
								<p className='text-red-400'>Invalid pattern</p>
							) || ''
						) ||
						''}
				</label>

				<label className="block">
					<Label className="block mb-2">Collection Type <span className="text-red-600">*</span></Label>
					<Controller
						name={"collectionTypeID"}
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange, onBlur } }) => (
							<Select
								className={`mt-1`}
								options={collectionTypesOption ? collectionTypesOption : []}
								placeholder={"Select collection type"}
								onChange={onChange}
								value={value}
								defaultValue={value}
								isDisabled={collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? true : false}
							/>
						)}
					/>
					{errors && errors.collectionTypeID &&
						(
							errors.collectionTypeID.type === 'required' && (
								<p className='text-red-400'>Collection type required</p>
							) || ''
						) ||
						''}
				</label>

				<label className="block">
					<Label className="block mb-2">Blockchain <span className="text-red-600">*</span></Label>
					<Controller
						name={"blockchainNetworkID"}
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange, onBlur } }) => (
							<Select
								className={`mt-1`}
								options={blockchainNetworksOption ? blockchainNetworksOption : []}
								placeholder={"Select network"}
								onChange={onChange}
								value={value}
								defaultValue={value}
								isDisabled={collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? true : false}
							/>
						)}
					/>
					{errors && errors.blockchainNetworkID &&
						(
							errors.blockchainNetworkID.type === 'required' && (
								<p className='text-red-400'>Blockchain required</p>
							) || ''
						) ||
						''}
				</label>

				<div className="">
					<div className="flex">
						<Radio label="Deploy New Smart Contact" type="radio" value="1" {...register(`deployContract`, { required: collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? false : true })} disabled={collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? true : false} />
						<Radio label="Use Shared Smart Contact" type="radio" value="0" {...register(`deployContract`, { required: collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? false : true })} disabled={collection && [...CONTRACT_DEPLOYED_STATUS.DEPLOYED].includes(+collection['contractStatus']) ? true : false} />
					</div>
					{errors && errors.deployContract &&
						(
							errors.deployContract.type === 'required' && (
								<p className='text-red-400'>Please choose any</p>
							) || ''
						) ||
						''}
				</div>

				<label className="">
					<Label className="block mb-2">Category <span className="text-red-600">*</span></Label>
					<Controller
						name={"categoryID"}
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange, onBlur } }) => (
							<Select
								className={`mt-1`}
								options={categoriesOption ? categoriesOption : []}
								placeholder={"Select category"}
								onChange={onChange}
								value={value}
								defaultValue={value}
							/>
						)}
					/>
					{errors && errors.categoryID &&
						(
							errors.categoryID.type === 'required' && (
								<p className='text-red-400'>Category required</p>
							) || ''
						) ||
						''}
				</label>
				<label className="">
					<Label className="block mb-2">Fiat Enable</Label>
					<Controller
						name={"isFiat"}
						control={control}
						rules={{ required: true }}
						render={({ field: { value, onChange, onBlur } }) => (
							<Select
								className={`mt-1 ` + ((!custodialSettings || (custodialSettings && custodialSettings.enableCustodialWallet === false)) ? ' bg-neutral-100 dark:bg-transparent ' : '')}
								options={fiatoptions}
								placeholder={"Select Fiat"}
								styles={(!custodialSettings || (custodialSettings && custodialSettings.enableCustodialWallet === false)) ? {
									control: (baseStyles, state) => ({
										...baseStyles,
										opacity: 0.8,
										pointerEvents: 'none'
									}),
								} : {
									control: (baseStyles, state) => ({
										...baseStyles
									}),
								}}
								onChange={onChange}
								value={value}
								defaultValue={value}
							/>
						)}
					/>
					{errors && errors.isFiat && (errors.isFiat.type === 'required' && (
						<p className='text-red-400'>Is Fiat required</p>
					) || ''
					) || ''
					}
				</label>

			</div>

			{/* <div className={"accordion-item-wrapper mb-4 mt-3"}>
				<div className={"accordion-title flex items-center justify-between"}>
					<div className="text-lg font-bold flex gap-2"><AiOutlineSetting className="text-2xl mt-1" /> <span className="flex flex-col"><p>Settings</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Collection Type, Blockchain, Category</small></span></div>
					<ButtonSecondary
						className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
						sizeClass={"py-2.5"}
						type="button"
						onClick={() => { setDropSection(!dropSection) }}
					>
						<span>
							<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${dropSection ? "rotate-180" : ""}`} aria-hidden="true" />
						</span>
					</ButtonSecondary>
				</div>
				<Transition
					show={dropSection}
					enter="transition-opacity duration-150"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className={"accordion-body"}>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mb-4 mt-4">
							<label className="block">
								<Label>Collection Type <span className="text-red-600">*</span></Label>
								<Controller
									name={"collectionTypeID"}
									control={control}
									rules={{ required: true }}
									render={({ field: { value, onChange, onBlur } }) => (
										<Select
											className={`mt-1`}
											options={collectionTypesOption ? collectionTypesOption : []}
											placeholder={""}
											onChange={onChange}
											value={value}
											defaultValue={value}
										/>
									)}
								/>
							</label>
							<label className="block">
								<Label>Blockchain <span className="text-red-600">*</span></Label>
								<Controller
									name={"blockchainNetworkID"}
									control={control}
									rules={{ required: true }}
									render={({ field: { value, onChange, onBlur } }) => (
										<Select
											className={`mt-1`}
											options={blockchainNetworksOption ? blockchainNetworksOption : []}
											placeholder={""}
											onChange={onChange}
											value={value}
											defaultValue={value}
										/>
									)}
								/>
							</label>
							<label className="block">
								<Label>Category <span className="text-red-600">*</span></Label>
								<Controller
									name={"categoryID"}
									control={control}
									rules={{ required: true }}
									render={({ field: { value, onChange, onBlur } }) => (
										<Select
											className={`mt-1`}
											options={categoriesOption ? categoriesOption : []}
											placeholder={""}
											onChange={onChange}
											value={value}
											defaultValue={value}
										/>
									)}
								/>
							</label>
						</div>
					</div>
				</Transition>
			</div> */}

			<div className={"accordion-item-wrapper mb-4 mt-3"}>
				<div className={"accordion-title flex items-center justify-between"}>
					<div className="text-lg font-bold flex gap-2">
						<CiImageOn className="text-2xl mt-1" /> <span className="flex flex-col"><p>Image</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Logo image, Banner Image, Feature image</small></span></div>
					<ButtonSecondary
						className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
						sizeClass={"py-2.5"}
						type="button"
						onClick={() => {
							setImageSection(!imageSection)
						}}
					>
						<span>
							<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${imageSection ? "rotate-180" : ""}`} aria-hidden="true" />
						</span>
					</ButtonSecondary>
				</div>
				<Transition
					show={imageSection}
					enter="transition-opacity duration-150"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className={"accordion-body mt-4"}>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
							<label className="block">
								<Label className="block mb-2">Logo Image</Label>
								<InputFile
									{...register("logoUrl", { required: false })}
									uploadedUrl={collection?.logoUrl}
									onCanPlayCapture={(e: any) => { if (e == null) { setValue('logoUrl', ''); } }} />
							</label>



							<label className="block">
								<Label className="block mb-2">Banner Image</Label>
								<InputFile {...register("bannerUrl", { required: false })} uploadedUrl={collection?.bannerUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('bannerUrl', ''); } }} />
							</label>

							<label className="block">
								<Label className="block mb-2">Feature Image</Label>
								<InputFile {...register("featureImageUrl", { required: false })} uploadedUrl={collection?.featureImageUrl} onCanPlayCapture={(e: any) => { if (e == null) { setValue('featureImageUrl', ''); } }} />
							</label>
						</div>
					</div>
				</Transition>
			</div>


			{/*galleryEnable && (
				<div className={"accordion-item-wrapper mb-4"}>
					<div className={"accordion-title flex items-center justify-between"}>
						<div className="text-lg font-bold flex gap-2"><RiFolderSettingsLine className="text-2xl mt-1" /> <span className="flex flex-col"><p>Gallery Settings</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">On or off some of the features related to your NFT</small></span></div>
						<ButtonSecondary
							className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
							sizeClass={"py-2.5"}
							type="button"
							onClick={() => {
								let gallerycollapsopenX = !gallerycollapsopen;
								setGallerycollapsopen(gallerycollapsopenX)
							}}
						>
							<span>
								<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${gallerycollapsopen ? "rotate-180" : ""}`} aria-hidden="true" />
							</span>
						</ButtonSecondary>
					</div>
					<Transition
						show={gallerycollapsopen}
						enter="transition-opacity duration-150"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity duration-150"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className={"accordion-body"}>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
								<FormItem label="Gallery Code" desc={''}>
									<Input placeholder={`Gallery code`} type="text" className="mt-1" {...register(`galleryCode`)} />
									{errors && errors.galleryCode && (errors.galleryCode.type === 'required' && (
										<p className='text-red-400'>Contract symbol required</p>
									) || errors.galleryCode.type === 'pattern' && (
										<p className='text-red-400'>Invalid pattern</p>
									) || ''
									) || ''}
								</FormItem>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mb-4 mt-4">
								<FormItem label="Is Private" desc={
									<div>
										Freezing your metadata will allow you to permanently lock and store all of this item's content in decentralized file storage.
									</div>
								}>
									<Switch label={""} {...register('isPrivate')} />
								</FormItem>
								{watch('isPrivate') && (
									<FormItem label="Gallery Password" isRequired={true}>
										<Input type={'password'} placeholder={"Enter gallery password"} {...register('galleryPassword', {
											required: true
										})} />
										{
											errors && errors.galleryPassword && errors.galleryPassword.type === 'required' &&
											(<p className='text-red-400'>Gallery password shouldn't be blank!</p>)
										}
									</FormItem>
								)}
							</div>
						</div>
					</Transition>
				</div>
			)*/}

			<div className={"accordion-item-wrapper mb-4"}>
				<div className={"accordion-title flex items-center justify-between"}>
					<div className="text-lg font-bold flex gap-2"><BiCalendar className="text-2xl mt-1" /> <span className="flex flex-col"><p>Dates</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Release date, Reveal date</small></span></div>
					<ButtonSecondary
						className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
						sizeClass={"py-2.5"}
						type="button"
						onClick={() => {
							setDateSection(!dateSection)
						}}
					>
						<span>
							<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${dateSection ? "rotate-180" : ""}`} aria-hidden="true" />
						</span>
					</ButtonSecondary>
				</div>
				<Transition
					show={dateSection}
					enter="transition-opacity duration-150"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className={"accordion-body"}>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mb-4 mt-4">
							{/*<label className="block">*/}
							<FormItem label="Release Date">
								{/*<Label className="mb-2 block">Release Date</Label>*/}
								<Controller
									name={"releaseDate"}
									control={control}
									rules={{
										required: getValues('revealDate') ? true : false,
										validate: {
											dateCompareRelease: value => value && getValues('revealDate') ? (new Date(value) < getValues('revealDate') ? true : false) : true,

										}
									}}
									render={({ field: { value, onChange, onBlur, name } }) => (
										<>
											{/*<DatePicker selected={value} placeholderText={'Release date'} onChange={onChange} dateFormat={DATE_FORMAT} />*/}
											<DateInput
												name={name}
												onChange={onChange}
												value={value}
												onBlur={onBlur}
												placeholder="Release date"
												showTimeSelect={false}
												dateFormat="MM-dd-yyyy"
											/>
										</>
									)}
								/>
								{errors && errors.releaseDate &&
									(
										errors.releaseDate.type === 'required' && (
											<p className='text-red-400'>Release date required</p>
										) ||
										errors.releaseDate.type === 'dateCompareRelease' && (
											<p className='text-red-400'>Release date should be less than reveal date</p>
										) || ''
									) ||
									''}
							</FormItem>
							{/*</label>*/}
							{/*<label className="block">*/}
							<FormItem label="Reveal Date">
								{/*<Label className="mb-2 block">Reveal Date</Label>*/}
								<Controller
									name={"revealDate"}
									control={control}
									rules={{
										required: false,
										validate: {
											dateCompareReveal: value => value && getValues('releaseDate') ? (new Date(value) > getValues('releaseDate') ? true : false) : true,

										}
									}}
									render={({ field: { value, onChange, onBlur, name } }) => (
										<>
											{/*<DatePicker selected={value} placeholderText={'Reveal date'} onChange={onChange} dateFormat={DATE_FORMAT} />*/}
											<DateInput
												name={name}
												onChange={onChange}
												value={value}
												onBlur={onBlur}
												placeholder="Reveal date"
												showTimeSelect={false}
												dateFormat="MM-dd-yyyy"
											/>
										</>
									)}
								/>
								{/* <>{console.log('errors: ', errors)}</> */}
								{errors && errors.revealDate &&
									(
										errors.revealDate.type === 'required' && (
											<p className='text-red-400'>Reveal date required</p>
										) ||
										errors.revealDate.type === 'dateCompareReveal' && (
											<p className='text-red-400'>Reveal date should be greater than release date</p>
										) || ''
									) ||
									''}
							</FormItem>
							{/*</label>*/}

						</div>
					</div>
				</Transition>
			</div>

			<div className={"accordion-item-wrapper mb-4"}>
				<div className={"accordion-title flex items-center justify-between"}>
					<div className="text-lg font-bold flex gap-2"><MdOutlineMonetizationOn className="text-2xl mt-1" /> <span className="flex flex-col"><p>Creator Earning</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing.</small></span></div>
					<ButtonSecondary
						className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
						sizeClass={"py-2.5"}
						type="button"
						onClick={() => {
							setCreatorEarningOpen(!creatorEarningOpen)
						}}
					>
						<span>
							<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${creatorEarningOpen ? "rotate-180" : ""}`} aria-hidden="true" />
						</span>
					</ButtonSecondary>
				</div>
				<Transition
					show={creatorEarningOpen}
					enter="transition-opacity duration-150"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className={"accordion-body"}>

						{creatorFeeFiels.length > 0 ? (
							<div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-2.5 mb-4 mt-4">
								<div className="flex items-center justify-between mb-4 mt-4">
									<h4 className="text-lg font-bold"></h4>
									<div className="sm:text-sm flex items-center text-blue-800 cursor-pointer mt-4" onClick={creatorFeesAppend}><AiOutlinePlus className="mr-2" /> Add New</div>
								</div>

								{creatorFeeFiels.map((fee: any, index: number) => (
									<div className="flex items-center" key={index}>
										<FormItem label="Wallet Address" className="mr-4 w-full">
											<Input placeholder={"Your Payout Wallet Address"} {...register(`creatorFees.${index}.earningWalletAddress`)} />
											{/* {
											errors && errors.creatorFees && errors.creatorFees[index] && errors.creatorFees[index]?.earningWalletAddress && errors.creatorFees[index]?.earningWalletAddress?.message ?
												<p className='text-red-400'>{errors.creatorFees[index]?.earningWalletAddress?.message}</p> : ''
										} */}
										</FormItem>
										<FormItem label="Earning Percentage" className="mr-4 w-full">
											<Input placeholder={"Primary Sales %"} {...register(`creatorFees.${index}.earningPercentage`)} />
											{/* {
											errors && errors.creatorFees && errors.creatorFees[index] && errors.creatorFees[index]?.earningPercentage && errors.creatorFees[index]?.earningPercentage?.message ?
												<p className='text-red-400'>{errors.creatorFees[index]?.earningPercentage?.message}</p> : ''
										} */}
										</FormItem>
										<div className="relative flex-shrink-0 flex mb-1">
											<span onClick={() => creatorFeesRemove(index)} className="ml-2 delete-btn relative pt-7"><ItemTypeDeleteIcon /></span>
										</div>
									</div>
								))}

								{/* {creatorFee.map((fee: any, i: any) => {
								if (!fee.deleted) {
									return <div className="flex items-center" key={i}>
										<label className="block w-72 mr-3">
											<Input placeholder={`Your Payout Wallet Address`} defaultValue={fee.data ? fee.data.earningWalletAddress : ''} type="text" className="mt-1" {...register(`earningWalletAddress${fee.id}`, { required: true })} />
										</label>
										<label className="block w-72 mr-3">
											<Input placeholder={`Primary Sales %`} defaultValue={fee.data ? fee.data.earningPercentage : ''} type="text" className="mt-1" {...register(`earningPercentage${fee.id}`, { required: true })} onChange={CheckNumber} />
										</label>
										<AiOutlineDelete onClick={() => { removeCreatorFee(fee.id) }} className="text-3xl cursor-pointer" />
									</div>
								}

							})} */}
								<>{console.log('errors: ', errors)}</>
							</div>
						) : (
							<div className="flex flex-col justify-center p-6">
								<div className="text-5xl text-center text-yellow-500"><i className="las la-box"></i></div>
								<div className="text-center"><strong>No Records Yet!</strong></div>
								<div className="text-center pointer cursor-pointer text-primary-700 mt-1.5">Please click <a className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm font-medium px-3 py-1 min-w-[84px]  ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 relative z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" onClick={addNewCreatorFee}>+ Add New</a> to create a new Creator Fee.</div>
							</div>
						)}
					</div>
				</Transition>
			</div>

			<div className={"accordion-item-wrapper mb-4"}>
				<div className={"accordion-title flex items-center justify-between"}>
					<div className="text-lg font-bold flex gap-2"><BiExtension className="text-2xl mt-1" /> <span className="flex flex-col"><p>Others</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal"></small></span></div>
					<ButtonSecondary
						className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
						sizeClass={"py-2.5"}
						type="button"
						onClick={() => {
							setOthersOpen(!othersOpen)
						}}
					>
						<span>
							<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${othersOpen ? "rotate-180" : ""}`} aria-hidden="true" />
						</span>
					</ButtonSecondary>
				</div>
				<Transition
					show={othersOpen}
					enter="transition-opacity duration-150"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className={"accordion-body"}>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-5 mt-6">
							<label className="block">
								<Label className="block mb-2">External Link</Label>
								<Input placeholder={``} type="text" className="mt-1" {...register(`externalLink`)} />
							</label>
						</div>
						<div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5 mt-6">

							<Switch label="Published(Public)"  {...register("isPublished")} />
							<Switch label="Is Fetured"  {...register("isFetured")} />
						</div>

						<div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-5 mt-6">

							<label className="block">
								<Label className="block mb-2">About</Label>
								<Textarea className="mt-1" rows={6} {...register(`about`)} />
							</label>
							<label className="block">
								<Label className="block mb-2">Description</Label>
								<Textarea className="mt-1" rows={6} {...register(`description`)} />
							</label>
						</div>
					</div>
				</Transition>
			</div>

			<div className={"accordion-item-wrapper mb-4"}>
				<div className={"accordion-title flex items-center justify-between"}>
					<div className="text-lg font-bold flex gap-2"><BiSearchAlt className="text-2xl mt-1" /> <span className="flex flex-col"><p>Search Engine Listing Preview</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Add title and description to see how this collection might appear on search engines</small></span></div>
					<ButtonSecondary
						className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
						sizeClass={"py-2.5"}
						type="button"
						onClick={() => {
							setSearchEngineOpen(!searchEngineOpen)
						}}
					>
						<span>
							<ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${searchEngineOpen ? "rotate-180" : ""}`} aria-hidden="true" />
						</span>
					</ButtonSecondary>
				</div>
				<Transition
					show={searchEngineOpen}
					enter="transition-opacity duration-150"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className={"accordion-body"}>
						<div className="grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-2.5 mb-4 mt-4">
							<label className="block">
								<Label className="block mb-2">Title</Label>
								<Input placeholder={`title`} type="text" className="mt-1" {...register(`metaTitle`)} />
							</label>
							<label className="block">
								<Label className="block mb-2">Description</Label>
								<Textarea className="mt-1" placeholder="description" rows={6} {...register(`metaDescription`)} />
							</label>
						</div>
					</div>
				</Transition>
			</div>

			{
				collection && collection['contractAddress'] && collection['contractAddress']['address'] && (
					<div className="mt-10 bg-neutral-100/70 dark:bg-neutral-800 border-l">
						<div className="p-2">
							Your contract on the <strong>{collection['chainLabel']}</strong>, is successfully deployed. 
							<br />Txn Hash:&nbsp;&nbsp;
							<strong className="cursor-pointer" onClick={redirectContractTxn}>
								{collection['transactionHash']}
							</strong>
							<br />Contract Address:&nbsp;&nbsp;
							<strong className="cursor-pointer" onClick={redirectContract}>
								{collection['contractAddress']['address']}
							</strong>
						</div>
					</div>
				) || ""
			}

			<div className="w-full border-b-2 mb-5 mt-5 border-neutral-100 dark:border-neutral-700 mt-10 mb-10"></div>
			<div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
				<ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
				<ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
			</div>
			{/* <div className={`text-center`}>
				<ButtonPrimary type="submit">Save</ButtonPrimary>
			</div> */}

		</form>
	);
};

export default Details;