import React, { useEffect, useState } from "react";
import Label from "components/Label/Label";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { ACTIONS, STORAGE } from "_config";
import { store } from "app/store";

interface IncomingProps {
    user: any;
    getUserDetails: any;
    userDetaits: any
}

const UpdateProfile = ({ user, getUserDetails, userDetaits }: IncomingProps) => {

    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [profileImageLink, setProfileImageLink] = useState<any>('')
    const [profileImageFile, setProfileImageFile] = useState<any>(null)

    const { register, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm()

    const handleKeyPress = (event: any) => {
        // console.log("keypreess", event)
        // return (event.charCode == 8 ||  event.charCode >= 48 && event.charCode <= 57)
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const mapCategoryImage = (file: any, type: string) => {
        // console.log({ id, file, type })
        return new Promise((resolve: any, reject: any) => {
            console.log("loggedIn user", user);
            let data: any = new FormData();
            data.append("", file);
            data.append("ItemId", user.userID);
            data.append("ItemType", 9);
            data.append("FileType", type);

            commonApi.fileUpload(data,
                (message: string, resp: any) => {
                    console.log('f complete')
                    if (resp.code === 200 && resp.uploadedFiles.length > 0 && resp.uploadedFiles[0].thumbUrl) {
                        //resp.uploadedFiles[0].thumbUrl;
                        //need to update session
                        let user: any = localStorage.getItem(STORAGE);
                        user = JSON.parse(user);
                        user['profileImageUrl'] = resp.uploadedFiles[0].thumbUrl;
                        localStorage.setItem(STORAGE, JSON.stringify(user));
                        store.dispatch({
                            type: ACTIONS.USER.LOGIN,
                            payload: {
                                user: user,
                                token: null
                            }
                        });
                    }
                    resolve(message)
                }, (message: string) => {
                    console.log('f failed')
                    reject(message)
                })
        })

    }

    const handleUpdateProfile = (data: any) => {
        console.log("Update Profile", { data });
        const file = data["file"]
        delete data["file"]
        delete data.cryptoAddress;
        data["customerID"] = userDetaits?.customerID
        console.log(data)
        showLoader()
        userApi.updateProfile(data, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            getUserDetails()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    useEffect(() => {
        if (userDetaits) {
            console.log({ userDetaits })
            reset({
                firstName: userDetaits.firstName,
                lastName: userDetaits.lastName,
                cryptoAddress: userDetaits.cryptoAddress,
                phoneNo: userDetaits.phoneNo,
                emailID: userDetaits.emailID,
                description: userDetaits.description,
                custodialWalletAddress: userDetaits.custodialWalletAddress,
            })
            setProfileImageLink(userDetaits.profileImageUrl)
        }
    }, [userDetaits])

    const handleImageChange = (event: any) => {
        console.log({ event: event.target.files })
        if (event.target.files && event.target.files[0]) {
            // setProfileImageFile(event.target.files[0])
            setProfileImageLink(URL.createObjectURL(event.target.files[0]));
            showLoader()
            mapCategoryImage(event.target.files[0], "profile").then((msg: any) => {
                toast.success(msg);
                hideLoader();
                getUserDetails()
            }).catch((msg: any) => {
                toast.success(msg);
                hideLoader();
                getUserDetails()
            });
        }
        // if (event.target.files && event.target.files[0]) {
        //     let reader = new FileReader();
        //     reader.onload = (e: any) => {
        //         setProfileImageLink(e.target.result);
        //     };
        //     reader.readAsDataURL(event.target.files[0]);
        // }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(handleUpdateProfile)}>
                <div className="flex flex-col md:flex-row mt-10">
                    <div className="flex-shrink-0 flex items-start">
                        <div className="relative rounded-full flex">
                            {profileImageLink.length > 0 ?
                                <Avatar sizeClass="w-32 h-32" imgUrl={profileImageLink} /> :
                                <Avatar sizeClass="w-32 h-32" />
                            }

                            <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer change-pic">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                        stroke="currentColor"
                                        strokeWidth={1.5}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span className="mt-1 text-xs"></span>
                            </div>
                            <input
                                type="file"
                                className="absolute inset-0 opacity-0 cursor-pointer change-pic"
                                {...register(`file`, { required: false })}
                                onChange={(e: any) => handleImageChange(e)}
                            />
                        </div>
                    </div>
                    <div className="flex-grow mt-10 md:mt-0 md:pl-11 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">
                        <div className="grid grid-cols-2 gap-8">
                            <div>
                                <Label>First Name</Label>
                                <Input className="mt-1.5"
                                    {...register(`firstName`, { required: false })}
                                />
                            </div>

                            <div>
                                <Label>Last Name</Label>
                                <Input className="mt-1.5"
                                    {...register(`lastName`, { required: false })}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-8">
                            <div>
                                <Label>Email</Label>
                                <Input
                                    className="mt-1.5"
                                    placeholder="example@email.com"
                                    {...register(`emailID`, {
                                        required: false,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        disabled: (userDetaits && userDetaits.emailID) ? true : false
                                    })}
                                />

                            </div>

                            <div>
                                <Label>Phone no</Label>
                                <Input className="mt-1.5" {...register(`phoneNo`, { required: false })} onKeyPress={(event: any) => handleKeyPress(event)} />
                            </div>
                        </div>
                        <div>
                            <Label>Crypto Wallet Address</Label>
                            <div className="mt-1.5 relative text-neutral-700 dark:text-neutral-300">
                                <Input
                                    className="!pr-10 "
                                    id={`cryptoaddressfield`}
                                    placeholder="Enter your crypto address here."
                                    {...register(`cryptoAddress`, {
                                        required: false,
                                        disabled: (true)
                                    })}
                                />
                                <span className="absolute right-2.5 cursor-pointer top-1/2 -translate-y-1/2" onClick={() => {
                                    const address = watch('cryptoAddress') || userDetaits.cryptoAddress;
                                    if (address) {
                                        toast.success("Copied.")
                                        navigator.clipboard.writeText(address);
                                    }
                                }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        {userDetaits?.isCustodialWalletEnabled == "true" ?
                            <div>
                                <Label>Custodial Wallet Address</Label>
                                <div className="mt-1.5 relative text-neutral-700 dark:text-neutral-300">
                                    <Input
                                        className="!pr-10 "
                                        id={`custodialWalletAddress`}
                                        placeholder="Enter your custodial wallet address here."
                                        {...register(`custodialWalletAddress`, {
                                            required: false,
                                            disabled: (true)
                                        })}
                                    />
                                </div>
                            </div>
                            : null
                        }

                        <div>
                            <Label>Details</Label>
                            <Textarea rows={5} className="mt-1.5" placeholder="" {...register(`description`, { required: false })} />
                        </div>

                        {/*
                        <div>
                            <Label>Zip code</Label>
                            <Input className="mt-1.5"
                                {...register(`zipCode`, { required: false })}
                                onKeyPress={(event: any) => handleKeyPress(event)}
                            />
                        </div>

                        <div>
                            <Label>Country</Label>
                            <Input className="mt-1.5"
                                {...register(`country`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>State</Label>
                            <Input className="mt-1.5"
                                {...register(`state`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>City</Label>
                            <Input className="mt-1.5"
                                {...register(`city`, { required: false })}
                            />
                        </div>

                        <div>
                            <Label>Address</Label>
                            <Textarea
                                rows={5}
                                className="mt-1.5"
                                placeholder=""
                                {...register(`address`, { required: false })}
                            />
                        </div>
                        */}

                        <div className="pt-2 m-4">
                            <ButtonPrimary className="w-full" type="submit">Update details</ButtonPrimary>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

export default UpdateProfile