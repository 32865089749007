import React, { FC, useEffect, useRef, useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from 'react-select'
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import { Controller, useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import Checkbox from "shared/Checkbox/nativeCheckbox"

interface IncomingProps {
    user: any; //Logged in user stored in session
    getUserDetails: any; //Get latest user data from api call
    userDetaits: any //Latest user data
}

const BillingShippingAdress = ({ user, getUserDetails, userDetaits }: IncomingProps) => {
    const sameAsBillingChkbox: any = useRef();
    const commonApi = useCommonApi();
    const userApi = useUserApi();
    const { showLoader, hideLoader } = useAppLoader()
    const { register, handleSubmit, control, getValues, watch, reset, formState: { errors } } = useForm({
        defaultValues: {
            address1: '',
            address2: '',
            country: null,
            state: '',
            city: '',
            zipCode: '',
            isShippingSameAsBilling: false,
            shippingAddress1: '',
            shippingAddress2: '',
            shippingCountry: null,
            shippingState: '',
            shippingCity: '',
            shippingZIPCode: ''
        }
    })
    const [countries, setCountries] = useState<any>(null);


    const onSubmitBillingShipping = (data: any) => {
        const params: any = Object.assign({}, JSON.parse(JSON.stringify(data)));
        params["customerID"] = userDetaits?.customerID;
        params['firstName'] = userDetaits.firstName;
        params['lastName'] = userDetaits.lastName;
        params['phoneNo'] = userDetaits.phoneNo;
        params['description'] = userDetaits.description;
        if (sameAsBillingChkbox?.current?.checked)
            params['isShippingSameAsBilling'] = true;
        else    
            params['isShippingSameAsBilling'] = false;
        if (userDetaits?.isCustodialWalletEnabled == "true") {
            params['custodialWalletAddress'] = userDetaits.custodialWalletAddress;
        }
        params.country = params.country.value;
        params.shippingCountry = params.shippingCountry.value;
        showLoader();
        userApi.updateProfile(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            getUserDetails()
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const loadCountries = () => {
        showLoader();
        commonApi.getCountries({}, (message: string, resp: any) => {
            hideLoader();
            if (resp.code === 200 && resp.successful === true) {
                resp.countries = resp.countries.map((o: any) => {
                    return {
                        label: o.countryName,
                        value: o.twoLetterIsoCode
                    }
                });
                setCountries(resp.countries);
            }
        }, (message: string) => {
            hideLoader();
        });

    }

    useEffect(() => {
        if (userDetaits && countries && Array.isArray(countries)) {
            console.log("On change UserDetails and Countries", userDetaits)
            reset({
                address1: userDetaits.address1,
                address2: userDetaits.address2,
                country: countries.find((o: any) => o.value === userDetaits.country),
                state: userDetaits.state,
                city: userDetaits.city,
                zipCode: userDetaits.zipCode,
                isShippingSameAsBilling: userDetaits.isShippingSameAsBilling,
                shippingAddress1: userDetaits.shippingAddress1,
                shippingAddress2: userDetaits.shippingAddress2,
                shippingCountry: countries.find((o: any) => o.value === userDetaits.shippingCountry),
                shippingState: userDetaits.shippingState,
                shippingCity: userDetaits.shippingCity,
                shippingZIPCode: userDetaits.shippingZIPCode
            })
        }
    }, [userDetaits, countries])

    useEffect(() => {
        loadCountries();
        console.log(user, userDetaits);
    }, [])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmitBillingShipping)} autoComplete={'off'}>
                <div className={`grid grid-cols-2 gap-8 mt-6`}>
                    <div>
                        <div className={`mb-4 mt-10`}>
                            <Label>Billing Address 1</Label>
                            <Input className="mt-1.5"
                                {...register(`address1`, { required: true })}
                            />
                            {errors?.address1 ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.address1.type === "required" ? 'Address 1 shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Billing Address 2</Label>
                            <Input className="mt-1.5"
                                {...register(`address2`, { required: false })}
                            />
                            {errors?.address2 ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.address2.type === "required" ? 'Address 2 shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Billing Country</Label>
                            <Controller
                                name={"country"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Select
                                        className={`mt-1 rounded-2xl`}
                                        options={countries}
                                        placeholder={"Select Country"}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />
                                )}
                            />
                            {errors?.country ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.country.type === "required" ? 'Country shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Billing State</Label>
                            <Input className="mt-1.5"
                                {...register(`state`, { required: true })}
                            />
                            {errors?.state ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.state.type === "required" ? 'State shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Billing City</Label>
                            <Input className="mt-1.5"
                                {...register(`city`, { required: true })}
                            />
                            {errors?.city ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.city.type === "required" ? 'City shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Billing Zip Code</Label>
                            <Input className="mt-1.5"
                                {...register(`zipCode`, { required: true })}
                            />
                            {errors?.city ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.city.type === "required" ? 'City shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                    </div>

                    <div>
                        <div className={`mb-4`}>
                            <label>
                                <Checkbox label="Same as billing address" {...register(`isShippingSameAsBilling`)} checked={getValues().isShippingSameAsBilling} ref={sameAsBillingChkbox} onChangeCapture={(e: any) => {
                                    var data = getValues();
                                    var country = Object.assign({}, JSON.parse(JSON.stringify(data.country)));
                                    if (e.target.checked) {
                                        console.log("On True", getValues())
                                        console.log(country)
                                        reset({
                                            country: country,
                                            shippingAddress1: data.address1,
                                            shippingAddress2: data.address2,
                                            shippingCountry: country,
                                            shippingState: data.state,
                                            shippingCity: data.city,
                                            shippingZIPCode: data.zipCode
                                        })
                                    } else {
                                        console.log("On False", getValues());
                                        reset({
                                            country: country,
                                            shippingAddress1: '',
                                            shippingAddress2: '',
                                            shippingCountry: null,
                                            shippingState: '',
                                            shippingCity: '',
                                            shippingZIPCode: ''
                                        })
                                    }
                                }} />
                            </label>
                        </div>
                        <div className={`mb-4`}>
                            <Label>Shipping Address 1</Label>
                            <Input className="mt-1.5"
                                {...register(`shippingAddress1`, { required: true })}
                            />
                            {errors?.shippingAddress1 ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.shippingAddress1.type === "required" ? 'Shipping address shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Shipping Address 2</Label>
                            <Input className="mt-1.5"
                                {...register(`shippingAddress2`, { required: false })}
                            />
                            {errors?.shippingAddress2 ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.shippingAddress2.type === "required" ? 'Shipping address 2 shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Shipping Country</Label>
                            <Controller
                                name={"shippingCountry"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Select
                                        className={`mt-1 rounded-2xl`}
                                        options={countries}
                                        placeholder={"Select Country"}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />
                                )}
                            />
                            {errors?.shippingCountry ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.shippingCountry.type === "required" ? 'Shipping country shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Shipping State</Label>
                            <Input className="mt-1.5"
                                {...register(`shippingState`, { required: true })}
                            />
                            {errors?.shippingState ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.shippingState.type === "required" ? 'Shipping state shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Shipping City</Label>
                            <Input className="mt-1.5"
                                {...register(`shippingCity`, { required: true })}
                            />
                            {errors?.shippingCity ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.shippingCity.type === "required" ? 'Shipping city shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                        <div className={`mb-4`}>
                            <Label>Shipping Zip Code</Label>
                            <Input className="mt-1.5"
                                {...register(`shippingZIPCode`, { required: true })}
                            />
                            {errors?.shippingZIPCode ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                                {errors.shippingZIPCode.type === "required" ? 'Shipping zip code shouldn\'t be blank.' : null}
                            </div> : null}
                        </div>
                    </div>
                </div>
                <div className="pt-2 m-4">
                    <ButtonPrimary className="w-full" type="submit">Update</ButtonPrimary>
                </div>
            </form>
        </React.Fragment>
    )
}

export default BillingShippingAdress