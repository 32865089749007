import React, { FC, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { BiMinus, BiPlus } from "react-icons/bi";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface Props {
	className?: string;
	quantity: number;
	max: number;
	onChange: (n:number)=>void;
	size?: string;
}

const QuantityCounter: FC<Props> = ({ className = '', quantity, max, onChange, size = 'sm' }) => {

	const updateQuantity = (n:number)=>{
		let _q = quantity + n;
		if(_q <= max && _q > 0){
			onChange(_q)
		} else {
			if(_q == 0){
				toast.dismiss()
				toast.error('Quantity cannot be 0')
			} else {
				toast.dismiss()
				toast.error('Maximum quantity reached')
			}
		}
	}
	



	return (
		<div className="quantity-counter">
			<div className={`flex my-3 rounded-md ${size=="sm"?"border":"border-2 text-3xl"} ${className}`} style={{width:`${size=="sm"?"96px":"150px"}`}}>
				<div className="flex flex-1 items-center justify-center cursor-pointer" onClick={() => { updateQuantity(-1) }} >
					<BiMinus />
				</div>

				<span className={`flex flex-1 items-center justify-center ${size=="sm"?"border-l border-r":"border-l-2 border-r-2 text-2xl"}`}>{quantity}</span>

				<div className="flex flex-1 items-center justify-center cursor-pointer" onClick={() => { updateQuantity(1) }} >
					<BiPlus />
				</div>
				
			</div>
		</div>
	);
};

export default QuantityCounter;
