import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useSphere } from '@react-three/cannon';
import { useThree, useFrame } from 'react-three-fiber';
import PointerLockControls from '../PointerLockControls/PointerLockControls'
import usePlayerControls from '../usePlayerControls/usePlayerControls'

import * as RAPIER from "@dimforge/rapier3d-compat"
import { useKeyboardControls } from "@react-three/drei"
import { CapsuleCollider, RigidBody, useRapier } from "@react-three/rapier"

const SPEED = 5
const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
const rotation = new THREE.Vector3()

const Player = (props) => {
  const ref = useRef();
  const rapier = useRapier();
  const [, get] = useKeyboardControls();
  const { camera } = useThree();
  /* const {
    forward,
    backward,
    left,
    right,
    jump,
    speed
  } = usePlayerControls() */
  /* const [ref, api] = useSphere(() => ([
    {
      mass: 1,
      type: "Dynamic",
      position: [-11, 5, 33],
      rotation: [0, 0, Math.PI / 2],
      args: 5,
      ...props
    }]
  )) */

  /* const velocity = useRef([0, 0, 0])
  useEffect(() => {
    //update reference everytime velocity changes
    api.velocity.subscribe(v => velocity.current = v)
  }, [api.velocity])

  useFrame(() => {
    //copy position of our physical sphere
    camera.position.copy(ref.current.position)

    const frontVector = new THREE.Vector3(0, 0, Number(backward) - Number(forward))
    const sideVector = new THREE.Vector3(Number(left) - Number(right), 0, 0)

    const direction = new THREE.Vector3()
    //calculate direction aligned with the camera
    direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(speed).applyEuler(camera.rotation)

    //apply the velocity to our sphere
    api.velocity.set(direction.x, velocity.current[1], direction.z)

    //to limit jump by check if jumping and velocity in y compared to almost zero i.e we are standing or at the top of our jump change value 100 to 0.01
    if (jump && Math.abs(velocity.current[1].toFixed(2)) < 100) {
      api.velocity.set(velocity.current[0], 10, velocity.current[2])
    }
  }) */

  useFrame(() => {
    const { forward, backward, left, right, jump } = get()
    //const velocity = ref.current.linvel()
    // update camera
    console.log(ref.current.position)
    //camera.position.set(...ref.current.position())
    // movement
    frontVector.set(0, 0, backward - forward)
    sideVector.set(left - right, 0, 0)
    direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(camera.rotation)
    //ref.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z })
    // jumping
    /* const world = rapier.world.raw()
    const ray = world.castRay(new RAPIER.Ray(ref.current.translation(), { x: 0, y: -1, z: 0 }))
    const grounded = ray && ray.collider && Math.abs(ray.toi) <= 1.75
    if (jump && grounded) ref.current.setLinvel({ x: 0, y: 7.5, z: 0 }) */
  })


  return (
    <>
      {/* <PointerLockControls /> */}
      {/* <mesh ref={ref} colliders={false} mass={1} type="dynamic" position={[0, 10, 0]} enabledRotations={[false, false, false]} ></mesh> */}
      <RigidBody ref={ref} colliders={false} mass={1} type="dynamic" position={[0, 10, 0]} enabledRotations={[false, false, false]} >
        <CapsuleCollider args={[0.75, 0.5]} />
      </RigidBody>
    </>
  )

}

export default Player
