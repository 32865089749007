import React, { useEffect, useState } from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import Vector1 from "images/Vector1.png";

import SectionLargeSlider from "./../../Normalize/containers/SectionLargeSlider";
import HomeRecentCollections from "./../../Normalize/components/homeRecentCollections";
import HomeFeatureCollections from "./../../Normalize/components/homeFeatureCollections";
import HomeRecentProducts from "./../../Normalize/components/homeRecentProducts";
import HomeActiveCategories from "./../../Normalize/components/homeActiveCategories";
import HomeSectionHowitsworks from "./../../Normalize/components/homeSectionHowitsworks";

import SectionSliderCollections from "components/SectionSliderCollections";
import SectionGridFeatureNFT from "./SectionGridFeatureNFT";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SiteHeader from "containers/SiteHeader";
import { useStoreSettingsSelector } from "_common/hooks/selectors/storeSelector";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

function PageHome() {
  const productApi = useProductApi();
  const collectionApi = useCollectionApi();

  const settings = useStoreSettingsSelector();

  const { showLoader, hideLoader } = useAppLoader();
  const [featureproducts, setFeatureproducts] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);
  const [recentCollections, setRecentCollections] = useState<any[]>([]);
  const [recentProducts, setRecentProducts] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);


  const getProducts = (type: string = '') => {
    const params: any = {
      usePaging: true,
      pageSize: 10,
      isActive: 1,
			isPublished: 1,
			isDeleted: 0
    };
    if (type === "featured") {
      params['isFeatured'] = 1;
    }
    if (type === "recent") {
      params['sortingBy'] = "sortingOrder";
      params['sortingOrder'] = "DESC";
    }
    productApi.getProducts(params, (message: string, resp: any) => {
      console.log(type + " Products", resp);
      if (resp.code === 200 && resp.successful === true) {
        if (type === "featured") {
          setFeatureproducts(resp.products);
        } else if (type === "recent") {
          setRecentProducts(resp.products);
        }
      }
    }, (message: string) => {
    });
  }

  const getCollections = (type: string = '') => {
    let params: any = {
      usePaging: true,
      pageSize: 4,
      pageNumber: 1,
      searchText: '',
      isPublished: 1
    };
    if (type === "featured") {
      params['isFetured'] = 1;
    }
    if (type === "recent") {
      params['sortingBy'] = "collectionID";
      params['sortingOrder'] = "DESC";
      params['pageSize'] = 4;
    }
    showLoader();
    collectionApi.getCollections(params, (message: string, resp: any) => {
      hideLoader();
      console.log("Featured Collection", resp);
      if (resp.code === 200 && resp.successful === true) {
        if (type === "featured") {
          setCollections(resp.collections);
        } else if (type === "recent") {
          setRecentCollections(resp.collections);
        }
        //setFeatureproducts(resp.products);
      }
    }, (message: string) => {
    });
  }

  const getCategories = () => {
    productApi.getCategories({
      isActive: 1,
      usePaging: true,
      pageSize: 8,
      pageNumber: 1
    }, (message: string, resp: any) => {
      if (resp.code === 200 && resp.successful === true) {
        setCategories(resp.categorydm)
      }
      console.log({ resp })
    }, (message: string) => {
      console.log(message);
    })
  }
  useEffect(() => {
    console.log("settings", settings);
    if (settings.settings.HomeSettings.isStoreFeaturedProductsHome.appValue === "true") {
      getProducts('featured')
    }

    if (settings.settings.HomeSettings.isShowFeaturedCollectionsHome.appValue === "true") {
      getCollections('featured');
    }

    if (settings.settings.HomeSettings.isShowRecentCollectionsHome.appValue === "true") {
      getCollections('recent');
    }

    if (settings.settings.HomeSettings.isShowBestsellerCollectionsHome.appValue === "true") {
      getProducts('recent')
    }

    getCategories();
  }, []);
  return (
    <React.Fragment>
      <div className="nc-PageHome relative overflow-hidden">
        <Helmet>
          <title>Home</title>
          <meta name="title" content={settings.settings?.SEO?.Metatags?.TitleMetatag?.appValue} />
          <meta name="description" content={settings.settings?.SEO?.Metatags?.DescriptionMetatag?.appValue} />
          <meta name="keywords" content={settings.settings?.SEO?.Metatags?.KeywordMetatag?.appValue} />
        </Helmet>
        {/* GLASSMOPHIN */}
        <BgGlassmorphism />
        {/*container relative space-y-20 mb-20 sm:space-y-24 sm:my-24 lg:space-y-32 lg:my-32*/}
        <div className="container relative mt-5 mb-20-n sm:mb-24-n lg:mt-20-n lg:mb-32-n pb-12 pt-12">
          {/* SECTION HERO */}
          <SectionHero
            className="pb-10"
            sliderdata={settings?.settings.HomeSettings}
            heading={
              <span>
                Discover 🖼
                <br /> collect, and sell <br /> extraordinary {` `}
                <span className="relative pr-3">
                  <img
                    className="w-full absolute bottom-3 -left-1"
                    src={Vector1}
                    alt="Vector1"
                  />
                  <span className="relative">NFTs</span>
                </span>
              </span>
            }
          />

          {/* SECTION 2 */}
          <SectionHowItWork />
        </div>

        {/* SECTION */}
        <div className={`bg-neutral-100/70 dark:bg-black/20`}>
          <div className="container relative space-y-24-n mb-24-n lg:space-y-32-n lg:mb-32-n">
            {recentCollections?.length > 0 && (
              <div className="relative py-20-n pt-12 pb-12 lg:py-28-n">
                <HomeRecentCollections collections={recentCollections} settings={settings?.settings.HomeSettings} />
                {/*<SectionGridAuthorBox
                  sectionStyle="style2"
                  data={Array.from("11111111")}
                  boxCard="box4"
                />*/}
              </div>
            )}
          </div>
        </div>

        <div className="container relative space-y-24-n mb-24-n lg:space-y-32-n lg:mb-32-n pb-12">
          {/*container relative space-y-24 my-24 lg:space-y-32 lg:my-32*/}
          {/* SECTION 3 */}
          {collections?.length > 0 && (
            <HomeFeatureCollections collections={collections} settings={settings?.settings.HomeSettings} />
          )}
        </div>

        <div className="bg-neutral-100/70 dark:bg-black/20 py-20-n lg:py-32-n pb-12 pt-12">
          <div className="container">
            <SectionLargeSlider products={featureproducts} />
          </div>
        </div>

        <div className="relative space-y-24-n my-24-n lg:space-y-32-n lg:my-32-n pb-12 pt-12">

          <div className="container pb-12">
            {recentProducts?.length > 0 && (
              <HomeRecentProducts products={recentProducts} settings={settings?.settings.HomeSettings} />
            )}
          </div>
          

          <div className="bg-neutral-100/70 dark:bg-black/20 py-20-n lg:py-32-n pb-12 pt-12">
              <div className="container">
                {categories.length > 0 && (
                  <HomeActiveCategories categories={categories} settings={settings?.settings.HomeSettings} />
                )}
              </div>
          </div>
          

          {/* SECTION */}
          <div className="container">
              <HomeSectionHowitsworks data={settings?.settings.HomeSettings} className={`mt-0 lg:mt-40 xl:mt-0`} />
          </div>

          {/*
          <SectionGridAuthorBox boxCard="box3" />

          <div className="relative py-20 lg:py-28">
            <BackgroundSection />
            <SectionSliderCollections />
          </div>

          <SectionGridFeatureNFT />

          <div className="relative py-20 lg:py-24">
            <BackgroundSection />
            <SectionBecomeAnAuthor />
          </div>

          <SectionSubscribe2 />

          <SectionSliderCategories />

          <SectionVideos />
          */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PageHome;
