import React, { FC, useEffect, useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";

const UpdatePassword = () => {

    const user: any = useUserSelector()
    const userApi = useUserApi()
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const { register, control, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm()

    const handleUpdatePassword = (data: any) => {
        delete data["confirmPassword"]
        data["userName"] = user?.userName
        // console.log(data)
        showLoader()
        userApi.changePassword(data, (message: string, resp: any) => {
            toast.success(message);
            resetValues()
            hideLoader();
        }, (message: string) => {
            toast.error(message)
            hideLoader()
        })
    }

    const resetValues = () => {
        reset({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(handleUpdatePassword)}>
                <div className="mb-5">
                    <Label>Current Password <span className="text-red-600">*</span></Label>
                    <Input type={'password'} className="mt-1.5"
                        {...register(`oldPassword`, { required: true })}
                    />
                </div>
                {errors?.oldPassword ? <p className='text-red-400'>
                    {errors.oldPassword.type === "required" ? 'Required' : null}
                </p> : null
                }

                <div className="mb-5">
                    <Label>New Password <span className="text-red-600">*</span></Label>
                    <Input type={'password'} className="mt-1.5"
                        {...register(`newPassword`, { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{0,}$/ })}
                    />
                </div>
                {errors?.newPassword ? <p className='text-red-400'>
                    {errors.newPassword.type === "required" ? 'Required' : null}
                    {errors.newPassword.type === "pattern" ? 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character' : null}
                </p> : null
                }

                <div className="mb-5">
                    <Label>Confirm Password <span className="text-red-600">*</span></Label>
                    <Input type={'password'} className="mt-1.5"
                        {...register(`confirmPassword`, {
                            required: true, validate: value =>
                                value === watch('newPassword') || "The passwords do not match"
                        })}
                    />
                </div>
                {errors?.confirmPassword ? <p className='text-red-400'>
                    {errors.confirmPassword.type === "required" ? 'Required' : null}
                    {errors.confirmPassword.type === "validate" ? "The passwords do not match" : null}
                </p> : null
                }

                <div className="pt-2 m-4">
                    <ButtonPrimary className="w-full">Update</ButtonPrimary>
                </div>
            </form>
        </React.Fragment>
    )
}

export default UpdatePassword