import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useCheckoutApi() {

  const callApi = useApiCall()

  const addCard = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CHECKOUT.ADD_CARD, data, onSuccess, onError);
  }

  const getCards = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CHECKOUT.GET_CARDS, data, onSuccess, onError);
  }
  const buyWithCardCoupon = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CHECKOUT.BUY_WITH_CARD_COUPON, data, onSuccess, onError);
  }

  const validateCoupon = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CHECKOUT.VALIDATE_COUPON, data, onSuccess, onError);
  }

  const buy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CHECKOUT.BUY, data, onSuccess, onError);
  }

  const buyWithMetamask = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CHECKOUT.BUY_WITH_WALLET, data, onSuccess, onError);
  }


  return {
    addCard, getCards, buyWithCardCoupon,
    validateCoupon: validateCoupon,
    buy: buy,
    buyWithMetamask
  }
}