import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select'
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Web3 from 'web3';

import Input from "shared/Input/Input";
import DateInput from "shared/Date/DateInput"
import { AiOutlinePlus } from 'react-icons/ai';
import Radio from "shared/Radio/nativeRadio";
import CheckBoxNative from "shared/Checkbox/nativeCheckbox";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { usePayoutGroupApi } from "_common/hooks/api/payoutGroupApiHook";
import { AUCTION_TYPES, ITEM_TYPE } from '../../_common/_constant'
import moment from "moment";
import { utcToLocal, localToUtc, CheckNumber, getSelectedOption } from "_common/functions";


import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import FormItem from "components/FormItem";
import { ProductPriceFormSchemaValidation } from "utils/form-validation";
import { ProductPrice_Form } from "_common/interfaces/FormProps";
import { useDateRangeTypeSelector } from '../../_common/hooks/selectors/commonSelector'
import { useUserSelector } from '../../_common/hooks/selectors/userSelector'

import DetailsPayoutGroup from '../PayoutGroup/addUpdate'
import NcCustomModal from "components/Modal/NcCustomModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import { BiCategory, BiTime } from "react-icons/bi";
import { IoMdPricetags } from "react-icons/io";
import { FaHandHoldingUsd } from "react-icons/fa";
import InputPrice from "components/InputPrice";
import useMetaMask from "../../_common/provider/metamask-provider";
import ConnectWallet from '../../themes/Normalize/components/Crypto/ConnectWallet'
import SwitchNetwork from '../../themes/Normalize/components/Crypto/SwitchNetwork'

export interface PriceNFTProps {
    className?: string;
    isLiked?: boolean;
    onDelete?: any
}

const Price: FC<PriceNFTProps> = ({ className = '' }) => {

    const { chainId, account } = useMetaMask()
	const { ethereum }: any = window;

    const { id: productId } = useParams();
    const navigate = useNavigate();
    const dateRangeTypes = useDateRangeTypeSelector();
    const { getPayoutGroups } = usePayoutGroupApi()
    const { getDateRangeTypes, saveContractTxn } = useCommonApi();
    const { getProducts, updateProductPrice } = useProductApi();
    const { getCollections } = useCollectionApi();
    const userDetails: any = useUserSelector()

    const { register, control, handleSubmit, getValues, watch, reset, resetField, setValue, formState: { errors } } = useForm<ProductPrice_Form>({
        defaultValues: {
            priceType: '1',
            auctionType: null,
            cryptoStartPrice: null,
            cryptoEndPrice: null,
            cryptoReservePrice: null,
            useDateRange: false,
            dateRangeType: null,
            dateFrom: new Date(),
            dateTo: new Date(),
            payoutGroup: null,
            charityName: '',
            charityPercentage: null,
            charityLink: ''
        },
        resolver: yupResolver(ProductPriceFormSchemaValidation)
    });

    const { showLoader, hideLoader } = useAppLoader();
    const [productDetails, setProductDetails] = useState<any>(null)
    const [payoutGroupList, setPayoutGroupList] = useState([])
    const [dateRangeTypeList, setDateRangeTypeList] = useState([])
    const [priceLabel, setPriceLabel] = useState("Price")
    const [priceDescription, setPriceDescription] = useState("Will be on sale until you transfer this item or cancel it")
    const watchAuctionType: any = watch("auctionType", {})
    const watchPriceType: any = watch("priceType", '1')
    const [collectionDetails, setCollectionDetails] = useState<any | null>(null)
    // const watchDateRangeType: any = watch("dateRangeType", null)
    // watch("dateRangeType", '1')
    const [isDatePickerOpen, setDatePickerOpen] = useState(false)
    const [isCharityOpen, setCharityOpen] = useState(false)
    const [isPriceTypeOpen, setPriceTypeOpen] = useState(false)
    const [showPayoutGroupModal, setShowPayoutGroupModal] = useState(false)
    const [modalContent, setModalContent] = useState<any>('')
    const [isShowWalletModal, setIsShowWalletModal] = useState(false);
    const [custodialWalletSettings, setCustodialWalletSettings] = useState<any>({
		isEnabled: false,
		custodialWalletAddress: ''
	})

    useEffect(() => {
        if (watchAuctionType && watchAuctionType['value'] === 1) {
            setPriceDescription("Set your starting bid price per unit")
            setPriceLabel("Price")
            resetField('cryptoEndPrice')
        }
        else if (watchAuctionType && watchAuctionType['value'] === 2) {
            setPriceDescription("Set your starting bid price per unit")
            setPriceLabel("Start Price")
            resetField('cryptoReservePrice')
        } else {
            setPriceDescription("Will be on sale until you transfer this item or cancel it")
            setPriceLabel("Price")
            resetField('cryptoEndPrice')
            resetField('cryptoReservePrice')
        }
    }, [watchAuctionType])

    useEffect(() => {
        if (watchPriceType === '1') {
            resetField('auctionType')
        }
    }, [watchPriceType])

    /*useEffect(() => {
        const dateFrom = getValues('dateFrom');
        if ( watchDateRangeType && watchDateRangeType['daysInterval'] && dateFrom ) {
            console.log('watchDateRangeType: ', watchDateRangeType)
            const dateTo = moment(dateFrom).add(watchDateRangeType['daysInterval'], 'days')
            setValue('dateTo', utcToLocal(dateTo));
        }
    }, [watchDateRangeType])*/

    useEffect(() => {
        if (productDetails && productDetails['collectionID']) {
            fetchPayoutGroup(productDetails['collectionID'])
            fetchSingleCollection(productDetails['collectionID'])
        }
    }, [productDetails])

    useEffect(() => {
        if (dateRangeTypes && dateRangeTypes.length) {
            const data = dateRangeTypes.map((e: any) => ({
                label: e['dateRange'],
                value: e['dateRangeID'],
                daysInterval: e['daysInterval']
            })) || []
            setDateRangeTypeList(data || [])
        }
    }, [dateRangeTypes])

    useEffect(() => {
        fetchProductDetails();
        listDateRangeTypes()
    }, [])

    useEffect(() => {
        if (productDetails && productDetails['productPrice'] && productDetails['productPrice'].length && dateRangeTypeList.length /*&& payoutGroupList.length*/) {
            const priceDetails = productDetails['productPrice'][0]
            const auctionType = AUCTION_TYPES.filter((e: any) => priceDetails['auctionType'] && e['value'] === +priceDetails['auctionType'])
            const dateRangeType = dateRangeTypeList.find((e: any) => priceDetails['dateRangeType'] && e['value'] === +priceDetails['dateRangeType'])
            const payoutGroup = payoutGroupList.filter((e: any) => priceDetails['payoutGroupID'] && e['value'] === +priceDetails['payoutGroupID'])

            reset({
                priceType: '' + priceDetails['priceType'],
                auctionType: auctionType && auctionType[0] || null,
                cryptoStartPrice: priceDetails['cryptoStartPrice'],
                cryptoEndPrice: priceDetails['cryptoEndPrice'] || null,
                cryptoReservePrice: priceDetails['cryptoReservePrice'] || null,
                useDateRange: priceDetails['useDateRange'] || false,
                dateRangeType: dateRangeType || null,
                dateFrom: priceDetails['dateFrom'] && utcToLocal(priceDetails['dateFrom']) || null,
                dateTo: priceDetails['dateTo'] && utcToLocal(priceDetails['dateTo']) || null,
                payoutGroup: payoutGroup && payoutGroup[0] || null,
                charityName: priceDetails['charityName'] || '',
                charityPercentage: priceDetails['charityPercentage'] || '',
                charityLink: priceDetails['charityLink'] || ''
            })
        }
    }, [productDetails, dateRangeTypeList, payoutGroupList])

    useEffect(() => {
		if (userDetails && Object.keys(userDetails).length) {
			setCustodialWalletSettings({
				isEnabled: userDetails['isCustodialWalletEnabled'] && (userDetails['isCustodialWalletEnabled'] === 'true' || userDetails['isCustodialWalletEnabled'] === true) || false,
				custodialWalletAddress: userDetails['custodialWalletAddress'] || ''
			})
		}
	}, [userDetails])

    const fetchProductDetails = () => {
        if (productId) {
            getProducts({ productID: productId }, (message: string, resp: any) => {
                const data = resp && resp.products && resp.products.length && resp.products[0] || null;
                if (data) {
                    setProductDetails(data)
                } else {
                    toast.error('Product not found!')
                    navigate('/products')
                }
            },
                (message: string, resp: any) => {
                    console.log('error resp: ', resp)
                    toast.error(message)
                })
        } else {
            toast.error('Product ID not found!')
            navigate('/products')
        }
    }

    const fetchSingleCollection = (id: number) => {
        showLoader()
        getCollections({ collectionID: id }, (message: string, resp: any) => {
            hideLoader();
            if (resp.code == 204) {
                toast.error(message)
            } else {
                if ( resp.collections && resp.collections[0] ) {
                    setCollectionDetails(resp.collections[0])
                }
            }
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const fetchPayoutGroup = (collectionId: number) => {
        getPayoutGroups({ collectionID: collectionId },
            (message: string, resp: any) => {
                const data = resp.payoutGrpdm && resp.payoutGrpdm.map((e: any) => ({
                    label: e['payoutGroupName'],
                    value: +e['payoutGroupID']
                })) || []
                setPayoutGroupList(data || [])
            },
            (message: string, resp: any) => {
                console.log('error resp: ', resp)
                toast.error(message)
            })
    }

    const listDateRangeTypes = () => {
        getDateRangeTypes({ isActive: 1 }, (message: string, resp: any) => {

        },
            (message: string, resp: any) => {
                console.log('error resp: ', resp)
                toast.error(message)
            })
    }

    const openPayoutGroupModal = () => {
        setShowPayoutGroupModal(true)
    }
    const closePayoutGroupModal = () => {
        setShowPayoutGroupModal(false)
        if (productDetails && productDetails['collectionID']) {
            fetchPayoutGroup(productDetails['collectionID'])
        }
    }

    const onSubmit = (data: any) => {
        if ( collectionDetails && collectionDetails['collectionTypeID'] == 1 && collectionDetails['contractStatus'] == 4 && productDetails['isEdition'] == 1 && !custodialWalletSettings['isEnabled'] ) {
            handleWalletConnect(collectionDetails, account, chainId, (connectedAcc: string) => _updateProductPrice(connectedAcc, data))
        } else {
            _updateProductPrice("", data);
        }
    }

    const _updateProductPrice = (connectedAcc: string, data: any) => {
        console.log('data: ', data)
        showLoader();
        const params = {
            productID: productId,
            priceType: +data['priceType'],
            auctionType: data['auctionType'] && data['auctionType']['value'] || 0,
            cryptoStartPrice: data['cryptoStartPrice'],
            cryptoEndPrice: data['cryptoEndPrice'] || 0,
            cryptoReservePrice: data['cryptoReservePrice'] || 0,
            useDateRange: data['useDateRange'],
            dateRangeType: data['dateRangeType'] && data['dateRangeType']['value'] || 0,
            dateFrom: data['dateFrom'] && localToUtc(data['dateFrom']) || null,
            dateTo: data['dateTo'] && localToUtc(data['dateTo']) || null,
            payoutGroupID: data['payoutGroup'] && +data['payoutGroup']['value'] || 0,
            charityName: data['charityName'] || '',
            charityPercentage: data['charityPercentage'] || 0,
            charityLink: data['charityLink'] || ''
        }
        updateProductPrice(params,
            (message: string, resp: any) => {
                hideLoader();
                updateContractEdition(params['cryptoStartPrice'], connectedAcc, message)
            },
            (message: string, resp: any) => {
                hideLoader();
                console.log('error resp: ', resp)
                toast.error(message)
            })
    }

    const updateContractEdition = (price: number, connectedAcc: string, message: string) => {
        if ( collectionDetails && collectionDetails['collectionTypeID'] == 1 && collectionDetails['contractStatus'] == 4 && productDetails['isEdition'] == 1 && !custodialWalletSettings['isEnabled']/* && custodialWalletSettings['custodialWalletAddress']*/ ) {

            const web3: any = new Web3(ethereum);
            const abi = collectionDetails && collectionDetails['contractAddress'] && JSON.parse(collectionDetails['contractAddress']['abi']) || '';
            const contractAddress = collectionDetails && collectionDetails['contractAddress'] && collectionDetails['contractAddress']['address'] || '';

            if ( abi && contractAddress ) {
                let depContract = new web3.eth.Contract(abi, contractAddress);
                let params: any = {
                    from: connectedAcc, //current user address
                    to: contractAddress, //contract address
                }
                const weiPrice = Web3.utils.toWei(String(price), 'ether')

                if ( productDetails['editionId'] == 0 ) {
                    // createEdition(uint256 _quantity,uint256 _price)
                    params['data'] = depContract.methods.createEdition(productDetails['quantity'], weiPrice).encodeABI()
                } else {
                    // updateEdition(uint256 _editionId, uint256 _quantity, uint256 _price, uint256 _numSold)
                    params['data'] = depContract.methods.updateEdition(productDetails['editionId'], productDetails['quantity'], weiPrice, productDetails['quantitySold']).encodeABI()
                }
                
                showLoader();
                web3.eth
                .sendTransaction(params)
                .on('transactionHash', function (hash: string) {
                    hideLoader();
                    handleSaveTxn(productDetails['productID'], hash)
                })
                .on('error', (error: any) => {
                    console.log('error: ', error)
                    toast.error('Some error occurred, please try again');
                    hideLoader();
                });
            } else {
                toast.success(message)
            }
        } else {
            toast.success(message)
        }
    }

    const handleSaveTxn = (id: number, hash: string) => {
		const params = {
			transactionHash: [{
				itemID: id,
				hashToken: hash,
				itemTypeID: ITEM_TYPE.PRODUCT
			}]
		}
		showLoader();
		saveContractTxn(params, (message: string, resp: any) => {
			hideLoader();
			toast.success('Product updated successfully')
		}, (message: string, resp: any) => {
			hideLoader();
			toast.error(message)
		})
	}

    const showWalletModal = () => {
		setIsShowWalletModal(true);
	}
	const closeWalletModal = () => {
		setIsShowWalletModal(false);
		setModalContent('')
	}

    const handleWalletConnect = async (collection: any, _account: string, _chainId: number, onSuccess: Function) => {
		if (typeof window !== 'undefined') {
			if (!ethereum) {
				toast.error('Please install the metamask extension');
			} else {
				if (_account) {
					if (_chainId === collection['chainID']) {
						closeWalletModal();
                        onSuccess(_account)
					} else {
						setModalContent(<SwitchNetwork changeChainId={collection['chainID']} callback={handleWalletConnect} item={collection} cancel={closeWalletModal} onSuccess={onSuccess} />)
						showWalletModal();
					}
				} else {
					setModalContent(<ConnectWallet cancel={closeWalletModal} callback={handleWalletConnect} item={collection} onSuccess={onSuccess} />)
					showWalletModal()
				}
			}
		}
	}

    const handleCancel = () => {
        navigate('/products')
    }

    const onChangeDateRange = (value: any) => {
        setValue('dateRangeType', value)
        console.log('value: ', value)

        const dateFrom = getValues('dateFrom');
        if (value['daysInterval'] && dateFrom) {
            const dateTo = moment(dateFrom).add(value['daysInterval'] - 1, 'days')
            setValue('dateTo', utcToLocal(dateTo));
        }
    }
    const onChangeDate = (type: string, value: string, cb: Function) => {
        const customDateRange = dateRangeTypeList.find((e: any) => e['daysInterval'] == null)
        if (type === 'dateFrom') {
            // setValue('dateFrom', new Date(value))
            cb(value)

            const dateTo: any = getValues('dateTo');
            if (dateTo) {
                const daysInterval = moment(dateTo).diff(value, 'days')
                const dateRange = dateRangeTypeList.find((e: any) => e['daysInterval'] == daysInterval + 1)
                if (dateRange) {
                    setValue('dateRangeType', dateRange)
                } else if (customDateRange) {
                    setValue('dateRangeType', customDateRange)
                }
            } else {
                if (customDateRange) {
                    setValue('dateRangeType', customDateRange)
                }
            }
        }
        else if (type === 'dateTo') {
            // setValue('dateTo', new Date(value))
            cb(value)

            const dateFrom: any = getValues('dateFrom');
            if (dateFrom) {
                const daysInterval = moment(value).diff(dateFrom, 'days')
                const dateRange = dateRangeTypeList.find((e: any) => e['daysInterval'] == daysInterval + 1)
                if (dateRange) {
                    setValue('dateRangeType', dateRange)
                } else if (customDateRange) {
                    setValue('dateRangeType', customDateRange)
                }
            } else {
                if (customDateRange) {
                    setValue('dateRangeType', customDateRange)
                }
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">


                    <div className={"accordion-item-wrapper"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div>
                                <div className="text-lg font-bold flex gap-2"><IoMdPricetags className="text-2xl mt-1" /> <span className="flex flex-col"><p>Set Product Price Type</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Textual traits that show as text boxes</small></span></div>
                                {
                                    errors &&
                                    (
                                        (errors.priceType && errors.priceType.message) ||
                                        (errors.auctionType && errors.auctionType.message) ||
                                        (errors.cryptoStartPrice && errors.cryptoStartPrice.message) ||
                                        (errors.cryptoReservePrice && errors.cryptoReservePrice.message) ||
                                        (errors.cryptoEndPrice && errors.cryptoEndPrice.message)
                                    ) && (
                                        <span className="text-red-400">(Invalid data provided)</span>
                                    ) || ''
                                }
                            </div>

                            <ButtonSecondary
                                type="button"
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                onClick={() => setPriceTypeOpen(!isPriceTypeOpen)}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isPriceTypeOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={isPriceTypeOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body mt-5"}>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                                    <FormItem label="Price Type" isRequired={true}>
                                        <div className="grid grid-cols-2 gap-4 mt-3 mb-4">
                                            <Radio label="Fixed" type="radio" value="1" {...register(`priceType`)} />
                                            <Radio label="Auction" type="radio" value="2" {...register(`priceType`)} />
                                        </div>
                                        {
                                            errors && errors.priceType && errors.priceType.message &&
                                            (<p className='text-red-400'>
                                                {errors.priceType.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mb-5 mt-0">
                                    <span>
                                        {watchPriceType === '1' ? 'Sell at a fixed price. Cancel sell order anytimes' : 'Auction to the highest bidder or sell at a declining price'}
                                    </span>
                                </div>

                                {
                                    watch('priceType') === '2' ? (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5 mb-4 mt-4">
                                            <FormItem label="Type" isRequired={true}>
                                                <Controller
                                                    name={"auctionType"}
                                                    control={control}
                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                        <Select
                                                            className={`mt-1 rounded-2xl`}
                                                            options={AUCTION_TYPES}
                                                            placeholder={"Select Auction type"}
                                                            onChange={onChange}
                                                            value={value}
                                                            defaultValue={value}
                                                        />
                                                    )}
                                                />
                                                {
                                                    errors && errors.auctionType && errors.auctionType.message &&
                                                    (<p className='text-red-400'>
                                                        {errors.auctionType.message}
                                                    </p>)
                                                }
                                            </FormItem>
                                        </div>
                                    ) : ''
                                }

                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">

                                    <FormItem label={priceLabel} isRequired={true} desc={priceDescription}>
                                        <InputPrice network={productDetails?.network} placeholder={"Price"} className={`pricetb_` + productDetails?.network} {...register('cryptoStartPrice')} />
                                        {
                                            errors && errors.cryptoStartPrice && errors.cryptoStartPrice.message &&
                                            (<p className='text-red-400'>
                                                {errors.cryptoStartPrice.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    {
                                        watch('priceType') === '2' ? (
                                            watchAuctionType && watchAuctionType['value'] === 1 ? (
                                                <FormItem label="Reserve Price" isRequired={true} desc="Create a hidden limit by setting a reserve price per unit">
                                                    <InputPrice network={productDetails?.network} placeholder={"Reserve Price"} className={`pricetb_` + productDetails?.network} {...register('cryptoReservePrice')} />
                                                    {
                                                        errors && errors.cryptoReservePrice && errors.cryptoReservePrice.message &&
                                                        (<p className='text-red-400'>
                                                            {errors.cryptoReservePrice.message}
                                                        </p>)
                                                    }
                                                </FormItem>
                                            ) : watchAuctionType && watchAuctionType['value'] === 2 ? (
                                                <FormItem label="End Price" isRequired={true} desc="Set ending price per unit for your auction">
                                                    <InputPrice network={productDetails?.network} placeholder={"End Price"} className={`pricetb_` + productDetails?.network} {...register('cryptoEndPrice')} />
                                                    {
                                                        errors && errors.cryptoEndPrice && errors.cryptoEndPrice.message &&
                                                        (<p className='text-red-400'>
                                                            {errors.cryptoEndPrice.message}
                                                        </p>)
                                                    }
                                                </FormItem>
                                            ) : ''
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div className={"accordion-item-wrapper"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div>
                                <div className="text-lg font-bold flex gap-2"><BiTime className="text-2xl mt-1" /> <span className="flex flex-col"><p>Set Duration</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Textual traits that show as text boxes</small></span></div>
                                {
                                    errors &&
                                    (
                                        (errors.useDateRange && errors.useDateRange.message) ||
                                        (errors.dateRangeType && errors.dateRangeType.message) ||
                                        (errors.dateFrom && errors.dateFrom.message) ||
                                        (errors.dateTo && errors.dateTo.message)
                                    ) && (
                                        <span className="text-red-400">(Invalid data provided)</span>
                                    ) || ''
                                }
                            </div>

                            <ButtonSecondary
                                type="button"
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                onClick={() => setDatePickerOpen(!isDatePickerOpen)}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isDatePickerOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>
                        <Transition
                            show={isDatePickerOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body mt-5"}>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-1 mb-4">
                                    <CheckBoxNative label="Use Date Range" {...register('useDateRange')} />
                                    {
                                        errors && errors.useDateRange && errors.useDateRange.message &&
                                        (<p className='text-red-400'>
                                            {errors.useDateRange.message}
                                        </p>)
                                    }
                                </div>
                                {
                                    watch('useDateRange') ? (
                                        <>
                                            <div className="grid grid-cols-3 sm:grid-cols-3 gap-5 sm:gap-2.5">
                                                <FormItem label="Date Range">
                                                    <Controller
                                                        name={"dateRangeType"}
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field: { value, onChange, onBlur } }) => (
                                                            <Select
                                                                className={`mt-1 rounded-2xl`}
                                                                options={dateRangeTypeList}
                                                                placeholder={"Select date range"}
                                                                onChange={(value: any) => onChangeDateRange(value)}
                                                                value={value}
                                                                defaultValue={value}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.dateRangeType && errors.dateRangeType.message &&
                                                        (<p className='text-red-400'>
                                                            {errors.dateRangeType.message}
                                                        </p>)
                                                    }
                                                </FormItem>
                                                <FormItem label="Starting">
                                                    <Controller
                                                        control={control}
                                                        name="dateFrom"
                                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                            <DateInput
                                                                name={name}
                                                                onChange={(value: string) => onChangeDate('dateFrom', value, onChange)}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                placeholder="Start Date"
                                                                showTimeSelect={true}
                                                                dateFormat="MM-dd-yyyy hh:mm a"
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.dateFrom && errors.dateFrom.message &&
                                                        (<p className='text-red-400'>
                                                            {errors.dateFrom.message}
                                                        </p>)
                                                    }
                                                </FormItem>
                                                <FormItem label="Ending">
                                                    <Controller
                                                        control={control}
                                                        name="dateTo"
                                                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                                            <DateInput
                                                                name={name}
                                                                onChange={(value: string) => onChangeDate('dateTo', value, onChange)}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                placeholder="End Date"
                                                                showTimeSelect={true}
                                                                dateFormat="MM-dd-yyyy hh:mm a"
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.dateTo && errors.dateTo.message &&
                                                        (<p className='text-red-400'>
                                                            {errors.dateTo.message}
                                                        </p>)
                                                    }
                                                </FormItem>
                                            </div>
                                        </>
                                    ) : ''
                                }
                            </div>
                        </Transition>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5" style={{ marginTop: 15, marginBottom: 18 }}>
                        <FormItem label="Payout Group">
                            <Controller
                                name={"payoutGroup"}
                                control={control}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Select
                                        className={`mt-1 rounded-2xl`}
                                        options={payoutGroupList}
                                        placeholder={"Select Payout Group"}
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={value}
                                    />
                                )}
                            />
                            {
                                errors && errors.payoutGroup && errors.payoutGroup.message &&
                                (<p className='text-red-400'>
                                    {errors.payoutGroup.message}
                                </p>)
                            }
                        </FormItem>
                        <div className="row mt-7">
                            <ButtonPrimary type="button" onClick={openPayoutGroupModal}> <AiOutlinePlus /> &nbsp; Add New</ButtonPrimary>
                        </div>
                    </div>

                    <div className={"accordion-item-wrapper"}>
                        <div className={"accordion-title flex items-center justify-between"}>
                            <div>
                                <div className="text-lg font-bold flex gap-2"><IoMdPricetags className="text-2xl mt-1" /> <span className="flex flex-col"><p>Charity</p> <small className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">Textual traits that show as text boxes</small></span></div>
                                {
                                    errors &&
                                    (
                                        (errors.charityName && errors.charityName.message) ||
                                        (errors.charityPercentage && errors.charityPercentage.message) ||
                                        (errors.charityLink && errors.charityLink.message)
                                    ) && (
                                        <span className="text-red-400">(Invalid data provided)</span>
                                    ) || ''
                                }
                            </div>

                            <ButtonSecondary
                                type="button"
                                className={"w-auto pl-2 pr-2 pt-2 pb-2 text-black"}
                                sizeClass={"py-2.5"}
                                onClick={() => setCharityOpen(!isCharityOpen)}
                            >
                                <span>
                                    <ChevronDownIcon className={`w-4 h-4 sm:w-5 sm:h-5 ${isCharityOpen ? "rotate-180" : ""}`} aria-hidden="true" />
                                </span>
                            </ButtonSecondary>
                        </div>

                        <Transition
                            show={isCharityOpen}
                            enter="transition-opacity duration-150"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className={"accordion-body mt-5"}>
                                <div className="grid grid-cols-3 sm:grid-cols-3 gap-5 sm:gap-2.5">
                                    <FormItem label="Name">
                                        <Input placeholder={"Charity name"} {...register('charityName')} />
                                        {
                                            errors && errors.charityName && errors.charityName.message &&
                                            (<p className='text-red-400'>
                                                {errors.charityName.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    <FormItem label="Percentage(%)">
                                        <Input type={'number'} placeholder={"Percentage"} {...register('charityPercentage')} />
                                        {
                                            errors && errors.charityPercentage && errors.charityPercentage.message &&
                                            (<p className='text-red-400'>
                                                {errors.charityPercentage.message}
                                            </p>)
                                        }
                                    </FormItem>
                                    <FormItem label="Link">
                                        <Input placeholder={"Charity link"} {...register('charityLink')} />
                                        {
                                            errors && errors.charityLink && errors.charityLink.message &&
                                            (<p className='text-red-400'>
                                                {errors.charityLink.message}
                                            </p>)
                                        }
                                    </FormItem>
                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                        <span>Fees - Servcie Fee {productDetails?.royaltyPlatformFee || 0}%</span>
                    </div>

                    <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                        <ButtonPrimary className="flex-1">Save</ButtonPrimary>
                        <ButtonSecondary type={"button"} className="flex-1" onClick={handleCancel}>Cancel</ButtonSecondary>
                    </div>

                </div>
            </form>

            <NcCustomModal contentExtraClass="max-w-3xl" modalTitle="Add Payout Group" modalStatus={showPayoutGroupModal} closeModal={closePayoutGroupModal}>
                <DetailsPayoutGroup
                    showPageTitle={false}
                    collectionId={productDetails && +productDetails['collectionID'] || null}
                    enableLoader={showLoader}
                    disableLoader={hideLoader}
                    handleCancel={closePayoutGroupModal}
                    readOnlyCollection={true}
                    payoutGroupData={{}}
                    classNames='bg-neutral-100 container lg:space-y-28 py-5 px-8 rounded-2xl space-y-20'
                />
            </NcCustomModal>
            <NcCustomModal contentExtraClass="max-w-3xl" modalTitle="Connect Wallet" modalStatus={isShowWalletModal} closeModal={closeWalletModal}>
				{modalContent}
			</NcCustomModal>
        </div>
    );
};

export default Price;