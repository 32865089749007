import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import NextPrev from "shared/NextPrev/NextPrev";
import NcImage from "shared/NcImage/NcImage";
import { AiFillEye, AiFillLike, AiOutlineLike } from "react-icons/ai";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LikeButton from "components/LikeButton";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import { nftsLargeImgs } from "contains/fakeData";
// import TimeCountDown from "./TimeCountDown";
import collectionPng from "images/nfts/collection.png";
import VerifyIcon from "components/VerifyIcon";
import ProductCard from "components/Products/ProductCard";
import Prices from "../../components/Prices";
import { useAuthStatus } from "_common/hooks/authHook";

export interface CardLarge1Props {
  className?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  isShowing?: boolean;
  item?: any;
}

const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  isShowing = true,
  onClickNext = () => {},
  onClickPrev = () => {},
  item = {},
}) => {

  const isAuth = useAuthStatus();
  const navigate = useNavigate()

  const getPrice = (product:any) => {
		if (product.productPrice.length) {
			let activePrice = product.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}
	}

  // console.log({item})

  return (
    <div className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse lg:flex-row justify-end ${className}`}>
      <div className="lg:absolute z-10 lg:left-0 lg:top-1/2 lg:transform lg:-translate-y-1/2 -mt-2 lg:mt-0 sm:px-5 lg:px-0 w-full lg:max-w-lg ">
        <div className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white dark:bg-neutral-900 shadow-lg rounded-3xl space-y-3 sm:space-y-8 ">
          {/* TITLE */}
          <h2 className="text-2xl lg:text-3xl 2xl:text-5xl font-semibold ">
            <Link to={`/product/` + item.productID} title="{item.productName}">
              {item.productName}
            </Link>
            {/* <Link to={"/nft-detailt"} title="Walking On Air">
              {randomTitle[Math.floor(Math.random() * randomTitle.length)]}
            </Link> */}
          </h2>

          {/* AUTHOR AND COLLECTION */}
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-12">
            <div className="flex items-center">
              {/*
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar sizeClass="w-10 h-10" />
              </div>
              */}
              <div className="ml-0">
                <div className="text-xs dark:text-neutral-400">Category</div>
                <div className="text-sm font-semibold flex items-center cursor-pointer" onClick={() => navigate(`/store?categoryID=${item?.categoryID}&collectionID=&blockchainNetworkID=&searchText=`)}>
                  <span>{item.categoryName}</span>
                  {/*<VerifyIcon />*/}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {/*<div className="flex-shrink-0 h-10 w-10">
                 <Avatar sizeClass="w-10 h-10" imgUrl={collectionPng} /> 
              </div>*/}
              <div className="">
                <div className="text-xs dark:text-neutral-400">Collection</div>
                <div className="text-sm font-semibold flex items-center cursor-pointer" onClick={() => navigate(`/collection/${item?.collectionID}`)}>
                  {item.collectionName}
                  {item.isVerify && <VerifyIcon /> }
                </div>
              </div>
            </div>
          </div>

          {/* PRICE */}
          <div className="pt-6">
            {/*
            <div className="flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative">
              <span className="block absolute bottom-full translate-y-1.5 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400 ring ring-offset-0 ring-white dark:ring-neutral-900">
                Price
              </span>
              <span className="text-3xl xl:text-4xl font-semibold text-green-500">
                1.000 ETH
              </span>              
              <span className="text-lg text-neutral-400 sm:ml-3.5">
                (≈ $3,221.22)
              </span>              
            </div>
            */}
            <Prices network={item?.network} size="lg" price={getPrice(item)} />
          </div>

          {/* AUTION TIME */}
          {/* <TimeCountDown /> */}

          <div className="w h-[1px] bg-neutral-100 dark:bg-neutral-700"></div>

          {/* DESCRIPTION */}
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
            {/*
            <ButtonPrimary href={`/product/` + item.productID} className="flex-1">
              Place a bid
            </ButtonPrimary>
            */}
            <ButtonPrimary href={`/product/` + item.productID} className="flex-1">
              View item
            </ButtonPrimary>
          </div>
        </div>
        <div className="p-4 sm:pt-8 sm:px-10 ">
          <NextPrev
            btnClassName="w-11 h-11 text-xl"
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        </div>
      </div>

      <div className="w-full lg:w-[64%] relative ">
        <div className="nc-CardLarge1__right ">
          <Link to={`/product/` + item.productID}>
            <NcImage
              containerClassName="aspect-w-1 aspect-h-1 relative"
              className="absolute inset-0 object-cover rounded-3xl sm:rounded-[40px] border-4 sm:border-[14px] border-white dark:border-neutral-800"
              src={item.productThumbUrl}
              alt={"title"}
            />
          </Link>

          {/* META TYPE */}
          {/* <ItemTypeVideoIcon className="absolute w-8 h-8 md:w-10 md:h-10 left-3 bottom-3 sm:left-7 sm:bottom-7 " /> */}

          {/* META FAVORITES */}
          <div className={`px-3.5 h-10 flex items-center justify-center px-0 rounded-full like-button absolute top-4 right-6`}>
            <AiFillEye color={'#9333ea'} size={30} />
            <span className="ml-2 text-sm like-count">{item?.viewCount}</span>
          </div>
          {/* <LikeButton className="absolute right-3 top-3 sm:right-7 sm:top-7 cstm-like-btn" id={item?.productID} likeCount={item?.totalLikeCount} type={2} isAuth={isAuth}/> */}
        </div>
      </div>
    </div>
  );
};

export default CardLarge1;
