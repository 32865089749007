import React, { FC, useEffect, useRef, useState } from "react";
import Label from "components/Label/Label";
import Select from 'react-select'
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonDanger from "shared/Button/ButtonDanger";
import Input from "shared/Input/Input";
// import Select from "shared/Select/Select";
import Switch from "shared/Switch/Switch";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import NcModal from "shared/NcModal/NcModal";
import toast from 'react-hot-toast';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import FormItem from "components/FormItem";
import MySwitch from "components/MySwitch";
import MyCompatibleSwitch from "components/MyCompatibleSwitch";

import { PayoutGroup_Form } from '../../_common/interfaces/FormProps'
import { PayoutGroupAddFormSchemaValidation } from '../../utils/form-validation'
import { useCollectionApi } from '../../_common/hooks/api/collectionApiHook'
import { usePayoutGroupApi } from '../../_common/hooks/api/payoutGroupApiHook'

export interface PayoutGroupAddUpdateProps {
  enableLoader: () => void;
  disableLoader: () => void;
  handleCancel: () => void;
  payoutGroupData?: any;
  readOnlyCollection?: boolean;
  collectionId?: number | null;
  classNames?: string;
  showPageTitle?: boolean;
}

const DetailsPayoutGroup: FC<PayoutGroupAddUpdateProps> = ({ collectionId = null, enableLoader, disableLoader, handleCancel, payoutGroupData = {}, classNames='', readOnlyCollection = false, showPageTitle = true }) => {
  
  const [ collectionList, setCollectionList ] = useState([])

  const { getCollections } = useCollectionApi()
  const { addPayoutGroup, updatePayoutGroup } = usePayoutGroupApi()
  
  const { register, control, getValues, setValue, watch, setError, clearErrors, handleSubmit, reset, formState: { errors } } = useForm<PayoutGroup_Form>({
      defaultValues: {
        collection: null,
        payoutGroupName: '',
        isDefault: false,
        lstItem: [{
          itemName: '',
          email: '',
          cryptoAddress: '',
          payoutPercent: 0.01
        }]
      },
      resolver: yupResolver(PayoutGroupAddFormSchemaValidation)
  });
  const { fields: listUserFields, append, remove } = useFieldArray({
      control,
      name: "lstItem"
  });

  useEffect(() => {
    fetchCollections();
  }, [])

  useEffect(() => {
    const updateFormParams: any = {}
    if ( collectionList.length ) {
      const selectedCollection = collectionList.filter((e: any) => e['value'] == collectionId)
      updateFormParams['collection'] = selectedCollection[0] || {}
    }
    if ( payoutGroupData && Object.keys(payoutGroupData).length ) {
        updateFormParams['payoutGroupName'] = payoutGroupData['payoutGroupName'] || '';
        updateFormParams['isDefault'] = payoutGroupData['isDefault'] || false;
        updateFormParams['lstItem'] = payoutGroupData['lstItem'] && payoutGroupData['lstItem'].map((e: any) => ({
          itemName: e['itemName'] || '',
          email: e['email'] || '',
          cryptoAddress: e['cryptoAddress'] || '',
          payoutPercent: e['payoutPercent'] || 0.01
        })) || [{
          itemName: '',
          email: '',
          cryptoAddress: '',
          payoutPercent: 0.01
        }]
    }
    reset(updateFormParams)
  }, [collectionList, payoutGroupData])

  const fetchCollections = () => {
    getCollections({}, 
      (message: string, resp: any) => {
          const data = resp && resp.collections && resp.collections.length && resp.collections.map((e: any) => ({
              label: e['collectionName'],
              value: e['collectionID']
          })) || []
          setCollectionList(data)
      },
      (message: string, resp: any) => {
          console.log('error resp: ', resp)
          toast.error(message)
      })
  }

  /*useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
          (element as HTMLTextAreaElement).setSelectionRange(
            (element as HTMLTextAreaElement).value.length,
            (element as HTMLTextAreaElement).value.length
          );
        }
      }, 400);
    }
  }, [show]);*/

  const payoutGroupSubmitted = (data: any) => {
    
    const totalPercentage = data.lstItem.reduce((a: any, c: any) => a += c['payoutPercent'], 0)
    if ( totalPercentage == 100 ) {
      enableLoader()
      const params = {
          payoutGroupID: payoutGroupData && payoutGroupData['payoutGroupID'] || 0,
          payoutGroupName: data['payoutGroupName'],
          collectionID: data['collection'] && +data['collection']['value'] || 0,
          isDefault: data.isDefault,
          lstItem: data.lstItem
      }
    
      if ( params && params['payoutGroupID'] ) {
        updatePayoutGroup(params,
          (message: string, resp: any) => {
              disableLoader()
              toast.success(message)
              handleCancel()
          },
          (message: string, resp: any) => {
              disableLoader()
              toast.error(message)
          })
      } else {
        addPayoutGroup(params,
          (message: string, resp: any) => {
              disableLoader()
              toast.success(message)
              handleCancel()
          },
          (message: string, resp: any) => {
              disableLoader()
              toast.error(message)
          })
      }
    } else {
      toast.error('Sum of total percent must be 100')
    }
  }

  const addNewPayoutGroup = () => {
    append({ itemName: "", email: '', cryptoAddress: '', payoutPercent: 0.01 })
  }

  /*const handleCancel = () => {
    reset({
        collection: null,
        payoutGroupName: '',
        isDefault: false,
        lstItem: [{
          itemName: '',
          email: '',
          cryptoAddress: '',
          payoutPercent: 0.01
        }]
    })
  }*/

  return (
    <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          {
            showPageTitle ? (
              <div className="max-w-2xl">
                <h2 className="text-3xl sm:text-4xl font-semibold">
                  { payoutGroupData && payoutGroupData['payoutGroupID'] ? 'Update Payout Group' : 'Create Payout Group' }
                </h2>
                {/* <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                  You can set preferred display name, create your profile URL and
                  manage other personal settings.
                </span> */}
              </div>
            ) : ''
          }
          
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form className="" onSubmit={handleSubmit(payoutGroupSubmitted)}>
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">

              {/* ---- */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-2.5">
                <FormItem label="Collection" isRequired={true}>
                    {/* <Controller
                        name={"collection"}
                        control={control}
                        render={({ field: { value, onChange, onBlur } }: any) => (
                          <Select disabled={readOnlyCollection}>
                          {
                            collectionList.map((collection: any, index: number) => (
                                <option key={index} value={collection} selected={value && value['value'] === collection.value}> 
                                  {collection.label} 
                                </option>
                            ))
                          }
                          </Select>
                        )}
                    />
                    { 
                        errors && errors.collection && errors.collection.message &&  
                        (<p className='text-red-400'>
                            {errors.collection.message}
                        </p>)
                    } */}
                    <Controller
                        name={"collection"}
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Select
                                className={`mt-1`}
                                classNamePrefix="select"
                                options={collectionList}
                                placeholder={"Select .."}
                                onChange={onChange}
                                value={value}
                                defaultValue={value}
                                isDisabled={readOnlyCollection}
                            />
                        )}
                    />
                    { 
                        errors && errors.collection && errors.collection.message &&  
                        (<p className='text-red-400'>
                            {errors.collection.message}
                        </p>)
                    }
                </FormItem>
                <FormItem label="Payout Group Name" isRequired={true}>
                    <Controller
                        name={"payoutGroupName"}
                        control={control}
                        render={({field}) => (
                          <Input {...field} placeholder={'Payout Group name'} />
                        )}
                    />
                    { 
                        errors && errors.payoutGroupName && errors.payoutGroupName.message &&  
                        (<p className='text-red-400'>
                            {errors.payoutGroupName.message}
                        </p>)
                    }
                </FormItem>
              </div>

              {/* <Controller
                  name={"isDefault"}
                  control={control}
                  render={({field: {name, value, ref, onChange}}) => (
                    <MyCompatibleSwitch 
                    label="Make it default" 
                    desc="It will make this payout group as a default for the selected collection"
                    ref={ref} 
                    name={name} 
                    enabled={value} 
                    onChange={onChange} 
                    />
                  )}
              /> */}

              <Switch label="Make it default"  {...register("isDefault")}/>

              <div className="flex justify-end mt-6">
                  <ButtonPrimary type="button" onClick={addNewPayoutGroup}> <AiOutlinePlus /> &nbsp; Add New</ButtonPrimary>
              </div>
              <div className="row">
                
                <table className="create-new-table">
                  <thead>
                    <tr>
                      <th className="text-left">Name</th>
                      <th className="text-left">Email</th>
                      <th className="text-left">Address(<span className="text-red-600">*</span>)</th> 
                      <th className="text-left">Percent(<span className="text-red-600">*</span>)</th>
                      <th className="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                      listUserFields.map((item: any, index: number) => (
                        <tr key={index}>
                            <td>
                              <label className="block">
                                    <Controller
                                        name={`lstItem.${index}.itemName`}
                                        control={control}
                                        render={({ field }) => <Input 
                                            {...field} 
                                            placeholder={'Name'}
                                            autoComplete={'off'}
                                        />}
                                    />
                                    {
                                      errors && errors.lstItem && errors.lstItem[index] && errors.lstItem[index]?.itemName && errors.lstItem[index]?.itemName?.message ?  
                                      <p className='text-red-400'>{errors.lstItem[index]?.itemName?.message}</p> : ''
                                    }
                                </label>
                            </td>
                            <td>
                              <label className="block">
                                    <Controller
                                        name={`lstItem.${index}.email`}
                                        control={control}
                                        render={({ field }) => <Input 
                                            {...field} 
                                            placeholder={'Email'}
                                            autoComplete={'off'}
                                        />}
                                    />
                                    {
                                      errors && errors.lstItem && errors.lstItem[index] && errors.lstItem[index]?.email && errors.lstItem[index]?.email?.message ?  
                                      <p className='text-red-400'>{errors.lstItem[index]?.email?.message}</p> : ''
                                    }
                                </label>
                            </td>
                            <td>
                              <label className="block">
                                    <Controller
                                        name={`lstItem.${index}.cryptoAddress`}
                                        control={control}
                                        render={({ field }) => <Input 
                                            {...field} 
                                            placeholder={'Crypto Address'}
                                            autoComplete={'off'}
                                        />}
                                    />
                                    {
                                      errors && errors.lstItem && errors.lstItem[index] && errors.lstItem[index]?.cryptoAddress && errors.lstItem[index]?.cryptoAddress?.message ?  
                                      <p className='text-red-400'>{errors.lstItem[index]?.cryptoAddress?.message}</p> : ''
                                    }
                                </label>
                            </td>
                            <td>
                              <label className="block">
                                    <Controller
                                        name={`lstItem.${index}.payoutPercent`}
                                        control={control}
                                        render={({ field }) => <Input 
                                            {...field} 
                                            placeholder={'Percentage'}
                                            autoComplete={'off'}
                                        />}
                                    />
                                    {
                                      errors && errors.lstItem && errors.lstItem[index] && errors.lstItem[index]?.payoutPercent && errors.lstItem[index]?.payoutPercent?.message ?  
                                      <p className='text-red-400'>{errors.lstItem[index]?.payoutPercent?.message}</p> : ''
                                    }
                                </label>
                            </td>
                            <td>
                            {
                                listUserFields.length > 1 ? 
                                <div className="col-sm-2">
                                    <ButtonDanger type="button" className="ml-3" onClick={() => remove(index)}> <BsFillTrashFill /></ButtonDanger>
                                </div>: ''
                            }
                            </td>
                        </tr>
                      ))
                  }
                  </tbody>
                </table>
              </div>
              

              {/* ---- */}
              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1" type="submit">Save</ButtonPrimary>
                <ButtonSecondary className="flex-1" type="button" onClick={handleCancel}>Cancel</ButtonSecondary>
              </div>
            </div>
          </form>
        </div>
  );
};

export default DetailsPayoutGroup;
