import React, { useEffect, Fragment, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NftMoreDropdown from "components/NftMoreDropdown";
import SocialsList from "shared/SocialsList/SocialsList";
import FollowButton from "components/FollowButton";
import LikeButton from "components/LikeButton";
import { Tab } from "@headlessui/react";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import ProductNFT from "../../components/ProductNFT";
import CollectionCard from "../../components/CollectionCard";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";

import { useUserApi } from "_common/hooks/api/UserApiHook";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";
import { useUserAuthSelector } from "_common/hooks/selectors/userSelector";
import VerifyIcon from "components/VerifyIcon";
import { TfiWallet } from 'react-icons/tfi'
import ArchiveFilterListBox from "components/ArchiveFilterListBox";
import NoDataFound from "components/NoDataFound";
import FavouriteButton from "components/FavouriteButton";
import { Link } from "react-router-dom";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import NftCard from '../../components/Nft/NftCard'

const lists = [
	{ name: "Most Recent" }
];

export interface AuthorPageProps {
	className?: string;
}

const PageAuthorDetailNormalize = () => {

	const { id: authorId } = useParams();
	const { getUserProfile, getAdminUserProfile } = useUserApi();
	const { getCollections } = useCollectionApi();
	const { getProducts, getMyProducts } = useProductApi();
	const isAuth = useUserAuthSelector()
	const productsContainer: any = useRef();
	const nftsContainer: any = useRef();
	const collectionsContainer: any = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const isCustomer = searchParams.get("isCus");

	const [authorDetails, setAuthorDetails] = useState<any>({})
	const [collectionList, setCollectionList] = useState<any[]>([])
	const [productList, setProductList] = useState<any[]>([])
	const [nftList, setNftList] = useState<any[]>([])
	const { showLoader, hideLoader } = useAppLoader();

	const limit: number = 12;
	const [currentCollectionPage, setCurrentCollectionPage] = useState<number>(1);
	const [totalCollectionPage, setTotalCollectionPage] = useState<number>(1);
	const [currentProductPage, setCurrentProductPage] = useState<number>(1);
	const [totalProductPage, setTotalProductPage] = useState<number>(1);
	const [currentNftPage, setCurrentNftPage] = useState<number>(1);
	const [totalNftPage, setTotalNftPage] = useState<number>(1);
	const [showProductLoader, setShowProductLoader] = useState<boolean>(false)
	const [showNftLoader, setShowNftLoader] = useState<boolean>(false)
	const [followerCount, setFollowerCount] = useState<number>(0);
	const [favouriteCount, setFavouriteCount] = useState<number>(0);
	const [likeCount, setLikeCount] = useState<number>(0);

	useEffect(() => {
		if (authorId) {
			fetAuthorDetails(+authorId)
			fetchCollections();
			fetchProducts();
			fetchNfts();
		}
	}, [authorId])

	const fetAuthorDetails = (id?: number) => {
		showLoader();
		if (isCustomer == 'true') {
			getUserProfile({ customerID: id || authorId }, (message: string, resp: any) => {
				hideLoader();
				if (resp && resp.successful === true) {
					const customer = resp['customers'] && resp['customers'].length ? resp['customers'][0] : null;
					setAuthorDetails(customer)
					setFavouriteCount(resp?.totalFavouriteCount)
					setLikeCount(resp?.totalLikeCount)
					setFollowerCount(resp?.totalFollowCount)
				} else {
					toast.error(message);
				}
			}, (message: string) => {
				hideLoader();
				toast.error(message);
			})
		} else {
			getAdminUserProfile({ userID: id || authorId }, (message: string, resp: any) => {
				hideLoader();
				if (resp && resp.successful === true) {
					const customer = resp['userDetails'] && resp['userDetails'].length ? resp['userDetails'][0] : null;
					setAuthorDetails(customer)
					setFavouriteCount(resp?.totalFavouriteCount)
					setLikeCount(resp?.totalLikeCount)
					setFollowerCount(resp?.totalFollowCount)
				} else {
					toast.error(message);
				}
			}, (message: string) => {
				hideLoader();
				toast.error(message);
			})
		}
	}

	const fetchCollections = () => {
		let params: any = {
			usePaging: true,
			addedBy: authorId,
			isCreatedByCustomer: isCustomer == 'true' ? 1 : 0,
			pageSize: limit,
			pageNumber: currentCollectionPage
		}

		showLoader();
		//document.removeEventListener('scroll', trackScrolling, true);
		getCollections(params, (message: string, resp: any) => {
			hideLoader();
			if (collectionList.length) {
				setCollectionList((prevState: any) => [...prevState, ...resp.collections])
			} else {
				setCollectionList(resp.collections);
			}
			setTotalCollectionPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const fetchProducts = () => {
		let params: any = {
			usePaging: true,
			addedBy: authorId,
			isCreatedByCustomer: isCustomer == 'true' ? 1 : 0,
			pageSize: limit,
			pageNumber: currentProductPage
		}

		setShowProductLoader(true)
		//document.removeEventListener('scroll', trackScrolling, true);
		getProducts(params, (message: string, resp: any) => {
			setShowProductLoader(false)
			if (productList.length) {
				setProductList((prevState: any) => [...prevState, ...resp.products])
			} else {
				setProductList(resp.products);
			}
			setTotalProductPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			setShowProductLoader(false)
			console.log(message);
		})
	}

	const fetchNfts = () => {
		let params: any = {
			usePaging: true,
            createdBy: authorId,
            isCreatedByCustomer: 1,
			pageSize: limit,
			pageNumber: currentNftPage
		}

		setShowNftLoader(true);
		getMyProducts(params, (message: string, resp: any) => {
			setShowNftLoader(false)
			if (nftList.length) {
				setNftList((prevState: any) => [...prevState, ...resp.nfTs])
			} else {
				setNftList(resp.nfTs);
			}
			setTotalNftPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			setShowNftLoader(false)
		})
	}

	useEffect(() => {
		if (currentNftPage > 1) {
			fetchNfts()
		}
	}, [currentNftPage]);

	useEffect(() => {
		if (currentProductPage > 1) {
			fetchProducts()
		}
	}, [currentProductPage]);

	useEffect(() => {
		if (currentCollectionPage > 1) {
			fetchCollections()
		}
	}, [currentCollectionPage]);

	const isBottom = (el: any) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}



	const addTrackScrollEvent = () => {
		function trackScrolling() {
			if (productsContainer.current) {
				if (isBottom(productsContainer.current)) {
					document.removeEventListener('scroll', trackScrolling, true);
					console.log('Trakc Scroll : NFT Bottom Reached.', currentProductPage, totalProductPage);
					if (currentProductPage < totalProductPage) {
						setCurrentProductPage(currentProductPage + 1);
					}
				}
			} else if (nftsContainer.current) {
				if (isBottom(nftsContainer.current)) {
					document.removeEventListener('scroll', trackScrolling, true);
					if (currentNftPage < totalNftPage) {
						setCurrentNftPage(currentNftPage + 1);
					}
				}
			} else if (collectionsContainer.current) {
				if (isBottom(collectionsContainer.current)) {
					document.removeEventListener('scroll', trackScrolling, true);
					console.log('Trakc Scroll : Collection Bottom Reached.', currentCollectionPage, totalCollectionPage);
					if (currentCollectionPage < totalCollectionPage) {
						setCurrentCollectionPage(currentCollectionPage + 1);
					}
				}
			} else {
				document.removeEventListener('scroll', trackScrolling, true);
			}
		};

		document.addEventListener('scroll', trackScrolling, true);

	}

	useEffect(() => {
		if (collectionList) {
			addTrackScrollEvent();
		} else {
			fetchCollections();
		}
	}, [collectionList])

	useEffect(() => {
		if (productList) {
			addTrackScrollEvent();
		} else {
			fetchProducts();
		}
	}, [productList])

	useEffect(() => {
		if (nftList) {
			addTrackScrollEvent();
		} else {
			fetchNfts();
		}
	}, [nftList])

	const productShowMore = () => {
		if (currentProductPage < totalProductPage) {
			setCurrentProductPage(currentProductPage + 1);
		}
	}

	const collectionShowMore = () => {
		if (currentCollectionPage < totalCollectionPage) {
			setCurrentCollectionPage(currentCollectionPage + 1);
		}
	}

	const copyText = (data: string) => {
		navigator.clipboard.writeText(data)
		toast.success('Copied')
	}

	return (
		<React.Fragment>
			<div className={`nc-AuthorPage`} data-nc-id="AuthorPage">
				<Helmet>
					<title>Author</title>
				</Helmet>

				{/* HEADER */}
				<div className="w-full">
					<div className="relative w-full h-40 md:h-60 2xl:h-72">
						<NcImage containerClassName="absolute inset-0" src={authorDetails && authorDetails?.bannerImageUrl ? authorDetails?.bannerImageUrl : authorDetails?.profileImageUrl ? authorDetails?.profileImageUrl : authorDetails?.profilePicturePath} className="object-cover w-full h-full" />
					</div>
					<div className="container -mt-10 lg:-mt-16">
						<div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row items-start cstm-sm-flex-col">
							<div className="w-32 lg:w-44 flex-shrink-0 sm:mt-0">
								<NcImage src={authorDetails && authorDetails?.profileImageUrl ? authorDetails?.profileImageUrl : authorDetails?.profilePicturePath} containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden" />
							</div>
							<div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
								<div className="max-w-screen-sm-1 ">
									<div className="relative flex flex-col md:flex-row items-start cstm-sm-flex-col justify-between">
										<h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold mb-3">
											<span>{authorDetails && authorDetails.firstName && authorDetails?.lastName ? `${authorDetails.firstName} ${authorDetails.lastName}` : 'NA'}</span>
											{/* <VerifyIcon
												className="ml-2"
												iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
											/> */}
										</h2>
										<div className="left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-wrap flex-row-reverse justify-end gap-2">

								<NftMoreDropdown
									type_id={9}
									actions={[
										{
											id: "report",
											name: "Report abuse",
											icon: "las la-flag",
										},
									]}
									containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
								/>

								<NcShareDropDown
									className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
									panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
								/>

								<FollowButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8 py-4"
									id={authorId}
									type={9}
									isAuth={isAuth}
									followerCount={followerCount}
									callBackFn={fetAuthorDetails}
								/>

								<LikeButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
									id={authorId}
									type={9}
									isAuth={isAuth}
									likeCount={likeCount}
									callBackFn={fetAuthorDetails}
								/>

								<FavouriteButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
									id={authorId}
									type={9}
									isAuth={isAuth}
									favouriteCount={favouriteCount}
									callBackFn={fetAuthorDetails}
								/>

							</div>
									</div>
									
									{
										authorDetails && authorDetails?.cryptoAddress && (
											<div className="flex items-center text-sm font-medium space-x-2.5 mt-2.5 text-green-600 cursor-pointer">
												<TfiWallet />
												<span className="text-neutral-700 dark:text-neutral-300">
													{authorDetails && authorDetails?.cryptoAddress}
												</span>
												<div onClick={() => copyText(authorDetails.cryptoAddress)}>
													<svg width="20" height="21" viewBox="0 0 20 21" fill="none">
														<path
															d="M18.05 9.19992L17.2333 12.6833C16.5333 15.6916 15.15 16.9083 12.55 16.6583C12.1333 16.6249 11.6833 16.5499 11.2 16.4333L9.79999 16.0999C6.32499 15.2749 5.24999 13.5583 6.06665 10.0749L6.88332 6.58326C7.04999 5.87492 7.24999 5.25826 7.49999 4.74992C8.47499 2.73326 10.1333 2.19159 12.9167 2.84993L14.3083 3.17493C17.8 3.99159 18.8667 5.71659 18.05 9.19992Z"
															stroke="currentColor"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M12.5498 16.6583C12.0331 17.0083 11.3831 17.3 10.5915 17.5583L9.2748 17.9917C5.96646 19.0583 4.2248 18.1667 3.1498 14.8583L2.08313 11.5667C1.01646 8.25833 1.8998 6.50833 5.20813 5.44167L6.5248 5.00833C6.86646 4.9 7.19146 4.80833 7.4998 4.75C7.2498 5.25833 7.0498 5.875 6.88313 6.58333L6.06646 10.075C5.2498 13.5583 6.3248 15.275 9.7998 16.1L11.1998 16.4333C11.6831 16.55 12.1331 16.625 12.5498 16.6583Z"
															stroke="currentColor"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</div>
											</div>
										) || ''
									}

									<span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400">
										{authorDetails && authorDetails?.description}
									</span>
								</div>
								<div className="mt-5">
									{authorDetails && authorDetails?.customerSocialSettings ?
										<SocialsList1 itemClass="block w-7 h-7" socialsList={authorDetails && authorDetails?.customerSocialSettings} isAuthorType={true} />
										: authorDetails?.socialSettings ?
											<>
												<SocialsList itemClass="block w-7 h-7" socialsList={authorDetails && authorDetails?.socialSettings} isAuthorType={true} />
											</>
											: null
									}
								</div>
							</div>
							
							{/*
							<div className="absolute bottom-3 mb-1 right-4">
								<a href={`https://v2devgallery.nftwards.com/New_ArtGallery.html?c=${authorId}`} target="_blank"><ButtonPrimary>Explore Gallery</ButtonPrimary></a>
							</div>
							*/}
						</div>
					</div>
				</div>

				<div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
					<main>
						<Tab.Group onChange={addTrackScrollEvent}>
							<div className="flex flex-col lg:flex-row justify-between ">
								<Tab.List className="flex space-x-0 sm:space-x-2 overflow-x-auto ">
									<Tab as={Fragment}>
										{({ selected }) => (
											<button
												className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${selected
													? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
													: "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
													} `}
											>
												Collection
											</button>
										)}
									</Tab>
									<Tab as={Fragment}>
										{({ selected }) => (
											<button
												className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${selected
													? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
													: "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
													} `}
											>
												Product
											</button>
										)}
									</Tab>
									<Tab as={Fragment}>
										{({ selected }) => (
											<button
												className={`flex-shrink-0 block font-medium px-4 py-2 text-sm sm:px-6 sm:py-2.5 capitalize rounded-full focus:outline-none ${selected
													? "bg-neutral-900 dark:bg-neutral-100 text-neutral-50 dark:text-neutral-900"
													: "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100/70 dark:hover:bg-neutral-800"
													} `}
											>
												NFT
											</button>
										)}
									</Tab>
								</Tab.List>
								<div className="mt-5 lg:mt-0 flex items-end justify-end">
									<ArchiveFilterListBox options={lists} />
								</div>
							</div>
							<Tab.Panels>
								<Tab.Panel className="">
									{/* LOOP ITEMS */}
									<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10" ref={collectionsContainer}>
										{collectionList?.map((collection: any, i: any) => (
											<CollectionCard data={collection} key={i} />
										))}
									</div>
									{collectionList?.length == 0 && (<NoDataFound />)}

									{/* PAGINATION */}
									<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
										{/* <Pagination /> */}
										{currentCollectionPage < totalCollectionPage && collectionList?.length != 0 && (
											<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
												<ButtonPrimary loading>Show me more</ButtonPrimary>
											</div>
										)}
									</div>
								</Tab.Panel>
								<Tab.Panel className="">
									{/* LOOP ITEMS */}
									<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10" ref={productsContainer}>
										{productList?.map((product: any, i: any) => (
											<ProductNFT data={product} key={i} />
										))}
									</div>
									{productList?.length == 0 && (<NoDataFound />)}

									{/* PAGINATION */}
									<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
										{currentProductPage < totalProductPage && productList?.length != 0 && (
											<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
												<ButtonPrimary loading={showProductLoader}>Show me more</ButtonPrimary>
											</div>
										)}
									</div>
								</Tab.Panel>
								<Tab.Panel className="">
									{/* LOOP ITEMS */}
									<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10" ref={nftsContainer}>
										{nftList?.map((nft: any, i: any) => (
											<NftCard nft={nft} key={i} />
										))}
									</div>
									{nftList?.length == 0 && (<NoDataFound />)}

									{/* PAGINATION */}
									<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
										{currentNftPage < totalNftPage && nftList?.length != 0 && (
											<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
												<ButtonPrimary loading={showNftLoader}>Show me more</ButtonPrimary>
											</div>
										)}
									</div>
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</main>


					{/* SUBCRIBES */}
					{/* <SectionBecomeAnAuthor /> */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default PageAuthorDetailNormalize;
