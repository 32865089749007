import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import * as rdd from 'react-device-detect';
import { toast } from "react-hot-toast";

import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import QrCodeImg from "images/qr-code.png";
import metamaskImg from "images/metamask.webp";
import walletconnectImg from "images/walletconnect.webp";
import walletlinkImg from "images/walletlink.webp";
import fortmaticImg from "images/fortmatic.webp";

import useMetaMask from "../../../../_common/provider/metamask-provider";
import { METAMASK_KEYS, CHAIN_ADD_CONFIG } from '../../../../_common/_constant'
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook'
import { useUserCryptoDetails } from '../../../../_common/hooks/selectors/userSelector'

export interface SwitchNetworkProps {
  className?: string;
  changeChainId: number;
  callback?: Function;
  cancel: Function;
  item?: any;
  onSuccess?: Function
}

const SwitchNetwork: FC<SwitchNetworkProps> = ({ className = "", changeChainId, callback, cancel, item = null, onSuccess = () => {} }) => {
  const { connect, disconnect, isLoading, isActive, chainId, account, addNetwork, switchNetwork, isConnected, authenticateWallet } = useMetaMask()
  const [showModal, setShowModal] = useState(false);
  const { connectWallet } = useUserApi()
  const navigate = useNavigate();
  const cryptoDetails = useUserCryptoDetails()
  const changeChainName = CHAIN_ADD_CONFIG[changeChainId as keyof typeof CHAIN_ADD_CONFIG]['chainName']

  useEffect(() => {
    if ( changeChainId && changeChainId === chainId ) {
      console.log('changeChainId: ', changeChainId, ' chianId: ', chainId)
      handleSwitchNetwork();
    }
  }, [chainId])

  const handleSwitchNetwork = () => {
    if ( callback ) {
      callback(item, account, chainId, onSuccess)
    } else {
      cancel();
    }
  }


  return (
    <div
      className={`nc-PageConnectWallet ${className}`}
      data-nc-id="PageConnectWallet"
    >
      <Helmet>
        <title>Switch Network</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Switch Network.
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              To continue, you need to switch network to {changeChainName}.
            </span>
          </div>
          <div>
            <ButtonPrimary type="button" onClick={() => switchNetwork(changeChainId)}>Continue</ButtonPrimary>
            <ButtonSecondary type="button" onClick={() => cancel()}>Cancel</ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchNetwork;
