import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import * as rdd from 'react-device-detect';
import { toast } from "react-hot-toast";

import { ACTIONS } from '../../../../_config';
import NcImage from "shared/NcImage/NcImage";
import QrCodeImg from "images/qr-code.png";
import metamaskImg from "images/metamask.webp";
import walletconnectImg from "images/walletconnect.webp";
import walletlinkImg from "images/walletlink.webp";
import fortmaticImg from "images/fortmatic.webp";

import useMetaMask from "../../../../_common/provider/metamask-provider";
import { METAMASK_KEYS } from '../../../../_common/_constant'
import { useUserApi } from '../../../../_common/hooks/api/UserApiHook'
import { useUserSelector, useUserCryptoDetails, useUserAuthSelector } from '../../../../_common/hooks/selectors/userSelector'
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

import NetworkBalance from './NetworkBalance'

export interface ConnectWalletProps {
  className?: string;
  showCustodialBalance?: boolean;
  showMetamaskBalance?: boolean;
  callback?: Function;
  cancel?: Function;
  item?: any;
  onSuccess?: Function;
}

const ConnectWallet: FC<ConnectWalletProps> = ({ className = "", callback, cancel, item = null, onSuccess = () => {}, showCustodialBalance = true, showMetamaskBalance = true }) => {
  const { connect, disconnect, isLoading, isActive, chainId, account, addNetwork, switchNetwork, isConnected, authenticateWallet, getBalance } = useMetaMask()
  const { connectWallet, login } = useUserApi()
  const navigate = useNavigate();
  const cryptoDetails = useUserCryptoDetails()
  const userDetails: any = useUserSelector()
  const dispatch = useDispatch()
  const isAuthenticated = useUserAuthSelector();
  const { showLoader, hideLoader } = useAppLoader();

  const onHandleLogin = (cryptoAddress: string) => {
    const inputparams = {
      cryptoAddress,
      isRegistrationUsingAddress: true
    }
    showLoader();
    login(inputparams, (message: any, resp: any) => {
      hideLoader();
      if (resp.code === 200 && resp.successful === true && resp.authenticateDataModel.length > 0) {
        toast.success(resp.message);
        navigate('/')
      } else {
        toast.error("Failed to authenticate.");
      }
    }, (message: string) => {
      hideLoader();
      toast.error(message);
      //console.log(message);
    });
  };

  useEffect(() => {
    if ( isActive ) {
      if ( isAuthenticated ) {
        handleConnectWallet();
      } else {
        onHandleLogin(account)
      }
    }
  }, [isActive])

  useEffect(() => {
    if ( !account ) {
      dispatch({
          type: ACTIONS.USER.CONNECT_WALLET,
          payload: []
      })
    }
  }, [account])

  const handleConnectWallet = () => {
    const browserObject = {
      browserName: rdd['browserName'],
      browserVersion: rdd['browserVersion'],
      deviceType: rdd['deviceType'],
      engineName: rdd['engineName'],
      engineVersion: rdd['engineVersion'],
      fullBrowserVersion: rdd['fullBrowserVersion'],
      getUA: rdd['getUA'],
      isAndroid: rdd['isAndroid'],
      isBrowser: rdd['isBrowser'],
      isChrome: rdd['isChrome'],
      isChromium: rdd['isChromium'],
      isConsole: rdd['isConsole'],
      isDesktop: rdd['isDesktop'],
      isEdge: rdd['isEdge'],
      isEdgeChromium: rdd['isEdgeChromium'],
      isElectron: rdd['isElectron'],
      isEmbedded: rdd['isEmbedded'],
      isFirefox: rdd['isFirefox'],
      isIE: rdd['isIE'],
      isIOS: rdd['isIOS'],
      isIOS13: rdd['isIOS13'],
      isIPad13: rdd['isIPad13'],
      isIPhone13: rdd['isIPhone13'],
      isIPod13: rdd['isIPod13'],
      isLegacyEdge: rdd['isLegacyEdge'],
      isMIUI: rdd['isMIUI'],
      isMacOs: rdd['isMacOs'],
      isMobile: rdd['isMobile'],
      isMobileOnly: rdd['isMobileOnly'],
      isMobileSafari: rdd['isMobileSafari'],
      isOpera: rdd['isOpera'],
      isSafari: rdd['isSafari'],
      isSamsungBrowser: rdd['isSamsungBrowser'],
      isSmartTV: rdd['isSmartTV'],
      isTablet: rdd['isTablet'],
      isWearable: rdd['isWearable'],
      isWinPhone: rdd['isWinPhone'],
      isWindows: rdd['isWindows'],
      isYandex: rdd['isYandex'],
      mobileModel: rdd['mobileModel'],
      mobileVendor: rdd['mobileVendor'],
      osName: rdd['osName'],
      osVersion: rdd['osVersion']
    }
    const params = {
      wallets: [{
          walletAddress: account,
          browserObject: JSON.stringify(browserObject),
          chainID: chainId
      }]
    }

    connectWallet(params, (message: string, resp: any) => {
        toast.success(message)
        if ( callback ) {
          callback(item, account, chainId, onSuccess)
        } else {
          if ( cancel ) {
            cancel();
          }
        }
    },
    (message: string, resp: any) => {
        console.log('error resp: ', resp)
        toast.error(message)
    })
  }

  return (
    <div className={`nc-ConnectWallet ${className}`} data-nc-id="ConnectWallet">
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Connect your wallet.
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              Connect with one of our available wallet providers or create a new
              one.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          {
            userDetails && userDetails['isCustodialWalletEnabled'] && (userDetails['isCustodialWalletEnabled'] === 'true' || userDetails['isCustodialWalletEnabled'] === true) && userDetails['custodialWalletAddress'] ? (
              <div>
                <div>
                  <p className="word-break"><strong>Connected Custodial Account:</strong> {userDetails['custodialWalletAddress']}</p>
                </div>
                <div>
                  {
                    showCustodialBalance && (<NetworkBalance address={userDetails['custodialWalletAddress']}></NetworkBalance>) || ''
                  }
                </div>
              </div>
            ) : ''
          }
          {
            cryptoDetails.length ? (
              <div>
                <div>
                  <p><strong>Connected Wallet Account:</strong> {cryptoDetails[0]?.walletAddress}</p>
                  {/* <p>Chain ID: {cryptoDetails[0]?.chainID}</p> */}
                </div>
                <div>
                  {
                    showMetamaskBalance && (<NetworkBalance address={cryptoDetails[0]?.walletAddress}></NetworkBalance>) ||''
                  }
                  
                </div>
                <div className="flex justify-center mt-10">
                  <ButtonPrimary type="button" onClick={disconnect}>Disconnect</ButtonPrimary>
                </div>
              </div>
            ) : (
              <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                <div className="space-y-3">
                    <div
                      key={'Metamask'}
                      onClick={() => connect(METAMASK_KEYS.CONNECT_METAMASK)}
                      typeof="button"
                      tabIndex={0}
                      className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border 
                    border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex 
                    focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 
                    dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200 cursor-pointer"
                    >
                        <div className="flex items-center w-full">
                          <NcImage
                            src={metamaskImg}
                            containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                          />
                          <div
                            className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                          >
                            Metamask
                          </div>
                        </div>
                    </div>
                    <div
                      key={'Walletconnect'}
                      onClick={() => connect(METAMASK_KEYS.WALLET_CONNECT)}
                      typeof="button"
                      tabIndex={0}
                      className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border 
                    border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex 
                    focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 
                    dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200 cursor-pointer"
                    >
                        <div className="flex items-center w-full">
                          <NcImage
                            src={walletconnectImg}
                            containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                          />
                          <div
                            className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                          >
                            Walletconnect
                          </div>
                        </div>
                    </div>

                    <div
                      key={'Walletlink'}
                      // onClick={() => setShowModal(true)}
                      onClick={() => connect(METAMASK_KEYS.COINBASE_WALLET)}
                      typeof="button"
                      tabIndex={0}
                      className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border 
                    border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex 
                    focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 
                    dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200 cursor-pointer"
                    >
                        <div className="flex items-center w-full">
                          <NcImage
                            src={walletlinkImg}
                            containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                          />
                          <div
                            className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                          >
                            Coinbase
                          </div>
                        </div>
                    </div>
                </div>
              </div>
            )
          }
          
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
