import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import * as rdd from 'react-device-detect';
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NftMoreDropdown from "components/NftMoreDropdown";
import SocialsList from "shared/SocialsList/SocialsList";
import FollowButton from "components/FollowButton";
import LikeButton from "components/LikeButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ProductNFT from "../components/ProductNFT";
import ViewCount from "../components/ViewCount";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";
import { useUserAuthSelector } from "_common/hooks/selectors/userSelector";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NoDataFound from "components/NoDataFound";
import toast from "react-hot-toast";
import FavouriteButton from "components/FavouriteButton";
import { useSiteSettingsSelector } from "_common/hooks/selectors/commonSelector";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { AiFillWindows, AiOutlineWarning } from "react-icons/ai";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { MdOutlineContentCopy } from "react-icons/md";
import Nav from "shared/Nav/Nav";
import NavItem2 from "components/NavItem2";
import { BsApple } from "react-icons/bs";
import { DiAndroid } from "react-icons/di";

import gplaybtn from 'images/g-play-btn.png';
import applebtn from 'images/apple-btn.png';
import qrcode from 'images/qr-code.png';

const browserObject = {
    browserName: rdd['browserName'],
    browserVersion: rdd['browserVersion'],
    deviceType: rdd['deviceType'],
    engineName: rdd['engineName'],
    engineVersion: rdd['engineVersion'],
    fullBrowserVersion: rdd['fullBrowserVersion'],
    getUA: rdd['getUA'],
    isAndroid: rdd['isAndroid'],
    isBrowser: rdd['isBrowser'],
    isChrome: rdd['isChrome'],
    isChromium: rdd['isChromium'],
    isConsole: rdd['isConsole'],
    isDesktop: rdd['isDesktop'],
    isEdge: rdd['isEdge'],
    isEdgeChromium: rdd['isEdgeChromium'],
    isElectron: rdd['isElectron'],
    isEmbedded: rdd['isEmbedded'],
    isFirefox: rdd['isFirefox'],
    isIE: rdd['isIE'],
    isIOS: rdd['isIOS'],
    isIOS13: rdd['isIOS13'],
    isIPad13: rdd['isIPad13'],
    isIPhone13: rdd['isIPhone13'],
    isIPod13: rdd['isIPod13'],
    isLegacyEdge: rdd['isLegacyEdge'],
    isMIUI: rdd['isMIUI'],
    isMacOs: rdd['isMacOs'],
    isMobile: rdd['isMobile'],
    isMobileOnly: rdd['isMobileOnly'],
    isMobileSafari: rdd['isMobileSafari'],
    isOpera: rdd['isOpera'],
    isSafari: rdd['isSafari'],
    isSamsungBrowser: rdd['isSamsungBrowser'],
    isSmartTV: rdd['isSmartTV'],
    isTablet: rdd['isTablet'],
    isWearable: rdd['isWearable'],
    isWinPhone: rdd['isWinPhone'],
    isWindows: rdd['isWindows'],
    isYandex: rdd['isYandex'],
    mobileModel: rdd['mobileModel'],
    mobileVendor: rdd['mobileVendor'],
    osName: rdd['osName'],
    osVersion: rdd['osVersion']
}

export interface AuthorPageProps {
    className?: string;
}

const PageCollectionGallery = () => {
    const { id } = useParams();
    const collectionApi = useCollectionApi();
    const { showLoader, hideLoader } = useAppLoader();
    const isAuth = useUserAuthSelector()
    const settings: any = useSiteSettingsSelector();
    const commonApi = useCommonApi();
    const userApi = useUserApi();
    const navigate = useNavigate();
    const [collectionDetails, setCollectionDetails] = useState<any>(null);
    const [showSuspiciousMsg, setShowSuspiciousMsg] = useState<boolean>(false)
    const [customer, setCustomer] = useState<any>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    const tabs: any = [
        {
            name: 'Computer', slug: 'computer', icon: '', component: () => {
                return (
                    <>
                        <div className="container cstm-tabs-container">
                            <div className="lg:flex gap-5 justify-center">
                                <div className="exbt-block p-5 rounded-3xl shadow-lg lg:w-2/5 dark:bg-neutral-800/80">
                                    <div className="flex justify-center mb-3"><AiFillWindows className="text-5xl" /></div>
                                    <h3 className="font-semibold mb-4 text-2xl text-center">Windows PC</h3>
                                    <p>1. Download the installation file by clicking on the download now button.</p>
                                    <p>2. If prompted, click Run or Save.</p>
                                        <ul className="ml-4 mb-2 mt-2">
                                            <li>• If you choose Save, to start installation, either: </li>
                                            <li>• Double-click the download. </li>
                                            <li>• Click Open file.</li>
                                        </ul>
                                    <p>3.1f you're asked, 'Do you want to allow this app to make changes to your device; click Yes.</p>
                                    <div className="flex justify-center mt-6">
                                        <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-2 sm:px-5  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 py-2.5 ">Download Now</button>
                                    </div>
                                </div>
                                <div className="exbt-block p-5 rounded-3xl shadow-lg lg:w-2/5 dark:bg-neutral-800/80">
                                    <div className="flex justify-center mb-3"><BsApple className="text-5xl" /></div>
                                    <h3 className="font-semibold mb-4 text-2xl text-center">Mac PC</h3>
                                    <p>1. Download the installation file by clicking on the download now button.</p>
                                    <p>2. If prompted, click Run or Save.</p>
                                        <ul className="ml-4 mb-2 mt-2">
                                            <li>• If you choose Save, to start installation, either: </li>
                                            <li>• Double-click the download. </li>
                                            <li>• Click Open file.</li>
                                        </ul>
                                    <p>3.1f you're asked, 'Do you want to allow this app to make changes to your device; click Yes.</p>
                                    <div className="flex justify-center mt-6">
                                        <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-2 sm:px-5  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 py-2.5 ">Download Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }, isDisabled: false
        },
        {
            name: 'Android', slug: 'android', icon: '', component: () => {
                return (
                    <>
                        <div className="container">
                            <div className="flex gap-5 justify-center">
                                <div className="exbt-block p-5 rounded-3xl shadow-lg lg:w-2/5 dark:bg-neutral-800/80">
                                    <h3 className="font-semibold mb-4 text-2xl text-center">Android</h3>
                                    <div className="p-3 text flex justify-center p-3 text-center">
                                        <img src={qrcode} className="w-1/4" />
                                    </div>
                                    <p className="text-center">Scan the QR Code to download</p>
                                    <div className="p-3 text flex flex-col justify-center items-center p-3 text-center">
                                        <span className="bg-neutral-300 flex font-semibold h-14 items-center justify-center mb-3 rounded-full w-14">OR</span>
                                        <img src={gplaybtn} className="w-56" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }, isDisabled: false
        },
        {
            name: 'iPhone & iPad', slug: 'iphone', icon: '', component: () => {
                return (
                    <>
                        <div className="container">
                            <div className="flex gap-5 justify-center">
                                <div className="exbt-block p-5 rounded-3xl shadow-lg lg:w-2/5 dark:bg-neutral-800/80">
                                    <h3 className="font-semibold mb-4 text-2xl text-center">IPhone & IPad</h3>
                                    <div className="p-3 text flex justify-center p-3 text-center">
                                        <img src={qrcode} className="w-1/4" />
                                    </div>
                                    <p className="text-center">Scan the QR Code to download</p>
                                    <div className="p-3 text flex flex-col justify-center items-center p-3 text-center">
                                        <span className="bg-neutral-300 flex font-semibold h-14 items-center justify-center mb-3 rounded-full w-14">OR</span>
                                        <img src={applebtn} className="w-56" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }, isDisabled: false
        }
    ]

    const user: any = useSelector((state: StateExtended) => state.user);

    const getCollections = () => {
        showLoader();
        collectionApi.getCollections({ collectionID: id }, (message: string, resp: any) => {
            // console.log(resp)
            if (resp.collections.length > 0) {
                setCollectionDetails(resp.collections[0])
                if (!resp.collections[0].galleryCode) {
                    navigate(`/collection/${resp.collections[0].collectionID}`)
                }
            }
            //setViewCount(resp.totalViewCount);
            //setFavouriteCount(resp?.totalFavouriteCount || 0)
            //setLikeCount(resp?.totalLikeCount || 0)
            //setFollowerCount(resp?.totalFollowCount || 0)
            hideLoader();
        }, (message: string) => {
            hideLoader();
            console.log(message);
        })
    }

    const getSiteSettings = () => {
        commonApi.getSiteSettings({}, (message: any, resp: any) => { }, (message: any) => { })
    }

    const getCustomer = () => {
        if (collectionDetails) {
            userApi.getUserProfile({ customerID: collectionDetails.addedBy }, (message: string, resp: any) => {
                if (resp.customers.length) {
                    setCustomer(resp.customers[0]);
                }
            }, (message: string) => {
                console.log(message);
            });
        }
    }

    useEffect(() => {
        if (settings && Object.keys(settings).length && collectionDetails) {
            const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
            if (spamSettings.length) {
                const banItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBanCollectionSpam"]) || []

                if (banItemSettings.length && collectionDetails.hasOwnProperty('reportedSpamCount') && +banItemSettings[0]['appValue'] <= collectionDetails['reportedSpamCount']) {
                    setShowSuspiciousMsg(true)
                }
            }
        }
        if (settings && Object.keys(settings).length) {
            const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
            if (spamSettings.length) {
                const blurItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBlurItemSpam"]) || []
                if (blurItemSettings.length) {
                    //setProductBlurLimit(+blurItemSettings[0]['appValue'])
                }
            }
        }
        getCustomer()
    }, [settings, collectionDetails]);

    useEffect(() => {
        if (id) {
            getCollections();
            // getCollectionFollowUnFollow();
        }
    }, [id])

    useEffect(() => {
        //addView();
        getSiteSettings();
        if (tab == null) {
            setSearchParams({ 'tab': tabs[0].slug })
        }
    }, [])

    return (
        <React.Fragment>
            <div className={`nc-AuthorPage`} data-nc-id="AuthorPage">
                <Helmet>
                    <title>{collectionDetails?.collectionName}</title>
                    <meta name="title" content={collectionDetails?.collectionName} />
                    <meta name="description" content={collectionDetails?.description} />
                </Helmet>

                {/* HEADER */}
                <div className="w-full">
                    <div className="relative w-full h-40 md:h-60 2xl:h-72">
                        <NcImage containerClassName="absolute inset-0" src={collectionDetails && collectionDetails?.bannerUrl} className="object-cover w-full h-full" />
                    </div>
                    <div className="container -mt-10 lg:-mt-16">
                        <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row items-start cstm-sm-flex-col">
                            <div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0 custom-sm-mt-0">
                                <NcImage src={collectionDetails && collectionDetails?.logoUrl} containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden" />
                            </div>
                            <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
                                {
                                    showSuspiciousMsg ?
                                        <div className="bg-pink-100 flex font-medium inline-flex justify-center nc-Badge px-2.5 py-1 relative rounded-full text-center text-pink-800 w-full mb-4" role="">
                                            <AiOutlineWarning size={20} /> &nbsp; <p>Reported for suspicious activity!</p>
                                        </div> : ''
                                }

                                <div className="max-w-screen-sm ">
                                    <span className="block mt-0 text-sm text-neutral-500 dark:text-neutral-400">{customer?.firstName} {customer?.lastName}</span>
                                    <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                                        <span>{collectionDetails && collectionDetails?.collectionName}</span>
                                    </h2>
                                    <span className="block mt-3 text-sm text-neutral-500 dark:text-neutral-400">
                                        {collectionDetails && collectionDetails?.about}
                                    </span>
                                    <div className={`flex items-center mt-2.5`}>
                                        <strong>Gallery Code:</strong>
                                        <span className={`ml-2 mr-2`}>{collectionDetails?.galleryCode}</span>
                                        <a className="cursor-pointer" onClick={() => {
                                            if (navigator.clipboard) {
                                                navigator.clipboard.writeText("bwn-ztrb-zhe");
                                                toast.success("Gallery code copied.");
                                            } else {
                                                toast.error("Gallery code not copied!");
                                            }

                                        }}><MdOutlineContentCopy /></a>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    {collectionDetails && collectionDetails?.socialURL && collectionDetails?.socialURL.length > 0 ?
                                        <SocialsList itemClass="block w-7 h-7" socialsList={collectionDetails && collectionDetails?.socialURL} />
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end gap-2 collection-right-btns items-center">
                                <NcShareDropDown
                                    className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
                                    panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* ====================== END HEADER ====================== */}
                <div className="container-full py-16 lg:pb-8 lg:pt-5 space-y-16 lg:space-y-28 pl-10 pr-10">
                    <div className={`text-center`}>
                        <h2 className="font-semibold mb-2 mt-4 text-3xl">Download Virtual Exhibition</h2>
                        <div className="text-gray-500">Loreum ipsum is simply dummy text of the printing and typesetting industry.</div>
                    </div>
                </div>

                <div className="container-full py-16 lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28 pl-10 pr-10">
                    <Nav
                        className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-auto"
                        containerClassName="mb-4 lg:mb-4 relative flex justify-center w-full text-sm md:text-base"
                    >
                        {tabs.map((item: any, index: any) => (
                            <NavItem2
                                key={index}
                                isActive={tab === item.slug}
                                onClick={() => {
                                    setSearchParams({ 'tab': item.slug })
                                }}
                            >
                                <div className="flex items-center justify-center sm:space-x-2.5 text-xs sm:text-sm ">
                                    <span
                                        className="hidden sm:inline-block"
                                    >{item.icon}</span>
                                    <span>{item.name}</span>
                                </div>
                            </NavItem2>
                        ))}
                    </Nav>
                    <div>
                        {tabs.map((_tab: any, i: any) => {
                            if (tab == _tab.slug) {
                                return <React.Fragment key={i}>{_tab.component()}</React.Fragment>
                            }
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PageCollectionGallery;
