import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useOrdersApi() {

  const callApi = useApiCall()

  const getMyOrdersList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ORDERS.MY_ORDER, data, onSuccess, onError);
  }

  const getOrder = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ORDERS.ORDER_DETAILS, data, onSuccess, onError);
  }

  const getOrderStatuses = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.ORDERS.ORDER_STATUSES, data, onSuccess, onError);
  }

  return {
    getMyOrdersList,
    getOrder,
    getOrderStatuses
  }
}