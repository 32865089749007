import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import * as rdd from 'react-device-detect';
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NftMoreDropdown from "components/NftMoreDropdown";
import SocialsList from "shared/SocialsList/SocialsList";
import FollowButton from "components/FollowButton";
import LikeButton from "components/LikeButton";
import { useParams } from "react-router-dom";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import ProductNFT from "../components/ProductNFT";
import ViewCount from "../components/ViewCount";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";
import { useUserAuthSelector } from "_common/hooks/selectors/userSelector";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NoDataFound from "components/NoDataFound";
import toast from "react-hot-toast";
import FavouriteButton from "components/FavouriteButton";
import { useSiteSettingsSelector } from "_common/hooks/selectors/commonSelector";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { AiOutlineWarning } from "react-icons/ai";
import { Link } from "react-router-dom";

const browserObject = {
	browserName: rdd['browserName'],
	browserVersion: rdd['browserVersion'],
	deviceType: rdd['deviceType'],
	engineName: rdd['engineName'],
	engineVersion: rdd['engineVersion'],
	fullBrowserVersion: rdd['fullBrowserVersion'],
	getUA: rdd['getUA'],
	isAndroid: rdd['isAndroid'],
	isBrowser: rdd['isBrowser'],
	isChrome: rdd['isChrome'],
	isChromium: rdd['isChromium'],
	isConsole: rdd['isConsole'],
	isDesktop: rdd['isDesktop'],
	isEdge: rdd['isEdge'],
	isEdgeChromium: rdd['isEdgeChromium'],
	isElectron: rdd['isElectron'],
	isEmbedded: rdd['isEmbedded'],
	isFirefox: rdd['isFirefox'],
	isIE: rdd['isIE'],
	isIOS: rdd['isIOS'],
	isIOS13: rdd['isIOS13'],
	isIPad13: rdd['isIPad13'],
	isIPhone13: rdd['isIPhone13'],
	isIPod13: rdd['isIPod13'],
	isLegacyEdge: rdd['isLegacyEdge'],
	isMIUI: rdd['isMIUI'],
	isMacOs: rdd['isMacOs'],
	isMobile: rdd['isMobile'],
	isMobileOnly: rdd['isMobileOnly'],
	isMobileSafari: rdd['isMobileSafari'],
	isOpera: rdd['isOpera'],
	isSafari: rdd['isSafari'],
	isSamsungBrowser: rdd['isSamsungBrowser'],
	isSmartTV: rdd['isSmartTV'],
	isTablet: rdd['isTablet'],
	isWearable: rdd['isWearable'],
	isWinPhone: rdd['isWinPhone'],
	isWindows: rdd['isWindows'],
	isYandex: rdd['isYandex'],
	mobileModel: rdd['mobileModel'],
	mobileVendor: rdd['mobileVendor'],
	osName: rdd['osName'],
	osVersion: rdd['osVersion']
}

export interface AuthorPageProps {
	className?: string;
}

const PageCollectionDetailNormalize = () => {
	let [categories] = useState([
		"Collectibles",
		"Created",
		"Liked",
		"Following",
		"Followers",
	]);
	const { id } = useParams();
	const productApi = useProductApi();
	const collectionApi = useCollectionApi();
	const { showLoader, hideLoader } = useAppLoader();
	const isAuth = useUserAuthSelector()
	const settings: any = useSiteSettingsSelector();
	const commonApi = useCommonApi();

	const limit: number = 12;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [products, setProducts] = useState<any>(null);
	const [collectionDetails, setCollectionDetails] = useState<any>(null);

	const [isOpen, setIsOpen] = React.useState(false);
	const [ddd, setDd] = React.useState(false);
	const [filter, setFilter] = useState<any>({});

	const [isfollowed, setIsfollowed] = React.useState(false);
	const [followdata, setFollowdata] = useState<any>(null);
	const [followerCount, setFollowerCount] = useState<number>(0);
	const [favouriteCount, setFavouriteCount] = useState<number>(0);
	const [likeCount, setLikeCount] = useState<number>(0);
	const [showSuspiciousMsg, setShowSuspiciousMsg] = useState<boolean>(false)
	const [productBlurLimit, setProductBlurLimit] = useState<number | null>(null);
	const [viewCount, setViewCount] = useState<number>(0);
	const [spamCount, setSpamCount] = useState<number>(0);

	const sortBy = [
		{ name: "Featured", id: "isFeatured" },
		{ name: "Reedamable", id: "isReedamable" }
	];


	const productsContainer: any = useRef();

	const user: any = useSelector((state: StateExtended) => state.user);

	const addView = () => {
		commonApi.addView({
			"itemType": 1,
			"itemID": id,
			"walletAddress": user?.user ? user.user.custodialWalletAddress : '',
			"browserObject": JSON.stringify(browserObject)
		}, (message: any, resp: any) => { }, (message: any) => { })
	}

	const isBottom = (el: any) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	const trackScrolling = () => {
		setDd(true)
		if (productsContainer.current) {
			console.log('Trakc Scroll : ', currentPage, totalPage)
			if (isBottom(productsContainer.current)) {
				console.log('Trakc Scroll : Bottom Reached.', currentPage, totalPage);
				if (currentPage < totalPage) {
					setCurrentPage(currentPage + 1);
				} else {

					console.log('SELF REMOVE LISTNER ----------------------------------------------------------------')
					document.removeEventListener('scroll', trackScrolling, true);
				}
			}
		} else {
			console.log('Trakc Scroll : #ERROR : No Element Found!')
		}
	};

	const getProducts = () => {
		//let listeners:any = getEventListeners(document);
		//console.log(listeners)
		//console.log('PRE REMOVE LISTNER ----------------------------------------------------------------')
		document.removeEventListener('scroll', trackScrolling, true);
		let params: any = {
			usePaging: true,
			pageSize: limit,
			pageNumber: currentPage,
			searchText: searchKey,
			collectionID: id,
			isPublished: 1,
			isActive: 1,
			isDeleted: 0
		}

		switch (filter?.radioBtnFiltrations?.sortBy) {
			case 'isFeatured':
				params.isFeatured = 1;
				break;
			case 'isReedamable':
				params.isReedamable = 1;
				break;
			default:
		}
		/*console.log('params => ', params); */

		showLoader();
		productApi.getProducts(params, (message: string, resp: any) => {
			hideLoader();
			if (products) {
				setProducts([...products, ...resp.products]);
			} else {
				setProducts(resp.products);
			}
			setTotalPage(Math.ceil(resp.totalRecord / limit));
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getCollections = () => {
		showLoader();
		collectionApi.getCollections({ collectionID: id, isPublished: 1 }, (message: string, resp: any) => {
			// console.log(resp)
			if (resp.collections.length > 0) {
				setCollectionDetails(resp.collections[0])
			}
			setViewCount(resp.totalViewCount);
			setSpamCount(resp.totalSpamCount);
			setFavouriteCount(resp?.totalFavouriteCount || 0)
			setLikeCount(resp?.totalLikeCount || 0)
			setFollowerCount(resp?.totalFollowCount || 0)
			hideLoader();
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	// const getCollectionFollowUnFollow = () => {
	// 	showLoader();
	// 	collectionApi.getCollectionfollowunfollow({
	// 		//id: '',
	// 		itemType: 1,
	// 		itemID: id,
	// 		// addedBy: (isAuth) ? user.user.userID : null,
	// 		urlid: '',
	// 		authrequired: isAuth
	// 	}, (message: string, resp: any) => {
	// 		hideLoader();
	// 		console.log(resp);
	// 		if (resp.code === 200 && resp.successful === true && resp.followUnfollows.length > 0) {
	// 			setIsfollowed(true);
	// 			setFollowdata(resp.followUnfollows[0]);
	// 			// setTotalFollowers(resp.totalRecord);
	// 		} else {
	// 			setIsfollowed(false);
	// 			setFollowdata(null);
	// 			// setTotalFollowers(0);
	// 		}
	// 	}, (message: string) => {
	// 		hideLoader();
	// 		console.log(message);
	// 	})
	// }

	// const applyFollowUnfollow = (value: number) => {
	// 	console.log(value, followdata);
	// 	if (followdata) {
	// 		showLoader();
	// 		collectionApi.saveUnfollow({
	// 			itemType: 1,
	// 			itemID: id,
	// 			id: followdata.id
	// 		}, (message: string, resp: any) => {
	// 			hideLoader();
	// 			getCollectionFollowUnFollow();
	// 			console.log(resp)
	// 		}, (message: string) => {
	// 			hideLoader();
	// 			console.log(message);
	// 		});
	// 	} else {
	// 		showLoader();
	// 		collectionApi.saveFollow({
	// 			itemType: 1,
	// 			itemID: id,
	// 			isFollow: value
	// 		}, (message: string, resp: any) => {
	// 			hideLoader();
	// 			getCollectionFollowUnFollow();
	// 			console.log(resp)
	// 		}, (message: string) => {
	// 			hideLoader();
	// 			console.log(message);
	// 		});
	// 	}
	// 	/*

	// 	*/
	// }

	const getSiteSettings = () => {
		commonApi.getSiteSettings({}, (message: any, resp: any) => { }, (message: any) => { })
	}

	useEffect(() => {
		if (settings && Object.keys(settings).length && collectionDetails) {
			const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
			if (spamSettings.length) {
				const banItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBanCollectionSpam"]) || []

				if (banItemSettings.length && collectionDetails && +banItemSettings[0]['appValue'] <= spamCount) {
					setShowSuspiciousMsg(true)
				}
			}
		}
		if (settings && Object.keys(settings).length) {
			const spamSettings = settings && settings['siteSettings'] && settings['siteSettings'].filter((e: any) => e['siteGroupName'] == "Spam") || []
			if (spamSettings.length) {
				const blurItemSettings = spamSettings[0]['keyValue'].filter((e: any) => e["MaxReporttoBlurItemSpam"]) || []
				if (blurItemSettings.length) {
					setProductBlurLimit(+blurItemSettings[0]['appValue'])
				}
			}
		}
	}, [settings, collectionDetails]);

	useEffect(() => {
		if (products) {
			if (currentPage == 1) {
				getProducts();
			} else {
				setCurrentPage(1);
			}
		}
	}, [filter])

	useEffect(() => {
		if (products) {
			if (currentPage == 1) {
				getProducts();
			} else {
				setCurrentPage(1);
			}
		}

	}, [searchKey])

	useEffect(() => {
		if (currentPage == 1) {
			setProducts(null)
		} else {
			getProducts();
		}

	}, [currentPage]);

	useEffect(() => {
		if (products) {
			if (!ddd) {
				console.log('ADD EVENT LISTNER');
				document.addEventListener('scroll', trackScrolling, true);
			}
		} else {
			getProducts();
		}
	}, [products])

	useEffect(() => {
		if (id) {
			getCollections();
			// getCollectionFollowUnFollow();
		}
	}, [id])

	useEffect(() => {
		addView();
		getSiteSettings();
	}, [])

	return (
		<React.Fragment>
			<div className={`nc-AuthorPage`} data-nc-id="AuthorPage">
				<Helmet>
					<title>{collectionDetails?.collectionName}</title>
					<meta name="title" content={collectionDetails?.collectionName} />
					<meta name="description" content={collectionDetails?.description} />
				</Helmet>

				{/* HEADER */}
				<div className="w-full">
					<div className="relative w-full h-40 md:h-60 2xl:h-72">
						<NcImage containerClassName="absolute inset-0" src={collectionDetails && collectionDetails?.bannerUrl} className="object-cover w-full h-full" />
					</div>
					<div className="container -mt-10 lg:-mt-16">
						<div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row items-start cstm-sm-flex-col">
							<div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0 custom-sm-mt-0">
								<NcImage src={collectionDetails && collectionDetails?.logoUrl} containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden" />
							</div>
							<div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
								{
									showSuspiciousMsg ?
										<div className="bg-pink-100 flex font-medium inline-flex justify-center nc-Badge px-2.5 py-1 relative rounded-full text-center text-pink-800 w-full mb-4" role="">
											<AiOutlineWarning size={20} /> &nbsp; <p>Reported for suspicious activity!</p>
										</div> : ''
								}

								<div className="max-w-screen-sm-1 ">
									<div className="relative flex flex-col md:flex-row items-start cstm-sm-flex-col justify-between">
										<div>
											<span className="block mt-0 text-sm text-neutral-500 dark:text-neutral-400">Collection Details</span>
											<h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
												<span>{collectionDetails && collectionDetails?.collectionName}</span>
											</h2>
										</div>
										<div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse flex-wrap justify-end gap-2 collection-right-btns items-center">

								<NftMoreDropdown
									type_id={1}
									actions={[
										{
											id: "report",
											name: "Report abuse",
											icon: "las la-flag",
										},
									]}
									containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
								/>

								<NcShareDropDown
									className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer mx-2"
									panelMenusClass="origin-top-right !-right-5 !w-40 sm:!w-52"
								/>
								{/* {(() => {
									if (isAuth) {
										if (isfollowed) {
											return (
												<div>
													<ButtonSecondary
														className={`relative z-10`}
														sizeClass={`px-4 py-1.5 min-w-[84px]`}
														fontSize={`text-sm font-medium`}
														onClick={() => {
															applyFollowUnfollow(0)
														}}
													>
														<span className="text-xs whitespace-nowrap">{totalFollowers} Unfollow</span>
													</ButtonSecondary>
												</div>
											)
										} else {
											return (
												<div>
													<ButtonPrimary
														className={`relative z-10`}
														sizeClass={`px-4 py-1.5 min-w-[84px]`}
														fontSize={`text-sm font-medium`}
														onClick={() => {
															applyFollowUnfollow(1)
														}}
													>
														<span className="text-xs whitespace-nowrap">{totalFollowers} Follow</span>
													</ButtonPrimary>
												</div>
											)
										}
									} else {
										return (
											<div>
												<ButtonSecondary
													className={`relative z-10`}
													sizeClass={`px-4 py-1.5 min-w-[84px]`}
													fontSize={`text-sm font-medium`}
													onClick={() =>
														toast.error('please login to follow')
													}
												>
													<span className="text-xs whitespace-nowrap">{totalFollowers} Follower(s)</span>
												</ButtonSecondary>
											</div>
										)
									}
								})()} */}

								<ViewCount count={viewCount} />
								<FollowButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8 py-4 mt-1"
									id={id}
									type={1}
									isAuth={isAuth}
									followerCount={followerCount}
									callBackFn={getCollections}
								/>

								<LikeButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8 mt-1"
									id={id}
									type={1}
									isAuth={isAuth}
									likeCount={likeCount}
									callBackFn={getCollections}
								/>

								<FavouriteButton
									className="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8 mt-1"
									id={id}
									type={1}
									isAuth={isAuth}
									favouriteCount={favouriteCount}
									callBackFn={getCollections}
								/>
							</div>
									</div>

									<span className="block mt-3 text-sm text-neutral-500 dark:text-neutral-400">
										{collectionDetails && collectionDetails?.about}
									</span>
									<span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100 mt-3">
										{collectionDetails && collectionDetails?.categoryName}
									</span>
								</div>
								<div className="mt-5">
									{collectionDetails && collectionDetails?.socialURL && collectionDetails?.socialURL.length > 0 ?
										<SocialsList itemClass="block w-7 h-7" socialsList={collectionDetails && collectionDetails?.socialURL} />
										: null
									}
								</div>
							</div>
							
							{collectionDetails?.galleryCode && (
								<div className="absolute right-6 bottom-3 mb-2">
									<Link to={`/gallery-app/${id}`}><ButtonPrimary>Explore Gallery</ButtonPrimary></Link>
								</div>
							)}
						</div>

					</div>
				</div>
				{/* ====================== END HEADER ====================== */}

				<div className="container-full py-16 lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28 pl-10 pr-10">
					<main>
						{/* LOOP ITEMS */}
						{/* LOOP ITEMS */}
						<div ref={productsContainer} className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-8 lg:mt-10">
							{products?.map((product: any, i: any) => (
								<ProductNFT data={product} productBlurLimit={productBlurLimit} key={i} />
							))}
						</div>

						{products?.length == 0 && (<NoDataFound />)}

						{/* PAGINATION */}
						{currentPage < totalPage && products?.length != 0 && (
							<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
								<ButtonPrimary loading>Show me more</ButtonPrimary>
							</div>
						)}


					</main>

				</div>
			</div>
		</React.Fragment>
	);
};

export default PageCollectionDetailNormalize;
