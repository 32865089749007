import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide"
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

export interface HomeFeatureCollectionProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  collections?: any;
  settings?: any;
}

const HomeFeatureCollections: React.FC<HomeFeatureCollectionProps> = ({
  className = "",
  itemClassName = "",
  collections = [],
  settings = null
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.3,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS]);

  

  return (
    <div className={`nc-SectionSliderCardNftVideo pt-14`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc={`${settings && settings.StoreFeaturedCollectionTextHome.value ? settings.StoreFeaturedCollectionTextHome.value : 'Click on music icon and enjoy NTF music or audio'}`} hasNextPrev>
          Featured Collections
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {collections.map((item: any, index: number) => (
              <li key={index} className={`glide__slide`}>
                <div className={`nc-CardNFTVideo relative flex flex-col group`}>
                  <div className="relative flex-shrink-0 ">
                    {/* 
                          AUDIO MEDiA 
                          <VideoForNft nftId={DEMO_NFT_ID} />
                          */}
                    <div className="">
                      <NcImage
                        containerClassName="flex aspect-w-16 aspect-h-9 w-full h-0 rounded-3xl overflow-hidden z-0"
                        src={item.logoUrl}
                        className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                      />
                    </div>
                    {/*
                          <LikeButton
                            liked={true}
                            className="absolute top-3 right-3 z-10 !h-9"
                          /> 
                          */}
                  </div>

                  <div className="p-5">
                    <div className="flex justify-between items-center">
                      <h2 className={`sm:text-lg font-semibold`}>
                        {item.collectionName}
                      </h2>
                      <div className="ml-2 flex items-center space-x-3">

                      </div>
                    </div>
                    {/*
                          <div className="flex justify-between items-end mt-3.5">
                            <Prices labelTextClassName="bg-white dark:bg-neutral-900 " />
                            <div className="text-right">
                              <span className="block text-xs text-neutral-500 dark:text-neutral-400 font-normal tracking-wide">
                                Remaining time
                              </span>
                              <span className="block font-semibold mt-0.5">3h : 15m : 20s</span>
                            </div>
                          </div>
                        */}
                  </div>
                  <Link to={`/collection/${item.collectionID}`} className="absolute inset-0"></Link>
                </div>
              </li>
            ))}
            <li className={`glide__slide`}>
              <Link to={"/collections"} className="block relative group">
                <div className="flex aspect-w-16 aspect-h-9 w-full h-0 rounded-3xl bg-neutral-100 dark:bg-neutral-800"></div>
                <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
                  <div className="flex items-center justify-center relative">
                    <span className="text-xl font-semibold">Collections</span>
                    <svg
                      className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 20.4999V3.66992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="text-sm mt-1">Show me more</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeFeatureCollections;
