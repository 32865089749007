import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductNFT from "../components/ProductNFT";
import SiteHeader from "./SiteHeader";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { Transition } from "@headlessui/react";
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/outline";
import TabFilters from "components/Filters/TabFilters";
import PageHeaderWithSearch from "shared/PageHeaderWithSearch/PageHeaderWithSearch";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useBlockchainNetworksSelector } from "_common/hooks/selectors/commonSelector";
import nftbanner from "../../../images/nft-bnr-2.jpg";
import NoDataFound from "components/NoDataFound";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./../components/Prices";
import { HiOutlineEyeOff } from "react-icons/hi";
import { BiLink } from "react-icons/bi";
export interface PageSearchProps {
	className?: string;
}

//const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {
const NftList = () => {

	const productApi = useProductApi();
	const collectionApi = useCollectionApi();
	const commonApi = useCommonApi();
	const blockchainNetworks = useBlockchainNetworksSelector();
	const { showLoader, hideLoader } = useAppLoader();
	const [queryParams, setQueryParams] = useSearchParams()

	const limit: number = 12;
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);
	const [searchKey, setSearchKey] = useState<string>('');
	const [products, setProducts] = useState<any>(null);
	const [isOpen, setIsOpen] = React.useState(false);
	const [ddd, setDd] = React.useState(false);
	const [filter, setFilter] = useState<any>({});
	const [prevFilter, setPrevFilter] = useState<any>({});
	const [categories, setCategories] = useState<any>(null);
	const [filterCategory, setFilterCategory] = useState<any>([]);
	const [collections, setCollections] = useState<any>([]);
	const [filterCollections, setFilterCollections] = useState<any>([]);
	const [filterChain, setFilterChain] = useState<any>([]);
	const [callingData, setCallingData] = useState<boolean>(false);
	const [categoryID, setCategoryID] = useState<any>('');
	const [collectionID, setCollectionID] = useState<any>('');
	const [blockchainNetworkID, setBlockchainNetworkID] = useState<any>('');
	const [showBlurFile, setShowBlurFile] = useState<boolean>(false)
	const [productBlurLimit, setProductBlurLimit] = useState<number | null>(null);

	const sortBy = [
		{ name: "Featured", id: "isFeatured" },
		{ name: "Reedamable", id: "isReedamable" }
	];


	const productsContainer: any = useRef();

	const getBlockchainNetworks = () => {
		commonApi.getBlockchainNetworks({}, (message: string, resp: any) => { }, (message: string, resp: any) => { console.log(message, resp) })
	}

	const getCollections = () => {
		collectionApi.getCollections({}, (message: string, resp: any) => {
			setCollections(resp.collections);
		}, (message: string) => {
			console.log(message);
		})
	}

	const getCategories = () => {
		let params: any = {
			usePaging: false,
			sortingBy: 'categoryName',
			sortingOrder: 'asc',
			parentCategoryID: 0
		}
		productApi.getCategories(params, (message: string, resp: any) => {
			if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('categorydm') && resp.categorydm.length > 0) {
				setCategories(resp.categorydm);
			}
		}, (message: string) => {
			console.log(message);
		});
	}

	const isBottom = (el: any) => {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	const addTrackScrollEvent = () => {
		function trackScrolling() {
			if (!callingData) {
				setDd(true)
				if (productsContainer.current) {
					if (isBottom(productsContainer.current)) {
						document.removeEventListener('scroll', trackScrolling, true);
						console.log('Trakc Scroll : Bottom Reached.', currentPage, totalPage);
						if (currentPage < totalPage) {
							setCurrentPage(currentPage + 1);
						}
					}
				} else {
					//console.log('Trakc Scroll : #ERROR : No Element Found!')
				}
			}

		};
		document.addEventListener('scroll', trackScrolling, true);
	}

	const getProducts = () => {
		if (currentPage > 1 && currentPage > totalPage && products != null) {
			console.log('noMore Data');
			return;
		}
		if (currentPage > 1 && currentPage > totalPage && totalPage == 1) {
			console.log('wait for prev Data');
			return;
		}
		setCallingData(true)
		//document.removeEventListener('scroll', trackScrolling, true);
		let params: any = {
			usePaging: true,
			pageSize: limit,
			isPublished: 1,
			pageNumber: currentPage,
			searchText: searchKey,
			isCreatedByCustomer: 1
		}

		switch (filter?.radioBtnFiltrations?.sortBy) {
			case 'isFeatured':
				params.isFeatured = 1;
				break;
			case 'isReedamable':
				params.isReedamable = 1;
				break;
			default:
		}

		if (filter?.radioBtnFiltrations?.category) {
			params.categoryID = filter?.radioBtnFiltrations?.category
		}

		if (filter?.radioBtnFiltrations?.collection) {
			params.collectionID = filter?.radioBtnFiltrations?.collection
		}

		if (filter?.radioBtnFiltrations?.blockchainNetwork) {
			params.blockchainNetworkID = filter?.radioBtnFiltrations?.blockchainNetwork
		}
		/*console.log('params => ', params); */

		showLoader();
		productApi.getNftsGuest(params, (message: string, resp: any) => {
			hideLoader();
			if (products) {
				setProducts([...products, ...resp.nfTs]);
			} else {
				setProducts(resp.nfTs);
			}
			setTotalPage(Math.ceil(resp.totalRecord / limit));
			setCallingData(false)
		}, (message: string) => {
			hideLoader();
			console.log(message);
			setCallingData(false)
		})
	}

	const getPrice = (data: any) => {
		if (data?.productPrice && data?.productPrice.length) {
			let activePrice = data.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}

	}

	useEffect(() => {
		if (JSON.stringify(filter) !== JSON.stringify(prevFilter)) {
			setPrevFilter(filter)
			if (products) {
				if (currentPage == 1) {
					setTotalPage(1);
					setProducts(null);

				} else {
					setCurrentPage(1);
				}
			}
		}

		// if (filter?.radioBtnFiltrations?.category && queryParams.get('categoryID')) {
		// 	setQueryParams(
		// 		createSearchParams({ categoryID: filter?.radioBtnFiltrations?.category })
		// 	)
		// }

		// if (filter?.radioBtnFiltrations?.collection && queryParams.get('collectionID')) {
		// 	setQueryParams(
		// 		createSearchParams({ collectionID: filter?.radioBtnFiltrations?.collection })
		// 	)
		// }

		// if (filter?.radioBtnFiltrations?.blockchainNetwork && queryParams.get('blockchainNetworkID')) {
		// 	setQueryParams(
		// 		createSearchParams({ blockchainNetworkID: filter?.radioBtnFiltrations?.blockchainNetwork })
		// 	)
		// }

	}, [filter])

	useEffect(() => {
		if (products) {
			if (currentPage == 1) {
				setTotalPage(1);
				setProducts(null);
			} else {
				setCurrentPage(1);
			}
		}
	}, [searchKey])

	useEffect(() => {
		if (currentPage == 1) {
			setTotalPage(1);
			setProducts(null)
		} else {
			getProducts();
		}

	}, [currentPage]);

	useEffect(() => {
		if (products) {
			addTrackScrollEvent();
		} else {
			getProducts();
		}
	}, [products]);

	useEffect(() => {
		if (collections) {
			let options: any = [];
			collections.map((col: any, i: any) => { options.push({ name: col.collectionName, id: col.collectionID },) });
			setFilterCollections(options)
		}
	}, [collections])

	useEffect(() => {
		if (categories) {
			let options: any = [];
			categories.map((col: any, i: any) => { options.push({ name: col.categoryName, id: col.categoryID },) });
			setFilterCategory(options)
		}
	}, [categories]);

	useEffect(() => {
		if (blockchainNetworks) {
			let options: any = [];
			blockchainNetworks.map((col: any, i: any) => { options.push({ name: col.chainLabel, id: col.networkID },) });
			setFilterChain(options)
		} else {
			getBlockchainNetworks();
		}
	}, [blockchainNetworks])

	useEffect(() => {
		getCategories();
		getCollections();

	}, [])

	useEffect(() => {
		if (queryParams) {
			queryParams.forEach((value: any, key: any) => {
				console.log({ key, value });
				if (key == 'categoryID') {
					setCategoryID(value)
				}
				else if (key == 'collectionID') {
					setCollectionID(value)
				}
				else if (key == 'blockchainNetworkID') {
					setBlockchainNetworkID(value)
				}
				else if (key == 'searchText') {
					setSearchKey(value)
				}
				else {
					console.log("not found")
				}
			});
			// setIsOpen(true)
		}
	}, [queryParams])

	useEffect(() => {
		// getProducts();
		if (sortBy.length && filterCollections.length && filterCategory.length && filterChain.length) {
			if (collectionID.length > 0 || categoryID.length > 0 || blockchainNetworkID.length > 0) {
				setIsOpen(true)
			}
			// console.log(queryParams)
		}
	}, [sortBy, filterCollections, filterCategory, filterChain])

	return (
		<React.Fragment>
			<SiteHeader />
			<div className={`nc-PageSearch`} data-nc-id="PageSearch">
				<Helmet>
					<title>NFTs</title>
				</Helmet>

				<PageHeaderWithSearch title="NFTs" onSearch={setSearchKey} searchKey={searchKey} banner={nftbanner} />

				<div className="container-full py-16 lg:pb-28 lg:pt-5 space-y-16 lg:space-y-28 pl-10 pr-10">
					<main>


						<div className={`flex flex-col relative`}>
							<div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
								<span>{/* left side */}</span>
								<span className="flex flex-shrink-0 text-right items-center">
									{/* <Link className="sm:space-x-2" to="/category/new">
										<ButtonPrimary>Add New</ButtonPrimary>
									</Link> */}
									<ButtonSecondary className="w-auto !pr-16 ml-2 border-primary-500 focus:ring-0 focus:ring-primary-200" sizeClass="pl-4 py-2.5 sm:pl-6" onClick={() => { setIsOpen(!isOpen); }}>

										<svg
											className={`w-4 h-4 sm:w-6 sm:h-6`}
											viewBox="0 0 24 24"
											fill="none"

										>
											<path
												d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M19.87 17.12L18.87 16.12"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>


										<span className="block truncate ml-2.5">Filter</span>
										<span className="absolute top-1/2 -translate-y-1/2 right-5">
											<ChevronDownIcon
												className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""
													}`}
												aria-hidden="true"
											/>
										</span>
									</ButtonSecondary>
								</span>
							</div>

							<Transition
								show={isOpen}
								enter="transition-opacity duration-150"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="transition-opacity duration-150"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8"></div>
								<TabFilters name="Category Filters"
									radioBtnFiltrations={[
										{
											key: 'sortBy',
											options: sortBy
										},
										{
											key: 'collection',
											options: filterCollections,
											value: collectionID
										},
										{
											key: 'category',
											options: filterCategory,
											value: categoryID
										},
										{
											key: 'blockchainNetwork',
											options: filterChain,
											value: blockchainNetworkID
										},
										/* {
											key: 'mySort2',
											options: [{ name: "Recently listed", id: "Recently-listed" }, { name: "Ending soon", id: "Ending-soon" }]
										} */
									]} onApplyFilter={setFilter}/* onApplySort={onApplySort} onSearch={onSearch} */ />
							</Transition>
						</div>

						{/* LOOP ITEMS */}
						<div ref={productsContainer} className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-8 gap-y-10 mt-8 lg:mt-10 custom-xs-col-2">
							{products?.map((product: any, i: number) => (
								<div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`} key={`myproduct-${i}`}>
									<div className={"relative flex-shrink-0" + (showBlurFile ? ' spam-product-card' : '')}>
										<div>
											{(() => {
												if (!product?.removedBlur && (productBlurLimit != null && Math.abs(productBlurLimit) <= product['reportedSpamCount'])) {
													return (
														<div className="spam-overlay spam-overlay-rounded-full">
															<HiOutlineEyeOff />
															<p className="text-sm mb-4">Inappropriate content.</p>
															<ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={() => {
																product['removedBlur'] = true;
															}}>view</ButtonSecondary>
														</div>
													)
												}
											})()}
											<Link className="block" to={"/nft/" + product?.nftid + "?guest=1"} >
												<NcImage
													containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
													src={product?.nftThumbUrl}
													className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
												/>
											</Link>
										</div>
										{/* 
										{Math.random() > 0.5 ? (
											<ItemTypeVideoIcon className="absolute top-3 left-3 !w-9 !h-9" />
										) : (
											<ItemTypeImageIcon className="absolute top-3 left-3 !w-9 !h-9" />
										)} 
										*/}
										{/*   
										<LikeButton liked={isLiked} className="absolute top-3 right-3 z-10 !h-9" /> 
										*/}
										{/*
										<div className="absolute bottom-3 inset-x-3 flex justify-end">
											{product?.isActive == 1 ? (<span className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-white bg-red-600 relative">Hold</span>) : (<span className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-900 bg-green-100  relative">Active</span>)}
										</div>
										*/}
									</div>
									<div className="p-4 py-4 space-y-3">
										<Link to={"/collection/" + product?.collectionID} className="block">
											<div className="flex justify-between">
												<span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative">
													{product?.collectionName}
												</span>
											</div>
										</Link>
										<Link to={"/nft/" + product?.nftid  + "?guest=1"} className={`block`}>
											<h2 className={`text-lg font-medium mt-0`}>
												{product?.name}
											</h2>
											{/* <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div> */}
											<div className="flex justify-between items-end mt-3">
												{/*<Prices network={product?.network} price={getPrice(product)} />*/}
												<div className={`flex items-center`}>
													<BiLink className="w-8 h-8 text-neutral-500 dark:text-neutral-400" style={{marginLeft: '-4px'}} />
													<div className={`ml-1.5`}>
														<div className={`text-xs text-neutral-500 dark:text-neutral-400`}>Chain Name</div>
														<span className={`text-sm font-medium`}>
															{(() => {
																if (product?.chainName && product.chainName) {
																	return (
																		(product.chainName[0].toUpperCase() + product.chainName.substring(1))
																	)
																}
															})()}
														</span>
													</div>
												</div>
											</div>
										</Link>
									</div>
								</div>
							))}
						</div>


						{products?.length == 0 && (<NoDataFound />)}



						{/* PAGINATION */}
						{currentPage < totalPage && products?.length != 0 && (
							<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row justify-center sm:items-center">
								<ButtonPrimary loading>Show me more</ButtonPrimary>
							</div>
						)}

					</main>


				</div>
			</div>
		</React.Fragment>
	);
};

export default NftList;
