import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebook from "images/socials/facebook.svg";
import vimeo from "images/socials/vimeo.svg";
import twitter from "images/socials/twitter.svg";
import telegram from "images/socials/telegram.svg";
import youtube from "images/socials/youtube.svg";
import { BsInstagram, BsGlobe, BsFacebook } from "react-icons/bs";
import { FaFax, FaRegAddressCard, FaSnapchat, FaTiktok } from "react-icons/fa";
import { AiOutlineMail, AiFillTwitterCircle } from "react-icons/ai";
import { ImTelegram } from "react-icons/im";
import { SiDiscord } from "react-icons/si";
import { CiMobile3 } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { addHttps } from "_common/functions";

export interface SocialsList1Props {
  className?: string;
  socialsList?: any;
  isAuthorType?: boolean;
  itemClass?: string;
}

const socials: SocialType[] = [
  { name: "Facebook", icon: facebook, href: "#" },
  { name: "Vimeo", icon: vimeo, href: "#" },
  { name: "Youtube", icon: youtube, href: "#" },
  { name: "Telegram", icon: telegram, href: "#" },
  { name: "Twitter", icon: twitter, href: "#" },
]

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-3", socialsList = {}, isAuthorType = false, itemClass = "block w-6 h-6" }) => {

  const acceptedAppKeys = ['FacebookSocial', 'TwitterSocial', 'InstagramSocial', 'DiscordSocial', 'WebsiteSocial', 'TelegramSocial', 'TiktokSocial', 'SnapchatSocial']

  const renderIcon = (data: any) => {
    switch (data) {
      case 'FacebookSocial':
        return <BsFacebook />
      case 'TwitterSocial':
        return <AiFillTwitterCircle size={30} />
      case 'InstagramSocial':
        return <BsInstagram />
      case 'DiscordSocial':
        return <SiDiscord />
      // case 'EmailSocial':
      //   return <AiOutlineMail />
      case 'WebsiteSocial':
        return <BsGlobe />
      case 'TelegramSocial':
        return <ImTelegram />
      case 'TiktokSocial':
        return <FaTiktok />
      // case 'AddressSocial':
      //   return <FaRegAddressCard />
      // case 'FaxSocial':
      //     return <FaFax />
      // case 'MobileSocial':
      //   return <CiMobile3 />
      // case 'PhoneSocial':
      //   return <FiPhoneCall />
      case 'SnapchatSocial':
        return <FaSnapchat />
      default:
        return
    }
  }

  const renderItem = (item: SocialType, index: number) => {
    // console.log({ item })
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <div className="flex-shrink-0 w-5 ">
          <img src={item.icon} alt="" />
        </div>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  const listTwo = Object.keys(socialsList)
  .filter((item: string) => acceptedAppKeys.includes(socialsList[item].appKey))
  .map((item: any) => (
    
    // <>{console.log(socialsList[item].appValue)}</>
    socialsList[item]?.appValue && socialsList[item]?.appValue.length > 1 &&
    <a
      href={addHttps(socialsList[item].appValue)}
      className={`${itemClass}`}
      key={Math.random()}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex-shrink-0 w-5 ">
        {renderIcon(socialsList[item].appKey)}
      </div>
    </a>
  ))

  const list = Object.keys(socialsList)
  .filter((item: string) => acceptedAppKeys.includes(socialsList[item].appKey))
  .map((item: any, index: number) => (
    socialsList[item]?.appValue && socialsList[item]?.appValue.length > 1 &&
    <a
      href={addHttps(socialsList[item].appValue)}
      className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
      key={index}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex-shrink-0 w-5 ">
        {renderIcon(socialsList[item].appKey)}
      </div>
    </a>
  ))

  // console.log({socialsList})

  return (
    <React.Fragment>
      {!isAuthorType ?
        <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
          {list}
        </div>
        :
        <nav
          className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300`}
          data-nc-id="SocialsList"
        >
          {listTwo}
        </nav>
      }
    </React.Fragment>
  );
};

export default SocialsList1;
