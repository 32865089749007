import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import { useProductApi } from "_common/hooks/api/productApiHook";
import Radio from "shared/Radio/Radio";
import { BiCategory } from "react-icons/bi";

// DEMO DATA
const typeOfProperty = [
  {
    name: "On Auction",
    description: "Items being auctioned",
    checked: true,
  },
  {
    name: "Buy now",
    description: "Items being auctioned",
    checked: true,
  },
  {
    name: "New",
    description: "Items being auctioned",
  },
  {
    name: "Has Offers",
    description: "Items being auctioned",
    checked: true,
  },
];

export interface PropertyTypeSelectProps {
  onChange?: (data: any) => void;
  fieldClassName?: string;
  setCategoryInputValue: any;
  categoryInputValue: any;
}

const PropertyTypeSelect: FC<PropertyTypeSelectProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  setCategoryInputValue,
  categoryInputValue,
}) => {

  const productApi = useProductApi()

  const [categories, setCategories] = useState<any>(null);
  const [filterCategory, setFilterCategory] = useState<any>([]);

  const getCategories = () => {
    let params: any = {
      usePaging: false,
      sortingBy: 'categoryName',
      sortingOrder: 'asc',
      parentCategoryID: 0
    }
    productApi.getCategories(params, (message: string, resp: any) => {
      if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('categorydm') && resp.categorydm.length > 0) {
        setCategories(resp.categorydm);
      }
    }, (message: string) => {
      console.log(message);
    });
  }

  useEffect(() => {
    if (categories) {
      let options: any = [];
      categories.map((col: any, i: any) => { options.push({ name: col.categoryName, id: col.categoryID },) });
      setFilterCategory(options)
    }
  }, [categories]);

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <Popover className="flex relative flex-1">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex text-left flex-1 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
              }`}
            // PHẦN LOCATION-INPUT KHÔNG BẮT ĐƯỢC EVEN-CLICK KHI CLICK VÀO NÚT NÀY, NÊN CẦN CHẠY HACK NÀY
            onClick={() => {
              if (window.innerWidth >= 1024) {
                document.querySelector("html")?.click();
              }
            }}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <BiCategory className="h-2/4 w-6" />
            </div>
            <div className="flex-grow">
              {categoryInputValue && filterCategory && categoryInputValue.length > 0 ? filterCategory.map((item: any, index: number) => (
                <span className="block xl:text-lg font-semibold min-w-[130px]" key={index}>
                  {item.id == categoryInputValue ? item.name : ""}
                </span>
              ))
                :
                <>
                  <span className="block xl:text-lg font-semibold">Category</span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                    Type of category
                  </span>
                </>
              }
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="">
                <div className="relative flex flex-col space-y-5 cstm-tab-filter-5 cstm-scrollbar">
                  {filterCategory && filterCategory.map((item: any, index: number) => (
                    <div key={index} className="">
                      <Radio
                        id={item.id}
                        name="radioNameSort"
                        label={item.name}
                        defaultChecked={+categoryInputValue === item.id}
                        onChange={(e) => { setCategoryInputValue(e) }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PropertyTypeSelect;
