import React from "react";
import NcImage from "shared/NcImage/NcImage";
import FloatSearchBox from './FloatSearchBox'

export interface props {
    title: string;
    onSearch: any;
    searchKey?:string;
    banner?: string;
}

const PageHeaderWithSearch: React.FC<props> = ({title, onSearch, searchKey="", banner}) => {
  return (
        <React.Fragment>
            <div className={`nc-HeadBackgroundCommon h-24 2xl:h-28 pt-6 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `} >
                {banner && <NcImage containerClassName="absolute inset-0" src={banner} className="object-cover w-full h-full" />}                
                <h1 className={"text-2xl sm:text-3xl lg:text-4xl font-semibold text-center relative"}>{title}</h1>
            </div>
            <FloatSearchBox onSearch={onSearch} searchKey={searchKey} />
        </React.Fragment>
    );
};

export default PageHeaderWithSearch;