import React from "react";
import { MdDelete } from 'react-icons/md';
export interface ItemTypeImageIconProps {
  className?: string;
}

const ItemTypeImageIcon: React.FC<ItemTypeImageIconProps> = ({
  className = "w-8 h-8 md:w-10 md:h-10",
}) => {
  return (
    <div
      className={`bg-indigo-500 flex items-center justify-center rounded-full text-white ${className}`}

    >
      <MdDelete/>

    </div>
  );
};

export default ItemTypeImageIcon;
