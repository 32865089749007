import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus, useChangePasswordHook } from '../_common/hooks/authHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { URLS } from './index';
import { useStoreSettingsSelector } from '_common/hooks/selectors/storeSelector';
const OpenRoutes = () => {
    const isAuth = useAuthStatus();
    const user: any = useUserSelector();
    const changePassword = useChangePasswordHook();
    const settings = useStoreSettingsSelector();
    const published:boolean = !!settings.config.isPublished;
    return (
        published?(isAuth?(changePassword?<Navigate to={URLS.RESET_PASSWORD} />:<Navigate to={URLS.HOME} />):<Outlet/>):<Navigate to="/unpublished" />
    )
}

export default  OpenRoutes;