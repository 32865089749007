import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useMetaGalleryApi() {

  const callApi = useApiCall()

  const getMetaGallery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.META_GALLERY.GET_META_GALLERY, data, onSuccess, onError);
  }

  const addMetaGallery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.META_GALLERY.ADD_META_GALLERY, data, onSuccess, onError);
  }
  const deleteMetaGallery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.META_GALLERY.DELETE_META_GALLERY, data, onSuccess, onError);
  }


  return {
    getMetaGallery,
    addMetaGallery,
    deleteMetaGallery
  }
}