import CardLarge from "../components/Nft/CardLarge";
import TimeCountDown from "components/CardLarge1/TimeCountDown";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import LikeButton from "components/LikeButton";
import Prices from "../components/Prices";
import VerifyIcon from "components/VerifyIcon";
import { nftsLargeImgs } from "contains/fakeData";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";

export interface SectionLargeSliderProps {
  className?: string;
  products?: any
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  className = "",
  products
}) => {
  const [indexActive, setIndexActive] = useState(0);

  const handleClickNext = () => {
    setIndexActive((state) => {
      if ( products.length && state >= (products.length - 1) ) {
        return 0;
      } else if ( products.length === 0 ) {
        return 0;
      } else {
        return state + 1;
      }
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (products.length && state === 0) {
        return products.length - 1;
      } else if ( products.length === 0 ) {
        return 0;
      } else {
        return state - 1;
      }
    });
  };

  const getPrice = (data: any) => {
    if (data.productPrice.length) {
      let activePrice = data.productPrice.filter((price: any) => price.isActive == 1);
      return activePrice.length ? activePrice[0].cryptoStartPrice : 'NA'
    } else {
      return 'NA';
    }

  }

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {products.map((product: any, index: number) =>
        indexActive === index ? (
          <CardLarge
            key={index}
            isShowing
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
            item={product}
          />
        ) : null
      )}
    </div>
  );
};

export default SectionLargeSlider;
