import FormItem from 'components/FormItem'
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import InputMask from 'react-input-mask'
import { useCreditCardValidator, images } from 'react-creditcard-validator'
import { AiOutlineEye } from 'react-icons/ai'
import { BsEyeSlash } from 'react-icons/bs'
import { useUserSelector } from '_common/hooks/selectors/userSelector'
import  Checkbox  from 'shared/Checkbox/nativeCheckbox'
import { useCheckoutApi } from '_common/hooks/api/checkoutApi'
import Select from 'react-select'
import { useUserApi } from '_common/hooks/api/UserApiHook'


export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    sizeClass?: string;
    fontClass?: string;
    rounded?: string;
    placeOrder?: any
    totalPayableAmount?: number;
    showCartPrice?: any;
    coupon?: any;
    countries?: any;
}

const CardPayment = ({
    className = "block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800",
    sizeClass = "h-11 px-4 py-3",
    fontClass = "text-sm font-normal",
    rounded = "rounded-lg",
    placeOrder,
    totalPayableAmount,
    showCartPrice,
    coupon,
    countries
}: InputProps
) => {
    const user: any = useUserSelector();
    const userApi = useUserApi();
    const checkoutApi = useCheckoutApi();
    const { register, handleSubmit, getValues, setValue, formState: { errors }, control } = useForm()

    const {
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getCardImageProps,
        meta: { erroredInputs }
    } = useCreditCardValidator();

    const [dtErrMsg, setDtErrMsg] = useState<string>('')
    const [view, setView] = useState<boolean>(false);
    const [myCards, setMyCards] = useState<any>(null);
    const [selectedCard, setSelectedCard] =useState<any>(null);
    const [userdetails, setUserdetails] = useState<any>(null);


    const collectLoggedinUserInfo = () => {
        userApi.getUserProfile({ customerID: user.userID }, (message: string, resp: any) => {
			if (resp.customers.length) {
				setUserdetails(resp.customers[0]);
                const xuser = resp.customers[0];
                console.log("X User", xuser);
                setValue('addressLine1', xuser.shippingAddress1);
                setValue('addressLine2', xuser.shippingAddress2);
                if (countries && Array.isArray(countries) && countries.length > 0) {
                    console.log("Country", countries.find((o:any) => o.value === xuser.shippingCountry));
                    setValue('country', countries.find((o:any) => o.value === xuser.shippingCountry));
                }
                //setValue('country', xuser[0].shippingCountry);
                setValue('state', xuser.shippingState);
                setValue('city', xuser.shippingCity);
                setValue('zipCode', xuser.shippingZIPCode);                
			}
		}, (message: string) => {
			console.log(message);
		})
    }

    const getMyCards = () =>{
		checkoutApi.getCards({},(message: any, resp: any)=>{
            if(resp.cards.data.length > 0){
                setMyCards(resp.cards.data)
            } else {
                setMyCards(null)
            }
            
		},(message: any)=>{});
	}

    const validateEmail = (email:string) => {
		return String(email)
			.toLowerCase()
			.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	};



	const addCard = (card:any) =>{
        return new Promise((resolve:any, reject:any) =>{
            checkoutApi.addCard({
                "email": (validateEmail(user.userName))?user.userName:'',
                "name": [user.firstName,user.lastName].join(' '),
                "creditCard": card,
                "customerId": user.userID
              },(message: any, resp: any)=>{
                resolve();
            },(message: any)=>{resolve()});
        });
	}

    const cardSubmit = (data: any) => {
        const checkformdata = Object.assign({}, JSON.parse(JSON.stringify(getValues())));
        //console.log("getValues", getValues());
        //return false;
        if (+(data.expiryDate.split('/')[0]) > 12) {
            setDtErrMsg("Month should be between 01 - 12")
            return
        }
        let params: any = {
            "addressLine1": checkformdata.addressLine1,
            "addressLine2": checkformdata.addressLine2,
            "country": checkformdata.country.value,
            "state": checkformdata.state,
            "city": checkformdata.city,
            "postalCode": checkformdata.zipCode,
            "addThisAddressAsCustomerShipping": true,
            "isCard": true,
            "cardNo": data?.cardNumber.replace(/ /g,''),
            "nameOnCard": data?.name,
            "cvv": data?.cvvNumber,
            "expiry": `${data.expiryDate.split('/')[0]}/20${data.expiryDate.split('/')[1]}`,
            "isSaveCard": false,
            "stripeCustomerID": "",
            "stripeCardID": ""
        };
        setDtErrMsg("");

        console.log ('card data =>',params);

        if (data.savecard) {
            addCard({
                "name": data?.name,
                "cardNumber": data?.cardNumber.replace(/ /g, ''),
                "expirationYear": `20${data.expiryDate.split('/')[1]}`,
                "expirationMonth": `${data.expiryDate.split('/')[0]}`,
                "cvc": data?.cvvNumber
            }).then(() => { placeOrder('card', params) })
        } else {
            placeOrder('card', params);
        }




    }

    const payBySelectedCard = () => {
        const checkformdata = Object.assign({}, JSON.parse(JSON.stringify(getValues())));
        //console.log("getValues", getValues());
        //return false;

        let params: any = {
            "addressLine1": checkformdata.addressLine1,
            "addressLine2": checkformdata.addressLine2,
            "country": checkformdata.country.value,
            "state": checkformdata.state,
            "city": checkformdata.city,
            "postalCode": checkformdata.zipCode,
            "addThisAddressAsCustomerShipping": true,
            "isCard": true,
            "cardNo": '',
            "nameOnCard": '',
            "cvv": '',
            "expiry": '',
            "isSaveCard": true,
            "stripeCustomerID": selectedCard.customer,
            "stripeCardID": selectedCard.id
        };
        console.log ('card data =>',params);
        setDtErrMsg("")
        placeOrder('card', params)
    }

    useEffect(()=>{
		getMyCards();        
	},[])
    
    useEffect(() => {
        collectLoggedinUserInfo();
        console.log("LoggedIn User", user);
    }, [countries])

    useEffect(()=>{
		if(myCards){
            setSelectedCard(myCards[0])
        } 
	},[myCards])


    return (
        <React.Fragment>
            <div className={`shipping-address-wrapper`}>
                <FormItem label="Billing Address 1" isRequired={true}>
                    <Input 
                        className={`mt-1.5`} 
                        {...register(`addressLine1`, { required: true })}
                    />
                    {errors?.addressLine1 ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                        {errors.addressLine1.type === "required" ? 'Address 1 shouldn\'t be blank.' : null}
                    </div> : null}
                </FormItem>
                <FormItem label="Billing Address 2" isRequired={true}>
                    <Input 
                        className={`mt-1.5`} 
                        {...register(`addressLine2`, { required: true })}
                    />
                    {errors?.addressLine2 ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                        {errors.addressLine2.type === "required" ? 'Address 2 shouldn\'t be blank.' : null}
                    </div> : null}
                </FormItem>
                <FormItem label="Billing Country" isRequired={true}>
                    <Controller
                        name={"country"}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Select
                                className={`mt-1 rounded-2xl`}
                                options={countries}
                                placeholder={"Select Country"}
                                onChange={onChange}
                                value={value}
                                defaultValue={value}
                            />
                        )}
                    />
                    {errors?.country ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                        {errors.country.type === "required" ? 'Country shouldn\'t be blank.' : null}
                    </div> : null}                    
                </FormItem>
                <FormItem label="Billing State" isRequired={true}>
                    <Input 
                        className={`mt-1.5`} 
                        {...register(`state`, { required: true })}
                    />
                    {errors?.state ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                        {errors.state.type === "required" ? 'State shouldn\'t be blank.' : null}
                    </div> : null}
                </FormItem>
                <FormItem label="Billing City" isRequired={true}>
                    <Input 
                        className={`mt-1.5`} 
                        {...register(`city`, { required: true })}
                    />
                    {errors?.city ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                        {errors.city.type === "required" ? 'City shouldn\'t be blank.' : null}
                    </div> : null}
                </FormItem>
                <FormItem label="Billing Zip Code" className={`mb-4`}>
                    <Input className="mt-1.5"
                        {...register(`zipCode`, { required: true })}
                    />
                    {errors?.zipCode ? <div className="invalid-feedback text-red-600 mt-2 text-sm">
                        {errors.zipCode.type === "required" ? 'Zip code shouldn\'t be blank.' : null}
                    </div> : null}
                </FormItem>
            </div>

            {myCards?
            <p className="text-lg font-semibold mb-3">Pay With</p>
            :null}
            {myCards?.map((card: any, i: any) => (
                <label className="border cursor-pointer flex gap-5 items-center mb-3 px-2 py-2 rounded-lg" key={i} htmlFor={card.id} >
                    <input
                        checked={selectedCard?.id == card.id}
                        onClick={()=>{setSelectedCard(card)}}
                        id={card.id}
                        name="card"
                        type="radio"
                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded-full border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    />
                    <p className="font-medium">xxxx xxxx xxxx <span className="font-medium">{card.last4}</span></p>
                </label>
            ))}
      

            {myCards ?
                    <label className="cursor-pointer flex gap-5 items-center mb-3 px-2 py-2 border rounded-lg" htmlFor={`new_card`} >
                        <input
                            onClick={() => { setSelectedCard(null) }}
                            id={`new_card`}
                            name="card"
                            type="radio"
                            className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded-full border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                        />
                        <p className="font-medium">New Card</p>
                    </label>
                    : null}

            

            {(selectedCard == null) ?
                <form onSubmit={handleSubmit(cardSubmit)} autoComplete="off" >                    

                    <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-10">
                            <FormItem label="Card Number" isRequired={true}>
                                <Controller
                                    name={"cardNumber"}
                                    control={control}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <InputMask
                                            mask="9999 9999 9999 9999"
                                            placeholder="4242 4242 4242 4242"
                                            type="text"
                                            onChange={onChange}
                                            value={value}
                                            onBlur={onBlur}
                                            className={`${className} ${rounded} ${fontClass} ${sizeClass} ${className}`}
                                            required={true}
                                        />
                                    )}
                                />
                            </FormItem>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                            <FormItem label="Expiray Date" isRequired={true}>
                                <Controller
                                    name={"expiryDate"}
                                    control={control}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <InputMask
                                            mask="99/99"
                                            placeholder="MM/YY"
                                            type="text"
                                            autoComplete="new-password"
                                            onChange={onChange}
                                            value={value}
                                            onBlur={onBlur}
                                            className={`${className} ${rounded} ${fontClass} ${sizeClass} ${className}`}
                                            required={true}
                                        />
                                    )}
                                />
                                <small className='text-red-600'>{dtErrMsg.length > 0 && dtErrMsg}</small>
                            </FormItem>
                            <FormItem label="CVV" isRequired={true} className="relative">
                                <Controller
                                    name={"cvvNumber"}
                                    control={control}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <InputMask
                                            mask="999"
                                            placeholder="xxx"
                                            autoComplete="new-password"
                                            onChange={onChange}
                                            value={value}
                                            onBlur={onBlur}
                                            type={view ? "text" : "password"}
                                            className={`${className} ${rounded} ${fontClass} ${sizeClass} ${className}`}
                                            required={true}
                                        />
                                    )}
                                />
                                {view ?
                                    <span className='absolute mt-1 right-2 top-10 cursor-pointer' onClick={() => setView(!view)}><AiOutlineEye /></span>
                                    :
                                    <span className='absolute mt-1 right-2 top-10 cursor-pointer' onClick={() => setView(!view)}><BsEyeSlash /></span>
                                }
                            </FormItem>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-1 gap-10 pb-5">
                            <FormItem label="Name On Card" isRequired={true}>
                                <Input type="text" placeholder={"Jhon Doe"} {...register('name', { required: true })} />
                            </FormItem>
                        </div>
                        {/* <ButtonPrimary>PAY</ButtonPrimary> */}
                    </div>

                    <Checkbox label="Save this card" defaultChecked={true}  {...register('savecard', { required: false })} />

                    <div className="flex mt-5 justify-center">
                        {totalPayableAmount != 0 && (<ButtonPrimary type="submit">Pay &nbsp; {showCartPrice(totalPayableAmount)}</ButtonPrimary>)}
                    </div>
                </form>
                : <div className="flex mt-5 justify-center">
                    {totalPayableAmount != 0 && (<ButtonPrimary type="button" onClick={payBySelectedCard}>Pay &nbsp; {showCartPrice(totalPayableAmount)}</ButtonPrimary>)}
                </div>}


        </React.Fragment>
    )
}

export default CardPayment