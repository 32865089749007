import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";

export interface PageSetforgottenpasswordProps {
    className?: string;
}



const PageSetforgottenpassword: FC<PageSetforgottenpasswordProps> = ({ className = "" }) => {

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();    
    const { showLoader, hideLoader } = useAppLoader();
    const userApi = useUserApi();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const onResetPasswordSubmit = (data: any) => {
        const inputparams = Object.assign({}, JSON.parse(JSON.stringify(data)));
        console.log("Resetpassword", inputparams);        
        showLoader();
        userApi.setforgottenpassword(inputparams, (message: any, resp: any) => {
            hideLoader();
            console.log("Api response", resp);            
            if (resp.code === 200 && resp.successful === true) {  
                toast.success("Password updated successfully.");
                navigate('/login');
            } else {
                toast.error(resp.message);
            }            
        }, (message: string) => {
            hideLoader();
            console.log(message);
            toast.error(message);
        });
    };    

    useEffect(() => {
        reset({
            emailId: searchParams.get('email'),
            recoveryKey: searchParams.get('token')
        })
    }, [])


    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Reset Password
                </h2>
                <div className="max-w-md mx-auto space-y-6">
                    {/* FORM */}                    
                    <form className="grid grid-cols-1 gap-6" method="post" onSubmit={handleSubmit(onResetPasswordSubmit)} autoComplete={'off'}>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            New Password                        
                            </span>
                            <Input type="password" className="mt-1" autoComplete="new-password" placeholder="Password"
                            {...register("password", { 
                                required: true,
                                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                minLength: 8                                
                            })}
                            />
                            {errors?.password ? <div className="invalid-feedback text-red-600">
                            {errors.password.type === "required" ? 'Password shouldn\'t be blank' : null}
                            {errors.password.type === "minLength" ? 'Password length must 8 character long.' : null}
                            {errors.password.type === "pattern" ? 'Password must be at least one uppercase, lowercase, number and special character.' : null}
                            </div> : null}
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Confirm new password                        
                            </span>
                            <Input type="password" className="mt-1" autoComplete="new-password" placeholder="Retype password"
                            {...register("newPassword", { 
                                required: true,
                                validate: (val: string) => {
                                    if (watch('password') != val) {
                                        return "Confirm Password is not matched with the New password.";
                                    }
                                }
                            })}
                            />
                            {errors?.newPassword? (
                                <div className="invalid-feedback text-red-600">
                                    <>
                                    {errors.newPassword.type === "required" ? 'Confirm password shouldn\'t be blank' : null}
                                    {errors.newPassword.message? errors.newPassword.message: null}        
                                    </>
                                </div>
                            ): null}
                            
                        </label>          
                                      
                        <ButtonPrimary type="submit">Submit</ButtonPrimary>
                        
                    </form>   
                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        <Link to="/login" className="text-green-600">
                            Back to Login
                        </Link>
                    </span>                 
                </div>
            </div>
        </div>
    );
};

export default PageSetforgottenpassword;
