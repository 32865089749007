import moment from "moment";
import ethereum from 'images/priceIcons/ethereum.png';
import polygon from 'images/priceIcons/polygon.png';
import binance from 'images/priceIcons/binance.png';
import avalanche from 'images/priceIcons/avalanche.png';
import inr from 'images/priceIcons/rupee-icon.png';
import dollar from 'images/priceIcons/doller-icon.png';
import bd from 'images/priceIcons/bd-icon.png';
import unknown from 'images/priceIcons/unknown.png';

export function usNumber(e: any) {
    let n = e.target.value.replace(/[^\d]/g, '');
    var x = n.match(/(\d{3})(\d*)/);
    var y = n.match(/(\d{3})(\d{3})(\d*)/);
    var z = n.match(/(\d{3})(\d{3})(\d{4})/);
    if (z) {
        e.target.value = '(' + z[1] + ') ' + z[2] + '-' + z[3];
    } else if (y) {
        e.target.value = '(' + y[1] + ') ' + y[2] + ((y[3] != '') ? ('-' + y[3]) : '');
    } else if (x && x[2] != '') {
        e.target.value = '(' + x[1] + ') ' + x[2];
    } else {
        e.target.value = n;
    }

}

export function creditCard(e: any) {
    let n = e.target.value.replace(/\D/g, '');
    var a = n.match(/(\d{4})(\d*)/);
    var b = n.match(/(\d{4})(\d{4})(\d*)/);
    var c = n.match(/(\d{4})(\d{4})(\d{4})(\d*)/);
    var d = n.match(/(\d{4})(\d{4})(\d{4})(\d{4})(\d*)/);
    if (d) {
        e.target.value = d[1] + ' ' + d[2] + ' ' + d[3] + ' ' + d[4];
    } else if (c) {
        e.target.value = c[1] + ' ' + c[2] + ' ' + c[3] + ((c[4] != '') ? (' ' + c[4]) : '');
    } else if (b) {
        e.target.value = b[1] + ' ' + b[2] + ((b[3] != '') ? (' ' + b[3]) : '');
    } else if (a && a[2] != '') {
        e.target.value = a[1] + ' ' + a[2];
    }

}

export function creditCardExp(e: any) {
    let n = e.target.value.replace(/\D/g, '');
    var x = n.match(/(\d{2})(\d*)/);
    var y = n.match(/(\d{2})(\d{2})(\d*)/);
    if (y) {
        e.target.value = y[1] > 12 ? 12 : y[1] + ' / ' + y[2]
    } else if (x && x[2] != '') {
        e.target.value = x[1] > 12 ? 12 : x[1] + ' / ' + x[2];
    }
}

export function CheckNumber(e: any) {
    let n: any = e.target.value.replace(/^0+/, '').replace(/[^\d.]/g, '')
    let min: number = parseFloat(e.target.getAttribute('min'));
    let max: number = parseFloat(e.target.getAttribute('max'));
    if (n == '') { n = 0 }
    if (min && parseFloat(n) < min) {
        n = min
    }

    if (max && parseFloat(n) > max) {
        n = max
    }
    e.target.value = n;
}

export function checkImageBase64Code(url: any) {
    if (url.search('data:image/') == 0) {
        return url;
    } else {

        return 'data:image/jpeg;base64,' + url
    }

}


export function utcToLocal(str: any) {
    return moment.utc(str).local().toDate();
}

export function localToUtc(str: any) {
    return moment.utc(str).format()
}


function getKeyValue(object: any, keys: any[]) {

    let value: any = object;
    keys.map((key: any) => {
        if (value[key] !== undefined) {
            value = value[key]
        } else {
            value = null;
        }
    });
    return value;
}

export function getSelectedOption(options: any[], optionKey: any[], matchKey: any) {
    let selected: any = null;
    options.map((opt: any) => {
        if (getKeyValue(opt, optionKey) == matchKey) {
            selected = opt;
        }
    });

    return selected;
}


export function getPriceIconUrl(network: string) {
    let imageUrl = '';
    switch (network) {
        case 'ethereum':
            imageUrl = ethereum;
            break;
        case 'polygon':
            imageUrl = polygon;
            break;
        case 'binance':
            imageUrl = binance;
            break;
        case 'avalanche':
            imageUrl = avalanche;
            break;
        case 'Indian Rupee':
            imageUrl = inr;
            break;
        case 'Indian Rup':
            imageUrl = inr;
            break;
        case 'United States Dollar':
            imageUrl = dollar;
            break;
        case 'Bahraini Dinar':
            imageUrl = bd;
            break;
        default: imageUrl = unknown;
    }
    return imageUrl;
    /*switch(network){
        case 'ethereum' : return ethereum;
        case 'polygon' : return polygon;
        case 'binance' : return binance;
        case 'avalanche' : return avalanche;
        case 'Indian Rupee' : return inr;
        case 'United States Dollar' : return dollar;
        case 'Bahraini Dinar' : return bd;
        default : return unknown;
    }*/
}

export function getPriceFormat(network: string, price: any) {
    if (price == 'NA') {
        return price;
    } else {

        switch (network) {
            case 'ethereum': return (+price).toFixed(6);
            case 'polygon': return (+price).toFixed(6);
            case 'binance': return (+price).toFixed(6);
            case 'avalanche': return (+price).toFixed(6);
            case 'Indian Rupee': return (+price).toFixed(2);
            case 'United States Dollar': return (+price).toFixed(2);
            case 'Bahraini Dinar': return (+price).toFixed(6);
            default: return (+price).toFixed(6);
        }
    }
}

export function addHttps(url: string) {
    if (url.indexOf('http://') === 0) {
        return url.replace('http://', 'https://');
    } else if (url.indexOf('https://') === 0) {
        return url;
    } else {
        return 'https://' + url;
    }
}