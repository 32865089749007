import { useSelector } from 'react-redux'
import { User } from '../../interfaces/models/user'
import { StateExtended } from '../../interfaces/StateExtended'


export function useThemeSelector() {
    const theme: any = useSelector((state: StateExtended) => state.store.theme);
    return theme
}

export function useStoreSelector() {
    const theme: any = useSelector((state: StateExtended) => state.store.details);
    return theme
}

export function useStoreTokenSelector() {
    const theme: any = useSelector((state: StateExtended) => state.store.token);
    return theme
}

export function useDomainSelector() {
    const domain: any = useSelector((state: StateExtended) => state.store.domain)
    return domain
}

export function useStoreSettingsSelector() {
    const settings: any = useSelector((state: StateExtended) => state.store.settings)
    return settings
}
