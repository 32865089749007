import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsImgs } from "contains/fakeData";
import NoDataImage from "images/no-data-small.png";
import ItemTypeImageIcon from "./ItemTypeImageIcon";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ClockIcon } from "@heroicons/react/outline";
import ItemTypeVideoIcon from "./ItemTypeVideoIcon";

interface Props {
  className?: string;
  msg?: string;
}

const NoDataFound: FC<Props> = ({ className = "", msg = "No data found!" }) => {
 
  return (
    <div className={`relative flex flex-col items-center max-w-[240px] ${className}`}   >
      <NcImage
        containerClassName=""
        src={NoDataImage}
        className="object-contain  max-w-[360px] w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
      />
      <p className="text-slate-500 mb-3 mt-3">{msg}</p>
    </div>
  );
};

export default NoDataFound;
