import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import Select from 'react-select';
import { URLS } from "_config";

export interface PageLoginProps {
  className?: string;
}



const PageLoginDefault: FC<PageLoginProps> = ({ className = "" }) => {
  const { register, control, watch, handleSubmit, formState: { errors } } = useForm();
  const [stores, setStores] = useState<any>([]);
  const [marketplaces, setMarketplaces] = useState<any>([]);
  const [loginModel, setLoginModel] = useState<any>(null);
  const [cryptoStatus, setCryptoStatus] = useState<boolean | null>(null);

  const { showLoader, hideLoader } = useAppLoader();

  const userApi = useUserApi();
  const cryptoAddress: any = watch('cryptoAddress');

  const checkCrypto = () => {
    if (cryptoStatus != null) {
      let match = (/^0x[a-fA-F0-9]{40}$/g).test(cryptoAddress);
      setCryptoStatus(match)
    }
  }

  useEffect(() => {
    checkCrypto();
  }, [cryptoAddress])



  const signInWithCryptoAddress = () => {
    let match = (/^0x[a-fA-F0-9]{40}$/g).test(cryptoAddress);
    if (match) {
      showLoader();
      userApi.login({
        cryptoAddress: cryptoAddress,
        isRegistrationUsingAddress: true
      }, (message: any, resp: any) => {
        hideLoader();
        if (resp.code === 200 && resp.successful === true && resp.authenticateDataModel.length > 0) {
          toast.success(resp.message);
        } else {
          toast.error("Failed to authenticate.");
        }
      }, (message: string) => {
        hideLoader();
        toast.error(message);
        //console.log(message);
      });
      userApi.signup({
        "cryptoAddress": cryptoAddress,
        "isRegistrationUsingAddress": true
      }, (message: any, resp: any) => {
        hideLoader();
        console.log('SIGN UP =>', resp)
      }, (message: string) => {
        hideLoader();
        toast.error(message);
        //console.log(message);
      });
      
    } else {
      setCryptoStatus(match);
    }
  }

  const onSubmitLogin = (data: any) => {
    const inputparams = Object.assign({}, JSON.parse(JSON.stringify(data)));
    delete inputparams.cryptoAddress;
    showLoader();
    userApi.login(inputparams, (message: any, resp: any) => {
      hideLoader();
      if (resp.code === 200 && resp.successful === true && resp.authenticateDataModel.length > 0) {
        toast.success(resp.message);
      } else {
        toast.error("Failed to authenticate.");
      }
    }, (message: string) => {
      hideLoader();
      toast.error(message);
      //console.log(message);
    });
  };

  useEffect(() => {

  }, [])


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to={URLS.SIGNUP} className="text-blue-800 dark:text-white">
              Create an account
            </Link>
          </span>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" method="post" onSubmit={handleSubmit(onSubmitLogin)} autoComplete={'off'}>

            {/* <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Crypto Address
              </span>
              <Input type="text" className="mt-1" {...register(`cryptoAddress`, { required: false, pattern: /^0x[a-fA-F0-9]{40}$/g })} />
              {cryptoStatus != null && !cryptoStatus && (
                <p className='text-red-400'>Invalid address!</p>
              )}
            </label>

            <ButtonPrimary type="button" onClick={signInWithCryptoAddress} disabled={cryptoAddress ? false : true}>Sign In</ButtonPrimary>

            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                OR
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div> */}

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="Enter your login email"
                className={"mt-1" + (errors.emailID ? " is-invalid" : "")}
                {...register("emailID", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
              />
              {errors?.emailID ? <div className="invalid-feedback text-red-600">
                {errors.emailID.type === "required" ? 'Email shouldn\'t be blank.' : null}
                {errors.emailID.type === "pattern" ? 'Invalid email address.' : null}
              </div> : null}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to={URLS.FORGOT_PASSWORD} className="text-sm text-blue-800 dark:text-white">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" autoComplete="new-password" placeholder="Enter your login password"
                {...register("password", { required: true })}
              />
              {errors?.password ? <div className="invalid-feedback text-red-600">
                {errors.password.type === "required" ? 'Password shouldn\'t be blank' : null}
              </div> : null}
            </label>
            <ButtonPrimary type="submit">Submit</ButtonPrimary>
          </form>



          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link to={URLS.CONNECT_WALLET} className="text-blue-800 dark:text-white">
              Click here
            </Link>{` `}
            to login via Metamask or any other wallet
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLoginDefault;
