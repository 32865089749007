import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useCollectionApi() {

  const callApi = useApiCall()

  const getCollectionTypes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.GET_COLLECTION_TYPES, data, onSuccess, onError);
  }
  const getCollections = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.GET_COLLECTIONS, data, onSuccess, onError);
  }  

  const deleteCollection = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.DELETE_COLLECTION, data, onSuccess, onError);
  }

  const addUpdateDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.ADD_UPDATE_DETAILS, data, onSuccess, onError);
  }

  const updateSocials = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.UPDATE_SOCIALS, data, onSuccess, onError);
  }
  const updatePresale = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.UPDATE_PRESALE, data, onSuccess, onError);
  }
  const updateGenesis = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.UPDATE_GENESIS, data, onSuccess, onError);
  }
  const assignPayoutGroup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.ASSIGN_PAYOUT_GROUP, data, onSuccess, onError);
  }

  const getMyCollections = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.GET_MY_COLLECTIONS, data, onSuccess, onError);
  }

  const getCollectionfollowunfollow = (data:any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.GET_FOLLOWUNFOLLOW_COLLECTION, data, onSuccess, onError);
  }

  const saveFollow = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.SAVE_FOLLOW, data, onSuccess, onError);
  }

  const saveUnfollow = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.SAVE_UNFOLLOW, data, onSuccess, onError);
  }

  const fetchContractAbi = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.FETCH_CONTRACT_ABI, data, onSuccess, onError);
  }
  const deployContractThroughAPI = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COLLECTION.DEPLOY_CONTRACT, data, onSuccess, onError);
  }

  return {
    getCollectionTypes: getCollectionTypes,
    addUpdateDetails: addUpdateDetails,
    getCollections: getCollections,
    deleteCollection: deleteCollection,
    updateSocials,
    updatePresale,
    updateGenesis,
    assignPayoutGroup,
    getMyCollections: getMyCollections,
    getCollectionfollowunfollow: getCollectionfollowunfollow,
    saveFollow: saveFollow,
    saveUnfollow: saveUnfollow,
    fetchContractAbi,
    deployContractThroughAPI
  }
}