export const getSubDomain = () => {
    var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : null;
    return subdomain
}
export const extractErrorMessage = (error: any, defaultMessage = 'Please try again') => {
    if (typeof error === 'string') {
        return error
    } else {
        try {
            if (error && error.response && error.response.message && error.response.message.length) {
                return error.response.message[0]
            } else if (error && error.data && error.data.errors && Object.keys(error.data.errors).length) {
                return error.data.errors[Object.keys(error.data.errors)[0]];
            } else if (error && error.data && error.data.message) {
                return error.data.message
            } else if (error && error.message) {
                return error.message
            } else if (error && error.clientMessage) {
                return error.clientMessage
            } else {
                return defaultMessage
            }
        } catch (err) {
            return "Unable to handle error";
        }
    }
}