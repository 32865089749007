import { call } from 'redux-saga/effects';
import { API_URL } from '../../_config'
import { CallApi } from './api/callApi';

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}
const asUrlParams = (obj: any) => {
    return '?' + serialize(obj);
}

export function* getCategories(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET_CATEGORIES, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addUpdateCategory(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.ADD_UPDATE_CATEGORY, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteCategory(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.PRODUCT.DELETE_CATEGORY + asUrlParams(data), {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCustomFieldType(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CUSTOMFIELD.GET_TYPE + asUrlParams(data), {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* saveCustomfield(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.CUSTOMFIELD.SAVE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCustomfields(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CUSTOMFIELD.GET + asUrlParams(data), {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteCustomfield(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.CUSTOMFIELD.DELETE + asUrlParams(data), {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getProducts(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET + asUrlParams(data), {}, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* mapProductPayout(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.MAPPRODUCTPAYOUTGROUP, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* publishProducts(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.PUBLISH, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteProducts(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.PRODUCT.DELETE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* featuredProducts(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.FEATURE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getPayoutGroup(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.PRODUCT_GROUP_LIST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* assignRedemptionProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.ASSIGN_REDEMPTION, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteRedemptionProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.PRODUCT.DELETE_REDEMPTION + asUrlParams(data), data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* listRedemptionProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.LIST_REDEMPTION, data, true);
        if (resp.status >= 200 && resp.status < 300 /*&& resp.data*/) {  // comment out due to 204
            if (resp.status == 204) {
                const emptyResp = {
                    code: 204,
                    message: "Success",
                    productRedeem: [],
                    returnID: 0,
                    successful: true,
                    totalRecord: 0
                }
                action && action.callbackSuccess && action.callbackSuccess(emptyResp);
            } else {
                action && action.callbackSuccess && action.callbackSuccess(resp.data);
            }
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* deleteProductPreviewImage(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.DELETE, API_URL.PRODUCT.DELETE_PREVIEW_IMAGE + asUrlParams(data), data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* assignPreviewProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.ASSIGN_PREVIEW_PRODUCT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* updateProductPrice(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.UPDATE_PRODUCT_PRICE, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* addProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.PRODUCT_ADD, data, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* updateProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.PUT, API_URL.PRODUCT.PRODUCT_ADD, data, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getMyProducts(action: any): any {
    try {
        const data = action.payload;
        const isAuth = data.hasOwnProperty('isguest') ? data.isguest : true;
         // !(data.hasOwnProperty('isguest') && data.isguest == '1');

        if ( data.hasOwnProperty('isguest') ) {
            delete data.isguest
        }

        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET_MY_PRODUCTS, data, true, isAuth);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getNftsGuest(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.NFTS_GUEST, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getMyProductsActual(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET + asUrlParams(data), {}, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getOwnerByNft(action: any): any {
    try {
        const data = action.payload;
        const isAuth = data.hasOwnProperty('isguest') ? data.isguest : true;
         //!(data.hasOwnProperty('isguest') && data.isguest == '1');

        if ( data.hasOwnProperty('isguest') ) {
            delete data.isguest
        }

        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET_OWNER_BY_NFT, data, true, isAuth);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getUnlockableContentNft(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET_UNLOCKABLE_CONTENT_NFT, data, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getUnlockableContentProduct(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.GET_UNLOCKABLE_CONTENT_PRODUCT, data, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* nftTransferWithCustodial(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.TRANSFER_WITH_CUSTODIAL_WALLET, data, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* nftTransferWithMetamask(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.PRODUCT.TRANSFER_WITH_METAMASK, data, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* downloadNFTs(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.PRODUCT.DOWNLOADNFT + asUrlParams(data), {}, true, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
