import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import React, { useEffect, useState } from "react";
import { useStoreSettingsSelector } from "_common/hooks/selectors/storeSelector";
import CookieConsent from "react-cookie-consent";
import { useCmsApi } from "_common/hooks/api/cmsApi";
import { useProductApi } from "_common/hooks/api/productApiHook";


const Footer: React.FC = () => {
  const settings = useStoreSettingsSelector();
  const cmsApi = useCmsApi();
  const productApi = useProductApi();

  const [cmsList, setCmsList] = useState<any>(null)
  const [categories, setCategories] = useState<any>(null)

  const getCmsList = () => {
    cmsApi.getCmsList({},
      (message: string, resp: any) => {
        let tempArr: any = resp?.cmSdm || []
        setCmsList(tempArr.filter((item: any) => item.header_Footer == 2 || item.header_Footer == 3))
      },
      (message: string) => {
        console.log(message)
      })
  }

  const getCategories = () => {
    let params: any = {
      usePaging: false,
      isActive: 1,
      sortingBy: 'categoryName',
      sortingOrder: 'asc',
      parentCategoryID: 0
    }
    productApi.getCategories(params, (message: string, resp: any) => {
      if (resp.code === 200 && resp.successful === true && resp.hasOwnProperty('categorydm') && resp.categorydm.length > 0) {
        setCategories(resp.categorydm);
      }
    }, (message: string) => {
      console.log(message);
    });
  }

  useEffect(() => {
    getCmsList()
    getCategories()
  }, []);

  return (
    <div className="nc-Footer relative py-20 lg:pt-10 pb-0 border-t border-neutral-200 dark:border-neutral-700 mt-10">
      {/*lg:pb-28*/}
      <div className="container relative flex flex-col gap-10 lg:flex-row">
        <div className="text-sm lg:w-2/5">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            {settings?.settings?.HomeSettings?.isShowBottomHome?.appValue == 'true' && (
              <p className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                {settings?.settings?.HomeSettings?.StoreBottomTextHome?.appValue}
              </p>
            )}
          </div>
        </div>

        {categories && categories.length > 0 ?
          <div className="text-sm flex-grow">
            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
              Categories
            </h2>
            <ul className="mt-5 gap-4 grid grid-cols-2">
              {categories.map((item: any, index: number) => (
                <li key={index}>
                  <a
                    key={index}
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    href={`/store?categoryID=${item?.categoryID}&collectionID=&blockchainNetworkID=&searchText=`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item?.categoryName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          : null
        }

        {cmsList && cmsList.length > 0 ?
          <div className="text-sm flex-grow">
            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
              Company
            </h2>
            <ul className="mt-5 space-y-4">
              {cmsList.map((item: any, index: number) => (
                <li key={index}>
                  <a
                    key={index}
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    href={`/page/${item?.cmsid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item?.title}
                  </a>
                </li>
              ))}
              <li>
                  <a
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    href={window.location.origin+"/contact-us"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
            </ul>
          </div>
          : null
        }

        <div className="text-sm flex-grow lg:w-24">
          <div className="col-span-2 md:col-span-1">
            <h2 className="font-semibold text-neutral-700 dark:text-neutral-200 mb-4">Social Media</h2>
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex flex-wrap gap-5"
              socialsList={settings?.settings?.SocialMediaLink}
            />
          </div>
        </div>
      </div>

      {settings?.settings?.HomeSettings?.isShowCopyrightHome?.appValue == 'true' && (
        <div className={`bg-neutral-100/70 mt-6 text-center text-neutral-500 text-sm p-3 dark:bg-neutral-800 dark:text-neutral-300`} dangerouslySetInnerHTML={{ __html: settings?.settings?.HomeSettings?.StoreCopyrightTextHome?.appValue }}></div>
      )}

      {settings?.settings?.CookieConsent?.IsEnableConsentCookie?.appValue == 'true' && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="cookieConsent"
          buttonClasses="cookie-accept-btn"
          declineButtonClasses="cookie-decline-btn"
          buttonWrapperClasses="btn-wrapper"
          enableDeclineButton flipButtons
          expires={1}
        >
          <div>{settings?.settings?.CookieConsent?.ConsentTextCookie?.appValue}</div>
          <a href={settings?.settings?.CookieConsent?.MoreInfoLinkCookie?.appValue} target="blank" >{settings?.settings?.CookieConsent?.MoreInfoLinkCookie?.appValue}</a>
        </CookieConsent>
      )}
    </div>
  );
};

export default Footer;
