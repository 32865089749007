import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { HiOutlineEyeOff } from "react-icons/hi";
import { BiLink } from "react-icons/bi";

export interface NftCardProps {
  className?: string;
  nft: any;
}

const NftCard: FC<NftCardProps> = ({ className = "", nft = {}}) => {

  const [showBlurFile, setShowBlurFile] = useState<boolean>(false)
  const [productBlurLimit, setProductBlurLimit] = useState<number | null>(null);

  return (
        <div className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md`}>
            <div className={"relative flex-shrink-0" + (showBlurFile ? ' spam-product-card' : '')}>
                <div>
                  {(() => {
                    if (!nft?.removedBlur && (productBlurLimit != null && Math.abs(productBlurLimit) <= nft['reportedSpamCount'])) {
                      return (
                        <div className="spam-overlay spam-overlay-rounded-full">
                          <HiOutlineEyeOff />
                          <p className="text-sm mb-4">Inappropriate content.</p>
                          <ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={() => {
                            nft['removedBlur'] = true;
                          }}>view</ButtonSecondary>
                        </div>
                      )
                    }
                  })()}
                  <Link className="block" to={"/nft/" + nft?.nftid} >
                    <NcImage
                      containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
                      src={nft?.nftThumbUrl}
                      className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                    />
                  </Link>
                </div>
                {/* 
                {Math.random() > 0.5 ? (
                  <ItemTypeVideoIcon className="absolute top-3 left-3 !w-9 !h-9" />
                ) : (
                  <ItemTypeImageIcon className="absolute top-3 left-3 !w-9 !h-9" />
                )} 
                */}
                {/*   
                <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10 !h-9" /> 
                */}
                {/*
                <div className="absolute bottom-3 inset-x-3 flex justify-end">
                  {product?.isActive == 1 ? (<span className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-white bg-red-600 relative">Hold</span>) : (<span className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-900 bg-green-100  relative">Active</span>)}
                </div>
                */}
            </div>
            <div className="p-4 py-4 space-y-3">
              <Link to={"/collection/" + nft?.collectionID} className="block">
                <div className="flex justify-between">
                  <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative">
                    {nft?.collectionName}
                  </span>
                </div>
              </Link>
              <Link to={"/nft/" + nft?.nftid} className={`block`}>
                <h2 className={`text-lg font-medium mt-0`}>
                  {nft?.name}
                </h2>
                {/* <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div> */}
                {
                  nft.collectionNetwork && nft.collectionNetwork.chainName && nft.collectionNetwork.chainName && (
                    <div className="flex justify-between items-end mt-3">
                      <div className={`flex items-center`}>
                        <BiLink className="w-8 h-8 text-neutral-500 dark:text-neutral-400" style={{marginLeft: '-4px'}} />
                        <div className={`ml-1.5`}>
                          <div className={`text-xs text-neutral-500 dark:text-neutral-400`}>Chain Name</div>
                          <span className={`text-sm font-medium`}>
                            {nft.collectionNetwork[0].toUpperCase() + nft.collectionNetwork.substring(1)}
                          </span>
                        </div>
                      </div>
                    </div> 
                  ) || ''
                }
                {/* <div className="flex justify-between items-end mt-3">
                  <div className={`flex items-center`}>
                    <BiLink className="w-8 h-8 text-neutral-500 dark:text-neutral-400" style={{marginLeft: '-4px'}} />
                    <div className={`ml-1.5`}>
                      <div className={`text-xs text-neutral-500 dark:text-neutral-400`}>Chain Name</div>
                      <span className={`text-sm font-medium`}>
                        {(() => {
                          if (product.collectionNetwork && product.collectionNetwork.chainName && product.collectionNetwork.chainName) {
                            return (
                              (product.collectionNetwork[0].toUpperCase() + product.collectionNetwork.substring(1))
                            )
                          }
                        })()}
                      </span>
                    </div>
                  </div>
                </div> */}
              </Link>
            </div>
				</div>
  );
};

export default NftCard;
