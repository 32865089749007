import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Textarea from "shared/Textarea/Textarea";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const { register, control, handleSubmit, formState: { errors }, getValues, watch } = useForm();

  const [cryptoStatus, setCryptoStatus] = useState<boolean | null>(null);

  const cryptoAddress: any = watch('cryptoAddress');

  console.log(errors);


  const checkCrypto = () => {
    if (cryptoStatus != null) {
      let match = (/^0x[a-fA-F0-9]{40}$/g).test(cryptoAddress);
      setCryptoStatus(match)
    }

  }

  useEffect(() => {
    checkCrypto();
  }, [cryptoAddress])



  const signUpWithCryptoAddress = () => {

    let match = (/^0x[a-fA-F0-9]{40}$/g).test(cryptoAddress);

    if (match) {
      showLoader();
      userApi.signup({
        "cryptoAddress": cryptoAddress,
        "isRegistrationUsingAddress": true
      }, (message: any, resp: any) => {
        hideLoader();
        console.log('SIGN UP =>', resp)
      }, (message: string) => {
        hideLoader();
        toast.error(message);
        //console.log(message);
      });
    } else {
      setCryptoStatus(match);
    }

  }


  const onSubmit = (data: any) => {
    showLoader();
    userApi.signup(data, (message: any, resp: any) => {
      hideLoader();
      console.log('SIGN UP =>', resp)
    }, (message: string) => {
      hideLoader();
      toast.error(message);
      //console.log(message);
    });
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">


          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
            {/* <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Crypto Address
              </span>
              <Input type="text" className="mt-1" {...register(`cryptoAddress`, { required: false, pattern: /^0x[a-fA-F0-9]{40}$/g })} />
              {cryptoStatus != null && !cryptoStatus && (
                <p className='text-red-400'>Ore baba</p>
              )}
            </label>

            <ButtonPrimary type="button" onClick={signUpWithCryptoAddress} disabled={cryptoAddress ? false : true}>Sign Up</ButtonPrimary>

            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                OR
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div> */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input type="text" className="mt-1" {...register(`firstName`, { required: true })} />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input type="text" className="mt-1" {...register(`lastName`, { required: true })} />
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input type="email" className="mt-1" {...register(`emailID`, { required: true })} />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Phone Number
              </span>
              <Input type="text" className="mt-1" {...register(`phoneNo`, { required: true })} />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" {...register(`password`, {
                required: true,
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                minLength: 8
              })} />
              {errors?.password ? <div className="invalid-feedback text-red-600">
                {errors.password.type === "required" ? 'Password shouldn\'t be blank' : null}
                {errors.password.type === "minLength" ? 'Password length must 8 character long.' : null}
                {errors.password.type === "pattern" ? 'Password must be at least one uppercase, lowercase, number and special character.' : null}
              </div> : null}
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input type="password" className="mt-1"  {...register(`confirmPassword`, {
                required: true,
                validate: (value: any) => {
                  let { password } = getValues();
                  return password === value || "Passwords should match!";
                }
              })} />
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Description
              </span>
              <Textarea className="mt-1" rows={6} {...register(`description`, { required: false })} />
            </label>

            <ButtonPrimary type="submit">Sign Up</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login" className="text-green-600">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
