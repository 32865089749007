import React, { useEffect, useId, useRef, useState } from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionGridFeatureNFT2 from "./SectionGridFeatureNFT2";
import SectionMagazine8 from "components/SectionMagazine8";
import SectionSliderCardNftVideo from "components/SectionSliderCardNftVideo";
import SectionSliderCollections2 from "components/SectionSliderCollections2";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useStoreSettingsSelector, useThemeSelector } from "_common/hooks/selectors/storeSelector";
import SiteHeader from "containers/SiteHeader";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "components/LikeButton";
import HomeFeatureCollections from "../components/homeFeatureCollections";
import HomeRecentCollections from "../components/homeRecentCollections";
import HomeRecentProducts from "../components/homeRecentProducts";
import { getCategories } from "app/sagas/productSaga";
import HomeActiveCategories from "../components/homeActiveCategories";
import nftPic from "images/nft-pic.png";
import HomeSectionHowitsworks from "../components/homeSectionHowitsworks";

function PageHome() {

  const productApi = useProductApi();
  const commonApi = useCommonApi();
  const collectionApi = useCollectionApi();
  const storeSettings = useStoreSettingsSelector();

  const { showLoader, hideLoader } = useAppLoader();
  const [homeSettings, setHomeSettings] = useState<any>(null);
  const [featureproducts, setFeatureproducts] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);
  const [recentCollections, setRecentCollections] = useState<any[]>([]);
  const [recentProducts, setRecentProducts] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);


  const getProducts = (type: string = '') => {
    const params: any = {
      usePaging: true,
      pageSize: 10,
      isActive: 1,
			isPublished: 1,
			isDeleted: 0
    };
    if (type === "featured") {
      params['isFeatured'] = 1;
    }
    if (type === "recent") {
      params['sortingBy'] = "sortingOrder";
      params['sortingOrder'] = "DESC";
    }
    productApi.getProducts(params, (message: string, resp: any) => {
      console.log(type + " Products", resp);
      if (resp.code === 200 && resp.successful === true) {
        if (type === "featured") {
          setFeatureproducts(resp.products);
        } else if (type === "recent") {
          setRecentProducts(resp.products);
        }
      }
    }, (message: string) => {
    });
  }

  const getCollections = (type: string = '') => {
    let params: any = {
      usePaging: true,
      pageSize: 4,
      pageNumber: 1,
      searchText: '',
      isPublished: 1
    };
    if (type === "featured") {
      params['isFetured'] = 1;
    }
    if (type === "recent") {
      params['sortingBy'] = "collectionID";
      params['sortingOrder'] = "DESC";
      params['pageSize'] = 4;
    }
    showLoader();
    collectionApi.getCollections(params, (message: string, resp: any) => {
      hideLoader();
      console.log("Featured Collection", resp);
      if (resp.code === 200 && resp.successful === true) {
        if (type === "featured") {
          setCollections(resp.collections);
        } else if (type === "recent") {
          setRecentCollections(resp.collections);
        }
        //setFeatureproducts(resp.products);
      }
    }, (message: string) => {
    });
  }

  const getCategories = () => {
    productApi.getCategories({
      isActive: 1,
      usePaging: true,
      pageSize: 8,
      pageNumber: 1
    }, (message: string, resp: any) => {
      if (resp.code === 200 && resp.successful === true) {
        setCategories(resp.categorydm)
      }
      console.log({ resp })
    }, (message: string) => {
      console.log(message);
    })
  }

  useEffect(() => {
    console.log("storeSettings", storeSettings);
    
    if (storeSettings.settings.HomeSettings.isStoreFeaturedProductsHome.value === "true") {
      getProducts('featured')
    }
    if (storeSettings.settings.HomeSettings.isShowFeaturedCollectionsHome.value === "true") {
      getCollections('featured');
    }
    if (storeSettings.settings.HomeSettings.isShowRecentCollectionsHome.value === "true") {
      getCollections('recent');
    }
    if (storeSettings.settings.HomeSettings.isShowBestsellerCollectionsHome.value === "true") {
      getProducts('recent')
    }
    getCategories();
    setHomeSettings(storeSettings.settings.HomeSettings);  

  }, [])

  return (
    <React.Fragment>
      <div className="nc-PageHome relative overflow-hidden">
        <Helmet>
          <title>Home (Normalize) Modern</title>
          <meta name="title" content={storeSettings.settings?.SEO?.Metatags?.TitleMetatag?.appValue} />
          <meta name="description" content={storeSettings.settings?.SEO?.Metatags?.DescriptionMetatag?.appValue} />
          <meta name="keywords" content={storeSettings.settings?.SEO?.Metatags?.KeywordMetatag?.appValue} />
        </Helmet>
        {/* GLASSMOPHIN */}
        <BgGlassmorphism />
        <div className="container relative mt-5 mb-6 sm:mb-24 lg:mt-20 lg:mb-32">
          {/* SECTION HERO */}
          <SectionHero2 sliderdata={homeSettings} />
          {/* SECTION 2 */}
          {/*<HomeSectionHowitsworks data={homeSettings} className={`mt-0 lg:mt-40 xl:mt-0`} />*/}
          <SectionHowItWork className="lg:mt-24 mt-6 xl:mt-48" />
        </div>

        {/* SECTION LAERGE SLIDER */}
        <div className="bg-neutral-100/70 dark:bg-black/20 lg:py-20 py-6">
          <div className="container">
            <SectionLargeSlider products={featureproducts} />
          </div>
        </div>

        <div className="container relative space-y-6 mb-6 lg:space-y-24 lg:mb-24">
          {/*container relative space-y-24 my-24 lg:space-y-32 lg:my-32*/}
          {/* SECTION 3 */}
          {collections?.length > 0 && (
            <HomeFeatureCollections collections={collections} settings={homeSettings} />
          )}


          {/* SECTION */}
          {recentCollections?.length > 0 && (
            <div className="relative py-6 lg:py-28">
              <BackgroundSection />
              <HomeRecentCollections collections={recentCollections} settings={homeSettings} />
              {/*<SectionGridAuthorBox
              sectionStyle="style2"
              data={Array.from("11111111")}
              boxCard="box4"
            />*/}
            </div>
          )}

          {/* SECTION 4 */}
          {recentProducts?.length > 0 && (
            <HomeRecentProducts products={recentProducts} settings={homeSettings} />
          )}
          {/*
          <SectionSliderCardNftVideo />
          */}
          {/* SECTION */}
          {/*
          <div className="relative py-20 lg:py-28">
            <BackgroundSection />
            <SectionSliderCollections2 cardStyle="style2" />
          </div>
          */}
          {/* SECTION */}

          {/*
          <SectionSubscribe2 />
          */}

          {/* SECTION */}
          {/*
          <div className="relative py-20 lg:py-28">
            <BackgroundSection className="bg-neutral-100/70 dark:bg-black/20 " />
            <SectionGridFeatureNFT2 />
          </div>
          */}

          {/* SECTION 1 */}

          {/*<SectionSliderCategories />*/}
          
      </div>
          <div className="bg-neutral-100/80 dark:bg-black/20 lg:py-20 py-6">
              <div className="container">
              {categories.length > 0 && (
                <HomeActiveCategories categories={categories} settings={homeSettings} />
              )}
          </div>
          </div>


      <div className="container pt-6 lg:pt-0">
          {/* SECTION */}
          <HomeSectionHowitsworks data={homeSettings} className={`mt-0 lg:mt-40 xl:mt-0`} />

          {/*homeSettings && homeSettings.isShowBottomHome.value === "true" && (
            <div className="nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center xl:mt-24 cstm-flex-col-reverse">
              <div className={`nc-SectionHowItWork`} data-nc-id="SectionHowItWork">
                <div dangerouslySetInnerHTML={{ __html: homeSettings?.StoreBottomTextHome?.value }}></div>
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-2/5">
                <NcImage src={nftPic} />
              </div>
            </div>
          )*/}

          {/* SECTION */}
          {/*
          <SectionVideos />
          */}
        </div>
      </div>
    </React.Fragment>
  );

}

export default PageHome;
