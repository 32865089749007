//import { getSubdomain } from './functions';
export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_NODE_BASE_URL = process.env.REACT_APP_API_NODE_URL;
export const API_NODE_API_KEY = process.env.REACT_APP_NODE_API_KEY;
export const CONTRACT_META_BASE_PATH = process.env.REACT_APP_NODE_URL;

export const CONTRACT_DEPLOY_NEW_TOKEN_URL = process.env.REACT_APP_ContractDeployNewTokenURL;
export const CONTRACT_DEPLOY_NEW_CONTRACT_URL = process.env.REACT_APP_ContractDeployNewContractURL;
export const CONTRACT_DEPLOY_ORDER_API_BASE_URL = process.env.REACT_APP_ContractDeployOrderAPIBaseUrl;
export const CONTRACT_DEPLOY_REDEEM_API_BASE_URL = process.env.REACT_APP_ContractDeployRedeemAPIBaseUrl;
export const CONTRACT_DEPLOY_PRESALE_API_BASE_URL = process.env.REACT_APP_ContractDeployPresaleAPIBaseUrl;

export const STORE_STORAGE = 'nft_store';
export const STORAGE = 'nft_store_user';
export const STORAGE_CART = 'nft_store_user_cart';
export const APP_URL = window.location.origin;

export const URLS = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  STORE: '/store',
  COLLECTIONS: '/collections',
  COLLECTION_DETAILS: '/collection/:id',
  PRODUCTS: '/products',
  PRODUCT_DETAILS: '/product/:id',
  PAGE: '/page/:id',
  CHECKOUT: '/checkout/:id',
  CART: '/cart',
  WELCOME: '/welcome',
  CATEGORIES: '/categories',
  ADD_UPDATE_CATEGORIES: '/category/:id',
  DASHBOARD: '/dashboard',
  TRAVELS: '/travels',
  TRAVEL_DETAILS: '/travels/:id',
  PRIVACY_POLICY: '/privacy-policy',
  NEW_TRAVEL: '/new-travel',
  ADD_RESIDENCE: '/add-residence',
  STUDENT_REGISTRATION: '/student-registration',
  ADD_ROOM: '/add-room',
  RESIDENCE_SUMMERY: '/residence-summery',
  DESCRIBE_ROOMS: '/describe-rooms',
  MORE_ABOUT_PROPERTY: '/more-about-property',
  UPLOAD_PROPERTY_IMAGES: '/upload-property-images',
  UPLOAD_PROPERTY_DOCUMENTS: '/upload-property-documents',
  CONNECT: '/connect',
  CONNECTED: '/connected',
  CONNECT_REQUEST: '/connect-request',
  CONNECTED_PROFILE: '/connected/:id',
  PROFILE: '/profile',
  CASES: '/cases',
  CASE_DETAILS: '/case/:id',
  ADVISORY: '/advisory',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_DETAILS: '/notification/:id',
  CREATE_CASE: '/create-case/:id',
  SUCCESS_PAGE: '/success',
  CANCEL_PAGE: '/cancel',
  NOTIFY_PAGE: '/notify',
  MAKE_PAYMENT: '/make-payment/:id',
  REGISTRATION_SUCCESSFUL: '/registration-successful',
  UPDATE_PROFILE: '/update-profile',
  OPEN_CASES: '/open-cases',
  OPEN_CASE_DETAILS: '/open-cases-details/:id',
  AUTHOR_DETAILS: "/author/:id",
  MY_PRODUCTS: "/my-products",
  MY_ORDERS: "/my-orders",
  ORDER_DETAILS: "/order/:id",
  GALLERY: "/gallery",
  CONNECT_WALLET: "/connect-wallet",
  MY_BASKET: "/my-basket",
  BASKET_CREATE: "/my-basket/create",
  BASKET_EDIT: "/my-basket/edit/:id",
  MY_GALLERY: "/my-gallery",
  GALLERY_CREATE: "/my-gallery/create",
  GALLERY_EDIT: "/my-gallery/edit/:id",
}

export const API_URL = {
  COMMON: {
    FILE_UPLOAD: `${API_BASE_URL}/FileUpload`,
    GET_BLOCKCHAIN_NETWORKS: `${API_BASE_URL}/BlockChainNetwork`,
    DELETE_LIKE_UNLIKE: `${API_BASE_URL}/LikeUnlike/{ID}`,
    POST_LIKE_UNLIKE: `${API_BASE_URL}/LikeUnlike`,
    GET_LIKE_UNLIKE: `${API_BASE_URL}/LikeUnlike`,
    GET_DATE_RANGE_TYPES: `${API_BASE_URL}/DateRange`,
    SAVE_CONTRACT_TXN: `${API_BASE_URL}/Transaction/SaveHash`,
    SAVE_CONTRACT_CREATE_UPDATE_TXN: `${API_BASE_URL}/Transaction/CreateUpdateTransactionHash`,
    FAVORITE: `${API_BASE_URL}/Favourite`,
    DELETE_REPORT: `${API_BASE_URL}/Report/{ID}`,
    POST_REPORT: `${API_BASE_URL}/Report`,
    GET_REPORT: `${API_BASE_URL}/Report/{ID}`,
    GET_REPORT_TYPE: `${API_BASE_URL}/ReportType`,
    GET_GLOBAL_SEARCH: `${API_BASE_URL}/GlobalSearch`,
    GET_SITE_SETTINGS: `${API_BASE_URL}/SiteSettings`,
    CONTACT_US: `${API_BASE_URL}/ContactUs`,
    ADD_VIEW: `${API_BASE_URL}/View`,
    COUNTRIES: `${API_BASE_URL}/Country`
  },
  BASKET: {
    API_BASKET: `${API_BASE_URL}/GalleryBasket`,
  },
  META_GALLERY: {
    API_META_GALLERY: `${API_BASE_URL}/MetaGallery`,
  },
  GALLERY: {
    INDUSTRY_LIST: `${API_BASE_URL}/GalleryIndustry`,
    LIST: `${API_BASE_URL}/MetaGallery`,
    ADD: `${API_BASE_URL}/MetaGallery`,
    EDIT: `${API_BASE_URL}/MetaGallery`,
    DELETE: `${API_BASE_URL}/MetaGallery`,
  },
  SITE_SETTINGS: {
    GET_SITE_SETTINGS: `${API_BASE_URL}/SiteSettings`,
    POST_SITE_SETTINGS: `${API_BASE_URL}/SiteSettings`,
    ADD_CUSTODIAL_WALLET: `${API_BASE_URL}/CustodialWallet`,
    FETCH_CUSTODIAL_WALLET: `${API_BASE_URL}/CustodialWallet`,
  },
  USER: {
    LOGIN: `${API_BASE_URL}/Customer/CustomerLogin`,
    SIGNUP: `${API_BASE_URL}/Customer/CustomerRegistration`,
    REFRESH_TOKEN: `${API_BASE_URL}/Refreshtoken`,
    ME: `${API_BASE_URL}details`,
    SOCIAL_LOGIN: `${API_BASE_URL}customer/social-login`,
    REGISTER: {
      STEP1: `https://prod-164.westeurope.logic.azure.com:443/workflows/df6a78bd31de47208a7f4508af78b82b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hyO70plLDoVzEw2FNzT-0BY4sMOos98KLBid9lFbVM0`,
      STEP2: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
      STEP3: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
      STEP4: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
    },
    FORGOT_PASSWORD: `${API_BASE_URL}/Customer/ForgotPassword`,
    RESET_PASSWORD: `${API_BASE_URL}/Customer/ValidateForgotPassword`,
    EDIT_PROFILE: `${API_BASE_URL}profile-update`,
    GET_USER_PROFILE: `${API_BASE_URL}/Customer/GetCustomer`,
    SAVE_PROFILE: `${API_BASE_URL}/Customer`,
    UPDATE_PASSWORD: `${API_BASE_URL}/Customer/ChangePasswod`,
    UPDATE_SOCIAL: `${API_BASE_URL}/Customer/Social`,
    CONNECT_WALLET: `${API_BASE_URL}/ConnectWallet`,
    GET_ADMIN_USER_PROFILE: `${API_BASE_URL}/Admin/UserDetails`,
  },
  PRODUCT: {
    GET_CATEGORIES: `${API_BASE_URL}/Category`,
    ADD_UPDATE_CATEGORY: `${API_BASE_URL}/Category`,
    DELETE_CATEGORY: `${API_BASE_URL}/Category`,
    GET: `${API_BASE_URL}/Product`,
    MAPPRODUCTPAYOUTGROUP: `${API_BASE_URL}/product/payoutgroup`,
    PUBLISH: `${API_BASE_URL}/product/publish`,
    FEATURE: `${API_BASE_URL}/product/feature`,
    DELETE: `${API_BASE_URL}/Product`,
    DELETE_REDEMPTION: `${API_BASE_URL}/Product/redeem/delete`,
    ASSIGN_REDEMPTION: `${API_BASE_URL}/Product/redeem`,
    LIST_REDEMPTION: `${API_BASE_URL}/Product/redeem`,
    DELETE_PREVIEW_IMAGE: `${API_BASE_URL}/FileUpload/ProductPreviewFileDelete`,
    ASSIGN_PREVIEW_PRODUCT: `${API_BASE_URL}/product/preview`,
    UPDATE_PRODUCT_PRICE: `${API_BASE_URL}/product/price`,
    PRODUCT_GROUP_LIST: `${API_BASE_URL}/ProductGroup`,
    PRODUCT_ADD: `${API_BASE_URL}/Product`,
    PRODUCT_UPDATE: `${API_BASE_URL}/Product`,
    GET_MY_PRODUCTS: `${API_BASE_URL}/NFT`,
    NFTS_GUEST: `${API_BASE_URL}/NFT`,
    GET_OWNER_BY_NFT: `${API_BASE_URL}/NFT/GetOwnerByNFT`,
    GET_UNLOCKABLE_CONTENT_NFT: `${API_BASE_URL}/NFT/UnlockableContent`,
    GET_UNLOCKABLE_CONTENT_PRODUCT: `${API_BASE_URL}/product/unlockablecontent`,
    TRANSFER_WITH_CUSTODIAL_WALLET: `${API_BASE_URL}/Transfer/TransferWithCustodialWallet`,
    TRANSFER_WITH_METAMASK: `${API_BASE_URL}/Transfer/TransferWithWallet`,
    DOWNLOADNFT: `${API_BASE_URL}/NFT/DownloadFile`
  },
  PAYOUT_GROUP: {
    GET_PAYOUT_GROUPS: `${API_BASE_URL}/PayoutGroup`,
    ADD_PAYOUT_GROUP: `${API_BASE_URL}/PayoutGroup`,
    UPDATE_PAYOUT_GROUP: `${API_BASE_URL}/PayoutGroup`,
  },
  COLLECTION: {
    GET_COLLECTION_TYPES: `${API_BASE_URL}/collection/collectiontype`,
    GET_COLLECTIONS: `${API_BASE_URL}/Collection`,
    ADD_UPDATE_DETAILS: `${API_BASE_URL}/Collection`,
    DELETE_COLLECTION: `${API_BASE_URL}/Collection`,
    UPDATE_SOCIALS: `${API_BASE_URL}/collection/social`,
    UPDATE_PRESALE: `${API_BASE_URL}/collection/presale`,
    UPDATE_GENESIS: `${API_BASE_URL}/collection/genesis`,
    ASSIGN_PAYOUT_GROUP: `${API_BASE_URL}/collection/payoutgroup`,
    GET_FOLLOW_UNFOLLOW: `${API_BASE_URL}/FollowUnfollow`,
    SAVE_FOLLOW: `${API_BASE_URL}/FollowUnfollow`,
    SAVE_UNFOLLOW: `${API_BASE_URL}/FollowUnfollow`,
    FETCH_CONTRACT_ABI: `${API_NODE_BASE_URL}/collection/initiate-contract`,
    DEPLOY_CONTRACT: `${API_BASE_URL}/collection/DeployContract`,
  },
  TRAVELS: {
    GET_TRAVELS: `https://prod-24.westeurope.logic.azure.com:443/workflows/5e9d284482974edd818a23cb2670ca79/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HZuu2lK2zqNLz4W8CtHZ8oA6ooK2yRr57pI6FzutwVY&travel=5aa3aacf-7666-ed11-9561-000d3a4bbc07`,
    STEP1: `https://prod-197.westeurope.logic.azure.com:443/workflows/4769ad4312c0450c94cccb9379813637/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2KUV2syX2FAy2_nNyMfZeSsHtr1sisPifokcpLBp8Ho`,
    STEP2: `https://prod-208.westeurope.logic.azure.com:443/workflows/381f431a20264ca2bc70a630074c5e08/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hC9Rfs0ZfTkmiP2HTXrmc_W_VWpsuzctNAdY74f6T_g`,
    STEP3: `https://prod-197.westeurope.logic.azure.com:443/workflows/4769ad4312c0450c94cccb9379813637/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2KUV2syX2FAy2_nNyMfZeSsHtr1sisPifokcpLBp8Ho`,
    STEP4: `https://prod-197.westeurope.logic.azure.com:443/workflows/4769ad4312c0450c94cccb9379813637/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2KUV2syX2FAy2_nNyMfZeSsHtr1sisPifokcpLBp8Ho`,
  },
  STORE: {
    CONFIG: `${API_BASE_URL}/StoreConfiguration`,
  },
  CUSTOMFIELD: {
    GET_TYPE: `${API_BASE_URL}/CustomFieldType`,
    SAVE: `${API_BASE_URL}/CustomField`,
    GET: `${API_BASE_URL}/CustomField`,
    DELETE: `${API_BASE_URL}/CustomField`
  },
  CMS: {
    GET_LIST: `${API_BASE_URL}/CMS`,
    DELETE_CMS: `${API_BASE_URL}/CMS`,
    ADD_CMS: `${API_BASE_URL}/CMS`,
    EDIT_CMS: `${API_BASE_URL}/CMS`,
  },
  CHECKOUT: {
    ADD_CARD: `${API_BASE_URL}/Stripe/AddCustomer`,
    GET_CARDS: `${API_BASE_URL}/Stripe/GetCardList`,
    VALIDATE_COUPON: `${API_BASE_URL}/Coupon/CouponByCouponCode`,
    BUY: `${API_BASE_URL}/Buy/BuyWithCoupon`,
    BUY_WITH_CARD_COUPON: `${API_BASE_URL}/Buy/BuyWithCardAndCoupon`,
    BUY_WITH_WALLET: `${API_BASE_URL}/Buy/BuyWithWallet`,
  },
  ORDERS: {
    MY_ORDER: `${API_BASE_URL}/Order/MyOrder`,
    ORDER_DETAILS: `${API_BASE_URL}/Order`,
    GET_ORDER_STATUSES: `${API_BASE_URL}/Order/OrderStatus`,
  },
}

export const ACTIONS = {
  THEME: {
    UPDATE: 'ACTIONS/THEME/UPDATE',
    UPDATE_STORE: 'ACTIONS/THEME/UPDATE_STORE',
  },
  CART: {
    UPDATE: 'ACTIONS/CART/UPDATE',
  },
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  SITE_SETTINGS: {
    GET_SITE_SETTINGS: 'ACTIONS/SITE_SETTINGS/GET_SITE_SETTINGS',
    POST_SITE_SETTINGS: 'ACTIONS/SITE_SETTINGS/POST_SITE_SETTINGS',
    ADD_CUSTODIAL_WALLET: 'ACTIONS/SITE_SETTINGS/ADD_CUSTODIAL_WALLET',
    FETCH_CUSTODIAL_WALLET: 'ACTIONS/SITE_SETTINGS/FETCH_CUSTODIAL_WALLET',
  },
  COMMON: {
    FILE_UPLOAD: 'ACTIONS/COMMON/FILE_UPLOAD',
    GET_DATE_RANGE_TYPES: 'ACTIONS/COMMON/GET_DATE_RANGE_TYPES',
    GET_BLOCKCHAIN_NETWORKS: 'ACTIONS/COMMON/GET_BLOCKCHAIN_NETWORKS',
    ORDER_STATUS: 'ACTIONS/COMMON/ORDER_STATUS',
    SETTINGS: 'ACTIONS/COMMON/SETTINGS',
    DELETE_LIKE_UNLIKE: 'ACTIONS/COMMON/DELETE_LIKE_UNLIKE',
    POST_LIKE_UNLIKE: 'ACTIONS/COMMON/POST_LIKE_UNLIKE',
    GET_LIKE_UNLIKE: 'ACTIONS/COMMON/GET_LIKE_UNLIKE',
    DELETE_REPORT: 'ACTIONS/COMMON/DELETE_REPORT',
    POST_REPORT: 'ACTIONS/COMMON/POST_REPORT',
    GET_REPORT: 'ACTIONS/COMMON/GET_REPORT',
    GET_REPORT_TYPE: 'ACTIONS/COMMON/GET_REPORT_TYPE',
    GET_GLOBAL_SEARCH: 'ACTIONS/COMMON/GET_GLOBAL_SEARCH',
    GET_SITE_SETTINGS: 'ACTIONS/COMMON/GET_SITE_SETTINGS',
    CONTACT_US: 'ACTIONS/COMMON/CONTACT_US',
    SAVE_CONTRACT_TXN: 'ACTIONS/COMMON/SAVE_CONTRACT_TXN',
    SAVE_CONTRACT_CREATE_UPDATE_TXN: 'ACTIONS/COMMON/SAVE_CONTRACT_CREATE_UPDATE_TXN',
    COUNTRIES: 'ACTIONS/COMMON/COUNTRIES'
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    SIGNUP: 'ACTIONS/USER/SIGNUP',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PRIVACY_POLICY: 'ACTIONS/USER/PRIVACY_POLICY',
    ME: 'ACTIONS/USER/ME',
    REGISTER: {
      STEP1: 'ACTIONS/USER/REGISTER/STEP1',
      STEP2: 'ACTIONS/USER/REGISTER/STEP2',
      STEP3: 'ACTIONS/USER/REGISTER/STEP3',
      STEP4: 'ACTIONS/USER/REGISTER/STEP4',
    },
    FORGOT_PASSWORD: 'ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'ACTIONS/USER/RESET_PASSWORD',
    EDIT_PROFILE: 'ACTIONS/USER/EDIT_PROFILE',
    GET_USER_PROFILE: 'ACTIONS/USER/GET_USER',
    SAVE_PROFILE: 'ACTIONS/USER/UPDATE_PROFILE',
    UPDATE_PASSWORD: 'ACTIONS/USER/UPDATE_PASSWORD',
    UPDATE_SOCIAL: 'ACTIONS/USER/UPDATE_SOCIAL',
    CONNECT_WALLET: 'ACTIONS/USER/CONNECT_WALLET',
    GET_ADMIN_USER_PROFILE: 'ACTIONS/USER/GET_ADMIN_USER_PROFILE',
  },
  FORGOT_PASSWORD: 'ACTIONS/USER/FORGOT_PASSWORD',
  RESET_PASSWORD: 'ACTIONS/USER/RESET_PASSWORD',
  EDIT_PROFILE: 'ACTIONS/USER/EDIT_PROFILE',
  PRODUCT: {
    GET_CATEGORIES: 'ACTIONS/PRODUCT/GET_CASES',
    ADD_UPDATE_CATEGORY: 'ACTIONS/PRODUCT/ADD_UPDATE_CATEGORY',
    DELETE_CATEGORY: 'ACTIONS/PRODUCT/DELETE_CATEGORY',
    GET: 'ACTIONS/PRODUCT/PRODUCT',
    MAPPRODUCTPAYOUTGROUP: 'SAGA_ACTIONS/PRODUCT/MAPPRODUCTPAYOUTGROUP',
    PUBLISH: 'ACTIONS/PRODUCT/PUBLISH',
    DELETE: 'ACTIONS/PRODUCT/DELETE',
    FEATURE: 'ACTIONS/PRODUCT/FEATURE',
    GET_MY_PRODUCTS: 'ACTIONS/PRODUCT/MY_PRODUCT',
    NFTS_GUEST: 'ACTIONS/PRODUCT/NFTS_GUEST',
    GET_MY_PRODUCTS_ACTUAL: 'ACTIONS/PRODUCT/GET_MY_PRODUCTS_ACTUAL',
    GET_OWNER_BY_NFT: 'ACTIONS/PRODUCT/GET_OWNER_BY_NFT',
    GET_UNLOCKABLE_CONTENT_NFT: 'ACTIONS/PRODUCT/GET_UNLOCKABLE_CONTENT_NFT',
    GET_UNLOCKABLE_CONTENT_PRODUCT: 'ACTIONS/PRODUCT/GET_UNLOCKABLE_CONTENT_PRODUCT',
    TRANSFER_WITH_CUSTODIAL_WALLET: 'ACTIONS/NFT/TRANSFER_WITH_CUSTODIAL_WALLET',
    TRANSFER_WITH_METAMASK: 'ACTIONS/NFT/TRANSFER_WITH_METAMASK',
    DOWNLOADNFT: 'ACTIONS/NFT/DOWNLOAD'
  },
  COLLECTION: {
    GET_COLLECTION_TYPES: 'ACTIONS/COLLECTION/GET_COLLECTION_TYPES',
    ADD_UPDATE_DETAILS: 'ACTIONS/COLLECTION/ADD_UPDATE_DETAILS',
    GET_COLLECTIONS: 'ACTIONS/COLLECTION/GET_COLLECTIONS',
    DELETE_COLLECTION: 'ACTIONS/COLLECTION/DELETE_COLLECTION',
    UPDATE_SOCIALS: 'ACTIONS/COLLECTION/UPDATE_SOCIALS',
    UPDATE_PRESALE: 'ACTIONS/COLLECTION/UPDATE_PRESALE',
    UPDATE_GENESIS: 'ACTIONS/COLLECTION/UPDATE_GENESIS',
    ASSIGN_PAYOUT_GROUP: 'ACTIONS/COLLECTION/ASSIGN_PAYOUT_GROUP',
    GET_MY_COLLECTIONS: 'ACTIONS/COLLECTION/GET_MY_COLLECTIONS',
    GET_FOLLOWUNFOLLOW_COLLECTION: 'ACTIONS/COLLECTION/GET_FOLLOWUNFOLLOW_COLLECTION',
    SAVE_FOLLOW: 'ACTIONS/COLLECTION/SAVE_FOLLOW',
    SAVE_UNFOLLOW: 'ACTIONS/COLLECTION/SAVE_UNFOLLOW',
    FETCH_CONTRACT_ABI: 'ACTIONS/COLLECTION/FETCH_CONTRACT_ABI',
    DEPLOY_CONTRACT: 'ACTIONS/COLLECTION/DEPLOY_CONTRACT',
  },
  PAYOUT_GROUP: {
    GET_PAYOUT_GROUPS: 'ACTIONS/PAYOUT_GROUP/GET_PAYOUT_GROUPS',
    ADD_PAYOUT_GROUP: 'ACTIONS/PAYOUT_GROUP/ADD_PAYOUT_GROUP',
    UPDATE_PAYOUT_GROUP: 'ACTIONS/PAYOUT_GROUP/UPDATE_PAYOUT_GROUP'
  },
  TRAVELS: {
    GET_TRAVELS: 'ACTIONS/TRAVELS/GET_TRAVELS',
    STEP1: 'ACTIONS/TRAVELS/STEP1',
    STEP2: 'ACTIONS/TRAVELS/STEP2',
    STEP3: 'ACTIONS/TRAVELS/STEP3',
    STEP4: 'ACTIONS/TRAVELS/STEP4',
  },
  STORE: {
    CONFIG: 'ACTIONS/STORE/CONFIGURATION',
    CONFIG_SAVE: 'ACTIONS/STORE/CONFIGURATION_SAVE',
    COMBINESTORE: 'ACTIONS/STORE/COMBINESTORE'
  },
  CUSTOMFIELD: {
    GET_TYPE: 'ACTIONS/CUSTOMFIELD/GET_TYPE',
    GET: 'ACTIONS/CUSTOMFIELD/GET',
    SAVE: 'ACTIONS/CUSTOMFIELD/SAVE',
    DELETE: 'ACTIONS/CUSTOMFIELD/DELETE'
  },
  CMS: {
    GET_LIST: 'ACTIONS/CMS/GET_LIST',
    DELETE_CMS: 'ACTIONS/CMS/DELETE_CMS',
    ADD_CMS: 'ACTIONS/CMS/ADD_CMS',
    EDIT_CMS: 'ACTIONS/CMS/EDIT_CMS',
  },
  CHECKOUT: {
    BUY_WITH_WALLET: 'ACTIONS/CHECKOUT/BUY_WITH_WALLET'
  },
  ORDERS: {
    MY_ORDER: 'ACTIONS/ORDERS/MY_ORDER',
  },
}

export const SAGA_ACTIONS = {
  COMMON: {
    FILE_UPLOAD: 'SAGA_ACTIONS/COMMON/FILE_UPLOAD',
    GET_BLOCKCHAIN_NETWORKS: 'SAGA_ACTIONS/COMMON/GET_BLOCKCHAIN_NETWORKS',
    DELETE_LIKE_UNLIKE: 'SAGA_ACTIONS/COMMON/DELETE_LIKE_UNLIKE',
    POST_LIKE_UNLIKE: 'SAGA_ACTIONS/COMMON/POST_LIKE_UNLIKE',
    GET_LIKE_UNLIKE: 'SAGA_ACTIONS/COMMON/GET_LIKE_UNLIKE',
    DELETE_REPORT: 'SAGA_ACTIONS/COMMON/DELETE_REPORT',
    POST_REPORT: 'SAGA_ACTIONS/COMMON/POST_REPORT',
    GET_REPORT: 'SAGA_ACTIONS/COMMON/GET_REPORT',
    GET_REPORT_TYPE: 'SAGA_ACTIONS/COMMON/GET_REPORT_TYPE',
    GET_FAVORITE: 'SAGA_ACTIONS/COMMON/GET_FAVORITE',
    POST_FAVORITE: 'SAGA_ACTIONS/COMMON/POST_FAVORITE',
    GET_GLOBAL_SEARCH: 'SAGA_ACTIONS/COMMON/GET_GLOBAL_SEARCH',
    GET_DATE_RANGE_TYPES: 'SAGA_ACTIONS/COMMON/GET_DATE_RANGE_TYPES',
    SAVE_CONTRACT_TXN: 'SAGA_ACTIONS/COMMON/SAVE_CONTRACT_TXN',
    SAVE_CONTRACT_CREATE_UPDATE_TXN: 'SAGA_ACTIONS/COMMON/SAVE_CONTRACT_CREATE_UPDATE_TXN',
    GET_SITE_SETTINGS: 'SAGA_ACTIONS/COMMON/GET_SITE_SETTINGS',
    CONTACT_US: 'SAGA_ACTIONS/COMMON/CONTACT_US',
    ADD_VIEW: 'SAGA_ACTIONS/COMMON/ADD_VIEW',
    COUNTRIES: 'SAGA_ACTIONS/COMMON/COUNTRIES'
  },
  BASKET:{
    GET_BASKET: 'SAGA_ACTIONS/BASKET/GET_BASKET',
    ADD_BASKET: 'SAGA_ACTIONS/BASKET/ADD_BASKET',
    EDIT_BASKET: 'SAGA_ACTIONS/BASKET/EDIT_BASKET',
    DELETE_BASKET: 'SAGA_ACTIONS/BASKET/DELETE_BASKET',
  },
  META_GALLERY:{
    GET_META_GALLERY: 'SAGA_ACTIONS/META_GALLERY/GET_META_GALLERY',
    ADD_META_GALLERY: 'SAGA_ACTIONS/META_GALLERY/ADD_META_GALLERY',
    DELETE_META_GALLERY: 'SAGA_ACTIONS/META_GALLERY/DELETE_META_GALLERY',
  },
  GALLERY: {
    INDUSTRY_LIST: `SAGA_ACTIONS/GALLERY/INDUSTRY_LIST`,
    LIST: 'SAGA_ACTIONS/GALLERY/LIST',
    ADD: 'SAGA_ACTIONS/GALLERY/ADD',
    EDIT: 'SAGA_ACTIONS/GALLERY/EDIT',
    DELETE: 'SAGA_ACTIONS/GALLERY/DELETE',
  },
  SITE_SETTINGS: {
    GET_SITE_SETTINGS: 'SAGA_ACTIONS/SITE_SETTINGS/GET_SITE_SETTINGS',
    POST_SITE_SETTINGS: 'SAGA_ACTIONS/SITE_SETTINGS/POST_SITE_SETTINGS',
    ADD_CUSTODIAL_WALLET: 'SAGA_ACTIONS/SITE_SETTINGS/ADD_CUSTODIAL_WALLET',
    FETCH_CUSTODIAL_WALLET: 'SAGA_ACTIONS/SITE_SETTINGS/FETCH_CUSTODIAL_WALLET',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGINSTEP1: 'SAGA_ACTIONS/USER/LOGINSTEP1',
    SIGNUP: 'SAGA_ACTIONS/USER/SIGNUP',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    PRIVACY_POLICY: 'SAGA_ACTIONS/USER/PRIVACY_POLICY',
    ME: 'SAGA_ACTIONS/USER/ME',
    REGISTER: {
      STEP1: 'SAGA_ACTIONS/USER/REGISTER/STEP1',
      STEP2: 'SAGA_ACTIONS/USER/REGISTER/STEP2',
      STEP3: 'SAGA_ACTIONS/USER/REGISTER/STEP3',
      STEP4: 'SAGA_ACTIONS/USER/REGISTER/STEP4',
    },
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
    EDIT_PROFILE: 'SAGA_ACTIONS/USER/EDIT_PROFILE',
    GET_USER_PROFILE: 'SAGA_ACTIONS/USER/GET_USER',
    SAVE_PROFILE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE',
    UPDATE_PASSWORD: 'SAGA_ACTIONS/USER/UPDATE_PASSWORD',
    UPDATE_SOCIAL: 'SAGA_ACTIONS/USER/UPDATE_SOCIAL',
    CONNECT_WALLET: 'SAGA_ACTIONS/USER/CONNECT_WALLET',
    GET_ADMIN_USER_PROFILE: 'SAGA_ACTIONS/USER/GET_ADMIN_USER_PROFILE',
  },
  PRODUCT: {
    GET_CATEGORIES: 'SAGA_ACTIONS/PRODUCT/GET_CASES',
    ADD_UPDATE_CATEGORY: 'SAGA_ACTIONS/PRODUCT/ADD_UPDATE_CATEGORY',
    DELETE_CATEGORY: 'SAGA_ACTIONS/PRODUCT/DELETE_CATEGORY',
    GET: 'SAGA_ACTIONS/PRODUCT/PRODUCT',
    MAPPRODUCTPAYOUTGROUP: 'SAGA_ACTIONS/PRODUCT/MAPPRODUCTPAYOUTGROUP',
    PUBLISH: 'SAGA_ACTIONS/PRODUCT/PUBLISH',
    DELETE: 'SAGA_ACTIONS/PRODUCT/DELETE',
    DELETE_REDEMPTION: 'SAGA_ACTIONS/PRODUCT/DELETE_REDEMPTION',
    DELETE_PREVIEW_IMAGE: 'SAGA_ACTIONS/PRODUCT/DELETE_PREVIEW_IMAGE',
    ASSIGN_PREVIEW_PRODUCT: 'SAGA_ACTIONS/PRODUCT/ASSIGN_PREVIEW_PRODUCT',
    LIST_REDEMPTION: 'SAGA_ACTIONS/PRODUCT/LIST_REDEMPTION',
    UPDATE_PRODUCT_PRICE: 'SAGA_ACTIONS/PRODUCT/UPDATE_PRODUCT_PRICE',
    ASSIGN_REDEMPTION: 'SAGA_ACTIONS/PRODUCT/ASSIGN_REDEMPTION',
    FEATURE: 'SAGA_ACTIONS/PRODUCT/FEATURE',
    PRODUCT_GROUP_LIST: 'SAGA_ACTIONS/PRODUCT/PRODUCT_GROUP_LIST',
    PRODUCT_ADD: 'SAGA_ACTIONS/PRODUCT/PRODUCT_ADD',
    PRODUCT_UPDATE: 'SAGA_ACTIONS/PRODUCT/PRODUCT_UPDATE',
    GET_MY_PRODUCTS: 'SAGA_ACTIONS/PRODUCT/MY_PRODUCT',
    NFTS_GUEST: 'SAGA_ACTIONS/PRODUCT/NFTS_GUEST',
    GET_MY_PRODUCTS_ACTUAL: 'SAGA_ACTIONS/PRODUCT/GET_MY_PRODUCTS_ACTUAL',
    GET_OWNER_BY_NFT: 'SAGA_ACTIONS/PRODUCT/GET_OWNER_BY_NFT',
    GET_UNLOCKABLE_CONTENT_NFT: 'SAGA_ACTIONS/PRODUCT/GET_UNLOCKABLE_CONTENT_NFT',
    GET_UNLOCKABLE_CONTENT_PRODUCT: 'SAGA_ACTIONS/PRODUCT/GET_UNLOCKABLE_CONTENT_PRODUCT',
    TRANSFER_WITH_CUSTODIAL_WALLET: 'SAGA_ACTIONS/NFT/TRANSFER_WITH_CUSTODIAL_WALLET',
    TRANSFER_WITH_METAMASK: 'SAGA_ACTIONS/NFT/TRANSFER_WITH_METAMASK',
    DOWNLOADNFT: 'SAGA_ACTIONS/NFT/DOWNLOAD',
  },
  CUSTOMFIELD: {
    GET_TYPE: 'SAGA_ACTIONS/CUSTOMFIELD/GET_TYPE',
    SAVE: 'SAGA_ACTIONS/CUSTOMFIELD/SAVE',
    GET: 'SAGA_ACTIONS/CUSTOMFIELD/GET',
    DELETE: 'SAGA_ACTIONS/CUSTOMFIELD/DELETE'
  },
  COLLECTION: {
    GET_COLLECTION_TYPES: 'SAGA_ACTIONS/COLLECTION/GET_COLLECTION_TYPES',
    ADD_UPDATE_DETAILS: 'SAGA_ACTIONS/COLLECTION/ADD_UPDATE_DETAILS',
    GET_COLLECTIONS: 'SAGA_ACTIONS/COLLECTION/GET_COLLECTIONS',
    DELETE_COLLECTION: 'SAGA_ACTIONS/COLLECTION/DELETE_COLLECTION',
    UPDATE_SOCIALS: 'SAGA_ACTIONS/COLLECTION/UPDATE_SOCIALS',
    UPDATE_PRESALE: 'SAGA_ACTIONS/COLLECTION/UPDATE_PRESALE',
    UPDATE_GENESIS: 'SAGA_ACTIONS/COLLECTION/UPDATE_GENESIS',
    ASSIGN_PAYOUT_GROUP: 'SAGA_ACTIONS/COLLECTION/ASSIGN_PAYOUT_GROUP',
    GET_MY_COLLECTIONS: 'SAGA_ACTIONS/COLLECTION/GET_MY_COLLECTIONS',
    GET_FOLLOWUNFOLLOW_COLLECTION: 'SAGA_ACTIONS/COLLECTION/GET_FOLLOWUNFOLLOW_COLLECTION',
    SAVE_FOLLOW: 'SAGA_ACTIONS/COLLECTION/SAVE_FOLLOW',
    SAVE_UNFOLLOW: 'SAGA_ACTIONS/COLLECTION/SAVE_UNFOLLOW',
    FETCH_CONTRACT_ABI: 'SAGA_ACTIONS/COLLECTION/FETCH_CONTRACT_ABI',
    DEPLOY_CONTRACT: 'SAGA_ACTIONS/COLLECTION/DEPLOY_CONTRACT',
  },
  PAYOUT_GROUP: {
    GET_PAYOUT_GROUPS: 'SAGA_ACTIONS/PAYOUT_GROUP/GET_PAYOUT_GROUPS',
    ADD_PAYOUT_GROUP: 'SAGA_ACTIONS/PAYOUT_GROUP/ADD_PAYOUT_GROUP',
    UPDATE_PAYOUT_GROUP: 'SAGA_ACTIONS/PAYOUT_GROUP/UPDATE_PAYOUT_GROUP'
  },
  TRAVELS: {
    GET_TRAVELS: 'ACTIONS/TRAVELS/GET_TRAVELS',
    STEP1: 'ACTIONS/TRAVELS/STEP1',
    STEP2: 'ACTIONS/TRAVELS/STEP2',
    STEP3: 'ACTIONS/TRAVELS/STEP3',
    STEP4: 'ACTIONS/TRAVELS/STEP4',
  },
  STORE: {
    CONFIG: 'SAGA_ACTIONS/STORE/CONFIGURATION',
    CONFIG_SAVE: 'SAGA_ACTIONS/STORE/CONFIGURATION_SAVE',
  },
  CMS: {
    GET_LIST: 'SAGA_ACTIONS/CMS/GET_LIST',
    DELETE_CMS: 'SAGA_ACTIONS/CMS/DELETE_CMS',
    ADD_CMS: 'SAGA_ACTIONS/CMS/ADD_CMS',
    EDIT_CMS: 'SAGA_ACTIONS/CMS/EDIT_CMS',
  },
  CHECKOUT: {
    VALIDATE_COUPON: 'SAGA_ACTIONS/CHECKOUT/VALIDATE_COUPON',
    BUY: 'SAGA_ACTIONS/CHECKOUT/BUY',
    BUY_WITH_WALLET: 'SAGA_ACTIONS/CHECKOUT/BUY_WITH_WALLET',
    ADD_CARD: 'SAGA_ACTIONS/CHECKOUT/ADD_CARD',
    GET_CARDS: 'SAGA_ACTIONS/CHECKOUT/GET_CARDS',
    BUY_WITH_CARD_COUPON: 'SAGA_ACTIONS/CHECKOUT/BUY_WITH_CARD_COUPON',
  },
  ORDERS: {
    MY_ORDER: 'SAGA_ACTIONS/ORDERS/MY_ORDER',
    ORDER_DETAILS: 'SAGA_ACTIONS/ORDERS/ORDER_DETAILS',
    ORDER_STATUSES: 'SAGA_ACTIONS/ORDERS/ORDER_STATUSES',
  },
}