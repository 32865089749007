import { type } from 'os';
import { ActionExtended } from '../../_common/interfaces/ActionExtended';
import { User } from '../../_common/interfaces/models/user';
import { ACTIONS } from '../../_config'

export interface StoreReducer {
  theme:any;
  domain:any;
  details:any;
  settings:any;
  token:any
}

const initialState: StoreReducer = {
  theme: null,
  domain:null,
  details:null,
  settings: null,
  token:null,
};

const storeReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.THEME.UPDATE:
      return {
        ...state,
        theme: action.payload.theme,
        domain: action.payload.domain,
      };
    case ACTIONS.THEME.UPDATE_STORE:
      return {
        ...state,
        details: action.payload.details,
        token: action.payload.token,
      };
    case ACTIONS.STORE.COMBINESTORE: 
      return {
        ...state,
        settings: action.payload.settings
      };
    
    default:
      return state;
  }
};

export default storeReducer;
