import React, { useState, useEffect, useMemo, useCallback, PropsWithChildren, createContext } from 'react'
// import { injectedConnector, walletconnect, walletlink, activateInjectedProvider } from "./wallet-connect";
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers'
import { toast } from 'react-hot-toast';
import Web3 from 'web3';

import { INFURA_KEY, METAMASK_KEYS, ConnectionConfig, CHAIN_ADD_CONFIG } from "../_constant";

interface window {
    ethereum: any
}

export const getLibrary = (provider: any): Web3Provider => {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}

export const MetaMaskContext = createContext<any>(null)

export const MetaMaskProvider = ({ children }: PropsWithChildren) => {

    const { activate, account, chainId, library, connector, active, deactivate } = useWeb3React<Web3Provider>()
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    // Init Loading
    /*useEffect(() => {
        connect().then(val => {
            setIsLoading(false)
        })
    }, [])*/

    useEffect(() => {
        console.log('account: ', account, ' chainId: ', chainId)
    }, [account, chainId])

    const handleIsActive = useCallback(() => {
        setIsActive(active)
    }, [active])

    useEffect(() => {
        handleIsActive()
    }, [handleIsActive])

    // Connect to MetaMask wallet
    const connect = async (type: string) => {
        if ( active ) {
            disconnect();
        }
        const _connectionConfig = ConnectionConfig[type]
        try {
            await activate(_connectionConfig)
        } catch(error) {
            console.log('Error on connecting: ', error)
        }
    }

    // Disconnect from Metamask wallet
    const disconnect = async () => {
        try {
            await deactivate()
        } catch(error) {
            console.log('Error on disconnecting: ', error)
        }
    }

    const addNetwork = async (chainId: number) => {
        // const { ethereum }: any = window;
        const chainParams = CHAIN_ADD_CONFIG[chainId as keyof typeof CHAIN_ADD_CONFIG]
        try {
            if ( connector ) {
                if ( chainParams ) {
                    const provider = await connector.getProvider();
                    await provider.request({
                        method: 'wallet_addEthereumChain', 
                        params: [chainParams]
                    })
                    console.log("You have switched to the right network")
                } else {
                    toast.error(`Error: Unsupported chian id ${chainId}`)
                }
            } else {
                toast.error('Connector is empty!')
            }

            /*if ( chainParams && ethereum ) {
                await ethereum.request({ method: 'wallet_addEthereumChain', params: [chainParams] });
            } else {
                toast.error(`Error: Unsupported chian id ${chainId}`)
            }*/
        } catch(err) {
            console.log('err: ', err)
            toast.error(`Error to add chian id ${chainId}`)
        }
    }

    const switchNetwork = async(chainId: number) => {
        try {
            console.log('chainId: ', chainId)
            if ( connector ) {
                const provider = await connector.getProvider();
                provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x"+Number(chainId).toString(16) }],
                })
            } else {
                toast.error('Connector is empty!')
            }

            /*const { ethereum }: any = window;
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: "0x"+Number(chainId).toString(16) }],
            })*/
            
        } catch (switchError: any) {
            console.log('switchError.code: ', switchError)
            // The network has not been added to MetaMask
            if ( switchError.code === -32000 ) {
                addNetwork(chainId)
            }
            else if (switchError.code === 4902) {
                console.log("Please add the Polygon network to MetaMask")
                addNetwork(chainId)
            } else {
                toast.error('Error to switch the network!')
            }
        }
    }

    const isConnected = async () => {
        try {
            const { ethereum }: any = window;
            const accounts = await ethereum.request({ method: 'eth_accounts' })
            if (accounts.length) {
                console.log(`You're connected to: ${accounts[0]}`);
            } else {
                console.log("Metamask is not connected");
            }
        } catch (error: any) {
            toast.error('Error to check network connection!')
        }
    }

    const getNetworkBalance = (account: string, rpcUrl: string): Promise<any> => {
        return new Promise<any>(async(resolve, reject) => {
            try {
                const web3 = new Web3(rpcUrl);
                const balance = await web3.eth.getBalance(account)
    
                return resolve(balance)
            } catch (error: any) {
                // toast.error('Error to check network connection!')
                return reject(error)
            }
        })
    }

    const authenticateWallet = async () => {
        try {
            const nonce = 'Are you sure to signin with nonce, 215487'
            //setIsLoading(true);
            /*const nonce = await getNonce(account);
            if (!nonce) {
                disconnectWallet();
                return;
            }*/
    
            var from = account;
            var params = [nonce, from];
            var method = "personal_sign";
            //   setToken('jwt_sdgfasvfwfkjhk454hkjbhsdkjf');
            //   setAddress('gsdfgdgdfgdfgdgdgdgd');
            const provider = await connector?.getProvider();
            const signResponse = await provider.request({ method, params, from });
            console.log('signResponse: ', signResponse)
            /*const verifyResponse = await http.POST("/v1/verify-signature", {
                publicAddress: from,
                signature: signResponse,
            });*/
        
            /*if (verifyResponse && verifyResponse.token) {
                setToken(verifyResponse.token);
                setAddress(verifyResponse.address);
            }*/
        } catch (e) {
          console.log("Error", e);
        //   disconnectWallet();
        } finally {
        //   setIsLoading(false);
        }
    };

    const values = useMemo(
        () => ({
            isActive,
            account,
            isLoading,
            chainId,
            library,
            connect,
            addNetwork,
            switchNetwork,
            authenticateWallet,
            isConnected,
            disconnect,
            getNetworkBalance
        }),
        [isActive, isLoading, account, chainId]
    )

    return (
        <MetaMaskContext.Provider value={values}>
            { children }
        </MetaMaskContext.Provider>
    );
}

export default function useMetaMask() {
    const context = React.useContext(MetaMaskContext)

    if (context === undefined) {
        throw new Error('useMetaMask hook must be used with a MetaMaskProvider component')
    }

    return context
}