import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCmsApi } from '_common/hooks/api/cmsApi'
import { useAppLoader } from '_common/hooks/common/appLoaderHook'
import { Helmet } from "react-helmet";
import NcImage from 'shared/NcImage/NcImage';
import parse from 'html-react-parser';

const Pages = () => {

    const cmsApi = useCmsApi()
    const { id } = useParams()
    const { showLoader, hideLoader } = useAppLoader()

    const [cmsDetails, setCmsDetails] = useState<any>(null)

    const getCmsDetails = () => {
        showLoader()
        cmsApi.getCmsList({ cmsid: id },
            (message: string, resp: any) => {
                console.log({ message, resp })
                if (resp.cmSdm && resp.cmSdm.length > 0) {
                    setCmsDetails(resp?.cmSdm[0])
                }
                hideLoader()
            },
            (message: string) => {
                console.log(message)
                hideLoader()
            })
    }

    useEffect(() => {
        if (id) {
            getCmsDetails()
        }
    }, [id])


    return (
        <div className={`nc-PageUploadItem`} data-nc-id="PageUploadItem">
            <Helmet>
                <title>{cmsDetails?.title}</title>
            </Helmet>
            <div className="w-full">
                <div className="relative w-full h-40 md:h-60 2xl:h-72">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src={cmsDetails?.bannerImageURL}
                        className="object-cover w-full h-full"
                    />
                </div>
                <div className="container">
                    
                    <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
                        <div className="max-w-2xl">
                            <h2 className="text-3xl sm:text-4xl font-semibold">
                                {cmsDetails?.title}
                            </h2>
                        </div>
                        <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
                        {cmsDetails?.body && parse(cmsDetails.body)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pages