import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "./ItemTypeImageIcon";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ClockIcon } from "@heroicons/react/outline";
import ItemTypeVideoIcon from "./ItemTypeVideoIcon";
import { HiOutlineEyeOff } from "react-icons/hi";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NftMoreDropdown from "components/NftMoreDropdown";

export interface CardNFTProps {
	className?: string;
	isLiked?: boolean;
	data: any;
	productBlurLimit?: number | null;
}

const MyProductNFT: FC<CardNFTProps> = ({ data, className = "", productBlurLimit = null, isLiked }) => {
	const navigate = useNavigate()
	const [showBlurFile, setShowBlurFile] = useState<boolean>(false)

	useEffect(() => {
		if (productBlurLimit != null && +productBlurLimit <= data['reportedSpamCount']) {
			setShowBlurFile(true);
		}
	}, [])

	const renderAvatars = () => {
		return (
			<div className="flex -space-x-1 ">
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
				<Avatar
					containerClassName="ring-2 ring-white dark:ring-neutral-900"
					sizeClass="h-5 w-5 text-sm"
				/>
			</div>
		);
	};

	const removeBlurEffect = () => {
		setShowBlurFile(false)
	}


	const getPrice = () => {
		if (data?.productPrice && data?.productPrice.length) {
			let activePrice = data.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice : 'NA'
		} else {
			return 'NA';
		}

	}


	return (
		<div
			className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] shadow-md ${className}`}
			data-nc-id="CardNFT"
		>
			<div className={"relative flex-shrink-0" + (showBlurFile ? ' spam-product-card' : '')}>
				<div>
					{
						showBlurFile && (
							<div className="spam-overlay spam-overlay-rounded-full">
								<HiOutlineEyeOff />
								<p className="text-sm mb-4">Inappropriate content.</p>
								<ButtonSecondary type="button" className="pt-1 pb-1 py-0" onClick={removeBlurEffect}>view</ButtonSecondary>
							</div>
						) || ''
					}
					<NcImage
						containerClassName="flex aspect-w-11 aspect-h-7 w-full h-0 rounded-3xl overflow-hidden z-0 rounded-b-none"
						src={data?.productThumbUrl}
						className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
					/>
				</div>
				{/* {Math.random() > 0.5 ? (
          <ItemTypeVideoIcon className="absolute top-3 left-3 !w-9 !h-9" />
        ) : (
          <ItemTypeImageIcon className="absolute top-3 left-3 !w-9 !h-9" />
        )} */}
				{/*   <LikeButton
          liked={isLiked}
          className="absolute top-3 right-3 z-10 !h-9"
        /> */}
				<div className="absolute top-3 left-0 w-full justify-between !h-9 flex ">
					<div className="absolute flex flex-row-reverse gap-2 justify-end right-2 sm:left-auto top-0">
						<NftMoreDropdown actions={[{
							id: "customer_fees_update",
							name: "Edit",
							icon: "las la-pen",
							callBack: () => {
								navigate(`/create-nft/${data?.productID}?tab=Details`)
							}
						}, {
							id: "customer_fees_view",
							name: "View",
							icon: "las la-eye",
							callBack: () => {
								navigate(`/product/${data?.productID}`)
							}
						}]}
							containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
						/>
					</div>
				</div>
			</div>
			<Link to={"/product/" + data?.productID} className="block">
				<div className="p-4 py-4 space-y-3">
					<div className="flex justify-between">
						<span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-gray-100  relative">
							{data?.collectionName}
						</span>
						{data?.isFeatured == 1 && (<span className="inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100  relative">Featured</span>)}

					</div>
					<h2 className={`text-lg font-medium mt-0`}>
						{data?.productName}
					</h2>

					{/* <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div> */}

					<div className="flex justify-between items-end ">

						<Prices network={data?.network} price={getPrice()} />
						{/* <div className="flex items-center text-sm text-neutral-500 dark:text-neutral-400">
            <ClockIcon className="w-4 h-4" />
            <span className="ml-1 mt-0.5">
              {Math.floor(Math.random() * 20) + 1} hours left
            </span>
          </div> */}
					</div>
				</div>

			</Link>
		</div>
	);
};

export default MyProductNFT;
