import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../../common/appApiCallHook';
import {
    BlankReq,
} from '../../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall();

    const fileUpload = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.FILE_UPLOAD, data, onSuccess, onError);
    }

    const getBlockchainNetworks = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_BLOCKCHAIN_NETWORKS, data, onSuccess, onError);
    }

    const getLikeUnlike = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_LIKE_UNLIKE, data, onSuccess, onError);
    }

    const postLikeUnlike = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_LIKE_UNLIKE, data, onSuccess, onError);
    }

    const getFavorite = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_FAVORITE, data, onSuccess, onError);
    }

    const postFavorite = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_FAVORITE, data, onSuccess, onError);
    }

    const deleteLikeUnlike = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_LIKE_UNLIKE, data, onSuccess, onError);
    }

    const getReport = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_REPORT, data, onSuccess, onError);
    }

    const postReport = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.POST_REPORT, data, onSuccess, onError);
    }

    const deleteReport = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.DELETE_REPORT, data, onSuccess, onError);
    }

    const getReportType = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_REPORT_TYPE, data, onSuccess, onError);
    }

    const getGlobalSearch = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_GLOBAL_SEARCH, data, onSuccess, onError);
    }

    const getSiteSettings = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_SITE_SETTINGS, data, onSuccess, onError);
    }


    const getDateRangeTypes = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.GET_DATE_RANGE_TYPES, data, onSuccess, onError);
    }

    const saveContractTxn = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_TXN, data, onSuccess, onError);
    }
    const addView = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.ADD_VIEW, data, onSuccess, onError);
    }

    const postContactUs = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.CONTACT_US, data, onSuccess, onError);
    }

    const saveContractDeployTxn = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.SAVE_CONTRACT_CREATE_UPDATE_TXN, data, onSuccess, onError);
    }

    const getCountries = (data: BlankReq, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.COUNTRIES, data, onSuccess, onError);
    }

    return {
        fileUpload: fileUpload,
        getBlockchainNetworks: getBlockchainNetworks,
        getLikeUnlike: getLikeUnlike,
        postLikeUnlike: postLikeUnlike,
        deleteLikeUnlike: deleteLikeUnlike,
        getReport: getReport,
        postReport: postReport,
        getFavorite: getFavorite,
        postFavorite: postFavorite,
        deleteReport: deleteReport,
        getReportType: getReportType,
        getGlobalSearch: getGlobalSearch,
        getDateRangeTypes, saveContractTxn,
        getSiteSettings: getSiteSettings,
        postContactUs: postContactUs,
        addView: addView,
        saveContractDeployTxn,
        getCountries: getCountries
    }
}