import CheckoutForm from "../../components/checkout/checkoutForm";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import VerifyIcon from "components/VerifyIcon";
import { nftsLargeImgs, personNames } from "contains/fakeData";
import TimeCountDown from "./TimeCountDown";
import TabDetail from "../NftDetailPage/TabDetail";
import collectionPng from "images/nfts/collection.png";
import ItemTypeVideoIcon from "components/ItemTypeVideoIcon";
import { Helmet } from "react-helmet";
import LikeButton from "../../components/LikeButton";
import FavoriteButton from "../../components/FavoriteButton";
import AccordionInfo from "./AccordionInfo";
import QuantityCounter from "../../components/checkout/QuantityCounter";
import NoUserImage from "images/avatars/Image-1.png";
import NoImage from "images/placeholder-small.png";
import { useProductApi } from "_common/hooks/api/productApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useParams } from "react-router-dom";
import NcShareDropDown from "shared/NcDropDown/NcShareDropDown";

import { BsBagCheck, BsCartPlus, BsCartX } from 'react-icons/bs'
import Prices from "../../components/Prices";

import { useAuthStatus } from "_common/hooks/authHook";
import NftMoreDropdown from "components/NftMoreDropdown";
import { store } from "app/store";
import { ACTIONS } from "_config";
import { useCartSelector } from "_common/hooks/selectors/cartSelector";
import { toast } from "react-hot-toast";
import { useCollectionApi } from "_common/hooks/api/collectionApiHook";
import { useSelector } from "react-redux";
import { StateExtended } from "_common/interfaces/StateExtended";
import { useUserApi } from "_common/hooks/api/UserApiHook";
import { Link } from "react-router-dom";
import { useStoreSettingsSelector } from "_common/hooks/selectors/storeSelector";

export interface NftDetailPageProps {
	className?: string;
	isPreviewMode?: boolean;
}
/*
const NftDetailPage: FC<NftDetailPageProps> = ({
  className = "",
  isPreviewMode,
})*/
const NftDetailPage: any = () => {
	const isAuth = useAuthStatus();
	const cart: any = useCartSelector();

	const { id } = useParams();
	const productApi = useProductApi();
	const userApi = useUserApi();
	const collectionApi = useCollectionApi();
	const { showLoader, hideLoader } = useAppLoader();

	const storeSettings = useStoreSettingsSelector();

	const [product, setProduct] = useState<any>(null);
	const [inCart, setInCart] = useState<boolean>(cart.filter((item: any) => item.productID == id).length > 0);

	const [moreoptions, setMoreoptions] = useState<any>([]);
	const [isfollowed, setIsfollowed] = React.useState(false);
	const [followdata, setFollowdata] = useState<any>(null);
	const [totalFollowers, setTotalFollowers] = useState<any>(0);
	const [collection, setCollection] = useState<any>(null);

	const [createdBy, setCreatedBy] = useState<any>(null);
	const [quantity, setQuantity] = useState<number>(1);

	const user: any = useSelector((state: StateExtended) => state.user);

	const getCollectionFollowUnFollow = () => {
		showLoader();
		collectionApi.getCollectionfollowunfollow({
			//id: '',
			itemType: 2,
			itemID: id,
			addedBy: (isAuth) ? user.user.userID : null,
			urlid: '',
			authrequired: isAuth
		}, (message: string, resp: any) => {
			hideLoader();
			if (resp.code === 200 && resp.successful === true && resp.followUnfollows.length > 0) {
				setIsfollowed(true);
				setFollowdata(resp.followUnfollows[0]);
				setTotalFollowers(resp.totalRecord);
				if (isAuth) {
					setMoreoptions([{
						id: "unfollow",
						name: "Unfollow",
						icon: "las la-times-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				} else {
					setMoreoptions([{
						id: "nofollow",
						name: resp.totalRecord + " Follower(s)",
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}]);
				}
			} else {
				setIsfollowed(false);
				setFollowdata(null);
				setTotalFollowers(0);
				if (isAuth) {
					setMoreoptions([{
						id: "follow",
						name: "Follow",
						icon: "las la-check-circle",
					}, {
						id: "report",
						name: "Report abuse",
						icon: "las la-flag",
					}])
				}
			}
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getProduct = () => {
		showLoader();
		productApi.getProducts({ productID: id }, (message: string, resp: any) => {
			hideLoader();
			setProduct(resp.products[0]);
			getCollectionFollowUnFollow();
		}, (message: string) => {
			hideLoader();
			console.log(message);
		})
	}

	const getPrice = () => {
		if (product.productPrice.length) {
			let activePrice = product.productPrice.filter((price: any) => price.isActive == 1);
			return activePrice.length ? activePrice[0].cryptoStartPrice * quantity : 'NA'
		} else {
			return 'NA';
		}
	}

	const addToCart = () => {
		let _cart: any = JSON.parse(JSON.stringify(cart));
		if (inCart) {
			let i: any = _cart.findIndex((item: any) => item.productID == id);
			_cart.splice(i, 1);
		} else {
			if (_cart.length == 0) {
				_cart.push(product)
			} else {
				let item: any = _cart[0];
				if (item.chainID == product.chainID && item.collectionID == product.collectionID) {
					_cart.push(product)
				} else {
					toast((t) => (
						<span>
							<p className="text-lg text-yellow-600">WARNING!</p>
							<p>Your cart contains different collection or network chain.</p>
							<p><b>Add Anyway</b> to remove all previous and new one or <b>Cancel</b></p>
							<br />
							<hr className="mb-3 mt-3" />
							<div className="align-middle flex justify-end">
								<ButtonPrimary className="bg-black m-1 sm:h-9"
									onClick={() => { toast.dismiss(t.id) }} >Cancel</ButtonPrimary>
								<ButtonPrimary className="bg-green-600 m-1 sm:h-9"
									onClick={() => {
										toast.dismiss(t.id);
										_cart = [product];
										setInCart(_cart.filter((item: any) => item.productID == id).length > 0);
										store.dispatch({
											type: ACTIONS.CART.UPDATE,
											payload: {
												items: _cart,
											}
										});
									}}
								>Add Anyway</ButtonPrimary>
							</div>
						</span>
					), { duration: 20000 });
				}
			}
		}

		setInCart(_cart.filter((item: any) => item.productID == id).length > 0);
		store.dispatch({
			type: ACTIONS.CART.UPDATE,
			payload: {
				items: _cart,
			}
		});
	}

	const applyFollowUnfollow = (value: number) => {
		if (followdata) {
			showLoader();
			collectionApi.saveUnfollow({
				itemType: 2,
				itemID: id,
				id: followdata.id
			}, (message: string, resp: any) => {
				hideLoader();
				getCollectionFollowUnFollow();
			}, (message: string) => {
				hideLoader();
				console.log(message);
			});
		} else {
			showLoader();
			collectionApi.saveFollow({
				itemType: 2,
				itemID: id,
				isFollow: value,
				walletAddress: '',
				browserObject: ''
			}, (message: string, resp: any) => {
				hideLoader();
				getCollectionFollowUnFollow();
			}, (message: string) => {
				hideLoader();
				console.log(message);
			});
		}
		/*
		
		*/
	}

	const getCreatedBy = () => {
		userApi.getUserProfile({ customerID: product.addedBy }, (message: string, resp: any) => {
			if (resp.customers.length) {
				setCreatedBy(resp.customers[0]);
			}
		}, (message: string) => {
			console.log(message);
		})
	}

	const getCollection = () => {
		collectionApi.getCollections({ collectionID: product.collectionID }, (message: string, resp: any) => {
			setCollection(resp.collections[0])
		}, (message: string) => {
			console.log(message);
		})
	}



	useEffect(() => {
		getProduct();
		setMoreoptions([
			{
				id: "report",
				name: "Report abuse",
				icon: "las la-flag",
			},
		]);
	}, []);

	useEffect(() => {
		if (product && createdBy == null) {
			getCreatedBy();
			getCollection();
		}
	}, [product]);



	return (
		<React.Fragment>
			<Helmet>
				<title>Checkout</title>
				<meta name="title" content={storeSettings.settings?.SEO?.Metatags?.TitleMetatag?.appValue} />
				<meta name="description" content={storeSettings.settings?.SEO?.Metatags?.DescriptionMetatag?.appValue} />
				<meta name="keywords" content={storeSettings.settings?.SEO?.Metatags?.KeywordMetatag?.appValue} />
			</Helmet>
			<div className={`nc-NftDetailPage`} data-nc-id="NftDetailPage">
				{/* MAIn */}
				<main className="container mt-11 flex pl-10 pr-10">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
						{/* CONTENT */}
						<div className="space-y-8 lg:space-y-10">
							{/* HEADING */}
							<div className="relative">
								<NcImage
									src={product?.productThumbUrl}
									containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
								/>
								{/* META TYPE */}
								{/* <ItemTypeVideoIcon className="absolute left-3 top-3  w-8 h-8 md:w-10 md:h-10" /> */}
								{isAuth && product && (<FavoriteButton className="absolute left-3 top-3 " type="product" _id={product?.productID} />)}

								{/* META FAVORITES */}
								{isAuth && product && (<LikeButton className="absolute right-3 top-3 " type="product" _id={product?.productID} />)}

							</div>




						</div>

						{/* SIDEBAR */}
						<div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
							<div className="divide-y divide-neutral-100 dark:divide-neutral-800">
								{/* ---------- 1 ----------  */}
								<div className="pb-9 space-y-5">
									<div className="flex justify-between items-center">
										<div>
											<Badge name={product?.categoryName} color="blue" />
										</div>
										<div className="flex"></div>

									</div>
									<div>
										<h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-3 flex gap-2 items-center">
											{product?.productName}
										</h2>
										{product?.isFeatured ? (<Badge name={"Featured"} color="green" />) : null}
									</div>

									{/* ---------- 4 ----------  */}
									<div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
										<div className="flex items-center ">
											<Avatar imgUrl={createdBy?.profileImageUrl ? createdBy?.profileImageUrl : NoUserImage} sizeClass="h-9 w-9" radius="rounded-full" />
											<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
												<span className="text-sm">Creator</span>
												<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
													{createdBy ?
														<Link to={`/author/${product.addedBy}`}><span>{`${createdBy.firstName} ${createdBy.lastName}`}</span></Link>
														:
														<span>Unknown</span>}

													{/* <VerifyIcon iconClass="w-4 h-4" /> */}
												</span>
											</span>
										</div>
										<div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
										<div className="flex items-center">
											<Avatar
												imgUrl={collection?.logoUrl ? collection?.logoUrl : NoImage}
												sizeClass="h-9 w-9"
												radius="rounded-full"
											/>
											<span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
												<span className="text-sm">Collection</span>
												<span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
													<Link to={`/collection/${product?.collectionID}`} className={`flex items-center`}><span>{product?.collectionName}</span> {collection?.isVerify ? <VerifyIcon iconClass="w-6 h-6" /> : null}</Link>

												</span>
											</span>
										</div>
									</div>
								</div>

								{/* ---------- 6 ----------  */}
								{/* <div className="py-9">
          <TimeCountDown />
        </div> */}

								{/* ---------- 7 ----------  */}
								{product ?
									<QuantityCounter size="lg" quantity={quantity} max={product.availabeQty} onChange={setQuantity} />
									: null}
								{/* PRICE */}
								<div className="pb-9 pt-6">
									<div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">


										{product && (
											<Prices network={product?.network} size="lg" price={getPrice()} />
										)}




										<span className="text-sm text-neutral-500 dark:text-neutral-400 ml-5 mt-2 sm:mt-0 sm:ml-10">
											[{product?.availabeQty} in stock]
										</span>
									</div>
								</div>

								{/* ---------- 9 ----------  */}
								{product && (
									<CheckoutForm products={[{ ...product, __cart_quantity: quantity }]} />
								)}


							</div>
						</div>
					</div>
				</main>

				{/* OTHER SECTION */}
				{/* {!isPreviewMode && (
          <div className="container py-24 lg:py-32">
       
            <div className="relative py-24 lg:py-28">
              <BackgroundSection />
              <SectionSliderCategories />
            </div>

           
            <SectionBecomeAnAuthor className="pt-24 lg:pt-32" />
          </div>
        )} */}
			</div>
		</React.Fragment>
	);
};

export default NftDetailPage;

