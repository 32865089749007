import React, { InputHTMLAttributes, useState } from "react";

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fontClass?: string;

}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(({
  className = "",
  children,
  label="",
  type = "radio",
  ...args
}, ref) => {
    return (
     
      <div className={`flex items-center text-sm sm:text-base ${className} `}>
        <input
        ref={ref}
        type={type}
        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded-full border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        {...args}
      />
     <label
        className="ml-2.5 sm:ml-3 block text-neutral-900 dark:text-neutral-100"
      >
        {label}      </label>
      </div>
      
    );
  }
);

export default Switch;
