import React from "react";
import ReactDOM from "react-dom/client";
//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from 'react-hot-toast';
import { Web3ReactProvider } from '@web3-react/core'
import AppLoader from './_common/pageLoader/appLoader';
import { MetaMaskProvider, getLibrary } from '../src/_common/provider/metamask-provider'
import { ACTIONS, STORAGE, STORE_STORAGE, STORAGE_CART } from "_config";
import ReactGA from 'react-ga';
import axios from "axios";
declare var google: any;

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const user: any = localStorage.getItem(STORAGE);
const cart = localStorage.getItem(STORAGE_CART);
if (cart) {
	store.dispatch({
		type: ACTIONS.CART.UPDATE,
		payload: {
			items: JSON.parse(cart),
		}
	});
}

function render() {
	root.render(
		<Web3ReactProvider getLibrary={getLibrary}>
			<MetaMaskProvider>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<AppLoader />
						<Toaster position="top-center" />
						<App />
					</PersistGate>
				</Provider>
			</MetaMaskProvider>
		</Web3ReactProvider>
	);
}

function renderbrokenpage() {
	root.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<div>
					<div className="text-center pt-24 bg-neutral-50" id="error" style={{ height: "100vh" }}>
						<svg height="100" width="100" style={{ margin: "0 auto" }}>
							<polygon points="50,25 17,80 82,80" strokeLinejoin="round" style={{ fill: "none", stroke: "#ff8a00", strokeWidth: 8 }}></polygon>
							<text x="42" y="74" fill="#ff8a00" fontFamily="sans-serif" fontWeight="900" fontSize="42px">!</text>
						</svg>
						<div className="row">
							<div className="col-md-12">
								<div className="main-icon text-warning"><span className="uxicon uxicon-alert"></span></div>
								<h1>Site not found (404 error)</h1>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-md-push-3">
								<p className="lead">If you think what you're looking for should be here, please contact the site owner.</p>
							</div>
						</div>
					</div>
				</div>
			</PersistGate>
		</Provider>
	);
}

function getStoreAuth(domain: any): Promise<any> {
	return new Promise((resolve, reject) => {
		try {
			axios.post(`${process.env.REACT_APP_API_URL}/APIAuthenticate/Info`, {
				domain: domain
			}).then(resp => {
				localStorage.setItem(STORE_STORAGE, resp.data.authenticateDataModel[0].responseToken);
				store.dispatch({
					type: ACTIONS.THEME.UPDATE_STORE,
					payload: {
						details: resp.data.authenticateDataModel[0].store[0],
						token: resp.data.authenticateDataModel[0].responseToken
					}
				});
				resolve(resp.data);
			}).catch(err => {
				return reject(null)
			})
		} catch (e) {
			return reject(null);
		}
	});
}


function getStoreSettingsCombine(): Promise<any> {
	const token = localStorage.getItem(STORE_STORAGE);
	return Promise.all([
		axios.get(`${process.env.REACT_APP_API_URL}/StoreConfiguration`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}),
		axios.get(`${process.env.REACT_APP_API_URL}/SiteSettings`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
	]);
}


const getHost = () => {
	if (window.location.hostname == 'localhost') {
		return 'v2devstore.nftwards.com'
		//return 'v2devstore-2.nftwards.com'
	} else {
		return window.location.hostname;
	}
}

const urlParams = new URLSearchParams(window.location.search);
const autoData:any= urlParams.get('auto');
if(autoData !== null){
	let data:any = autoData.replace(/\|/g,'Q')
	localStorage.setItem(STORAGE,atob(data));
	window.location.href = '/login'
}

try {
	if (user) {
		store.dispatch({
			type: ACTIONS.USER.LOGIN,
			payload: {
				user: JSON.parse(user),
				token: null
			}
		});
	}
	getStoreAuth(getHost()).then((_store: any) => {
		const storeinfo:any = {
			config: null,
			settings: null
		};

		store.dispatch({
			type: ACTIONS.THEME.UPDATE,
			payload: {
				theme: {
					name: _store.authenticateDataModel[0].store[0].themeName,
					index: _store.authenticateDataModel[0].store[0].themeIndex
				},
				domain: getHost()
			}
		});

		if (_store.storeConfiguration.code === 200 && Array.isArray(_store.storeConfiguration.storeConfiguration) && _store.storeConfiguration.storeConfiguration.length > 0 && _store.storeConfiguration.storeConfiguration[0]) {
			storeinfo.config = _store.storeConfiguration.storeConfiguration[0];
		}
		if (_store.siteSettings.code === 200 && Array.isArray(_store.siteSettings.siteSettings) && _store.siteSettings.siteSettings.length > 0) {
			const settings: any = {};
			for (var i = 0; i < _store.siteSettings.siteSettings.length; i++) {
				const tempsettings: any = {};
				for (var j = 0; j < _store.siteSettings.siteSettings[i].keyValue.length; j++) {
					_store.siteSettings.siteSettings[i].keyValue[j]['value'] = _store.siteSettings.siteSettings[i].keyValue[j].appValue;
					tempsettings[_store.siteSettings.siteSettings[i].keyValue[j].appKey] = _store.siteSettings.siteSettings[i].keyValue[j];
				}
				for (var k = 0; k < _store.siteSettings.siteSettings[i].childSiteGroupDetails.length; k++) {
					var levelone: any = {};
					for (var l = 0; l < _store.siteSettings.siteSettings[i].childSiteGroupDetails[k].keyValue.length; l++) {
						_store.siteSettings.siteSettings[i].childSiteGroupDetails[k].keyValue[l]['value'] = _store.siteSettings.siteSettings[i].childSiteGroupDetails[k].keyValue[l].appValue;
						levelone[_store.siteSettings.siteSettings[i].childSiteGroupDetails[k].keyValue[l].appKey] = _store.siteSettings.siteSettings[i].childSiteGroupDetails[k].keyValue[l];
					}
					tempsettings[(_store.siteSettings.siteSettings[i].childSiteGroupDetails[k].childSiteGroupName).replace(/ /g, '')] = levelone
				}
				settings[(_store.siteSettings.siteSettings[i].siteGroupName).replace(/ /g, '')] = tempsettings;
			}
			storeinfo.settings = settings;
		}
		store.dispatch({
			type: ACTIONS.STORE.COMBINESTORE,
			payload: {
				settings: storeinfo
			}
		});
		render();
		setTimeout(() => {
			new google.translate.TranslateElement({
				pageLanguage: 'en',
				autoDisplay: false
			}, 'google_translate_element');
			if (storeinfo.settings && storeinfo.settings.Analytics.AnalyticsGA4Analytics.appValue) {
				//console.log("GA Initialize", storeinfo.settings.Analytics.AnalyticsGA4Analytics.appValue);
				ReactGA.initialize(storeinfo.settings.Analytics.AnalyticsGA4Analytics.appValue);
				ReactGA.pageview(window.location.pathname + window.location.search);
			}
		}, 500);

		/*
		getStoreSettingsCombine().then((data: any) => {
			const storeinfo = {
				config: null,
				settings: null
			};
			if (data[0].status === 200 && data[0].data.code === 200 && Array.isArray(data[0].data.storeConfiguration) && data[0].data.storeConfiguration.length > 0 && data[0].data.storeConfiguration[0]) {
				storeinfo.config = data[0].data.storeConfiguration[0];
			}
			if (data[1].status === 200 && data[1].data.code === 200 && Array.isArray(data[1].data.siteSettings) && data[1].data.siteSettings.length > 0) {
				const settings: any = {};
				for (var i = 0; i < data[1].data.siteSettings.length; i++) {
					const tempsettings: any = {};
					for (var j = 0; j < data[1].data.siteSettings[i].keyValue.length; j++) {
						tempsettings[data[1].data.siteSettings[i].keyValue[j].appKey] = data[1].data.siteSettings[i].keyValue[j];
					}
					settings[(data[1].data.siteSettings[i].siteGroupName).replace(/ /g, '')] = tempsettings;
				}
				storeinfo.settings = settings;
			}
			store.dispatch({
				type: ACTIONS.STORE.COMBINESTORE,
				payload: {
					settings: storeinfo
				}
			});
			render();
		}).catch(() => {
			renderbrokenpage();
		});
		*/
	}).catch(() => {
		renderbrokenpage();
		//alert('SERVER ERROR')
	})
} catch {
	renderbrokenpage();
	//alert('SERVER ERROR')
}

reportWebVitals();
