import { ArrowRightIcon } from "@heroicons/react/outline";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import CardAuthorBox4 from "components/CardAuthorBox4/CardAuthorBox4";
import Heading from "components/Heading/Heading";
import NavItem2 from "components/NavItem2";
import VerifyIcon from "components/VerifyIcon";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Nav from "shared/Nav/Nav";
import NcImage from "shared/NcImage/NcImage";

export interface SectionGridAuthorBoxProps {
    className?: string;
    gridClassName?: string;
    collections?: any[];
    settings?: any;
}

const HomeRecentCollections: FC<SectionGridAuthorBoxProps> = ({
    className = "",
    gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
    collections = [],
    settings = null
}) => {
    return (
        <div className={`nc-SectionGridAuthorBox relative ${className}`}>
            <div>
                <Heading
                    className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
                    fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
                    isCenter
                    desc={`${settings && settings.StoreTrendingEventTextHome.value ? settings.StoreTrendingEventTextHome.value : ''}`}
                >
                    Recent Collections
                </Heading>
            </div>
            <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
                {collections.map((item: any, index: number) => {
                    return (
                        <div
                            key={'recentproduct-' + index}
                            className={`nc-CardAuthorBox4 relative flex flex-col overflow-hidden group bg-white dark:bg-neutral-800 group rounded-3xl hover:shadow-xl transition-shadow ${className}`}
                            data-nc-id="CardAuthorBox4"
                        >
                            <div className="relative flex-shrink-0 h-36">
                                {/*authorIndex && (
                                    <Badge
                                        className="absolute top-2 left-3 !font-semibold"
                                        name={
                                            authorIndex === 1 ? `🏆 #${authorIndex}` : `🏅 #${authorIndex}`
                                        }
                                        color={
                                            authorIndex === 1 ? "red" : authorIndex === 2 ? "green" : "yellow"
                                        }
                                    />
                                )*/}
                                <NcImage
                                    containerClassName="flex h-full w-full flex-shrink-0 rounded-3xl overflow-hidden"
                                    src={item.logoUrl}
                                />
                            </div>

                            <div className="pb-5 px-4 pt-1.5">
                                <div className="text-center relative flex items-center justify-center ">
                                    <div className="relative">
                                        <svg
                                            className="mx-auto h-14 -mt-[38px] text-white dark:text-neutral-800 dark:group-hover:text-neutral-800"
                                            width="134"
                                            height="54"
                                            viewBox="0 0 134 54"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M101.734 19.8581C99.2658 17.4194 96.9737 14.8065 94.5052 12.1935C94.1526 11.671 93.6237 11.3226 93.0947 10.8C92.7421 10.4516 92.5658 10.2774 92.2131 9.92903C85.6895 3.83226 76.6974 0 67 0C57.3026 0 48.3105 3.83226 41.6105 9.92903C41.2579 10.2774 41.0816 10.4516 40.7289 10.8C40.2 11.3226 39.8474 11.671 39.3184 12.1935C36.85 14.8065 34.5579 17.4194 32.0895 19.8581C23.2737 28.7419 11.4605 30.4839 -0.176331 30.8323V54H16.3974H32.0895H101.558H110.197H134V30.6581C122.363 30.3097 110.55 28.7419 101.734 19.8581Z"
                                                fill="currentColor"
                                            />
                                        </svg>

                                        <div className="absolute -top-7 left-1/2 -translate-x-1/2">
                                            <Avatar
                                                containerClassName=""
                                                sizeClass="w-12 h-12 text-2xl"
                                                radius="rounded-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2.5 flex items-start items-center justify-between">
                                    <div>
                                        <h2 className={`text-base font-medium flex items-center`}>
                                            <span className="">
                                                {item.collectionName}
                                            </span>
                                            {item.isVerify && <VerifyIcon /> }
                                        </h2>
                                        {/*
                                        <span className={`block mt-0.5 text-sm `}>
                                        <span className="font-medium">12.321</span>
                                        <span className={`ml-1.5 text-neutral-500 dark:text-neutral-400`}>
                                            ETH
                                        </span>
                                        </span>
                                        */}
                                    </div>
                                    {/*
                                    <FollowButton
                                        sizeClass="px-4 py-2 min-w-[84px]"
                                        isFollowing={following}
                                    />
                                    */}
                                </div>
                            </div>

                            <Link to={`/collection/${item.collectionID}`} className="absolute inset-0"></Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default HomeRecentCollections;