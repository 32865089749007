import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { useCommonApi } from "_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "_common/hooks/common/appLoaderHook";
import { useUserSelector } from "_common/hooks/selectors/userSelector";

export interface LikeButtonProps {
  className?: string;
  isAuth?: boolean;
  id?: string;
  type?: number;
  likeCount?: number;
  liked?: boolean;
  callBackFn?: any;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  className,
  id,
  type,
  isAuth,
  likeCount = 0,
  callBackFn,
}) => {

  const [isLiked, setIsLiked] = useState(false);

  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()
  const user: any  = useUserSelector()

  const likeClicked = (isLike: number) => {
    showLoader()
    commonApi.postLikeUnlike({
      "itemType": type,
      "itemID": id,
      "isLike": isLike
    },
      (message: string, resp: any) => {
        toast.success(message)
        hideLoader()
        getLikeUnlike()
        callBackFn()
      },
      (message: string) => {
        toast.error(message)
        hideLoader()
      })
  }

  const handleOnCLickLikeUnlike = () => {
    if (!isLiked) {
      if (id) {
        likeClicked(1)
        setIsLiked(!isLiked)
      }
    } else {
      if (id) {
        likeClicked(0)
        setIsLiked(!isLiked)
      }
    }
  }

  const getLikeUnlike = () => {
    showLoader()
    commonApi.getLikeUnlike({
      "itemType": type,
      "itemID": id,
			"addedBy": (isAuth) ? user?.userID: null,
			authrequired: isAuth
    }, (message: string, resp: any) => {
      // console.log(resp)
      if (resp?.likeUnlikes && resp?.likeUnlikes.length > 0) {
        setIsLiked(resp.likeUnlikes[0]?.likeDetails[0]?.isLiked)
      }
      hideLoader()
    },
      (message: string) => {
        console.log(message)
        hideLoader()
      })
  }

  useEffect(() => {
    if (id) {
      getLikeUnlike()
    }
  }, [id])

  return (
    <button
      className={`px-3.5 h-10 flex items-center justify-center px-0 rounded-full like-button ${className}`}
      onClick={isAuth ? handleOnCLickLikeUnlike : () => toast.error('please login to like')}
    >
      {isAuth && isLiked ?
        <AiFillLike color={'#9333ea'} size={30} />
        :
        <AiOutlineLike size={30} />
      }
      <span className="ml-2 text-sm like-count">{likeCount}</span>
    </button>
  );
};

export default LikeButton;
