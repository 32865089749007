import { AsyncThunkPayloadCreator } from "@reduxjs/toolkit";
import { getPriceFormat, getPriceIconUrl } from "_common/functions";
import { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";

interface props {
    product:any
}

const EditionListing:FC<props> = ({product})=>{
    const [list,setList] = useState<any[]| null>(null);

    const showCartPrice = (network:any,price: any) => {
		return (
			<div className="flex items-center">
				<NcImage
					className="object-contain"
					src={getPriceIconUrl(network)}
					containerClassName="nc-NcImage flex aspect-w-11 aspect-h-12 p-3 mr-2 h-0 rounded-3xl overflow-hidden z-0"
				/>
				<span className="font-medium text-md">{getPriceFormat(network,price)}</span>
			</div>
		)
	}

    useEffect(()=>{
        if(product && product.productEdition){
            let _list:any[] = []; 
            product.productEdition.map((edition:any)=>{
                if(edition.isEdition){
                    _list.push(edition)
                }
            });
            setList(_list);
        }
    },[product])

    return (
        <>
            {list?.length ?
                <div className="pb-9 pt-0">
                    {/* <p className="text-lg font-semibold">Edition Listing</p> */}
                    <div className="cstm-table-height cstm-scrollbar">
                        <table className="w-full table-fixed">
                            <thead>
                                <tr>
                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">OWNED BY</th>
                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">Price</th>
                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">ID</th>
                                    <th className="text-left dark:text-gray-100 text-gray-900 p-1">NUmber</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((edition:any, i:any)=>(
                                    <tr key={i} className="border-t">
                                    <td className="p-1 py-2">
                                    <p>{[edition.firstName,edition.lastName].join(' ')}</p>
                                    </td>
                                    <td className="p-1 py-2">
                                        <p>{showCartPrice(product.network,edition.cryptoPrice)}</p>
                                    </td>
                                    <td className="p-1 py-2">
                                    <p>{edition.editionId}</p>
                                    </td>
                                    <td className="p-1 py-2">
                                    <p>{edition.editionNumber}</p>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                : null}
        </>
    )
}


export default EditionListing;