import React, { FC, useEffect, useState } from "react";
import ButtonDropDownShare from "components/ButtonDropDownShare";
import NftMoreDropdown from "components/NftMoreDropdown";
import { FaLock } from "react-icons/fa";

interface Props {
  id:number;
  callViewContent: Function;
  clickable?:boolean;
}

const UnlockableContent:FC<Props> = ({id, callViewContent = () => {}, clickable = false}) => {
  return (
    <div className="flow-root">
      <div className={"-mx-3 -my-1.5 border dark:text-neutral-300 flex gap-2 items-center p-3 rounded-lg text-lg text-neutral-700 text-sm " + (clickable ? 'cursor-pointer' : '')} onClick={() => callViewContent()}>
        <FaLock /> <span className="font-medium text-sm">Unlockable Content</span>
      </div>
    </div>
  );
};

export default UnlockableContent;
