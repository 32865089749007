import React from 'react'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useAppLoader } from '_common/hooks/common/appLoaderHook'
import { useCommonApi } from '_common/hooks/api/common/commonApiHook'
import toast from 'react-hot-toast'
import { useStoreSettingsSelector } from '_common/hooks/selectors/storeSelector'
import SocialsList1 from 'shared/SocialsList1/SocialsList1'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { FiSmartphone } from 'react-icons/fi'
import { FaFax, FaRegAddressBook } from 'react-icons/fa'
import { BsGlobe } from 'react-icons/bs'

const ContactUs = () => {

    const { showLoader, hideLoader } = useAppLoader()
    const commonApi = useCommonApi()
    const settings = useStoreSettingsSelector()

    const { register, handleSubmit, formState: { errors } } = useForm()

    const handleKeyPress = (event: any) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const onSubmit = (data: any) => {
        // console.log({ data })
        showLoader()
        commonApi.postContactUs(data,
            (message: string, resp: any) => {
                toast.success(message)
                hideLoader()
            }, (message: string) => {
                toast.error(message)
                hideLoader()
            })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <div className="nc-PageContact overflow-hidden " data-nc-id="PageContact">
                <div className="mb-24 lg:mb-32">
                    <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Contact Us
                    </h2>
                    <div className="container max-w-7xl mx-auto">
                        <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
                            <div className="max-w-sm space-y-8">
                                {settings?.settings?.SocialMediaLink?.AddressSocial?.appValue && settings?.settings?.SocialMediaLink?.AddressSocial?.appValue.length > 0 ?
                                    <div>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider flex items-center gap-2">
                                            <FaRegAddressBook /> ADDRESS
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                            {settings?.settings?.SocialMediaLink?.AddressSocial?.appValue}
                                        </span>
                                    </div>
                                    : null
                                }
                                {settings?.settings?.SocialMediaLink?.EmailSocial?.appValue && settings?.settings?.SocialMediaLink?.EmailSocial?.appValue.length > 0 ?
                                    <div>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider flex items-center gap-2">
                                            <AiOutlineMail /> EMAIL
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                            <a href={`mailto:${settings?.settings?.SocialMediaLink?.EmailSocial?.appValue}`}>{settings?.settings?.SocialMediaLink?.EmailSocial?.appValue}</a>
                                        </span>
                                    </div>
                                    : null
                                }
                                {settings?.settings?.SocialMediaLink?.PhoneSocial?.appValue && settings?.settings?.SocialMediaLink?.PhoneSocial?.appValue.length > 0 ?
                                    <div>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider flex items-center gap-2">
                                            <AiOutlinePhone /> PHONE
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                            <a href={`tel:${settings?.settings?.SocialMediaLink?.PhoneSocial?.appValue}`}>{settings?.settings?.SocialMediaLink?.PhoneSocial?.appValue}</a>
                                        </span>
                                    </div>
                                    : null
                                }
                                {settings?.settings?.SocialMediaLink?.MobileSocial?.appValue && settings?.settings?.SocialMediaLink?.MobileSocial?.appValue.length > 0 ?
                                    <div>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider flex items-center gap-2">
                                            <FiSmartphone /> MOBILE
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                            <a href={`tel:${settings?.settings?.SocialMediaLink?.MobileSocial?.appValue}`}>{settings?.settings?.SocialMediaLink?.MobileSocial?.appValue}</a>
                                        </span>
                                    </div>
                                    : null
                                }
                                {settings?.settings?.SocialMediaLink?.FaxSocial?.appValue && settings?.settings?.SocialMediaLink?.FaxSocial?.appValue.length > 0 ?
                                    <div>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider flex items-center gap-2">
                                            <FaFax /> FAX
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                            <a href={`tel:${settings?.settings?.SocialMediaLink?.FaxSocial?.appValue}`}>{settings?.settings?.SocialMediaLink?.FaxSocial?.appValue}</a>
                                        </span>
                                    </div>
                                    : null
                                }
                                <div>
                                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider flex items-center gap-2">
                                        <BsGlobe /> SOCIALS
                                    </h3>
                                    <nav
                                        className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 mt-2"
                                        data-nc-id="SocialsList"
                                    >
                                        <SocialsList1 className="flex flex-wrap gap-5"
                                            socialsList={settings?.settings?.SocialMediaLink}
                                        />
                                    </nav>
                                </div>
                            </div>
                            <div>
                                <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
                                    <label className="block">
                                        <label
                                            className="nc-Label text-base font-medium text-neutral-900 dark:text-neutral-200 "
                                            data-nc-id="Label"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                            placeholder="Example Doe"
                                            {...register("name", { required: true })}
                                        />
                                        {errors?.name ? <div className="invalid-feedback text-red-600 text-sm ml-1">
                                            {errors.name.type === "required" ? 'Name shouldn\'t be blank.' : null}
                                        </div> : null}
                                    </label>
                                    <label className="block">
                                        <label
                                            className="nc-Label text-base font-medium text-neutral-900 dark:text-neutral-200 "
                                            data-nc-id="Label"
                                        >
                                            Phone No
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                            placeholder="9008803405"
                                            onKeyPress={(event: any) => handleKeyPress(event)}
                                            {...register("phoneNo", { required: true, minLength: 10, maxLength: 10 })}
                                        />
                                        {errors?.phoneNo ? <div className="invalid-feedback text-red-600 text-sm ml-1">
                                            {errors.phoneNo.type === "required" ? 'Phone No shouldn\'t be blank.' : null}
                                            {errors.phoneNo.type === "minLength" ? 'Phone no. should be 10 digit' : null}
                                            {errors.phoneNo.type === "maxLength" ? 'Phone no. should be 10 digit' : null}
                                        </div> : null}
                                    </label>
                                    <label className="block">
                                        <label
                                            className="nc-Label text-base font-medium text-neutral-900 dark:text-neutral-200 "
                                            data-nc-id="Label"
                                        >
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                            placeholder="example@example.com"
                                            {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                        />
                                        {errors?.email ? <div className="invalid-feedback text-red-600 text-sm ml-1">
                                            {errors.email.type === "required" ? 'Email shouldn\'t be blank.' : null}
                                            {errors.email.type === "pattern" ? 'Invalid email address.' : null}
                                        </div> : null}
                                    </label>
                                    <label className="block">
                                        <label
                                            className="nc-Label text-base font-medium text-neutral-900 dark:text-neutral-200 "
                                            data-nc-id="Label"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1"
                                            placeholder="subject"
                                            {...register("subject", { required: true })}
                                        />
                                        {errors?.subject ? <div className="invalid-feedback text-red-600 text-sm ml-1">
                                            {errors.subject.type === "required" ? 'Subject shouldn\'t be blank.' : null}
                                        </div> : null}
                                    </label>
                                    <label className="block">
                                        <label
                                            className="nc-Label text-base font-medium text-neutral-900 dark:text-neutral-200 "
                                            data-nc-id="Label"
                                        >
                                            Message
                                        </label>
                                        <textarea
                                            className="block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-1"
                                            rows={6}
                                            defaultValue={""}
                                            {...register("message", { required: true })}
                                        />
                                        {errors?.message ? <div className="invalid-feedback text-red-600 text-sm ml-1">
                                            {errors.message.type === "required" ? 'Message shouldn\'t be blank.' : null}
                                        </div> : null}
                                    </label>
                                    <div>
                                        <button
                                            className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
                                            type="submit"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default ContactUs