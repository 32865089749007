import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus, useChangePasswordHook } from '../_common/hooks/authHook';
import { URLS } from './index';
import { useStoreSettingsSelector } from '_common/hooks/selectors/storeSelector';
const FreeRoutes = () => {
    const isAuth = useAuthStatus();
    const changePassword = useChangePasswordHook();
    const settings = useStoreSettingsSelector();
    const published:boolean = !!settings.config.isPublished;


    console.log({isAuth,changePassword,settings,published})
    return (
        published?(isAuth?(changePassword?<Navigate to={URLS.RESET_PASSWORD} />:<Outlet/>):<Outlet/>):<Navigate to="/unpublished" />
    )
}

export default  FreeRoutes;