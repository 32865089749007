import React, { InputHTMLAttributes, useState } from "react";

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  fontClass?: string;
  subLabel?: string;

}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(({
  subLabel = "",
  className = "",
  children,
  label="",
  type = "checkbox",
  ...args
}, ref) => {
    const uniqueid = Date.now().toString(36) + Math.random().toString(36).substr(2);

    return (
     
      <div className={`flex text-sm sm:text-base ${className}`}>
        <input
        ref={ref}
        type={type}
        id={args?.id? args.id: uniqueid} 
        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        {...args}
      />
        {label && (
          <label htmlFor={args?.id? args.id: uniqueid} className="ml-2.5 sm:ml-3.5 flex flex-col flex-1 justify-center" >
            <span className={`text-neutral-900 dark:text-neutral-100 ${!!subLabel ? "-mt-0.5" : ""}`} >
              {label}
            </span>
            {subLabel && (
              <p className="mt-0.5 text-neutral-500 dark:text-neutral-400 text-sm font-light">
                {subLabel}
              </p>
            )}
          </label>
        )}
      </div>
      
    );
  }
);

export default Switch;
